import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import {
  createObjectiveSuccess,
  fetchObjectiveCategoriesListSuccess,
} from './actions';
import {
  CREATE_OBJECTIVE_REQUEST,
  FETCH_OOBJECTIVE_CATEGORIES_LIST_REQUEST,
} from './constants';
import { ENDPOINTS } from '../../constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import messages from './messages';
import { push } from 'connected-react-router';
import toasterVariants from '../../constants/toasterVariants';
import { OBJECTIVES } from 'routes';

export function* createObjectiveRequest({ objectiveData }) {
  try {
    const newObjective = yield call(request, {
      url: ENDPOINTS.OBJECTIVES,
      method: 'post',
      data: {
        categoryId: +objectiveData.objectiveCategory,
        title: objectiveData.title,
        description: objectiveData.campaignType,
        icon: 'online-attention',
        info: objectiveData.info,
      },
    });
    yield put(push(OBJECTIVES));
    yield put(createObjectiveSuccess(newObjective));
    yield put(
      enqueueSnackbar({
        message: messages.objectiveCreated,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* fetchObjectiveCategoriesRequest() {
  try {
    const data = yield call(request, {
      url: ENDPOINTS.CATEGORIES,
      method: 'get',
    });
    yield put(fetchObjectiveCategoriesListSuccess(data));
  } catch (error) {
    console.error(error);
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export default function* objectivePageSaga() {
  yield takeLatest(CREATE_OBJECTIVE_REQUEST, createObjectiveRequest);
  yield takeLatest(
    FETCH_OOBJECTIVE_CATEGORIES_LIST_REQUEST,
    fetchObjectiveCategoriesRequest
  );
}
