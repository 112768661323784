import React from 'react';
import CampaignTasks from '../../Task';
import PropTypes from 'prop-types';
import styles from './SingleCampaignTasks.module.scss';
import { useIntl } from 'react-intl';
import messages from './messages';
import { CAMPAIGN_TASKS_TYPES } from '../../../constants';

const SingleCampaignTasks = ({ tasks }) => {
  const { formatMessage } = useIntl();

  const preCampaignTasks = tasks
    ? tasks.filter((task) => task.type === CAMPAIGN_TASKS_TYPES.PRE_CAMPAIGN)
    : [];

  const liveCampaignTasks = tasks
    ? tasks.filter((task) => task.type === CAMPAIGN_TASKS_TYPES.LIVE_CAMPAIGN)
    : [];

  const followUpTasks = tasks
    ? tasks.filter((task) => task.type === CAMPAIGN_TASKS_TYPES.FOLLOW_UP)
    : [];

  return (
    <div className={styles.container}>
      {preCampaignTasks.length > 0 && (
        <CampaignTasks
          title={formatMessage(messages.preCampaign)}
          type="preCampaignTasks"
          tasks={preCampaignTasks}
        />
      )}
      {liveCampaignTasks.length > 0 && (
        <CampaignTasks
          title={formatMessage(messages.liveCampaign)}
          type="liveCampaignTasks"
          tasks={liveCampaignTasks}
        />
      )}
      {followUpTasks.length > 0 && (
        <CampaignTasks
          title={formatMessage(messages.followUp)}
          type="followUpTasks"
          tasks={followUpTasks}
        />
      )}
    </div>
  );
};

export default SingleCampaignTasks;

SingleCampaignTasks.propTypes = {
  tasks: PropTypes.array,
};
