import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import { deleteObjectiveSuccess, fetchObjectivesListSuccess } from './actions';
import {
  DELETE_OBJECTIVE_REQUEST,
  FETCH_OBJECTIVES_LIST_REQUEST,
} from './constants';
import { ENDPOINTS } from '../../constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import messages from './messages';
import toasterVariants from '../../constants/toasterVariants';

export function* fetchObjectivesListRequest() {
  try {
    const data = yield call(request, {
      url: ENDPOINTS.OBJECTIVES_ALL,
      method: 'get',
    });
    yield put(fetchObjectivesListSuccess(data));
  } catch (error) {
    console.error(error);
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* deleteObjectiveRequest({ id }) {
  try {
    yield call(request, {
      url: ENDPOINTS.OBJECTIVE_SINGLE.replace('{id}', id),
      method: 'delete',
    });
    yield put(
      enqueueSnackbar({
        message: messages.objectiveDeleted,
        variant: toasterVariants.success,
      })
    );
    yield put(deleteObjectiveSuccess(id));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
    console.error(error);
  }
}

export default function* objectivesListSaga() {
  yield takeLatest(FETCH_OBJECTIVES_LIST_REQUEST, fetchObjectivesListRequest);
  yield takeLatest(DELETE_OBJECTIVE_REQUEST, deleteObjectiveRequest);
}
