import {
  FETCH_BUSINESS_RULES_REQUEST,
  FETCH_BUSINESS_RULES_SUCCESS,
  UPDATE_BUSINESS_RULES,
} from './constants';

export function fetchBusinessRulesRequest() {
  return {
    type: FETCH_BUSINESS_RULES_REQUEST,
  };
}

export function fetchBusinessRulesSuccess(businessRules) {
  return {
    type: FETCH_BUSINESS_RULES_SUCCESS,
    businessRules,
  };
}

export function updateBusinessRules(businessRules) {
  return {
    type: UPDATE_BUSINESS_RULES,
    businessRules,
  };
}
