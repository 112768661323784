import { TRANSFER_REQUEST, TRANSFER_SUCCESS } from './constants';

export function transferRequest(payload) {
  return {
    type: TRANSFER_REQUEST,
    payload,
  };
}

export function transferSuccess(payload) {
  return {
    type: TRANSFER_SUCCESS,
    payload,
  };
}
