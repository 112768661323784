import produce from 'immer';
import {
  CREATE_ADMIN_ERROR,
  CREATE_ADMIN_REQUEST,
  CREATE_ADMIN_SUCCESS,
  DELETE_ADMIN_SUCCESS,
  EDIT_ADMIN_ERROR,
  EDIT_ADMIN_REQUEST,
  EDIT_ADMIN_SUCCESS,
  FETCH_ADMINS_ERROR,
  FETCH_ADMINS_REQUEST,
  FETCH_ADMINS_SUCCESS,
} from './constants';

export const initialState = {
  admins: {
    data: null,
    perPage: null,
    page: null,
    total: null,
    loading: false,
  },
  creatingAdmin: false,
  editingAdmin: false,
};

/* eslint-disable default-case */
const advertisersReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_ADMINS_REQUEST:
        draft.admins.loading = true;
        break;
      case FETCH_ADMINS_SUCCESS:
        draft.admins.loading = false;
        draft.admins.perPage = action.admins.perPage;
        draft.admins.page = action.admins.page;
        draft.admins.total = action.admins.total;
        draft.admins.data = action.admins.data;
        break;
      case FETCH_ADMINS_ERROR:
        draft.admins.loading = false;
        break;
      case CREATE_ADMIN_REQUEST:
        draft.creatingAdmin = true;
        break;
      case CREATE_ADMIN_SUCCESS:
        draft.creatingAdmin = false;
        draft.admins.total = draft.admins.total + 1;
        draft.admins.data = [action.admin, ...draft.admins.data];
        break;
      case CREATE_ADMIN_ERROR:
        draft.creatingAdmin = false;
        break;
      case DELETE_ADMIN_SUCCESS:
        draft.admins.total = draft.admins.total - 1;
        draft.admins.data = draft.admins.data.filter(
          (admin) => admin.id !== action.adminId
        );
        break;

      case EDIT_ADMIN_REQUEST:
        draft.editingAdmin = true;
        break;
      case EDIT_ADMIN_SUCCESS:
        draft.editingAdmin = false;
        draft.admins.data = draft.admins.data.map((admin) => {
          if (admin.id === action.admin.id) {
            return action.admin;
          } else {
            return admin;
          }
        });
        break;
      case EDIT_ADMIN_ERROR:
        draft.editingAdmin = false;
        break;
    }
  });

export default advertisersReducer;
