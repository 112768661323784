import { ReactComponent as PasswordHidden } from 'assets/images/password-hidden.svg';
import { ReactComponent as PasswordVisible } from 'assets/images/password-visible.svg';
import Icon from 'components/Icon';
import { ErrorMessage, Field } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Error from '../Error';
import styles from '../FormFieldStyles.module.scss';

const PasswordField = ({
  name,
  label,
  placeholder,
  required,
  minValue,
  maxValue,
  autoFocus,
  showPassword,
}) => {
  const { formatMessage } = useIntl();
  const [isContentHidden, setIsContentHidden] = useState(true);

  return (
    <div className={styles.field}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <Field
        type={isContentHidden ? 'password' : 'text'}
        placeholder={placeholder}
        name={name}
        required={required}
        autoFocus={autoFocus}
        className={styles.input}
      />
      {showPassword && (
        <button
          className={styles.eyeButton}
          type="button"
          onClick={() => setIsContentHidden(!isContentHidden)}
        >
          <Icon
            className={styles.inputEye}
            icon={isContentHidden ? PasswordHidden : PasswordVisible}
          />
        </button>
      )}
      <Error>
        <ErrorMessage name={name}>
          {(msg) =>
            formatMessage(msg, {
              label,
              minValue,
              maxValue,
            })
          }
        </ErrorMessage>
      </Error>
    </div>
  );
};

export default PasswordField;

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showPassword: PropTypes.bool,
  minValue: PropTypes.string,
  maxValue: PropTypes.string,
  autoFocus: PropTypes.bool,
};
