import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import { FETCH_AUDIT_LOGS_REQUEST } from './constants';
import { ENDPOINTS } from '../../constants';
import { format } from 'util';
import { fetchAuditLogsSuccess } from './actions';
import { enqueueSnackbar } from 'containers/Notifier/actions';

export function* fetchAuditLogsRequest({ page, perPage }) {
  try {
    const auditLogs = yield call(request, {
      url: format(ENDPOINTS.AUDIT_LOGS, page, perPage),
      method: 'get',
    });
    yield put(fetchAuditLogsSuccess(auditLogs));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export default function* auditLogsSaga() {
  yield takeLatest(FETCH_AUDIT_LOGS_REQUEST, fetchAuditLogsRequest);
}
