import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { format } from 'util';
import { ENDPOINTS } from '../../constants';
import {
  createAdminError,
  createAdminSuccess,
  deleteAdminSuccess,
  editAdminError,
  editAdminSuccess,
  fetchAdminsError,
  fetchAdminsSuccess,
} from './actions';
import {
  CREATE_ADMIN_REQUEST,
  DELETE_ADMIN_REQUEST,
  EDIT_ADMIN_REQUEST,
  FETCH_ADMINS_REQUEST,
} from './constants';
import messages from './messages';
import toasterVariants from 'constants/toasterVariants';

export function* fetchAdmins({ page, perPage }) {
  try {
    const admins = yield call(request, {
      url: format(ENDPOINTS.ADMINS, page, perPage),
      method: 'get',
    });
    yield put(fetchAdminsSuccess(admins));
  } catch (error) {
    yield put(fetchAdminsError());
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* createAdmin({ adminData, meta: { onSuccess } }) {
  try {
    const admin = yield call(request, {
      url: ENDPOINTS.CREATE_SINGLE_ADMIN,
      method: 'post',
      data: adminData,
    });
    yield put(createAdminSuccess(admin));
    onSuccess();
    yield put(
      enqueueSnackbar({
        message: messages.adminCreated,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );

    yield put(createAdminError());
  }
}

export function* deleteAdmin({ adminId }) {
  try {
    yield call(request, {
      url: ENDPOINTS.DELETE_ADMIN.replace('{adminId}', adminId),
      method: 'delete',
    });
    yield put(deleteAdminSuccess(adminId));
    yield put(
      enqueueSnackbar({
        message: messages.adminDeleted,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* editAdmin({ adminId, adminData, meta: { onSuccess } }) {
  try {
    const admin = yield call(request, {
      url: ENDPOINTS.EDIT_ADMIN.replace('{adminId}', adminId),
      method: 'put',
      data: adminData,
    });
    yield put(editAdminSuccess(admin));
    onSuccess();
    yield put(
      enqueueSnackbar({
        message: messages.adminEdited,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );

    yield put(editAdminError());
  }
}

export default function* adminsSaga() {
  yield takeLatest(FETCH_ADMINS_REQUEST, fetchAdmins);
  yield takeLatest(CREATE_ADMIN_REQUEST, createAdmin);
  yield takeLatest(DELETE_ADMIN_REQUEST, deleteAdmin);
  yield takeLatest(EDIT_ADMIN_REQUEST, editAdmin);
}
