import {
  CHANGE_USER_ROLE_REQUEST,
  CHANGE_USER_ROLE_SUCCESS,
  FETCH_SINGLE_CAMPAIGN,
  FETCH_SINGLE_CAMPAIGN_SUCESS,
  UPDATE_SINGLE_CAMPAIGN_REQUEST,
  UPDATE_SINGLE_CAMPAIGN_ERROR,
  UPDATE_SINGLE_CAMPAIGN_SUCCESS,
  CREATE_CAMPAIGN_SUPERVISOR_TASK_REQUEST,
  CREATE_CAMPAIGN_SUPERVISOR_TASK_SUCCESS,
  CREATE_CAMPAIGN_SUPERVISOR_TASK_ERROR,
  DELETE_CAMPAIGN_SUPERVISOR_TASK_REQUEST,
  DELETE_CAMPAIGN_SUPERVISOR_TASK_ERROR,
  DELETE_CAMPAIGN_SUPERVISOR_TASK_SUCCESS,
  REMOVE_JOINED_USER_FROM_CAMPAIGN,
  SUCCESSFULLY_REMOVED_USER_FROM_CAMPAIGN,
  APPROVE_CAMPAIGN_REQUEST,
  REQUEST_CHANGES_REQUEST,
  CANCEL_CAMPAIGN_REQUEST,
  SET_IS_CAMPAIGN_INTERNAL_REQUEST,
  SET_IS_CAMPAIGN_INTERNAL_SUCCESS,
  UPDATE_SINGLE_CAMPAIGN_END_DATE_REQUEST,
  UPDATE_SINGLE_CAMPAIGN_END_DATE_SUCCESS,
  UPDATE_SINGLE_CAMPAIGN_END_DATE_ERROR,
  DELETE_CAMPAIGN_GRADE_RANGE_REQUEST,
  DELETE_CAMPAIGN_GRADE_RANGE_SUCCESS,
  DELETE_CAMPAIGN_GRADE_RANGE_ERROR,
  SET_CAMPAIGN_GRADE_RANGE_REQUEST,
  SET_CAMPAIGN_GRADE_RANGE_SUCCESS,
  SET_CAMPAIGN_GRADE_RANGE_ERROR,
} from './constants';

export function fetchSingleCampaign(campaignId) {
  return {
    type: FETCH_SINGLE_CAMPAIGN,
    campaignId,
  };
}

export function fetchSingleCampaignSuccess(campaign) {
  return {
    type: FETCH_SINGLE_CAMPAIGN_SUCESS,
    campaign,
  };
}

export function updateSingleCampaignRequest(id, campaignData) {
  return {
    type: UPDATE_SINGLE_CAMPAIGN_REQUEST,
    id,
    campaignData,
  };
}

export function updateSingleCampaignSuccess(singleCampaign) {
  return {
    type: UPDATE_SINGLE_CAMPAIGN_SUCCESS,
    singleCampaign,
  };
}

export function updateSingleCampaignError() {
  return {
    type: UPDATE_SINGLE_CAMPAIGN_ERROR,
  };
}

export function updateSingleCampaignEndDateRequest(id, campaignData) {
  return {
    type: UPDATE_SINGLE_CAMPAIGN_END_DATE_REQUEST,
    id,
    campaignData,
  };
}

export function updateSingleCampaignEndDateSuccess(singleCampaign) {
  return {
    type: UPDATE_SINGLE_CAMPAIGN_END_DATE_SUCCESS,
    singleCampaign,
  };
}

export function updateSingleCampaignEndDateError() {
  return {
    type: UPDATE_SINGLE_CAMPAIGN_END_DATE_ERROR,
  };
}

export function createCampaignSupervisorTaskRequest(id, supervisorTask) {
  return {
    type: CREATE_CAMPAIGN_SUPERVISOR_TASK_REQUEST,
    id,
    supervisorTask,
  };
}

export function createCampaignSupervisorTaskSuccess(supervisorTask) {
  return {
    type: CREATE_CAMPAIGN_SUPERVISOR_TASK_SUCCESS,
    supervisorTask,
  };
}

export function createCampaignSupervisorTaskError() {
  return {
    type: CREATE_CAMPAIGN_SUPERVISOR_TASK_ERROR,
  };
}

export function deleteCampaignSupervisorTaskRequest(
  campaignId,
  supervisorTaskId
) {
  return {
    type: DELETE_CAMPAIGN_SUPERVISOR_TASK_REQUEST,
    campaignId,
    supervisorTaskId,
  };
}

export function deleteCampaignSupervisorTaskSuccess(supervisorTaskId) {
  return {
    type: DELETE_CAMPAIGN_SUPERVISOR_TASK_SUCCESS,
    supervisorTaskId,
  };
}

export function deleteCampaignSupervisorTaskError() {
  return {
    type: DELETE_CAMPAIGN_SUPERVISOR_TASK_ERROR,
  };
}

export function removeJoinedUserFromCampaign(campaignId, userId) {
  return {
    type: REMOVE_JOINED_USER_FROM_CAMPAIGN,
    campaignId,
    userId,
  };
}

export function successfullyRemovedUserFromCampaign(userId) {
  return {
    type: SUCCESSFULLY_REMOVED_USER_FROM_CAMPAIGN,
    userId,
  };
}

export function changeUserRoleRequest(campaignId, userId, isSupervisor) {
  return {
    type: CHANGE_USER_ROLE_REQUEST,
    campaignId,
    userId,
    isSupervisor,
  };
}

export function changeUserRoleSuccess(userId, isSupervisor) {
  return {
    type: CHANGE_USER_ROLE_SUCCESS,
    userId,
    isSupervisor,
  };
}

export function approveCampaign(campaignId) {
  return {
    type: APPROVE_CAMPAIGN_REQUEST,
    campaignId,
  };
}

export function requestChanges(campaignId, comment) {
  return {
    type: REQUEST_CHANGES_REQUEST,
    campaignId,
    comment,
  };
}

export function cancelCampaign(campaignId) {
  return {
    type: CANCEL_CAMPAIGN_REQUEST,
    campaignId,
  };
}

export function setIsCampaignInternalRequest(campaignId, isInternal) {
  return {
    type: SET_IS_CAMPAIGN_INTERNAL_REQUEST,
    campaignId,
    isInternal,
  };
}

export function setIsCampaignInternalSuccess(campaign) {
  return {
    type: SET_IS_CAMPAIGN_INTERNAL_SUCCESS,
    campaign,
  };
}

export function deleteCampaignGradeRangeRequest(campaignId, gradeId) {
  return {
    type: DELETE_CAMPAIGN_GRADE_RANGE_REQUEST,
    campaignId,
    gradeId,
  };
}

export function deleteCampaignGradeRangeRequestSuccess(campaignGradeId) {
  return {
    type: DELETE_CAMPAIGN_GRADE_RANGE_SUCCESS,
    campaignGradeId,
  };
}

export function deleteCampaignGradeRangeRequestError() {
  return {
    type: DELETE_CAMPAIGN_GRADE_RANGE_ERROR,
  };
}

export function setCampaignGradeRangeRequest(campaignId, gradeId) {
  return {
    type: SET_CAMPAIGN_GRADE_RANGE_REQUEST,
    campaignId,
    gradeId,
  };
}

export function setCampaignGradeRangeRequestSuccess(campaignGrade) {
  return {
    type: SET_CAMPAIGN_GRADE_RANGE_SUCCESS,
    campaignGrade,
  };
}

export function setCampaignGradeRangeRequestError() {
  return {
    type: SET_CAMPAIGN_GRADE_RANGE_ERROR,
  };
}
