import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectStaffUser = (state) => state.staffActivity || initialState;

const makeSelectStaffUsers = () =>
  createSelector(selectStaffUser, (substate) => substate.staffUsers);

const selectedStaffUserSelector = () =>
  createSelector(selectStaffUser, (substate) => substate.selectedStaffUser);

const getStaffActivityStatusSelector = () =>
  createSelector(
    selectStaffUser,
    (substate) => substate.getStaffActivityStatus
  );

const staffActivitySelector = () =>
  createSelector(selectStaffUser, (substate) => substate.staffActivity);

export {
  makeSelectStaffUsers,
  selectedStaffUserSelector,
  getStaffActivityStatusSelector,
  staffActivitySelector,
};
