import React, { Fragment, useEffect } from 'react';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuditLogsRequest } from './actions';
import reducer from './reducer';
import saga from './saga';
import { useIntl } from 'react-intl';
import DashboardLayout from 'components/Layout/DashboardLayout';
import ResourceTable from 'components/ResourceTable';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import { makeSelectAuditLogs } from './selectors';
import messages from './messages';
import { canActivate } from 'utils/permissions';
import { LIST_AUDIT_LOGS } from 'permisssions';
import { formatDate } from '../../utils/date';
import { DATE_FORMAT } from '../../constants';

const key = 'auditLogs';

function AuditLogsPage() {
  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const auditLogs = useSelector(makeSelectAuditLogs());
  const { data, perPage, total } = auditLogs || [];

  useEffect(() => {
    dispatch(fetchAuditLogsRequest(1, 25));
  }, [dispatch]);
  //
  // useEffect(() => {
  //   dispatch(fetchAuditLogsRequest(1, 25));
  // }, [dispatch]);

  const fetchPageAuditLogs = (pageNumber) => {
    dispatch(fetchAuditLogsRequest(pageNumber, perPage));
  };

  const tableData = data?.map((auditLog) => {
    return {
      entityId: auditLog.entityId,
      entityName: auditLog.entityName,
      tableName: auditLog.tableName,
      propertyName: auditLog.propertyName,
      propertyValue: auditLog.propertyValue,
      createdAt: auditLog.createdAt,
    };
  });

  const tableHeader = [
    { key: 'entityId', label: formatMessage(messages.entityId) },
    { key: 'entityName', label: formatMessage(messages.entityName) },
    { key: 'tableName', label: formatMessage(messages.tableName) },
    { key: 'propertyName', label: formatMessage(messages.propertyName) },
    { key: 'propertyValue', label: formatMessage(messages.propertyValue) },
    { key: 'createdAt', label: formatMessage(messages.createdAt) },
  ];

  const handlePerPage = (perPage) => {
    localStorage.setItem('perPage', perPage);
    localStorage.setItem('pageNumberUsers', 1);
    localStorage.setItem('pageNumberCampaigns', 1);
    localStorage.setItem('pageNumberAdvertisers', 1);
    dispatch(fetchAuditLogsRequest(1, perPage));
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardMainHeader title={formatMessage(messages.auditLogs)} />
        <ResourceTable
          rowsNumber={auditLogs ? auditLogs.length : 0}
          hasMore={auditLogs?.length < total}
          fetchPage={fetchPageAuditLogs}
          resource={auditLogs ? auditLogs : auditLogs.toString()}
          headerInformation={tableHeader}
          noDataContent={formatMessage(messages.noAuditLogs)}
          handleSort={() => {}}
          perPage={perPage ? perPage : ''}
          handlePerPage={handlePerPage}
          canAccess={canActivate(LIST_AUDIT_LOGS)}
        >
          {tableData?.map((el) => (
            <Fragment key={el.id}>
              {Object.entries(el).map(([propertyName, propertyValue], index) =>
                propertyName === 'id' ? null : propertyName === 'createdAt' ? (
                  <TableColumnField
                    key={index}
                    data={formatDate(
                      propertyValue,
                      DATE_FORMAT.MONTH_DAY_YEAR_TIME
                    )}
                  />
                ) : (
                  <TableColumnField key={index} data={propertyValue} />
                )
              )}
            </Fragment>
          ))}
        </ResourceTable>
      </DashboardLayout>
    </div>
  );
}

export default AuditLogsPage;
