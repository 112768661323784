import { ORDER } from '../../constants';
import {
  GET_STAFF_ACTIVITY_ATTEMPT,
  GET_STAFF_ACTIVITY_ERROR,
  GET_STAFF_ACTIVITY_RESET,
  GET_STAFF_ACTIVITY_SUCCESS,
  FETCH_STAFF_USERS_LIST_REQUEST,
  FETCH_STAFF_USERS_LIST_SUCCESS,
  SET_SELECTED_STAFF_USER,
} from './constants';

export function getStaffActivityAttempt(dateFrom, dateTo, staffUserId) {
  return {
    type: GET_STAFF_ACTIVITY_ATTEMPT,
    dateFrom,
    dateTo,
    staffUserId,
  };
}

export function getStaffActivitySuccess(staffActivity) {
  return {
    type: GET_STAFF_ACTIVITY_SUCCESS,
    payload: staffActivity,
  };
}

export function getStaffActivityError(error) {
  return {
    type: GET_STAFF_ACTIVITY_ERROR,
    payload: error,
  };
}

export function getStaffActivityReset() {
  return {
    type: GET_STAFF_ACTIVITY_RESET,
  };
}

export function fetchStaffUsersListRequest(
  page,
  perPage,
  search = '',
  sortBy = 'createdAt',
  order = ORDER.DESC
) {
  return {
    type: FETCH_STAFF_USERS_LIST_REQUEST,
    page,
    perPage,
    search,
    sortBy,
    order,
  };
}

export function fetchStaffUsersListSuccess(users, search, sortBy, order) {
  return {
    type: FETCH_STAFF_USERS_LIST_SUCCESS,
    users,
    search,
    sortBy,
    order,
  };
}

export function setSelectedStaffUser(user) {
  return {
    type: SET_SELECTED_STAFF_USER,
    user,
  };
}
