import { ORDER } from '../../constants';
import {
  FETCH_AI_PROMPT_LOGS_REQUEST,
  FETCH_AI_PROMPT_LOGS_SUCCESS,
} from './constants';

export function fetchAiPromptLogsRequest(page, perPage, search = '') {
  return {
    type: FETCH_AI_PROMPT_LOGS_REQUEST,
    page,
    perPage,
    search,
  };
}

export function fetchAiPromptLogsSuccess(logs, search) {
  return {
    type: FETCH_AI_PROMPT_LOGS_SUCCESS,
    logs,
    search,
  };
}
