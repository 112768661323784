import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import {
  CHANGE_PRIORITY_REQUEST,
  DELETE_STARTER_CAMPAING_REQUEST,
  FETCH_STARTER_CAMPAIGNS_REQUEST,
  UPLOAD_STARTER_REQUEST,
} from './constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';

import { ENDPOINTS } from '../../constants';
import toasterVariants from 'constants/toasterVariants';
import {
  changeCampaignPrioritySuccess,
  deleteStarterCampaignSuccess,
  fetchStarterCampaignsSuccess,
  uploadStarterSuccess,
} from './actions';
import { format } from 'util';

export function* uploadStarter({ payload }) {
  try {
    const formData = new FormData();
    for (const key of Object.keys(payload.files)) {
      formData.append('files', payload.files[key]);
    }

    formData.append('isPrivate', payload.isPrivate);
    formData.append('isEySpecial', payload.isEySpecial);
    formData.append('pointsRequired', payload.pointsRequired);
    formData.append('tourCampaignId', payload.tourCampaignId);

    const data = yield call(request, {
      url: ENDPOINTS.STARTER,
      method: 'post',
      data: formData,
    });
    yield put(
      enqueueSnackbar({
        message: 'Starter Campaign uploaded',
        variant: toasterVariants.success,
      })
    );
    yield put(uploadStarterSuccess(data));
    const campaigns = yield call(request, {
      url: format(ENDPOINTS.STARTER_CAMPIGNS, 1, 15, '', '', 'ASC'),
      method: 'get',
    });
    yield put(fetchStarterCampaignsSuccess(campaigns, '', '', 'ASC'));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* fetchStarterCampaigns({
  page,
  perPage,
  search,
  sortBy,
  order,
}) {
  try {
    const campaigns = yield call(request, {
      url: format(
        ENDPOINTS.STARTER_CAMPIGNS,
        page,
        perPage,
        search,
        sortBy,
        order
      ),
      method: 'get',
    });
    yield put(fetchStarterCampaignsSuccess(campaigns, search, sortBy, order));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* changeCampaignPriority({ campaign, priority }) {
  try {
    const newCampaign = yield call(request, {
      url: ENDPOINTS.CHANGE_PRIORITY.replace('{campaignId}', campaign.id),
      data: { priority: priority.value },
      method: 'put',
    });
    yield put(changeCampaignPrioritySuccess(newCampaign));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* deleteCampaign({ campaign }) {
  try {
    yield call(request, {
      url: ENDPOINTS.DELTE_STARTER_CAMPAIGN + '/' + campaign.id,
      method: 'delete',
    });
    yield put(deleteStarterCampaignSuccess(campaign));
  } catch (err) {}
}

export default function* starterSaga() {
  yield takeLatest(UPLOAD_STARTER_REQUEST, uploadStarter);
  yield takeLatest(FETCH_STARTER_CAMPAIGNS_REQUEST, fetchStarterCampaigns);
  yield takeLatest(CHANGE_PRIORITY_REQUEST, changeCampaignPriority);
  yield takeLatest(DELETE_STARTER_CAMPAING_REQUEST, deleteCampaign);
}
