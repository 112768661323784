import axios from 'axios';
import config from 'config';
import { sessionExpired, setToken } from 'containers/App/actions';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import jwtDecode from 'jwt-decode';
import messages from 'messages';
import { call, put } from 'redux-saga/effects';
import { getItem, removeItem, setItem } from 'utils/localStorage';
import { ENDPOINTS } from '../constants';
import { EYCROWD_ACCESS_TOKEN, EYCROWD_REFRESH_TOKEN } from './constants';

export const api = axios.create({
  baseURL: config.api.baseUrl,
  headers: {
    Version: 0.1,
  },
});

api.interceptors.response.use(
  (response) => response.data,
  (error) => {
    return Promise.reject(error.response);
  }
);

export const getApi = () => {
  return api;
};

export default function* request({ url, method, data, headers = {} }) {
  try {
    let token = yield call(getItem, EYCROWD_ACCESS_TOKEN);
    if (token) {
      if (Date.now() / 1000 >= jwtDecode(token).exp) {
        token = yield call(refreshToken);
      }
      headers.Authorization = `Bearer ${token}`;
    }
  } catch (error) {
    if (error.status === 500) {
      yield call(removeItem, EYCROWD_ACCESS_TOKEN);
      yield call(removeItem, EYCROWD_REFRESH_TOKEN);
      yield put(sessionExpired());
      yield put(
        enqueueSnackbar({
          message: messages.sessionExpired,
        })
      );
    }
    throw error;
  }
  return yield call(api, { method, url, headers, data });
}

export function* refreshToken() {
  const refToken = yield call(getItem, EYCROWD_REFRESH_TOKEN);

  const { accessToken: token, refreshToken: newRefToken } = yield call(api, {
    url: ENDPOINTS.AUTH_REFRESH_TOKEN,
    method: 'post',
    data: {
      refreshToken: refToken,
    },
  });

  yield call(setItem, EYCROWD_ACCESS_TOKEN, token);
  yield call(setItem, EYCROWD_REFRESH_TOKEN, newRefToken);

  yield put(setToken(token));

  return token;
}
