import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import { FETCH_AI_PROMPT_LOGS_REQUEST } from './constants';
import { ENDPOINTS } from '../../constants';
import { format } from 'util';
import { fetchAiPromptLogsSuccess } from './actions';
import { enqueueSnackbar } from 'containers/Notifier/actions';

export function* fetchAiPromptLogsRequest({ page, perPage, search }) {
  try {
    const logs = yield call(request, {
      url: format(ENDPOINTS.AI_PROMPT_LOGS, page, perPage, search),
      method: 'get',
    });

    yield put(fetchAiPromptLogsSuccess(logs, search));
  } catch (error) {
    console.log(error);
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export default function* aiPromptLogsSaga() {
  yield takeLatest(FETCH_AI_PROMPT_LOGS_REQUEST, fetchAiPromptLogsRequest);
}
