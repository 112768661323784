import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectBulkCampaign = (state) => state.bulkCampaignsPage || initialState;

const makeSelectCreateBrandStorySummaryPending = () =>
  createSelector(
    selectBulkCampaign,
    (substate) => substate.createBrandSummaryPending
  );

const makeSelectBrandSummary = () =>
  createSelector(selectBulkCampaign, (substate) => substate.brandSummary);

const makeSelectSocialMediaUrls = () =>
  createSelector(selectBulkCampaign, (substate) => substate.socialMediaUrls);

const makeSelectBrandName = () =>
  createSelector(selectBulkCampaign, (substate) => substate.brandName);

const makeSelectIndustry = () =>
  createSelector(selectBulkCampaign, (substate) => substate.industry);

const makeSelectAddress = () =>
  createSelector(selectBulkCampaign, (substate) => substate.address);

const makeSelectCampaignsSuggestionsPending = () =>
  createSelector(
    selectBulkCampaign,
    (substate) => substate.createCampaignSuggestionsPending
  );

const makeSelectPlatformActions = () =>
  createSelector(selectBulkCampaign, (substate) => substate.platformActions);

const makeSelectCampaignSuggestions = () =>
  createSelector(
    selectBulkCampaign,
    (substate) => substate.campaignSuggestions
  );

const makeSelectSelectedTasks = () =>
  createSelector(selectBulkCampaign, (substate) => substate.selectedTasks);

const makeSelectBulkCreateCampaignsPending = () =>
  createSelector(
    selectBulkCampaign,
    (substate) => substate.bulkCreateCampaignsPending
  );

export {
  makeSelectCreateBrandStorySummaryPending,
  makeSelectBrandSummary,
  makeSelectSocialMediaUrls,
  makeSelectBrandName,
  makeSelectIndustry,
  makeSelectAddress,
  makeSelectCampaignsSuggestionsPending,
  makeSelectPlatformActions,
  makeSelectCampaignSuggestions,
  makeSelectSelectedTasks,
  makeSelectBulkCreateCampaignsPending,
};
