import React from 'react';
import styles from './PaginationBar.module.scss';
import classNames from 'classnames';

const PaginationBarPresentation = (props) => {
  const {
    loadPreviousPage,
    loadNextPage,
    page,
    renderPageNumbers,
    visiblePageNumbers,
    loadPage,
    totalPages,
  } = props;

  const aFirstStyle =
    page === 1
      ? styles.paginationFooterBtnSelected
      : styles.paginationFooterBtn;

  const aLastStyle =
    page === totalPages
      ? styles.paginationFooterBtnSelected
      : styles.paginationFooterBtn;

  return (
    <div className={styles.paginationFooter}>
      <div className={classNames({ [styles.controlBtnLeft]: page > 1 })}>
        <a
          className={styles.buttonControl}
          onClick={loadPreviousPage}
          href={''}
        >
          <span>Previous</span>
        </a>
      </div>
      <div className={styles.numberWrap}>
        <a
          className={aFirstStyle}
          key={1}
          onClick={() => loadPage(1)}
          href={''}
        >
          <span>1</span>
        </a>
      </div>
      {totalPages !== 1
        ? renderPageNumbers(visiblePageNumbers, page, totalPages)
        : null}
      {totalPages !== 1 ? (
        <div className={styles.numberWrap}>
          <a
            className={aLastStyle}
            key={totalPages}
            onClick={() => loadPage(totalPages)}
            href={''}
          >
            <span>{totalPages}</span>
          </a>
        </div>
      ) : null}
      <div
        className={classNames({ [styles.controlBtnRight]: page < totalPages })}
      >
        <a
          className={styles.buttonControlRight}
          onClick={loadNextPage}
          href={''}
        >
          <span>Next</span>
        </a>
      </div>
    </div>
  );
};

export default PaginationBarPresentation;
