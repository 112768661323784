import DashboardLayout from 'components/Layout/DashboardLayout';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import React, { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { CREATE_EYCROWD_TASK_PAGE } from 'routes';
import messages from './messages';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTaskRequest, fetchTasksListRequest } from './actions';
import ResourceTable from 'components/ResourceTable';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import { ReactComponent as recycleBinIcon } from 'assets/images/delete.svg';
import Icon from 'components/Icon';
import styles from 'components/ResourceTable/Table.module.scss';
import { useConfirmationModal } from 'components/ConfirmationModal';
import { canActivate } from '../../utils/permissions';
import {
  CREATE_EYCROWD_TASKS,
  DELETE_EYCROWD_TASKS,
  LIST_EYCROWD_TASKS,
} from '../../permisssions';

const key = 'eycrowdTasks';

const EyCrowdTasks = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  const history = useHistory();
  const [openConfirmationModal] = useConfirmationModal();

  const tasks = useSelector((state) => state.eycrowdTasks?.tasks);

  useEffect(() => {
    dispatch(fetchTasksListRequest());
  }, [dispatch]);

  const { formatMessage } = useIntl();

  const tableHeader = [{ label: formatMessage(messages.taskDescription) }];

  const handleDeleteTask = (id) => {
    openConfirmationModal(formatMessage(messages.areYouSure), () =>
      dispatch(deleteTaskRequest(id))
    );
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardMainHeader
          titleMain="EyCrowd tasks"
          btnText={formatMessage(messages.createTask)}
          onClick={() => history.push(CREATE_EYCROWD_TASK_PAGE)}
          canAccess={canActivate(CREATE_EYCROWD_TASKS)}
        />

        <ResourceTable
          hasExtraActions
          headerInformation={tableHeader}
          hasMore={false}
          rowsNumber={tasks?.length}
          noDataContent={formatMessage(messages.noTasks)}
          hidePagination
          canAccess={canActivate(LIST_EYCROWD_TASKS)}
        >
          {tasks?.map((task, index) => {
            return (
              <Fragment key={task.id}>
                <TableColumnField data={task.description} />
                <td className={styles.td}>
                  {canActivate(DELETE_EYCROWD_TASKS) && (
                    <button
                      className={styles.actionButton}
                      onClick={() => handleDeleteTask(task.id)}
                    >
                      <Icon icon={recycleBinIcon} size={'sm20'} />
                    </button>
                  )}
                </td>
              </Fragment>
            );
          })}
        </ResourceTable>
      </DashboardLayout>
    </div>
  );
};

export default EyCrowdTasks;
