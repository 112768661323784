import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { useIntl } from 'react-intl';
import DashboardLayout from 'components/Layout/DashboardLayout';
import ResourceTable from 'components/ResourceTable';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import { formatDate } from '../../utils/date';
import reducer from './reducer';
import saga from './saga';
import { makeSelectNoticeCards } from './selectors';
import { DATE_FORMAT } from '../../constants';
import messages from './messages';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import {
  deleteNoticeCardRequest,
  fetchNoticeCardsRequest,
  toggleNoticeCardActiveStatusRequest,
  uploadNoticeCardRequest,
} from './actions';
import { LIST_NOTICE_CARDS, CREATE_NOTICE_CARD } from 'permisssions';
import { canActivate } from 'utils/permissions';
import ReactSwitch from 'react-switch';
import styles from 'components/ResourceTable/Table.module.scss';
import CreateNoticeCardModal from './CreateNoticeCardModal';
import { ReactComponent as DeleteIcon } from '.././../assets/images/delete.svg';

const key = 'noticeCards';

function NoticeCardsPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const noticeCards = useSelector(makeSelectNoticeCards());
  const { data, perPage, total, search } = noticeCards || [];
  const [isModalOpen, setModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  useEffect(() => {
    dispatch(fetchNoticeCardsRequest(1, 25));
  }, [dispatch]);

  const handleToggleActive = (id) => {
    dispatch(toggleNoticeCardActiveStatusRequest(id));
  };

  const handleDeleteCard = (id) => {
    dispatch(deleteNoticeCardRequest(id));
  };

  const fetchNoticeCards = (pageNumber) => {
    dispatch(fetchNoticeCardsRequest(pageNumber, perPage, search));
  };

  const handleChange = (search) => {
    dispatch(fetchNoticeCardsRequest(1, perPage, search));
  };

  const handlePerPage = (perPage) => {
    localStorage.setItem('perPage', perPage);
    localStorage.setItem('pageNumberUsers', 1);
    localStorage.setItem('pageNumberCampaigns', 1);
    localStorage.setItem('pageNumberAdvertisers', 1);
    dispatch(fetchNoticeCardsRequest(1, perPage, search));
  };

  const tableHeader = [
    { key: 'type', label: formatMessage(messages.type), sortable: false },
    {
      key: 'subType',
      label: formatMessage(messages.subType),
      sortable: false,
    },
    { key: 'title', label: formatMessage(messages.title), sortable: false },
    {
      key: 'description',
      label: formatMessage(messages.description),
      sortable: false,
    },
    {
      key: 'footerDescription',
      label: formatMessage(messages.footerDescription),
      sortable: false,
    },
    {
      key: 'position',
      label: formatMessage(messages.position),
      sortable: false,
    },
    {
      key: 'createdAt',
      label: formatMessage(messages.createdAt),
      sortable: true,
    },
    {
      key: 'status',
      label: formatMessage(messages.status),
      sortable: false,
    },
  ];

  const tableData = data?.map((noticeCard) => {
    return {
      entityId: noticeCard.id,
      type: noticeCard.type,
      subType: noticeCard.subType ? noticeCard.subType : '',
      title: noticeCard.title ? noticeCard.title : '',
      description: noticeCard.description ? noticeCard.description : '',
      footerDescription: noticeCard.footerDescription
        ? noticeCard.footerDescription
        : '',
      position: noticeCard.position.toString(),
      createdAt: noticeCard.createdAt,
      status: noticeCard.isActive ? 'Active' : 'Inactive',
      isActive: noticeCard.isActive,
    };
  });

  const onUpload = (values) => {
    dispatch(uploadNoticeCardRequest(values));
  };

  return (
    <DashboardLayout>
      <CreateNoticeCardModal
        modalOpen={isModalOpen}
        onClose={() => {
          setPreviewImage(null);
          setModalOpen(false);
        }}
        onUpload={onUpload}
        close={() => {
          setPreviewImage(null);
          setModalOpen(false);
        }}
        previewImage={previewImage}
        setPreviewImage={setPreviewImage}
      />
      <DashboardMainHeader
        titleMain={formatMessage(messages.noticeCards)}
        search
        onChange={handleChange}
        btnText={'Create Notice Card'}
        canAccess={canActivate(CREATE_NOTICE_CARD)}
        onClick={() => setModalOpen(true)}
      />
      <ResourceTable
        rowsNumber={data ? data.length : 0}
        hasMore={data?.length < total}
        fetchPage={fetchNoticeCards}
        resource={noticeCards}
        headerInformation={tableHeader}
        handleSort={() => {}}
        noDataContent={formatMessage(messages.noNoticeCards)}
        perPage={perPage ? perPage : ''}
        handlePerPage={handlePerPage}
        canAccess={canActivate(LIST_NOTICE_CARDS)}
        hasExtraActions
      >
        {tableData?.map((el) => (
          <Fragment key={el.entityId}>
            {Object.entries(el).map(([propertyName, propertyValue], index) =>
              propertyName === 'entityId' ||
              propertyName === 'isActive' ? null : propertyName ===
                'createdAt' ? (
                <TableColumnField
                  key={index}
                  data={formatDate(
                    propertyValue,
                    DATE_FORMAT.MONTH_DAY_YEAR_TIME
                  )}
                />
              ) : (
                <TableColumnField key={index} data={propertyValue} />
              )
            )}
            <td className={styles.td}>
              <div className={styles.actionButtons}>
                <ReactSwitch
                  className={styles.actionButton}
                  offColor="#dcdcdc"
                  onColor="#268a45"
                  checked={el.isActive}
                  onChange={() => handleToggleActive(el.entityId)}
                />
                <div
                  className={styles.deleteIconWrapper}
                  onClick={() => handleDeleteCard(el.entityId)}
                >
                  <DeleteIcon />
                </div>
              </div>
            </td>
          </Fragment>
        ))}
      </ResourceTable>
    </DashboardLayout>
  );
}

export default NoticeCardsPage;
