import DashboardLayout from 'components/Layout/DashboardLayout';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createTaskRequest } from './actions';
import SingleEyCrowdTaskForm from './SingleEyCrowdTaskForm';
import { useInjectSaga } from 'utils/injectSaga';
import saga from './saga';

const key = 'eycrowdTask';

const SingleEyCrowdTaskPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useInjectSaga({ key, saga });

  const handleSubmit = (data) => {
    dispatch(createTaskRequest(data));
  };
  return (
    <div>
      <DashboardLayout>
        <DashboardMainHeader cancel onCancel={() => history.goBack()} />
        <SingleEyCrowdTaskForm onSubmit={handleSubmit} />
      </DashboardLayout>
    </div>
  );
};

export default SingleEyCrowdTaskPage;
