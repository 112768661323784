import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectApp = (state) => state.app || initialState;

const makeSelectUser = () =>
  createSelector(selectApp, (substate) => substate.user);

const makeSelectIsAuthenticated = () =>
  createSelector(selectApp, (substate) => !!substate.token);

const makeSelectError = () =>
  createSelector(selectApp, (substate) => substate.error);

const makeSelectToken = () =>
  createSelector(selectApp, (substate) => substate.token);

const makeSelectInterests = () =>
  createSelector(selectApp, (substate) => substate.interests);

const makeSelectPermissions = () =>
  createSelector(selectApp, (substate) => substate.permissions);

const makeSelectDisabilities = () =>
  createSelector(selectApp, (substate) => substate.disabilities);

export {
  makeSelectUser,
  makeSelectError,
  makeSelectIsAuthenticated,
  makeSelectToken,
  makeSelectDisabilities,
  makeSelectInterests,
  makeSelectPermissions,
};
