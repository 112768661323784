import produce from 'immer';
import { FETCH_AI_PROMPT_LOGS_SUCCESS } from './constants';

export const initialState = {
  aiPromptLogs: {
    data: [],
    perPage: null,
    page: null,
    total: null,
    search: '',
  },
};

const aiPromptLogsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_AI_PROMPT_LOGS_SUCCESS:
        draft.aiPromptLogs.data = action.logs.data;
        draft.aiPromptLogs.perPage = action.logs.perPage;
        draft.aiPromptLogs.page = action.logs.page;
        draft.aiPromptLogs.total = action.logs.total;
        draft.aiPromptLogs.search = action.search;
        break;
      default:
        break;
    }
  });

export default aiPromptLogsReducer;
