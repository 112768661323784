import { defineMessages } from 'react-intl';

export const scope = 'single_campaign.tasks';

export default defineMessages({
  preCampaign: {
    id: `${scope}.pre_campaign`,
    defaultMessage: 'Pre Campaign Tasks',
  },
  liveCampaign: {
    id: `${scope}.live_campaign`,
    defaultMessage: 'Active Campaign Tasks',
  },
  followUp: {
    id: `${scope}.follow_up`,
    defaultMessage: 'Post Campaign Tasks',
  },
});
