import React from 'react';
import messages from './messages';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { InputField } from 'components/Forms';
import { BUSINESS_RULES_LABELS } from '../../constants';
import Button from 'components/Buttons/Button';
import { useIntl } from 'react-intl';
import { updateBusinessRulesSchema } from './validations';
import { formatObjectValues } from 'utils/businessRules';
import { canActivate } from '../../utils/permissions';
import { UPDATE_BUSINESS_RULES_VARIABLES } from '../../permisssions';
import MaintenanceModeSwitchWrapper from './MaintenanceModeSwitchWrapper';

const BusinessRulesForm = ({ onSubmit, data }) => {
  const formattedData = formatObjectValues(data);

  const dataArray = Object.keys(formattedData);

  const { formatMessage } = useIntl();

  return (
    <div>
      <Formik
        onSubmit={onSubmit}
        initialValues={formattedData}
        enableReinitialize={true}
        validationSchema={updateBusinessRulesSchema}
      >
        {({ isValid, dirty }) => (
          <Form>
            {dataArray.map((item, index) => {
              if (BUSINESS_RULES_LABELS[item]) {
                return (
                  <InputField
                    key={index}
                    required
                    type={
                      item === 'customersFreeSlotsUpdatedAt' ? 'text' : 'number'
                    }
                    disabled={item === 'customersFreeSlotsUpdatedAt'}
                    name={item}
                    label={formatMessage(BUSINESS_RULES_LABELS[item])}
                    placeholder={formatMessage(BUSINESS_RULES_LABELS[item])}
                  />
                );
              }
              // else if (item === 'maintenanceMode') {
              //   return <MaintenanceModeSwitchWrapper />;
              // } TODO temp removed. Needs refactoring, also.
            })}
            {dataArray.length > 0 &&
              canActivate(UPDATE_BUSINESS_RULES_VARIABLES) && (
                <Button
                  disabled={!isValid || !dirty}
                  type="submit"
                  title={formatMessage(messages.update)}
                  alt
                  isLarge
                />
              )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

BusinessRulesForm.propTypes = {
  data: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default BusinessRulesForm;
