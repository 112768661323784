import { defineMessages } from 'react-intl';

export const scope = 'single_campaign.campaign.details';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Details',
  },
  location: {
    id: `${scope}.location`,
    defaultMessage: 'Location',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  terms: {
    id: `${scope}.terms_and_conditions`,
    defaultMessage: 'Terms & Conditions',
  },
  values: {
    id: `${scope}.company_values`,
    defaultMessage: 'Company values',
  },
  task: {
    id: `${scope}.task`,
    defaultMessage: 'Task',
  },
  supervisorTasks: {
    id: `${scope}.supervisor_tasks`,
    defaultMessage: 'Supervisor Tasks',
  },
  step01: {
    id: `${scope}.step_01`,
    defaultMessage: 'Step 01',
  },
  step02: {
    id: `${scope}.step_02`,
    defaultMessage: 'Step 02',
  },
  addSupervisorTasks: {
    id: `${scope}.add_supervisor_tasks`,
    defaultMessage: 'Add supervisor tasks',
  },
});
