import messages from 'messages.js';

export const CAMPAIGN_CALCULATIONS = {
  CAMPAIGN_INTERVAL: '%sHrs %sMin',
  TAXES_AND_FEES_PER_USER_HOUR: 14.5,
};

export const CAMPAIGN_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  ACTIVE: 'active',
  FINISHED: 'finished',
  CANCELED: 'canceled',
  IN_REVIEW: 'in-review',
  CHANGES_REQUESTED: 'changes-requested',
};

export const CAMPAIGN_TASKS_TYPES = {
  PRE_CAMPAIGN: 'pre-campaign',
  LIVE_CAMPAIGN: 'live-campaign',
  FOLLOW_UP: 'follow-up',
};

export const CAMPAIGN_TASKS_CATEGORIES = {
  DEFAULT: 'default',
  ARRIVAL: 'arrival',
  CHECK_IN: 'check-in',
};

export const CAMPAIGN_TYPES = {
  IN_PERSON: 'in-person',
  DIGITAL: 'digital',
  HYBRID: 'hybrid',
};

export const CAMPAIGN_TYPES_OPTIONS = [
  {
    id: CAMPAIGN_TYPES.IN_PERSON,
    label: messages.campaignTypes.inPerson,
  },
  {
    id: CAMPAIGN_TYPES.DIGITAL,
    label: messages.campaignTypes.digital,
  },
  {
    id: CAMPAIGN_TYPES.HYBRID,
    label: messages.campaignTypes.hybrid,
  },
];

export const CAMPAIGN_VALIDATIONS = {
  MIN_EYVOCATE_AGE: 18,
  MIN_PRICE_PER_HOUR: 13,
  MIN_HOURS_CAMPAIGN_DURATION: 2,
  MIN_DAYS_DURATION_TO_CAMPAIGN_START: 14,
  DAYS_APPLICATION_DEADLINE_BEFORE_START_DATE: 2,
  DAYS_APPLICATION_DEADLINE_AFTER_TODAY: 1,
  TIME_PICKER_INTERVALS_MINS: 1,
};

export const DEMOGRAPHIC_OPTIONS = {
  HE: 'he',
  SHE: 'she',
  THEY: 'they',

  MALE: 'male',
  FEMALE: 'female',
  CUSTOM: 'custom',
};
