import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectAdmins = (state) => state.admins || initialState;

const makeSelectAdmins = () =>
  createSelector(selectAdmins, (substate) => substate.admins);

const makeSelectCreateAdminPending = () =>
  createSelector(selectAdmins, (substate) => substate.creatingAdmin);

const makeSelectEditAdminPending = () =>
  createSelector(selectAdmins, (substate) => substate.editingAdmin);

export {
  makeSelectAdmins,
  makeSelectCreateAdminPending,
  makeSelectEditAdminPending,
};
