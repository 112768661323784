import React, { useEffect, useState } from 'react';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLogsRequest } from './actions';
import reducer from './reducer';
import saga from './saga';
import { useIntl } from 'react-intl';
import DashboardLayout from 'components/Layout/DashboardLayout';
import ResourceTable from 'components/ResourceTable';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import { makeSelectLogs } from './selectors';
import { Fragment } from 'react';
import styles from 'components/ResourceTable/Table.module.scss';
import messages from './messages';
import { formatDate } from 'utils/date';
import { DATE_FORMAT } from '../../constants';
import { LogType } from './constants';
import { canActivate } from 'utils/permissions';
import { LIST_LOGS } from 'permisssions';
import Select from 'react-select';

const key = 'logs';

function LogsPage() {
  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });

  const [selectedLogType, setSelectedLogType] = useState({
    value: 'exception',
    label: 'Exception',
  });

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const logs = useSelector(makeSelectLogs());
  const { data, perPage, total, search, sortBy, order } = logs || [];

  useEffect(() => {
    dispatch(fetchLogsRequest(1, 25, selectedLogType.value, ''));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchLogsRequest(1, 25, selectedLogType.value, ''));
  }, [selectedLogType, dispatch]);

  const fetchPageLogs = (pageNumber) => {
    dispatch(
      fetchLogsRequest(pageNumber, perPage, selectedLogType.value, search)
    );
  };

  const tableHeader = [
    { key: 'log', label: formatMessage(messages.log) },
    { key: '', label: '' },
  ];

  const handleChange = (search) => {
    dispatch(fetchLogsRequest(1, perPage, selectedLogType.value, search));
  };

  const handlePerPage = (perPage) => {
    dispatch(fetchLogsRequest(1, perPage, selectedLogType.value, search));
  };

  const options = [
    { value: 'exception', label: 'Exception' },
    { value: 'finish_task', label: 'FinishTask' },
    { value: 'payment', label: 'Payment' },
    { value: 'malicious_attempt', label: 'MaliciousAttempt' },
  ];

  return (
    <div>
      <DashboardLayout>
        <DashboardMainHeader
          title={formatMessage(messages.logs)}
          search
          onChange={handleChange}
        />
        <Select
          options={options}
          onChange={setSelectedLogType}
          value={selectedLogType}
          styles={{
            container: (provided) => ({
              ...provided,
              width: '20%',
              marginBottom: '2rem',
            }),
            menuPortal: (provided) => ({ ...provided }),
          }}
        />
        <ResourceTable
          rowsNumber={logs ? logs.length : 0}
          hasMore={logs?.length < total}
          fetchPage={fetchPageLogs}
          resource={logs ? logs : logs.toString()}
          headerInformation={tableHeader}
          noDataContent={formatMessage(messages.noLogs)}
          handleSort={() => {}}
          perPage={perPage ? perPage : ''}
          handlePerPage={handlePerPage}
          canAccess={canActivate(LIST_LOGS)}
        >
          {data.map((log, index) => {
            switch (true) {
              case log.type === LogType.Exception:
                // statusCode: number,
                // timestamp: any,
                // path: string,
                // message: string,
                // details: string,
                return (
                  <Fragment key={index}>
                    <TableColumnField
                      data={
                        <div className={styles.log}>
                          <b>TIME:&nbsp;</b>
                          {` ${formatDate(
                            log.createdAt,
                            DATE_FORMAT.MONTH_DAY_YEAR_TIME
                          )}`}
                          <b>&nbsp;STATUS CODE:&nbsp;</b> {log.data.statusCode}
                          <b>&nbsp;MESSAGE:&nbsp;</b>{' '}
                          {log.data.message
                            ? log.data.message.toString()
                            : log.data.description}
                          {log.data.details
                            ? `<b>&nbsp; DETAILS:&nbsp;</b> ${log.data.details})`
                            : ''}
                          <b>&nbsp;PATH:&nbsp;</b> {log.data.path}
                        </div>
                      }
                    />
                    <TableColumnField data={<div></div>} />
                  </Fragment>
                );
              case log.type === LogType.FinishTask:
                // campaignId: number,
                // campaignName: string,
                // taskId: number,
                // userId: number,
                // username: string,
                // description: string,
                return (
                  <Fragment key={index}>
                    <TableColumnField
                      data={
                        <div className={styles.log}>
                          <b>TIME:&nbsp;</b>
                          {` ${formatDate(
                            log.createdAt,
                            DATE_FORMAT.MONTH_DAY_YEAR_TIME
                          )}`}
                          <b>&nbsp;CAMPAIGN:&nbsp;</b> {log.data.campaignName} [
                          {log.data.campaignId}]<b>&nbsp;TASK ID:&nbsp;</b>{' '}
                          {log.data.taskId}
                          <b>&nbsp;USERNAME:&nbsp;</b> {log.data.username} [
                          {log.data.userId}]<b>&nbsp;DESCRIPTION:&nbsp;</b>{' '}
                          {log.data.description}
                        </div>
                      }
                    />
                    <TableColumnField data={<div></div>} />
                  </Fragment>
                );
              case log.type === LogType.Payment:
                // title: string,
                // data: any
                return (
                  <Fragment key={index}>
                    <TableColumnField
                      data={
                        <div className={styles.log}>
                          <b>TIME:&nbsp;</b>
                          {` ${formatDate(
                            log.createdAt,
                            DATE_FORMAT.MONTH_DAY_YEAR_TIME
                          )}`}
                          <b>&nbsp;TITLE:&nbsp;</b> {log.data.title}
                          <b>&nbsp;DATA:&nbsp;</b> {log.data.dataJSON}
                        </div>
                      }
                    />
                    <TableColumnField data={<div></div>} />
                  </Fragment>
                );
              case log.type === LogType.MaliciousAttempt:
                // ipAddress: string,
                // privateIpAddress: any,
                // numberOfAttempts: number,
                // averageRequestsPerHour: number,
                return (
                  <Fragment key={index}>
                    <TableColumnField
                      data={
                        <div className={styles.log}>
                          <b>TIME:&nbsp;</b>
                          {` ${formatDate(
                            log.createdAt,
                            DATE_FORMAT.MONTH_DAY_YEAR_TIME
                          )}`}
                          <b>&nbsp;PUBLIC IP ADDRESS:&nbsp;</b>{' '}
                          {log.data.ipAddress}
                          <b>&nbsp;PRIVATE IP ADDRESS:&nbsp;</b>{' '}
                          {log.data.privateIpAddress}
                          <b>&nbsp;NUMBER OF ATTEMPTS:&nbsp;</b>{' '}
                          {log.data.numberOfAttempts}
                          <b>&nbsp;AVERAGE REQUESTS PER HOUR:&nbsp;</b>{' '}
                          {log.data.averageRequestsPerHour}
                        </div>
                      }
                    />
                    <TableColumnField data={<div></div>} />
                  </Fragment>
                );
            }
          })}
        </ResourceTable>
      </DashboardLayout>
    </div>
  );
}

export default LogsPage;
