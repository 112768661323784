import produce from 'immer';

import {
  CHANGE_USER_ROLE_SUCCESS,
  FETCH_SINGLE_CAMPAIGN_SUCESS,
  UPDATE_SINGLE_CAMPAIGN_REQUEST,
  UPDATE_SINGLE_CAMPAIGN_ERROR,
  UPDATE_SINGLE_CAMPAIGN_SUCCESS,
  UPDATE_SINGLE_CAMPAIGN_END_DATE_SUCCESS,
  CREATE_CAMPAIGN_SUPERVISOR_TASK_REQUEST,
  CREATE_CAMPAIGN_SUPERVISOR_TASK_SUCCESS,
  CREATE_CAMPAIGN_SUPERVISOR_TASK_ERROR,
  DELETE_CAMPAIGN_SUPERVISOR_TASK_REQUEST,
  DELETE_CAMPAIGN_SUPERVISOR_TASK_SUCCESS,
  DELETE_CAMPAIGN_SUPERVISOR_TASK_ERROR,
  SUCCESSFULLY_REMOVED_USER_FROM_CAMPAIGN,
  SET_IS_CAMPAIGN_INTERNAL_SUCCESS,
  DELETE_CAMPAIGN_GRADE_RANGE_REQUEST,
  DELETE_CAMPAIGN_GRADE_RANGE_ERROR,
  DELETE_CAMPAIGN_GRADE_RANGE_SUCCESS,
  SET_CAMPAIGN_GRADE_RANGE_REQUEST,
  SET_CAMPAIGN_GRADE_RANGE_SUCCESS,
  SET_CAMPAIGN_GRADE_RANGE_ERROR,
} from './constants';

export const initialState = {
  campaign: {},
  isPending: false,
  handleCampaignGradeRangePending: false,
};

/* eslint-disable default-case, no-param-reassign */
const singleCampaignPageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_SINGLE_CAMPAIGN_SUCESS:
        draft.campaign = action.campaign;
        break;
      case UPDATE_SINGLE_CAMPAIGN_REQUEST:
      case CREATE_CAMPAIGN_SUPERVISOR_TASK_REQUEST:
      case DELETE_CAMPAIGN_SUPERVISOR_TASK_REQUEST:
        draft.isPending = true;
        break;
      case UPDATE_SINGLE_CAMPAIGN_SUCCESS:
        draft.campaign = { ...draft.campaign, ...action.singleCampaign };
        draft.isPending = false;
        break;
      case UPDATE_SINGLE_CAMPAIGN_END_DATE_SUCCESS:
        draft.campaign = { ...draft.campaign, ...action.singleCampaign };
        draft.isPending = false;
        break;
      case CREATE_CAMPAIGN_SUPERVISOR_TASK_SUCCESS:
        draft.campaign.supervisorTasks = [
          ...draft.campaign.supervisorTasks,
          action.supervisorTask,
        ];
        draft.isPending = false;
        break;
      case DELETE_CAMPAIGN_SUPERVISOR_TASK_SUCCESS:
        draft.campaign.supervisorTasks = draft.campaign.supervisorTasks.filter(
          (task) => task.id !== action.supervisorTaskId
        );
        draft.isPending = false;
        break;
      case CREATE_CAMPAIGN_SUPERVISOR_TASK_ERROR:
      case UPDATE_SINGLE_CAMPAIGN_ERROR:
      case DELETE_CAMPAIGN_SUPERVISOR_TASK_ERROR:
        draft.isPending = false;
        break;
      case SUCCESSFULLY_REMOVED_USER_FROM_CAMPAIGN:
        draft.campaign.campaignUsers = draft.campaign.campaignUsers.filter(
          (campaignUser) => campaignUser.userId !== action.userId
        );
        break;
      case CHANGE_USER_ROLE_SUCCESS:
        const user = draft.campaign.campaignUsers.find(
          ({ userId }) => userId === action.userId
        );
        user.isSupervisor = action.isSupervisor;
        break;
      case SET_IS_CAMPAIGN_INTERNAL_SUCCESS:
        draft.campaign.isInternal = action.campaign.isInternal;
        break;
      case DELETE_CAMPAIGN_GRADE_RANGE_REQUEST:
        draft.handleCampaignGradeRangePending = true;
        break;
      case DELETE_CAMPAIGN_GRADE_RANGE_SUCCESS:
        draft.campaign.campaignGrades = draft.campaign.campaignGrades.filter(
          (campaignGrade) => campaignGrade.id !== action.campaignGradeId
        );
        draft.handleCampaignGradeRangePending = false;
        break;
      case DELETE_CAMPAIGN_GRADE_RANGE_ERROR:
        draft.handleCampaignGradeRangePending = false;
        break;
      case SET_CAMPAIGN_GRADE_RANGE_REQUEST:
        draft.handleCampaignGradeRangePending = true;
        break;
      case SET_CAMPAIGN_GRADE_RANGE_SUCCESS:
        draft.campaign.campaignGrades = [
          ...draft.campaign.campaignGrades,
          action.campaignGrade,
        ];
        draft.handleCampaignGradeRangePending = false;
        break;
      case SET_CAMPAIGN_GRADE_RANGE_ERROR:
        draft.handleCampaignGradeRangePending = false;
        break;
    }
  });

export default singleCampaignPageReducer;
