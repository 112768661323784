import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectCoupons = (state) => state.coupons || initialState;

const makeSelectCoupons = () =>
  createSelector(selectCoupons, (substate) => substate.coupons);

const makeSelectGeneratingCoupon = () =>
  createSelector(selectCoupons, (substate) => substate.generatingCoupon);

const makeSelectEditingCoupon = () =>
  createSelector(selectCoupons, (substate) => substate.editingCoupon);

export {
  makeSelectCoupons,
  makeSelectGeneratingCoupon,
  makeSelectEditingCoupon,
};
