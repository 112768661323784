import DashboardLayout from 'components/Layout/DashboardLayout';
import styles from './SingleStaffActivityPage.module.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  getStaffActivityStatusSelector,
  selectedStaffUserSelector,
  staffActivitySelector,
} from 'containers/StaffActivityPage/selectors';
import spinner from '../../assets/images/spinner.gif';
import { Form, Formik } from 'formik';
import DateField from 'components/Forms/DateField';
import Button from 'components/Buttons/Button';
import { canActivate } from 'utils/permissions';
import { GET_STAFF_ACTIVITIES } from 'permisssions';
import { now } from 'utils/date';
import { singleStaffUserActivitySchema } from './validations';
import {
  getStaffActivityAttempt,
  getStaffActivityReset,
  getStaffActivitySuccess,
  setSelectedStaffUser,
} from 'containers/StaffActivityPage/actions';
import { STAFF_ACTIVITY } from 'routes';
import StaffGalleryActivity from './StaffGalleryActivity';
import { saveAs } from 'file-saver';

const SingleStaffActivityPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const selectedStaffUser = useSelector(selectedStaffUserSelector());
  const getStaffActivityStatus = useSelector(getStaffActivityStatusSelector());
  const staffActivity = useSelector(staffActivitySelector());
  const [staffActivityByDays, setStaffActivityByDays] = useState(null);
  const [selectedGalleries, setSelectedGalleries] = useState([]);
  const [showEnablePopUpHint, setShowEnablePopUpHint] = useState(false);

  if (!Object.keys(selectedStaffUser).length) {
    history.push(STAFF_ACTIVITY);
  }

  const handleDownloadSelectedGalleries = () => {
    if (selectedGalleries.length > 1) {
      setShowEnablePopUpHint(true);
    }
    selectedGalleries.forEach((gallery) => {
      saveAs(gallery.galleryMedia.url, gallery.galleryMedia.name);
    });
    setSelectedGalleries([]);
  };

  useEffect(() => {
    if (showEnablePopUpHint) {
      setTimeout(() => {
        setShowEnablePopUpHint(false);
      }, 2000);
    }
  }, [showEnablePopUpHint]);

  useEffect(() => {
    if (selectedGalleries.length > 1) {
      setShowEnablePopUpHint(true);
    }
  }, [selectedGalleries]);

  const handleSubmit = async (values) => {
    dispatch(
      getStaffActivityAttempt(
        new Date(values.dateFrom).setHours(0, 0, 0),
        new Date(values.dateTo).setHours(24, 0, 0),
        selectedStaffUser.id
      )
    );
  };

  useEffect(() => {
    if (getStaffActivityStatus.success || getStaffActivityStatus.error) {
      dispatch(getStaffActivityReset());
    }
  }, [getStaffActivityStatus.success, getStaffActivityStatus.error, dispatch]);

  useEffect(() => {
    let staffActivityByDaysArrayObject = [];
    if (staffActivity.results) {
      for (let activity of staffActivity.results) {
        let staffActivityObjectCreatedAtKey = new Date(
          new Date(activity.createdAt).setHours(0, 0, 0)
        );
        if (staffActivityByDaysArrayObject[staffActivityObjectCreatedAtKey]) {
          staffActivityByDaysArrayObject[staffActivityObjectCreatedAtKey]++;
        } else {
          staffActivityByDaysArrayObject[staffActivityObjectCreatedAtKey] = 1;
        }
      }
      setStaffActivityByDays(staffActivityByDaysArrayObject);
    }
  }, [staffActivity]);

  const renderStaffActivityByDays = useMemo(() => {
    if (staffActivityByDays) {
      let staffActivityByDayArray = [];
      for (let staffActivityByDay of Object.keys(staffActivityByDays)) {
        staffActivityByDayArray.push(
          <div className={styles.byDayWrapper}>
            <p>• {staffActivityByDay.replace('00:00:00', '')}:</p>
            <p className={styles.perDayNumberText}>
              &nbsp;{staffActivityByDays[staffActivityByDay]}
            </p>
          </div>
        );
      }
      return staffActivityByDayArray;
    }
  }, [staffActivityByDays]);

  return (
    <div>
      <DashboardLayout>
        <div className={styles.titleMainContainer}>
          <button
            className={styles.cancelButtonWrap}
            onClick={() => {
              history.goBack();
              dispatch(setSelectedStaffUser({}));
              dispatch(getStaffActivitySuccess({}));
            }}
          >
            Back
          </button>
          <h3 className={styles.titleMain}>
            {selectedStaffUser.firstName +
              ' ' +
              selectedStaffUser.lastName +
              ' (' +
              selectedStaffUser.username +
              ')'}
          </h3>
          {!!selectedGalleries.length ? (
            <div className={styles.downloadWrapper}>
              {showEnablePopUpHint && (
                <div className={styles.downloadTextWrapper}>
                  <p className={styles.downloadTextStyling}>
                    If downloading multiple files, accept browser pop-ups
                  </p>
                </div>
              )}
              <button
                className={styles.downloadButton}
                onClick={() => handleDownloadSelectedGalleries()}
                disabled={!selectedGalleries.length}
              >
                Download
              </button>
            </div>
          ) : (
            <div className={styles.emptyDiv} />
          )}
        </div>
        {!Object.keys(staffActivity).length ? (
          <Formik
            initialValues={{
              dateFrom: now(),
              dateTo: now(),
            }}
            validationSchema={singleStaffUserActivitySchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ isValid, values }) => {
              return canActivate(GET_STAFF_ACTIVITIES) ? (
                <div className={styles.formWrapper}>
                  <Form className={styles.form}>
                    <p className={styles.formExplanation}>
                      Choose the date interval for which to display activities:
                    </p>
                    <DateField
                      name="dateFrom"
                      label={'Date from'}
                      selected={values.dateFrom}
                    />
                    <DateField
                      name="dateTo"
                      label={'Date to'}
                      selected={values.dateTo}
                      minDate={values.dateFrom}
                    />
                    <Button
                      alt
                      disabled={!isValid || getStaffActivityStatus.attempt}
                      type="submit"
                      isLarge
                      className={styles.button}
                    >
                      {getStaffActivityStatus.attempt ? (
                        <div className={styles.loadingSpinner}>
                          <img
                            src={spinner}
                            alt="loading spinner"
                            className={styles.loadingSpinnerImg}
                          />
                        </div>
                      ) : (
                        <>View activity</>
                      )}
                    </Button>
                  </Form>
                </div>
              ) : null;
            }}
          </Formik>
        ) : (
          <>
            {staffActivityByDays && (
              <>
                <h5>Content amount by day:</h5>
                <div className={styles.staffActivityByDaysWrapper}>
                  {Object.keys(staffActivityByDays).length ? (
                    renderStaffActivityByDays
                  ) : (
                    <p>No content found for this date interval</p>
                  )}
                </div>
              </>
            )}
            <h5>Gallery:</h5>
            <StaffGalleryActivity
              staffGalleryActivity={staffActivity.results}
              selectedGalleries={selectedGalleries}
              setSelectedGalleries={setSelectedGalleries}
            />
          </>
        )}
      </DashboardLayout>
    </div>
  );
};

export default SingleStaffActivityPage;
