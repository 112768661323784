import Button from 'components/Buttons/Button';
import DashboardLayout from 'components/Layout/DashboardLayout';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApi } from 'utils/request';
import styles from './ChangePassword.module.scss';
import { PasswordField } from 'components/Forms';
import { AUTH } from '../../constants';
import { changePasswordSchema } from './validations';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { canActivate } from '../../utils/permissions';
import { CHANGE_PASSWORD } from '../../permisssions';

const ChangePassword = () => {
  const token = useSelector((state) => state.app.token);

  const dispatch = useDispatch();

  const changePassword = async (values) => {
    try {
      const { currentPassword, newPassword, newPassowrdConfirm } = values;
      const api = getApi();
      if (newPassword !== newPassowrdConfirm) {
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: "Passwords don't  match",
          })
        );
        return;
      }
      await api.post(
        '/auth/admin/change-password',
        {
          currentPassword: currentPassword,
          newPassword: newPassword,
          newPassowrdConfirm: newPassowrdConfirm,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(
        enqueueSnackbar({
          variant: 'success',
          message: 'Password changed',
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          variant: 'error',
          message: err.data.message,
        })
      );
    }
  };

  return (
    <DashboardLayout>
      <div className={styles.flexColumn}>
        <Formik
          initialValues={{
            currentPassword: '',
            newPassword: '',
            newPassowrdConfirm: '',
          }}
          validationSchema={changePasswordSchema}
          onSubmit={changePassword}
        >
          {canActivate(CHANGE_PASSWORD)
            ? ({ isValid, dirty }) => (
                <Form>
                  <PasswordField
                    name="currentPassword"
                    label={'Current password'}
                    placeholder={'Current password'}
                    required
                    minValue={`${AUTH.MIN_PASSWORD_CHARACTERS}`}
                    maxValue={`${AUTH.MAX_PASSWORD_CHARACTERS}`}
                    showPassword
                  />
                  <PasswordField
                    name="newPassword"
                    label={'New password'}
                    placeholder={'New password'}
                    required
                    minValue={`${AUTH.MIN_PASSWORD_CHARACTERS}`}
                    maxValue={`${AUTH.MAX_PASSWORD_CHARACTERS}`}
                    showPassword
                  />
                  <PasswordField
                    name="newPassowrdConfirm"
                    label={'Confirm new password'}
                    placeholder={'Confirm new password'}
                    required
                    minValue={`${AUTH.MIN_PASSWORD_CHARACTERS}`}
                    maxValue={`${AUTH.MAX_PASSWORD_CHARACTERS}`}
                    showPassword
                  />
                  <Button
                    alt
                    disabled={!isValid || !dirty}
                    type="submit"
                    title={'Submit'}
                    isLarge
                  />
                </Form>
              )
            : null}
        </Formik>
      </div>
    </DashboardLayout>
  );
};

export default ChangePassword;
