export const LOGS = '[Logs]';

export const FETCH_LOGS_REQUEST = `${LOGS} FETCH_LOGS_REQUEST`;
export const FETCH_LOGS_SUCCESS = `${LOGS} FETCH_LOGS_SUCCESS`;

export const LogType = {
  Exception: 'exception',
  FinishTask: 'finish_task',
  Payment: 'payment',
  MaliciousAttempt: 'malicious_attempt',
};
