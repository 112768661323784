import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import ResourceTable from 'components/ResourceTable';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectWebsiteForms } from './selectors';
import { fetchWebsiteFormsRequest } from './actions';
import { DATE_FORMAT } from '../../constants';
import DashboardLayout from 'components/Layout/DashboardLayout';
import { formatDate } from 'utils/date';
import styles from './WebsiteFormsPage.module.scss';
import classNames from 'classnames';
import { ReactComponent as EyeIcon } from 'assets/images/password-visible.svg';
import FormPreviewModal from './FormPreviewModal';

const key = 'websiteForms';

const TABS = {
  E_MAGAZINE: 'e-magazine',
  CAMPAIGN_REQUEST: 'campaign-request',
};

const WebsiteFormsPage = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(TABS.E_MAGAZINE);
  const [selectedForm, setSelectedForm] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const websiteForms = useSelector(makeSelectWebsiteForms());

  const types = useMemo(() => {
    if (selectedTab === TABS.E_MAGAZINE) {
      return ['e-magazine', 'help'];
    } else {
      return ['campaign-request'];
    }
  }, [selectedTab]);

  useEffect(() => {
    dispatch(
      fetchWebsiteFormsRequest(
        localStorage.getItem('pageNumberUsers')
          ? localStorage.getItem('pageNumberUsers')
          : 1,
        localStorage.getItem('perPage') ? localStorage.getItem('perPage') : 25,
        types
      )
    );
  }, [dispatch, types]);

  const { data, perPage, total } = websiteForms || [];

  const fetchWebsiteFormsPage = (pageNumber) => {
    dispatch(fetchWebsiteFormsRequest(pageNumber, perPage, types));
  };

  const tableHeader = [
    { key: 'email', label: 'Email' },
    { key: 'fullName', label: 'Full Name' },
    { key: 'type', label: 'Type' },
    ...(selectedTab === TABS.E_MAGAZINE
      ? [
          {
            key: 'info',
            label: 'Info',
          },
        ]
      : []),
    { key: 'createdAt', label: 'Created At' },
    ...(selectedTab === TABS.CAMPAIGN_REQUEST
      ? [
          {
            key: 'actions',
            label: 'Actions',
          },
        ]
      : []),
  ];

  const handlePerPage = (perPage) => {
    localStorage.setItem('perPage', perPage);
    dispatch(fetchWebsiteFormsRequest(1, perPage, types));
  };

  const onRowClick = (form) => {
    setSelectedForm(form);
    setModalOpen(true);
  };

  return (
    <DashboardLayout>
      <div className={styles.header}>
        <h3>Website Forms</h3>
        <div className={styles.switchTabsContainer}>
          <p
            className={classNames(
              styles.switchTabsText,
              selectedTab === TABS.E_MAGAZINE && styles.switchTabsTextSelected
            )}
            onClick={() => setSelectedTab(TABS.E_MAGAZINE)}
          >
            E Magazine/Help
          </p>
          <div className={styles.divider} />
          <p
            className={classNames(
              styles.switchTabsText,
              selectedTab === TABS.CAMPAIGN_REQUEST &&
                styles.switchTabsTextSelected
            )}
            onClick={() => setSelectedTab(TABS.CAMPAIGN_REQUEST)}
          >
            Campaign Requests
          </p>
        </div>
      </div>
      <div className={styles.content}>
        <ResourceTable
          rowsNumber={data ? data.length : 0}
          hasMore={data?.length < total}
          headerInformation={tableHeader}
          canAccess
          noDataContent={'No Data'}
          fetchPage={fetchWebsiteFormsPage}
          resource={websiteForms}
          perPage={
            localStorage.getItem('perPage')
              ? localStorage.getItem('perPage')
              : perPage
          }
          handlePerPage={handlePerPage}
        >
          {data.map((websiteForm) => {
            return (
              <Fragment key={websiteForm.id} onClick={onRowClick}>
                <TableColumnField data={websiteForm.email} />
                <TableColumnField data={websiteForm.fullName} />
                <TableColumnField data={websiteForm.type} />
                {selectedTab === TABS.E_MAGAZINE && (
                  <TableColumnField data={websiteForm.data.additionalInfo} />
                )}
                <TableColumnField
                  data={formatDate(
                    websiteForm.createdAt,
                    DATE_FORMAT.MONTH_DAY_YEAR_TIME
                  )}
                />
                {selectedTab === TABS.CAMPAIGN_REQUEST && (
                  <TableColumnField
                    data={
                      <div
                        className={styles.eyeIcon}
                        onClick={() => {
                          onRowClick(websiteForm);
                        }}
                      >
                        <EyeIcon width={24} height={24} />
                      </div>
                    }
                  />
                )}
              </Fragment>
            );
          })}
        </ResourceTable>
      </div>
      <FormPreviewModal
        modalOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setSelectedForm(null);
        }}
        data={selectedForm?.data}
      />
    </DashboardLayout>
  );
};

export default WebsiteFormsPage;
