import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import styles from './CampaignDetails.module.scss';
import { useIntl } from 'react-intl';
import messages from './messages';
import InputField from 'components/Forms/InputField';
import { updateCampaignSupervisorTasksSchema } from 'containers/SingleCampaignPage/validations';
import {
  createCampaignSupervisorTaskRequest,
  deleteCampaignSupervisorTaskRequest,
} from 'containers/SingleCampaignPage/actions';
import { makeSelectSingleCampaignIsPending } from 'containers/SingleCampaignPage/selectors';
import { CAMPAIGN_STATUS } from '../../../constants';

const CampaignDetails = ({ singleCampaign }) => {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const { id } = useParams();

  const { location, description, companyValues, supervisorTasks } =
    singleCampaign || {};

  const isPending = useSelector(makeSelectSingleCampaignIsPending());

  const handleCreateSupervisorTask = (question) => {
    dispatch(createCampaignSupervisorTaskRequest(id, question));
  };

  const handleRemoveSupervisorTask = (taskId) => {
    taskId && dispatch(deleteCampaignSupervisorTaskRequest(id, taskId));
  };

  return (
    <div className={styles.container}>
      <h6>{formatMessage(messages.title)}</h6>
      {location && (
        <>
          <div className={styles.firstRow}>
            <p className={styles.label}>{formatMessage(messages.location)}</p>
            <p
              className={styles.text}
            >{`${location.address}, ${location.city}`}</p>
          </div>
        </>
      )}

      <p className={styles.label}>{formatMessage(messages.description)}</p>
      <p className={styles.text}>{description}</p>
      <p className={styles.label}>{formatMessage(messages.values)}</p>
      <p className={styles.text}>{companyValues}</p>
      {/* <p className={styles.label}>{formatMessage(messages.supervisorTasks)}</p>
      {supervisorTasks?.map((task, index) => (
        <div key={index} className={styles.taskWrapper}>
          <div>
            <p className={styles.label}>{`${formatMessage(messages.task)} ${
              index + 1
            }`}</p>
            <p className={styles.text}>{task.question}</p>
          </div>
          {singleCampaign.status === CAMPAIGN_STATUS.PUBLISHED && (
            <button
              type="button"
              className={styles.supervisorTasksButton}
              onClick={() => handleRemoveSupervisorTask(task.id)}
              disabled={isPending}
            >
              <span>-</span>
            </button>
          )}
        </div>
      ))}
      {singleCampaign.status === CAMPAIGN_STATUS.PUBLISHED && (
        <Formik
          initialValues={{ question: '' }}
          validationSchema={updateCampaignSupervisorTasksSchema}
          onSubmit={(values, { resetForm }) => {
            handleCreateSupervisorTask(values);
            resetForm();
          }}
        >
          <Form>
            <div className={styles.taskWrapper}>
              <InputField
                name="question"
                type="text"
                component="textarea"
                label={formatMessage(messages.addSupervisorTasks)}
                placeholder={formatMessage(messages.description)}
                textarea
                required
                className={styles.textArea}
              />
              <button
                type="submit"
                disabled={isPending}
                className={styles.supervisorTasksButton}
              >
                <span>+</span>
              </button>
            </div>
          </Form>
        </Formik>
      )} */}
    </div>
  );
};

export default CampaignDetails;

CampaignDetails.propTypes = {
  singleCampaign: PropTypes.object,
};
