import React, { useState } from 'react';
import styles from './Gallery.module.scss';
import GalleryModal from './GalleryModal';
import { ReactComponent as playButton } from 'assets/images/play-button.svg';
import Icon from 'components/Icon';

const Gallery = ({ galleryTitle, gallery, showUsernames }) => {
  const [selectedMedia, setSelectedMedia] = useState(null);

  return (
    <>
      <GalleryModal
        media={selectedMedia}
        isOpen={selectedMedia !== null}
        onClose={() => setSelectedMedia(null)}
      />
      <div className={styles.galleryWrap}>
        <p className={styles.galleryTitle}>{galleryTitle}</p>
        <div className={styles.gallery}>
          {gallery?.map((item, index) => {
            if (item.galleryMedia.type === 'photo')
              return (
                <div key={index} className={styles.imagePlaceHolder}>
                  <img
                    src={item.galleryMedia.thumbnailUrl}
                    alt="img"
                    className={styles.image}
                    onClick={() => {
                      setSelectedMedia(item.galleryMedia);
                    }}
                  />
                  {showUsernames && <p>@{item.user.username}</p>}
                </div>
              );
            else if (item.galleryMedia.type === 'video')
              return (
                <div
                  className={styles.videoWrap}
                  onClick={() => {
                    setSelectedMedia(item.galleryMedia);
                  }}
                >
                  <video
                    className={styles.image}
                    autoPlay={false}
                    onClick={() => {
                      setSelectedMedia(item.galleryMedia);
                    }}
                  >
                    <source src={item.galleryMedia.url} type="video/mp4" />
                  </video>
                  <Icon
                    icon={playButton}
                    size={'lg'}
                    className={styles.playButton}
                    onClick={() => {
                      setSelectedMedia(item.galleryMedia);
                    }}
                  />
                  {showUsernames && <p>@{item.user.username}</p>}
                </div>
              );
          })}
        </div>
      </div>
    </>
  );
};

export default Gallery;
