import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import {
  CHANGE_PRIORITY_REQUEST,
  DELETE_TOUR_CAMPAING_REQUEST,
  FETCH_TOUR_CAMPAIGNS_REQUEST,
  UPLOAD_TOUR_REQUEST,
} from './constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';

import { ENDPOINTS } from '../../constants';
import toasterVariants from 'constants/toasterVariants';
import {
  changeCampaignPrioritySuccess,
  deleteTourCampaignSuccess,
  fetchTourCampaignsSuccess,
  uploadTourSuccess,
} from './actions';
import { format } from 'util';

export function* uploadTour({ files }) {
  try {
    const formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    const data = yield call(request, {
      url: ENDPOINTS.UPLOAD_TOUR,
      method: 'post',
      data: formData,
    });
    yield put(
      enqueueSnackbar({
        message: 'Tour Campaign uploaded',
        variant: toasterVariants.success,
      })
    );
    yield put(uploadTourSuccess(data));
    const campaigns = yield call(request, {
      url: format(ENDPOINTS.TOUR_CAMPAIGNS, 1, 15, '', '', 'ASC'),
      method: 'get',
    });
    yield put(fetchTourCampaignsSuccess(campaigns, '', '', 'ASC'));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* fetchTourCampaigns({ page, perPage, search, sortBy, order }) {
  try {
    const campaigns = yield call(request, {
      url: format(
        ENDPOINTS.TOUR_CAMPAIGNS,
        page,
        perPage,
        search,
        sortBy,
        order
      ),
      method: 'get',
    });
    yield put(fetchTourCampaignsSuccess(campaigns, search, sortBy, order));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* changeCampaignPriority({ campaign, priority }) {
  try {
    const newCampaign = yield call(request, {
      url: ENDPOINTS.CHANGE_PRIORITY.replace('{campaignId}', campaign.id),
      data: { priority: priority.value },
      method: 'put',
    });
    yield put(changeCampaignPrioritySuccess(newCampaign));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* deleteCampaign({ campaign }) {
  try {
    yield call(request, {
      url: ENDPOINTS.DELTE_TOUR_CAMPAIGN + '/' + campaign.id,
      method: 'delete',
    });
    yield put(deleteTourCampaignSuccess(campaign));
  } catch (err) {}
}

export default function* starterSaga() {
  yield takeLatest(UPLOAD_TOUR_REQUEST, uploadTour);
  yield takeLatest(FETCH_TOUR_CAMPAIGNS_REQUEST, fetchTourCampaigns);
  yield takeLatest(CHANGE_PRIORITY_REQUEST, changeCampaignPriority);
  yield takeLatest(DELETE_TOUR_CAMPAING_REQUEST, deleteCampaign);
}
