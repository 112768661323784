import dashboardLogo from 'assets/images/logo-black.png';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './DashboardLayout.module.scss';
import { ADMINISTRATORS, ADVERTISERS, OBJECTIVES } from 'routes';
import { canActivate } from '../../../utils/permissions';
import {
  CHANGE_PASSWORD,
  LIST_ADVERTISERS,
  LIST_AUDIT_LOGS,
  LIST_LOGS,
  LIST_BUSINESS_RULES_VARIABLES,
  LIST_CAMPAIGNS,
  LIST_COUPONS,
  LIST_EYCROWD_TASKS,
  LIST_INSTANT_CAMPAIGNS,
  LIST_OBJECTIVES,
  LIST_TEMPLATES,
  LIST_TOUR_CAMPAIGNS,
  LIST_USERS,
  GET_STAFF_ACTIVITIES,
  LIST_ADMINISTRATORS,
  CREATE_TRANSFERS,
  CREATE_AI_PROMPTS,
  LIST_AI_PROMPT_LOGS,
  LIST_NOTICE_CARDS,
} from '../../../permisssions';

const SIDE_BAR_ITEMS = [
  {
    label: 'Advertisers',
    route: ADVERTISERS,
    permission: LIST_ADVERTISERS,
  },
  {
    label: 'Campaigns',
    route: '/campaigns',
    permission: LIST_CAMPAIGNS,
  },
  {
    label: 'Website Forms',
    route: '/website-forms',
    permission: null,
  },
  {
    label: 'Bulk Campaigns',
    route: '/bulk-campaigns',
    permission: null,
  },
  {
    label: 'Users',
    route: '/users',
    permission: LIST_USERS,
  },
  // {
  //   label: 'Supervisor Tasks',
  //   route: '/supervisor-tasks',
  //   permission: LIST_SUPERVISORS_TASKS,
  // },
  {
    label: 'Notice Cards',
    route: '/notice-cards',
    permission: LIST_NOTICE_CARDS,
  },
  {
    label: 'Business Rules',
    route: '/business-rules',
    permission: LIST_BUSINESS_RULES_VARIABLES,
  },
  {
    label: 'AI Prompts',
    route: '/ai',
    permission: CREATE_AI_PROMPTS,
  },
  {
    label: 'AI Prompt Logs',
    route: '/ai-prompt-logs',
    permission: LIST_AI_PROMPT_LOGS,
  },
  {
    label: 'Audit Logs',
    route: '/audit-logs',
    permission: LIST_AUDIT_LOGS,
  },
  {
    label: 'Logs',
    route: '/logs',
    permission: LIST_LOGS,
  },
  {
    label: 'EyCrowd Tasks',
    route: '/eycrowd-tasks',
    permission: LIST_EYCROWD_TASKS,
  },
  // {
  //   label: 'Feed Items',
  //   route: '/feed-items',
  //   permission: UPDATE_FEED_ITEM_PRIORITY,
  // },
  {
    label: 'Objectives',
    route: OBJECTIVES,
    permission: LIST_OBJECTIVES,
  },
  {
    label: 'Instant campaign',
    route: '/starter-campaign',
    permission: LIST_INSTANT_CAMPAIGNS,
  },
  // {
  //   label: 'Sales example campaigns',
  //   route: '/sales-example-campaigns',
  //   permission: null
  // },
  {
    label: 'Templates',
    route: '/templates',
    permission: LIST_TEMPLATES,
  },
  {
    label: 'Tour campaigns',
    route: '/tour-campaigns',
    permission: LIST_TOUR_CAMPAIGNS,
  },
  // {
  //   label: 'Analytics',
  //   route: '/analytics',
  //   permission: null
  // },
  {
    label: 'Transfer',
    route: '/transfer',
    permission: CREATE_TRANSFERS,
  },
  {
    label: 'Coupon Generator',
    route: '/coupons',
    permission: LIST_COUPONS,
  },
  {
    label: 'Staff Activity',
    route: '/staff-activity',
    permission: GET_STAFF_ACTIVITIES,
  },
  {
    label: 'Administrators',
    route: ADMINISTRATORS,
    permission: LIST_ADMINISTRATORS,
  },
  {
    label: 'Change password',
    route: '/change-password',
    permission: CHANGE_PASSWORD,
  },
];

const DashboardSideBar = () => (
  <div className={styles.sideBar}>
    <div className={styles.logoWrapper}>
      <img src={dashboardLogo} alt="EyCrowd logo" />
    </div>
    <ul className={styles.list}>
      {SIDE_BAR_ITEMS.map((item) =>
        canActivate(item.permission) ? (
          <li className={styles.listItem} key={item.label}>
            <NavLink
              to={item.route}
              activeClassName={styles.linkActive}
              className={styles.link}
            >
              {item.label}
            </NavLink>
          </li>
        ) : null
      )}
    </ul>
  </div>
);

export default DashboardSideBar;
