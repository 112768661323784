import React from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './Tooltip.module.scss';

const Tooltip = ({ label, numOfCharacters = 20 }) => {
  return (
    <div data-tip data-for={label}>
      {label?.length > numOfCharacters ? (
        <div>
          <p>{label.substring(0, numOfCharacters)}...</p>
          <ReactTooltip
            arrowColor="transparent"
            className={styles.tooltipOverride}
            id={label}
            place="top"
            effect="solid"
          >
            <p className={styles.label}>{label}</p>
          </ReactTooltip>
        </div>
      ) : (
        <p>{label}</p>
      )}
    </div>
  );
};

export default Tooltip;
