import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import {
  fetchStaffUsersListSuccess,
  getStaffActivityError,
  getStaffActivitySuccess,
} from './actions';
import {
  FETCH_STAFF_USERS_LIST_REQUEST,
  GET_STAFF_ACTIVITY_ATTEMPT,
} from './constants';
import { ENDPOINTS } from '../../constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { format } from 'util';

export function* getStaffActivity({ dateFrom, dateTo, staffUserId }) {
  try {
    const staffActivity = yield call(request, {
      url: ENDPOINTS.GET_STAFF_ACTIVITY.replace('{dateFrom}', dateFrom)
        .replace('{dateTo}', dateTo)
        .replace('{staffUserId}', staffUserId),
      method: 'get',
    });
    yield put(getStaffActivitySuccess(staffActivity));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
    console.error(error);
    yield put(getStaffActivityError(error));
  }
}

export function* fetchStaffUsersListRequest({
  page,
  perPage,
  search,
  sortBy,
  order,
}) {
  let isStaff = true;

  try {
    const users = yield call(request, {
      url: format(
        ENDPOINTS.USERS_PAGE,
        page,
        perPage,
        search,
        sortBy,
        order,
        isStaff
      ),
      method: 'get',
    });
    yield put(fetchStaffUsersListSuccess(users, search, sortBy, order));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export default function* staffActivityPageSaga() {
  yield takeLatest(GET_STAFF_ACTIVITY_ATTEMPT, getStaffActivity);
  yield takeLatest(FETCH_STAFF_USERS_LIST_REQUEST, fetchStaffUsersListRequest);
}
