import {
  CHANGE_PRIORITY_REQUEST,
  CHANGE_PRIORITY_SUCCESS,
  DELETE_TOUR_CAMPAING_REQUEST,
  DELETE_TOUR_CAMPAING_SUCCESS,
  FETCH_TOUR_CAMPAIGNS_REQUEST,
  FETCH_TOUR_CAMPAIGNS_SUCCESS,
  UPLOAD_TOUR_REQUEST,
  UPLOAD_TOUR_SUCCESS,
} from './constants';
import { ORDER } from '../../constants';

export function uploadTourRequest(files) {
  return {
    type: UPLOAD_TOUR_REQUEST,
    files,
  };
}

export function uploadTourSuccess(starter) {
  return {
    type: UPLOAD_TOUR_SUCCESS,
    starter,
  };
}

export function fetchTourCampaignsRequest(
  page,
  perPage,
  search = '',
  sortBy = '',
  order = ORDER.ASC
) {
  return {
    type: FETCH_TOUR_CAMPAIGNS_REQUEST,
    page,
    perPage,
    search,
    sortBy,
    order,
  };
}

export function fetchTourCampaignsSuccess(campaigns, search, sortBy, order) {
  return {
    type: FETCH_TOUR_CAMPAIGNS_SUCCESS,
    campaigns,
    search,
    sortBy,
    order,
  };
}

export function deleteTourCampaignRequest(campaign) {
  return {
    type: DELETE_TOUR_CAMPAING_REQUEST,
    campaign,
  };
}

export function deleteTourCampaignSuccess(campaign) {
  return {
    type: DELETE_TOUR_CAMPAING_SUCCESS,
    campaign,
  };
}

export function changeCampaignPriorityRequest(campaign, priority) {
  return {
    type: CHANGE_PRIORITY_REQUEST,
    campaign,
    priority,
  };
}

export function changeCampaignPrioritySuccess(campaign) {
  return {
    type: CHANGE_PRIORITY_SUCCESS,
    campaign,
  };
}
