import DashboardLayout from 'components/Layout/DashboardLayout';
import React, { useEffect, useMemo, useState } from 'react';
import styles from './TransferMoneyScreen.module.scss';
import AsyncSelect from 'react-select/async';
import { getApi } from 'utils/request';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'components/Buttons/Button';
import saga from './saga';
import { useInjectSaga } from 'utils/injectSaga';
import { transferRequest } from './actions';
import { canActivate } from '../../utils/permissions';
import { CREATE_TRANSFERS } from '../../permisssions';

const key = 'transfer';

const TransferMoneyPage = () => {
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();
  const [userOptions, setUserOptions] = useState([]);
  const [campaignOptions, setCampaignOptions] = useState([]);
  const token = useSelector((state) => state.app.token);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCampaign, setSelectedCampaigns] = useState(null);
  // noinspection JSUnusedLocalSymbols
  const [amount, setAmount] = useState(null);
  const [secret, setSecret] = useState(null);
  const [userRoleValue, setUserRoleValue] = useState(null);

  const fetchUserOptions = async (term) => {
    const api = getApi();
    const response = await api.get(
      `/admin/users?page=1&perPage=25&search=${term}&sortBy=createdAt&order=DESC`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data.map((user) => {
      return {
        value: user,
        label: user.username,
      };
    });
  };

  const duration = useMemo(() => {
    let diffInMilliSeconds = Math.abs(
      new Date(selectedCampaign?.startDate) -
        new Date(selectedCampaign?.endDate)
    );
    return diffInMilliSeconds / 3600 / 1000;
  }, [selectedCampaign]);

  useEffect(() => {
    if (selectedCampaign && selectedUser) {
      const userEarnings =
        selectedCampaign.pricePerHour *
        duration *
        (selectedCampaign.campaignUsers.find(
          (user) => user.userId === selectedUser.id
        ).isSupervisor
          ? 0.7
          : 0.6);
      setUserRoleValue(userEarnings);
    }
  }, [selectedCampaign, selectedUser, duration]);

  const fetchCampaignOptions = async (term) => {
    if (!selectedUser) return [];
    const api = getApi();
    let response;
    if (term === '') {
      response = await api.get(
        `admin/payment/unpaid-campaigns/${selectedUser?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      response = await api.get(
        `admin/payment/unpaid-campaigns/${selectedUser?.id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }

    const retItems = response?.map((campaign) => {
      return {
        value: campaign,
        label: campaign.name + ' ' + campaign.company.name,
      };
    });
    setCampaignOptions(retItems);
    return retItems;
  };

  useEffect(() => {
    const fetchInitOptions = async () => {
      const users = await fetchUserOptions('');
      setUserOptions(users);
      const campaigns = await fetchCampaignOptions('');
      setCampaignOptions(campaigns);
    };
    // noinspection JSIgnoredPromiseFromCall
    fetchInitOptions();
  }, []);

  useEffect(() => {
    const fetchCampaignOptionsAsync = async () => {
      const campaigns = await fetchCampaignOptions('');
      // setCampaignOptions([{ label: 'label', value: 'value' }]);
      setCampaignOptions(campaigns);
    };
    if (selectedUser) {
      // noinspection JSIgnoredPromiseFromCall
      fetchCampaignOptionsAsync();
    }
  }, [selectedUser]);

  return canActivate(CREATE_TRANSFERS) ? (
    <DashboardLayout>
      <div className={styles.flexColumn}>
        <div className={styles.inputWrapperSm}>
          <p>Pick a user</p>
          <AsyncSelect
            onInputChange={(e) => {
              fetchUserOptions(e);
            }}
            loadOptions={fetchUserOptions}
            defaultOptions={userOptions}
            onChange={(e) => {
              setSelectedUser(e.value);
              fetchCampaignOptions('');
            }}
            styles={{
              container: (provided) => ({
                ...provided,
                width: '100%',
              }),
            }}
          />
        </div>
        <div className={styles.inputWrapperSm}>
          <p>Pick a campaign</p>
          <AsyncSelect
            isSearchable={false}
            isDisabled={!selectedUser}
            loadOptions={fetchCampaignOptions}
            defaultOptions={campaignOptions}
            onChange={(e) => {
              setSelectedCampaigns(e.value);
            }}
            styles={{
              container: (provided) => ({
                ...provided,
                width: '100%',
              }),
            }}
          />
        </div>
        <div className={styles.inputWrapperSm}>
          <p>Amount</p>
          <div className={styles.inputField}>
            <input
              className={styles.input}
              type="number"
              placeholder="Amount"
              value={userRoleValue ? userRoleValue.toFixed(2) : null}
              disabled={true}
              onChange={(e) => {
                setAmount(Math.round(e.target.value));
              }}
            />
          </div>
        </div>
        <div className={styles.inputWrapperSm}>
          <p>Enter secret</p>
          <div className={styles.inputField}>
            <input
              name="secret"
              className={styles.input}
              type="text"
              placeholder="Enter secret"
              onChange={(e) => {
                setSecret(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={styles.buttonWrap}>
          <Button
            type="submit"
            disabled={
              secret === null ||
              userRoleValue === null ||
              selectedCampaign === null ||
              selectedUser === null
            }
            title="Submit"
            alt
            className={styles.submitButton}
            onClick={() => {
              dispatch(
                transferRequest({
                  userId: selectedUser.id,
                  campaignId: selectedCampaign.id,
                  secret: secret,
                })
              );
            }}
          />
        </div>
      </div>
    </DashboardLayout>
  ) : null;
};

export default TransferMoneyPage;
