import { defineMessages } from 'react-intl';

export const scope = 'single_task_page';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the SingleTaskPage container!',
  },
  taskCreated: {
    id: `${scope}.task_created`,
    defaultMessage: 'Task successfully created',
  },
  taskDescription: {
    id: `${scope}.text.task_description`,
    defaultMessage: 'Task description',
  },
  campaignType: {
    id: `${scope}.text.campaign_type`,
    defaultMessage: 'Campaign type',
  },
  createTask: {
    id: `${scope}.text.createTask`,
    defaultMessage: 'Create task',
  },
});
