import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { makeSelectObjectives } from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import DashboardLayout from 'components/Layout/DashboardLayout';
import ResourceTable from 'components/ResourceTable';
import { deleteObjectiveRequest, fetchObjectivesListRequest } from './actions';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import { useConfirmationModal } from 'components/ConfirmationModal';
import { CREATE_OBJECTIVE } from 'routes';
import { useHistory } from 'react-router';
import { Fragment } from 'react';
import styles from 'components/ResourceTable/Table.module.scss';
import Icon from 'components/Icon';
import { ReactComponent as recycleBinIcon } from 'assets/images/delete.svg';
import Tooltip from 'components/Tooltip';
import {
  CREATE_OBJECTIVES,
  DELETE_OBJECTIVES,
  LIST_OBJECTIVES,
} from '../../permisssions';
import { canActivate } from '../../utils/permissions';

const key = 'objectives';

function ObjectivesPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const dispatch = useDispatch();

  const [openConfirmationModal] = useConfirmationModal();

  const history = useHistory();
  const { formatMessage } = useIntl();

  const objectives = useSelector(makeSelectObjectives());

  useEffect(() => {
    dispatch(fetchObjectivesListRequest());
  }, [dispatch]);

  const handleDeleteObjective = (id) => {
    openConfirmationModal(formatMessage(messages.areYouSure), () =>
      dispatch(deleteObjectiveRequest(id))
    );
  };

  const tableHeader = [
    { label: formatMessage(messages.title) },
    { label: formatMessage(messages.campaignType) },
    { label: formatMessage(messages.info) },
  ];

  return (
    <div>
      <DashboardLayout>
        <DashboardMainHeader
          titleMain="Objectives"
          btnText={formatMessage(messages.createObjective)}
          onClick={() => history.push(CREATE_OBJECTIVE)}
          canAccess={canActivate(CREATE_OBJECTIVES)}
        />
        <ResourceTable
          hasExtraActions
          headerInformation={tableHeader}
          hasMore={false}
          rowsNumber={objectives.length}
          noDataContent={formatMessage(messages.noObjectives)}
          hidePagination
          canAccess={canActivate(LIST_OBJECTIVES)}
        >
          {objectives.map((objective, index) => {
            return (
              <Fragment key={objective.id}>
                <TableColumnField
                  data={
                    <Tooltip label={objective.title} numOfCharacters={40} />
                  }
                />
                <TableColumnField data={objective.description} />
                <TableColumnField
                  data={<Tooltip label={objective.info} numOfCharacters={40} />}
                />

                <td className={styles.td}>
                  {canActivate(DELETE_OBJECTIVES) && (
                    <button
                      className={styles.actionButton}
                      onClick={() => handleDeleteObjective(objective.id)}
                    >
                      <Icon icon={recycleBinIcon} size={'sm20'} />
                    </button>
                  )}
                </td>
              </Fragment>
            );
          })}
        </ResourceTable>
      </DashboardLayout>
    </div>
  );
}

export default ObjectivesPage;
