import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import DashboardLayout from 'components/Layout/DashboardLayout';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import { Link, useHistory } from 'react-router-dom';
import styles from './StaffActivityPage.module.scss';
import { fetchStaffUsersListRequest, setSelectedStaffUser } from './actions';
import { makeSelectStaffUsers } from './selectors';
import { DATE_FORMAT } from '../../constants';
import globalMessages from 'messages';
import { canActivate } from 'utils/permissions';
import { GET_STAFF_ACTIVITIES } from 'permisssions';
import ResourceTable from 'components/ResourceTable';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import Tooltip from 'components/Tooltip';
import { formatDate } from 'utils/date';
import { SINGLE_STAFF_ACTIVITY } from 'routes';
import messages from './messages';
import classNames from 'classnames';
import Button from 'components/Buttons/Button';
import eyCrowdIcon from '../../assets/images/icon-eycrowd.png';

const key = 'staffActivity';

function StaffActivityPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const history = useHistory();

  const redirectToStaffUserActivityOverview = (user) => {
    dispatch(setSelectedStaffUser(user));
    history.push(SINGLE_STAFF_ACTIVITY.replace(':id', user.id), user);
  };

  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const staffUsers = useSelector(makeSelectStaffUsers());

  useEffect(() => {
    dispatch(
      fetchStaffUsersListRequest(
        localStorage.getItem('pageNumberStaffUsers')
          ? localStorage.getItem('pageNumberStaffUsers')
          : 1,
        localStorage.getItem('perPageStaffUsers')
          ? localStorage.getItem('perPageStaffUsers')
          : 25
      )
    );
  }, [dispatch]);

  const { data, perPage, total, search, sortBy, order } = staffUsers || [];

  // const fetchMoreUsers = () => {
  //   dispatch(fetchUsersListRequest(page + 1, perPage, search, sortBy, order));
  // };

  const fetchUsersPage = (pageNumber) => {
    localStorage.setItem('pageNumberStaffUsers', pageNumber);
    dispatch(
      fetchStaffUsersListRequest(pageNumber, perPage, search, sortBy, order)
    );
  };

  const tableHeader = [
    {
      key: 'username',
      label: formatMessage(messages.username),
      sortable: true,
    },
    { key: 'name', label: formatMessage(messages.name), sortable: true },
    {
      key: 'dateOfBirth',
      label: formatMessage(messages.dateOfBirth),
      sortable: true,
    },
    { key: 'gender', label: formatMessage(messages.gender) },
    { key: 'city', label: formatMessage(messages.city), sortable: true },
    {
      key: 'createdAt',
      label: formatMessage(messages.createdAt),
      sortable: true,
    },
    {
      key: 'activity',
      label: '',
      sortable: false,
    },
  ];

  const handleChange = (search) => {
    dispatch(fetchStaffUsersListRequest(1, perPage, search));
  };

  const handleSort = (sortBy, order) => {
    dispatch(fetchStaffUsersListRequest(1, perPage, search, sortBy, order));
  };

  const handlePerPage = (perPage) => {
    localStorage.setItem('perPageStaffUsers', perPage);
    localStorage.setItem('pageNumberStaffUsers', 1);

    dispatch(fetchStaffUsersListRequest(1, perPage, search, sortBy, order));
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardMainHeader
          titleMain={formatMessage(messages.header)}
          totalRows={staffUsers.total}
          onClick={redirectToStaffUserActivityOverview}
          search
          onChange={handleChange}
          canAccess={canActivate(GET_STAFF_ACTIVITIES)}
        />
        <ResourceTable
          rowsNumber={data ? data.length : 0}
          // loadMore={fetchMoreUsers}
          hasMore={data?.length < total}
          headerInformation={tableHeader}
          noDataContent={formatMessage(messages.noUsers)}
          handleSort={handleSort}
          fetchPage={fetchUsersPage}
          resource={staffUsers}
          perPage={
            localStorage.getItem('perPageStaffUsers')
              ? localStorage.getItem('perPageStaffUsers')
              : perPage
          }
          handlePerPage={handlePerPage}
          canAccess={canActivate(GET_STAFF_ACTIVITIES)}
        >
          {data.map((user, index) => {
            return (
              <Fragment key={user.id}>
                {canActivate(GET_STAFF_ACTIVITIES) && (
                  <TableColumnField
                    data={
                      <Link
                        to={{
                          pathname: SINGLE_STAFF_ACTIVITY.replace(
                            ':id',
                            user.id
                          ),
                        }}
                        onClick={() => dispatch(setSelectedStaffUser(user))}
                      >
                        <>
                          <p className={styles.underlinedUsername}>
                            {user.username}
                          </p>
                        </>
                        {/* <Tooltip label={user.username} /> */}
                      </Link>
                    }
                  />
                )}
                <TableColumnField
                  data={
                    <div className={styles.userFullName}>
                      <Tooltip label={user.firstName + ' ' + user.lastName} />
                      {user.isStaff ? (
                        <img
                          className={styles.eyCrowdIcon}
                          src={eyCrowdIcon}
                          alt="isStaff-icon"
                        />
                      ) : null}
                    </div>
                  }
                />
                <TableColumnField
                  data={formatDate(user.dateOfBirth, DATE_FORMAT.YEAR)}
                />
                <TableColumnField
                  data={
                    user.gender && formatMessage(globalMessages[user.gender])
                  }
                />
                <TableColumnField
                  data={user.city ? <Tooltip label={user.city} /> : user.city}
                />
                <TableColumnField
                  data={formatDate(
                    user.createdAt,
                    DATE_FORMAT.MONTH_DAY_YEAR_TIME
                  )}
                />
                {canActivate(GET_STAFF_ACTIVITIES) && (
                  <TableColumnField
                    data={
                      <Button
                        className={classNames(
                          styles.button,
                          styles.requestChangesButton
                        )}
                        type="submit"
                        title={'View Activity'}
                        onClick={() =>
                          redirectToStaffUserActivityOverview(user)
                        }
                      />
                    }
                  />
                )}
              </Fragment>
            );
          })}
        </ResourceTable>
      </DashboardLayout>
    </div>
  );
}

export default StaffActivityPage;
