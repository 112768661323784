import Yup from 'utils/validations';

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8)
    .max(100)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
    .required(),
  passwordConfirmation: Yup.string()
    .required()
    .oneOf([Yup.ref('password')], {
      id: 'global.validations.passwords_dont_match',
    }),
});
