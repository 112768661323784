import { defineMessages } from 'react-intl';

export const scope = 'campaign_users_list';

export default defineMessages({
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Remove',
  },
  promote: {
    id: `${scope}.promote`,
    defaultMessage: 'Promote',
  },
  demote: {
    id: `${scope}.demote`,
    defaultMessage: 'Demote',
  },
});
