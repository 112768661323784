import { ReactComponent as PlusSign } from 'assets/images/add.svg';
import { ReactComponent as ChevronLeftIcon } from 'assets/images/chevron-left.svg';
import classNames from 'classnames';
import Button from 'components/Buttons/Button';
import SearchField from 'components/Forms/SearchField';
import Icon from 'components/Icon';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './DashboardLayout.module.scss';

const DashboardMainHeader = ({
  titleMain,
  title,
  search,
  icon,
  btnText,
  status,
  linkTo,
  canAccess,
  dashboardHeaderButtonLeft,
  onClick,
  isPending,
  onChange,
  cancel,
  onCancel,
  totalRows,
  secondaryBtnText,
  blockUserBtnText,
  onSecondaryBtnClick,
  isBlockPending,
  onBlock,
  ...props
}) => {
  return (
    <>
      {linkTo && (
        <Link className={styles.return} to={linkTo}>
          <Icon className={styles.icon} icon={ChevronLeftIcon} />
          <span className={styles.returnText}>Return</span>
        </Link>
      )}
      <div className={styles.mainHeadeWrapper}>
        {cancel && (
          <button className={styles.cancelButtonWrap} onClick={onCancel}>
            Cancel
          </button>
        )}
        <div>
          {titleMain && (
            <div className={styles.titleMainContainer}>
              <h3 className={styles.titleMain}>{titleMain}</h3>
              {totalRows && <p>({totalRows} total)</p>}
            </div>
          )}
          {title && <h4 className={styles.title}>{title}</h4>}
        </div>
        <div className={styles.mainHeadeRightWrapper}>
          {search && <SearchField onChange={onChange} />}
          {btnText && canAccess && (
            <Button
              disabled={isPending}
              onClick={onClick}
              className={classNames(styles.dashboardHeaderButton, {
                [styles.dashboardHeaderButtonLeft]: dashboardHeaderButtonLeft,
              })}
              alt
            >
              {icon && (
                <Icon
                  className={styles.createCampaignButtonIcon}
                  icon={PlusSign}
                  size={'sm14'}
                />
              )}
              {btnText}
            </Button>
          )}
          {secondaryBtnText && (
            <Button
              disabled={isPending}
              onClick={onSecondaryBtnClick}
              className={classNames(styles.dashboardHeaderButton, {
                [styles.dashboardHeaderButtonLeft]: dashboardHeaderButtonLeft,
              })}
              alt
            >
              {icon && (
                <Icon
                  className={styles.createCampaignButtonIcon}
                  icon={PlusSign}
                  size={'sm14'}
                />
              )}
              {secondaryBtnText}
            </Button>
          )}
          {blockUserBtnText && (
            <Button
              disabled={isBlockPending}
              onClick={onBlock}
              className={classNames(styles.dashboardHeaderButton, {
                [styles.dashboardHeaderButtonLeft]: dashboardHeaderButtonLeft,
              })}
              alt
            >
              {icon && (
                <Icon
                  className={styles.createCampaignButtonIcon}
                  icon={PlusSign}
                  size={'sm14'}
                />
              )}
              {blockUserBtnText}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};
export default DashboardMainHeader;
