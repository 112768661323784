import { defineMessages } from 'react-intl';

export const scope = 'logs';

export default defineMessages({
  logs: {
    id: `${scope}.logs`,
    defaultMessage: 'Logs',
  },
  noLogs: {
    id: `${scope}.noLogs`,
    defaultMessage: 'No Logs',
  },
  log: {
    id: `${scope}.log`,
    defaultMessage: 'Log',
  },
});
