import React from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';

function Button({
  className,
  title,
  children,
  disabled,
  buttonReverse,
  isLarge,
  alt,
  altReverse,
  ...rest
}) {
  const buttonClasses = classNames(
    styles.button,
    {
      [styles.buttonReverse]: buttonReverse,
      [styles.alt]: alt,
      [styles.disabled]: disabled,
      [styles.buttonLg]: isLarge,
      [styles.altReverse]: altReverse,
    },
    className
  );
  return (
    <button disabled={disabled} className={buttonClasses} {...rest}>
      {children} {title}
    </button>
  );
}

export default Button;
