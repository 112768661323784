import DashboardLayout from 'components/Layout/DashboardLayout';
import React, { Fragment, useEffect, useState } from 'react';
import reducer from './reducer';
import saga from './saga';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeCampaignPriorityRequest,
  deleteStarterCampaignRequest,
  fetchStarterCampaignsRequest,
  uploadStarterRequest,
} from './actions';
import { useIntl } from 'react-intl';
import { makeSelectStarterCampaigns } from './selectors';
import messages from './messages';
import globalMessages from './../../messages';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import ResourceTable from 'components/ResourceTable';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import Select from 'react-select';
import { ReactComponent as recycleBinIcon } from 'assets/images/delete.svg';
import Icon from 'components/Icon';
import styles from 'components/ResourceTable/Table.module.scss';
import UploadInstantCampaign from './UploadInstantCampaign';
import { ReactComponent as copyIcon } from 'assets/images/copy.svg';
import eyCrowdIcon from '../../assets/images/icon-eycrowd.png';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import toasterVariants from 'constants/toasterVariants';
import { canActivate } from '../../utils/permissions';
import {
  CREATE_INSTANT_CAMPAIGNS,
  DELETE_INSTANT_CAMPAIGNS,
  LIST_INSTANT_CAMPAIGNS,
} from '../../permisssions';
import { useHistory } from 'react-router-dom';

const key = 'starter';

const StarterCampaignPage = () => {
  const { formatMessage } = useIntl();
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();

  const options = [
    { value: 'very-low', label: 'Very low' },
    { value: 'low', label: 'Low' },
    { value: 'medium', label: 'Medium' },
    { value: 'high', label: 'High' },
    { value: 'very-high', label: 'Very high' },
  ];

  const CampaignGenderSelection = {
    'all-genders': formatMessage(messages.allGenders),
    'mostly-male': formatMessage(messages.mostlyMale),
    'mostly-female': formatMessage(messages.mostlyFemale),
    male: formatMessage(messages.onlyMale),
    female: formatMessage(messages.onlyFemale),
  };

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const campaigns = useSelector(makeSelectStarterCampaigns());
  const { data, perPage, total, search, sortBy, order } = campaigns || [];

  const dispatch = useDispatch();

  const onUpload = (
    files,
    isPrivate,
    isEySpecial,
    pointsRequired,
    tourCampaignId
  ) => {
    dispatch(
      uploadStarterRequest({
        files,
        isPrivate: isPrivate,
        isEySpecial: isEySpecial,
        pointsRequired: pointsRequired,
        tourCampaignId,
      })
    );
  };

  const getCampaignSelectedOption = (optionValue) => {
    return options.find((o) => o.value === optionValue);
  };

  useEffect(() => {
    dispatch(
      fetchStarterCampaignsRequest(
        localStorage.getItem('pageNumberCampaigns')
          ? localStorage.getItem('pageNumberCampaigns')
          : 1,
        localStorage.getItem('perPage') ? localStorage.getItem('perPage') : 25
      )
    );
  }, [dispatch]);

  const fetchPageCampaigns = (pageNumber) => {
    localStorage.setItem('pageNumberCampaigns', pageNumber);
    dispatch(
      fetchStarterCampaignsRequest(pageNumber, perPage, search, sortBy, order)
    );
  };

  const tableHeader = [
    {
      key: 'campaignName',
      label: formatMessage(messages.campaignName),
      sortable: true,
    },
    {
      key: 'pricePerHour',
      label: formatMessage(globalMessages.price),
      sortable: true,
    },
    { key: 'genders', label: formatMessage(messages.genders) },
    {
      key: 'priority',
      label: 'Priority',
    },
    {
      key: 'actions',
      label: 'Actions',
    },
  ];

  const handleChange = (search) => {
    dispatch(fetchStarterCampaignsRequest(1, perPage, search, sortBy, order));
  };

  const handleSort = (sortBy, order) => {
    dispatch(fetchStarterCampaignsRequest(1, perPage, search, sortBy, order));
  };

  const handlePerPage = (perPage) => {
    localStorage.setItem('perPage', perPage);
    localStorage.setItem('pageNumberUsers', 1);
    localStorage.setItem('pageNumberCampaigns', 1);
    localStorage.setItem('pageNumberAdvertisers', 1);
    dispatch(fetchStarterCampaignsRequest(1, perPage, search, sortBy, order));
  };

  return (
    <DashboardLayout>
      <UploadInstantCampaign
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onUpload={onUpload}
        close={() => setModalOpen(false)}
      />
      <DashboardMainHeader
        titleMain={'Instant campaigns'}
        totalRows={campaigns.total}
        search
        btnText={'Upload instant campaign'}
        onClick={() => setModalOpen(true)}
        onChange={handleChange}
        canAccess={canActivate(CREATE_INSTANT_CAMPAIGNS)}
      />
      <ResourceTable
        rowsNumber={data ? data.length : 0}
        hasMore={data?.length < total}
        fetchPage={fetchPageCampaigns}
        resource={campaigns}
        headerInformation={tableHeader}
        noDataContent={formatMessage(messages.noCampaigns)}
        handleSort={handleSort}
        perPage={localStorage.getItem('perPage')}
        handlePerPage={handlePerPage}
        canAccess={canActivate(LIST_INSTANT_CAMPAIGNS)}
      >
        {data.map((campaign) => {
          return (
            <Fragment key={campaign.id}>
              <TableColumnField
                data={
                  <div className={styles.campaignName}>
                    <p>{campaign.name}</p>
                    {campaign.isPrivate && (
                      <img
                        className={styles.eyCrowdIcon}
                        src={eyCrowdIcon}
                        alt="isStaff-icon"
                      />
                    )}
                  </div>
                }
              ></TableColumnField>
              <TableColumnField data={campaign.pricePerHour} />
              <TableColumnField
                data={CampaignGenderSelection[campaign.genderSelection]}
              />
              <TableColumnField
                data={
                  <div style={{ elevation: 2000, zIndex: 1000 }}>
                    <Select
                      value={getCampaignSelectedOption(campaign.priority)}
                      onChange={(e) => {
                        dispatch(changeCampaignPriorityRequest(campaign, e));
                      }}
                      options={options}
                    />
                  </div>
                }
              />
              <TableColumnField
                data={
                  <div className={styles.actionsWrap}>
                    {canActivate(DELETE_INSTANT_CAMPAIGNS) && (
                      <button
                        className={styles.actionButton}
                        onClick={() => {
                          dispatch(deleteStarterCampaignRequest(campaign));
                        }}
                      >
                        <div className={styles.campaignName}>
                          <Icon icon={recycleBinIcon} size={'sm20'} />
                        </div>
                      </button>
                    )}

                    {campaign.isPrivate && (
                      <button
                        className={styles.actionButton}
                        onClick={() => {
                          dispatch(
                            enqueueSnackbar({
                              message: 'Copied',
                              variant: toasterVariants.success,
                            })
                          );
                          navigator.clipboard.writeText(
                            campaign.invitationLink
                          );
                        }}
                      >
                        <div className={styles.campaignName}>
                          <Icon icon={copyIcon} size={'sm20'} />
                        </div>
                      </button>
                    )}
                    <button
                      className={styles.actionButton}
                      style={{
                        border: '1px solid gray',
                        borderRadius: '5px',
                        padding: '5px',
                      }}
                      onClick={() => {
                        history.push(`starter-campaign/${campaign.id}`);
                      }}
                    >
                      View content
                    </button>
                  </div>
                }
              />
            </Fragment>
          );
        })}
      </ResourceTable>
    </DashboardLayout>
  );
};

export default StarterCampaignPage;
