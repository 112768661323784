export const getVisiblePageNumbers = (
  numOfVisibleItems,
  pageArray,
  visibleNumber
) => {
  const index = pageArray.indexOf(visibleNumber);
  const visibleNumberOffset = Math.floor(numOfVisibleItems / 2);
  let beginIndex = 0;

  if (index < visibleNumberOffset) {
    beginIndex = 0;
  } else {
    beginIndex = index - visibleNumberOffset;
  }

  let endIndex = beginIndex + numOfVisibleItems;

  if (endIndex > pageArray.length && pageArray.length > numOfVisibleItems) {
    beginIndex = pageArray.length - numOfVisibleItems;
    endIndex = pageArray.length;
  }

  const visibleNumbers = pageArray.slice(
    beginIndex,
    beginIndex + numOfVisibleItems
  );

  return visibleNumbers;
};

export const getNumOfPages = (total, perPage) => {
  return Math.ceil(total / perPage);
};

export const getPageArray = (numOfPages) => {
  const pageNumbers = [];
  let i = 1;
  while (i <= numOfPages) {
    pageNumbers.push(i);
    i++;
  }
  return pageNumbers;
};
