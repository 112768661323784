import { defineMessages } from 'react-intl';

export const scope = 'global';

export default defineMessages({
  email: {
    id: `${scope}.validations.email`,
    defaultMessage: '{label} must be a valid email',
  },
  required: {
    id: `${scope}.validations.required`,
    defaultMessage: '{label} is required',
  },
  oneOf: {
    id: `${scope}.validations.one_of`,
    defaultMessage: '{label} must match with {value}',
  },
  sessionExpired: {
    id: `${scope}.session_expired`,
    defaultMessage: 'Your session has expired',
  },
  min: {
    id: `${scope}.validations.min`,
    defaultMessage: '{label} must have at least {minValue} characters',
  },
  max: {
    id: `${scope}.validations.max`,
    defaultMessage: '{label} must have max {maxValue} characters',
  },
  integer: {
    id: `${scope}.validations.integer`,
    defaultMessage: '{label} must be a whole number',
  },
  positive: {
    id: `${scope}.validations.positive`,
    defaultMessage: '{label} must be a positive number',
  },
  matches: {
    id: `${scope}.validations.matches`,
    defaultMessage: '{label} must contain uppercase, lowercase and digits',
  },
  somethingWentWrong: {
    id: `${scope}.errors.something_went_wrong`,
    defaultMessage: 'Something went wrong',
  },
  unauthorized: {
    id: `${scope}.errors.unauthorized`,
    defaultMessage: 'Unauthorized',
  },
  male: {
    id: `${scope}.text.male`,
    defaultMessage: 'Male',
  },
  female: {
    id: `${scope}.text.female`,
    defaultMessage: 'Female',
  },
  custom: {
    id: `${scope}.text.custom`,
    defaultMessage: 'Custom',
  },
  usernameTaken: {
    id: `${scope}.text.usernameTaken`,
    defaultMessage: 'Username is already taken',
  },
  usernameTooLong: {
    id: `${scope}.text.usernameTooLong`,
    defaultMessage: 'Username must not exceed 20 characters',
  },
  usernameTooShort: {
    id: `${scope}.text.usernameTooShort`,
    defaultMessage: 'Username must be longer than or equal to 3 characters',
  },
  businessRules: {
    campaignMinStartDate: {
      id: `${scope}.business_rules.campaignMinStartDate`,
      defaultMessage: 'Campaign min start date (days)',
    },
    campaignMaxStartDate: {
      id: `${scope}.business_rules.campaignMaxStartDate`,
      defaultMessage: 'Campaign max start date (days)',
    },
    campaignMinDuration: {
      id: `${scope}.business_rules.campaignMinDuration`,
      defaultMessage: 'Campaign min duration (hours)',
    },
    campaignMaxDuration: {
      id: `${scope}.business_rules.campaignMaxDuration`,
      defaultMessage: 'Campaign max duration (hours)',
    },
    campaignMinNumOfEyvocatesSelector: {
      id: `${scope}.business_rules.campaignMinNumOfEyvocatesSelector`,
      defaultMessage: 'Campaign min number of EyVocates',
    },
    campaignMaxNumOfEyvocatesSelector: {
      id: `${scope}.business_rules.campaignMaxNumOfEyvocatesSelector`,
      defaultMessage: 'Campaign max number of EyVocates',
    },
    campaignDefNumOfEyvocatesSelector: {
      id: `${scope}.business_rules.campaignDefNumOfEyvocatesSelector`,
      defaultMessage: 'Campaign default num of EyVocates',
    },
    campaignMinNumOfAppliedEyvocates: {
      id: `${scope}.business_rules.campaignMinNumOfAppliedEyvocates`,
      defaultMessage: 'Campaign min number of applied EyVocates',
    },
    campaignMinPricePerHour: {
      id: `${scope}.business_rules.campaignMinPricePerHour`,
      defaultMessage: 'Campaign min price per hour',
    },
    campaignDefaultPricePerHour: {
      id: `${scope}.business_rules.campaignDefaultPricePerHour`,
      defaultMessage: 'Campaign default price per hour',
    },
    campaignFollowUpTasksDurationDefaultValue: {
      id: `${scope}.business_rules.campaignFollowUpTasksDurationDefaultValue`,
      defaultMessage: 'Campaign follow up tasks default duration (minutes)',
    },
    customersFreeSlots: {
      id: `${scope}.business_rules.customersFreeSlots`,
      defaultMessage: 'Customer Free Slots',
    },
    customersFreeSlotsUpdatedAt: {
      id: `${scope}.business_rules.customersFreeSlotsUpdatedAt`,
      defaultMessage: 'Customer Free Slots Updated At',
    },
    customNotificationTime: {
      id: `${scope}.business_rules.customNotificationTime`,
      defaultMessage: 'Custom notification time',
    },
    usersIdleTime: {
      id: `${scope}.business_rules.usersIdleTime`,
      defaultMessage: 'Users idle time',
    },
    campaignExclusivePeriod: {
      id: `${scope}.business_rules.campaignExclusivePeriod`,
      defaultMessage: 'Campaign exclusive period (days)',
    },

    campaignExclusivePointsRequired: {
      id: `${scope}.business_rules.campaignExclusivePointsRequired`,
      defaultMessage: 'Campaign exclusive points required',
    },
    campaignWindowMinDuration: {
      id: `${scope}.business_rules.campaignWindowMinDuration`,
      defaultMessage: 'Campaign window min duration (days)',
    },
    campaignWindowMaxDuration: {
      id: `${scope}.business_rules.campaignWindowMaxDuration`,
      defaultMessage: 'Campaign window max duration (days)',
    },
    minCampaignsForCustomExperience: {
      id: `${scope}.business_rules.minCampaignsForCustomExperience`,
      defaultMessage: 'Min Campaigns for custom experience',
    },
  },
  businessTypes: {
    llc: {
      id: `${scope}.business_types.llc`,
      defaultMessage: 'LLC',
    },
    'c-corp': {
      id: `${scope}.business_types.c-corp`,
      defaultMessage: 'C-Corp',
    },
    's-corp': {
      id: `${scope}.business_types.s-corp`,
      defaultMessage: 'S-Corp',
    },
    partnership: {
      id: `${scope}.business_types.partnership`,
      defaultMessage: 'Partnership',
    },
    'sole-proprietorship': {
      id: `${scope}.business_types.sole-proprietorship`,
      defaultMessage: 'Sole Proprietorship',
    },
  },
  adminPermissions: {
    super_admin: {
      id: `${scope}.admin_permissions.super_admin`,
      defaultMessage: 'Super Admin',
    },
    admin: {
      id: `${scope}.admin_permissions.admin`,
      defaultMessage: 'Admin',
    },
    field_guide: {
      id: `${scope}.admin_permissions.field_guide`,
      defaultMessage: 'Field Guide',
    },
    client_guide: {
      id: `${scope}.admin_permissions.client_guide`,
      defaultMessage: 'Client Guide',
    },
    engineering: {
      id: `${scope}.admin_permissions.engineering`,
      defaultMessage: 'Engineering',
    },
    learning_and_development: {
      id: `${scope}.admin_permissions.learning_and_development`,
      defaultMessage: 'Learning and Development',
    },
    marketing: {
      id: `${scope}.admin_permissions.marketing`,
      defaultMessage: 'Marketing',
    },
  },
  companyName: {
    id: `${scope}.text.company_name`,
    defaultMessage: 'Company name',
  },
  businessType: {
    id: `${scope}.text.business_type`,
    defaultMessage: 'Business legal type',
  },
  einNumber: {
    id: `${scope}.text.ein_number`,
    defaultMessage: 'EIN Number',
  },
  companyAddress: {
    id: `${scope}.text.company_address`,
    defaultMessage: 'Company address',
  },
  companyPhone: {
    id: `${scope}.text.company_phone`,
    defaultMessage: 'Phone Number',
  },
  contactPersonName: {
    id: `global.text.contact_person_name`,
    defaultMessage: 'Full name',
  },
  businessContactInfoSubtitle: {
    id: `${scope}.text.business_contact_info_subtitle`,
    defaultMessage: 'Business point of contact',
  },
  companyNamePlaceholder: {
    id: `${scope}.placeholder.companyName`,
    defaultMessage: 'Enter company name',
  },
  businessTypePlaceholder: {
    id: `${scope}.placeholder.business_type`,
    defaultMessage: 'Select business type',
  },
  einNumberPlaceholder: {
    id: `${scope}.placeholder.ein_number`,
    defaultMessage: 'Enter number',
  },
  companyAddressStreetInputLabel: {
    id: `${scope}.input_label.company_address_street`,
    defaultMessage: 'Street',
  },
  companyAddressStreetPlaceholder: {
    id: `${scope}.placeholder.company_address_street`,
    defaultMessage: 'Street',
  },
  companyAddressCityInputLabel: {
    id: `${scope}.input_label.company_address_city`,
    defaultMessage: 'City',
  },
  companyAddressCityPlaceholder: {
    id: `${scope}.placeholder.company_address_city`,
    defaultMessage: 'City',
  },
  companyAddressCountryInputLabel: {
    id: `${scope}.input_label.company_address_country`,
    defaultMessage: 'Country',
  },
  companyAddressCountryPlaceholder: {
    id: `${scope}.placeholder.company_address_country`,
    defaultMessage: 'Country',
  },
  companyAddressStateInputLabel: {
    id: `${scope}.input_label.company_address_state`,
    defaultMessage: 'State',
  },
  companyAddressStatePlaceholder: {
    id: `${scope}.placeholder.company_address_state`,
    defaultMessage: 'State',
  },
  companyAddressZipInputLabel: {
    id: `${scope}.input_label.company_address_zip`,
    defaultMessage: 'ZIP',
  },
  companyAddressZipPlaceholder: {
    id: `${scope}.placeholder.company_address_zip`,
    defaultMessage: 'ZIP',
  },
  companyPhonePlaceholder: {
    id: `${scope}.placeholder.company_phone`,
    defaultMessage: 'Enter phone number',
  },
  contactPersonNamePlaceholder: {
    id: `${scope}.placeholder.contact_person_name`,
    defaultMessage: 'Enter contact person name',
  },
  contactPersonPhoneInputLabel: {
    id: `${scope}.input_label.contact_person_phone`,
    defaultMessage: 'Mobile phone number',
  },
  contactPersonPhonePlaceholder: {
    id: `${scope}.placeholder.contact_person_phone`,
    defaultMessage: 'Enter contact person number',
  },
  save: {
    id: `${scope}.button.save`,
    defaultMessage: 'Save',
  },
  confirm: {
    id: `${scope}.text.confirm`,
    defaultMessage: 'Confirm',
  },
  cancel: {
    id: `${scope}.text.cancel`,
    defaultMessage: 'Cancel',
  },
  'in-person': {
    id: `${scope}.text.in-person`,
    defaultMessage: 'In Person',
  },
  digital: {
    id: `${scope}.text.digital`,
    defaultMessage: 'Digital',
  },
  hybrid: {
    id: `${scope}.text.hybrid`,
    defaultMessage: 'Hybrid',
  },
  he: {
    id: `${scope}.text.he`,
    defaultMessage: 'He',
  },
  she: {
    id: `${scope}.text.she`,
    defaultMessage: 'She',
  },
  they: {
    id: `${scope}.text.they`,
    defaultMessage: 'They',
  },
  genderSelection: {
    'all-genders': {
      id: `${scope}.text.all_genders`,
      defaultMessage: 'All Genders',
    },
    'mostly-female': {
      id: `${scope}.text.mostly_female`,
      defaultMessage: 'Mostly Female',
    },
    'mostly-male': {
      id: `${scope}.text.mostly_male`,
      defaultMessage: 'Mostly Male',
    },
    female: {
      id: `${scope}.text.only_female`,
      defaultMessage: 'Only Female',
    },
    male: {
      id: `${scope}.text.only_male`,
      defaultMessage: 'Only Male',
    },
    custom: {
      id: `${scope}.text.custom`,
      defaultMessage: 'Only Custom',
    },
    'mostly-custom': {
      id: `${scope}.text.mostly_custom`,
      defaultMessage: 'Mostly Custom',
    },
  },
  interests: {
    gaming: {
      id: `${scope}.text.gaming`,
      defaultMessage: 'Gaming',
    },
    cooking: {
      id: `${scope}.text.cooking`,
      defaultMessage: 'Cooking',
    },
    fashion: {
      id: `${scope}.text.fashion`,
      defaultMessage: 'Fashion',
    },
    music: {
      id: `${scope}.text.music`,
      defaultMessage: 'Music',
    },
    beauty: {
      id: `${scope}.text.beauty`,
      defaultMessage: 'Beauty',
    },
    movies: {
      id: `${scope}.text.movies`,
      defaultMessage: 'Movies',
    },
    tech: {
      id: `${scope}.text.tech`,
      defaultMessage: 'Tech',
    },
    photography: {
      id: `${scope}.text.photography`,
      defaultMessage: 'Photography',
    },
    'social-media': {
      id: `${scope}.text.social-media`,
      defaultMessage: 'Social media',
    },
    automotive: {
      id: `${scope}.text.automotive`,
      defaultMessage: 'Automotive',
    },
    lifestyle: {
      id: `${scope}.text.lifestyle`,
      defaultMessage: 'Lifestyle',
    },
    'home-and-garden': {
      id: `${scope}.text.home-and-garden`,
      defaultMessage: 'Home and garden',
    },
    'food-and-drink': {
      id: `${scope}.text.food-and-drink`,
      defaultMessage: 'Food and drink',
    },
    'pets-and-animals': {
      id: `${scope}.text.pets-and-animals`,
      defaultMessage: 'Pets and animals',
    },
    outdoors: {
      id: `${scope}.text.outdoors`,
      defaultMessage: 'Outdoors',
    },
    'arts-and-crafts': {
      id: `${scope}.text.arts-and-crafts`,
      defaultMessage: 'Arts and crafts',
    },
    sports: {
      id: `${scope}.text.sports`,
      defaultMessage: 'Sports',
    },
  },
  disabilities: {
    test1: {
      id: `${scope}.text.test1`,
      defaultMessage: 'Test 1',
    },
    test2: {
      id: `${scope}.text.test2`,
      defaultMessage: 'Test 2',
    },
    test3: {
      id: `${scope}.text.test3`,
      defaultMessage: 'Test 3',
    },
    'learning-disability': {
      id: `${scope}.text.learning-disability`,
      defaultMessage: 'Learning disability',
    },
    'mobility-disability': {
      id: `${scope}.text.mobility-disability`,
      defaultMessage: 'Mobility disability',
    },
    'medical-disability': {
      id: `${scope}.text.medical-disability`,
      defaultMessage: 'Medical disability',
    },
    'psychiatric-disability': {
      id: `${scope}.text.psychiatric-disability`,
      defaultMessage: 'Psychiatric disability',
    },
    'visual-impairments': {
      id: `${scope}.text.visual-impairments`,
      defaultMessage: 'Visual impairments',
    },
    'hearing-disability': {
      id: `${scope}.text.hearing-disability`,
      defaultMessage: 'Deaf and hard of hearing',
    },
    'autism-spectrum-disorder': {
      id: `${scope}.text.autism-spectrum-disorder`,
      defaultMessage: 'Autism spectrum disorders',
    },
    none: {
      id: `${scope}.text.none`,
      defaultMessage: 'None',
    },
  },
  campaignTypes: {
    inPerson: {
      id: `${scope}.text.in-person`,
      defaultMessage: 'In Person',
    },
    digital: {
      id: `${scope}.text.digital`,
      defaultMessage: 'Digital',
    },
    hybrid: {
      id: `${scope}.text.hybrid`,
      defaultMessage: 'Hybrid',
    },
  },
  groups: {
    id: `${scope}.text.groups`,
    defaultMessage: 'Groups',
  },
  individual: {
    id: `${scope}.text.individual`,
    defaultMessage: 'Individual',
  },
  random: {
    id: `${scope}.text.random`,
    defaultMessage: 'Random',
  },
  minDate: {
    id: `${scope}.validations.min_date`,
    defaultMessage:
      '{label} must be, at least, {value} {unit} {when} {dateField}',
  },
  maxDate: {
    id: `${scope}.validations.max_date`,
    defaultMessage:
      '{label} must be, at least, {value} {unit} {when} {dateField}',
  },
  confirmationModal: {
    confirm: {
      id: `${scope}.button.confirm`,
      defaultMessage: 'Confirm',
    },
    cancel: {
      id: `${scope}.button.cancel`,
      defaultMessage: 'Cancel',
    },
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  start: {
    id: `${scope}.start`,
    defaultMessage: 'Start',
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: 'Price',
  },
  advertisers: {
    id: `${scope}.advertisers`,
    defaultMessage: 'Advertisers',
  },
});
