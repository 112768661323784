import React, { useCallback, useEffect } from 'react';
import AppBar from 'components/AppBar';
import { ConfirmationModalProvider } from 'components/ConfirmationModal';
import Notifier from 'containers/Notifier';
import { SnackbarProvider } from 'notistack';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { fetchAuthenticatedUser, fetchCommonData, logout } from './actions';
import Routes from './Routes';
import saga from './saga';
import { makeSelectToken, makeSelectUser } from './selectors';
import LoadingSpinner from './../../components/LoadingSpinner/LoadingSpinner.presentation';
import NewRelic from 'new-relic-agent-react';
import config from 'config';

const key = 'app';

function App() {
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();

  const token = useSelector(makeSelectToken());
  const user = useSelector(makeSelectUser());

  useEffect(() => {
    if (token) {
      dispatch(fetchAuthenticatedUser());
    }
  }, [token, dispatch]);

  useEffect(() => {
    dispatch(fetchCommonData());
  }, [dispatch]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <HelmetProvider>
      {config.newrelic.enabled === 'true' && (
        <NewRelic
          licenseKey={config.newrelic.licenseKey}
          applicationID={config.newrelic.applicationId}
        />
      )}
      <ConfirmationModalProvider>
        <SnackbarProvider
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Helmet>
            <title>EyCrowd admin</title>
          </Helmet>
          {token && !user ? (
            <LoadingSpinner />
          ) : (
            <>
              {user && <AppBar onLogout={handleLogout} />}
              <Routes />
            </>
          )}
          <Notifier />
        </SnackbarProvider>
      </ConfirmationModalProvider>
    </HelmetProvider>
  );
}

export default App;
