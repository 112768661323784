import DashboardLayout from 'components/Layout/DashboardLayout';
import React, { Fragment, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import {
  deleteTourCampaignRequest,
  fetchTourCampaignsRequest,
  uploadTourRequest,
} from './actions';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import Icon from 'components/Icon';
import styles from 'components/ResourceTable/Table.module.scss';
import { makeSelectTourCampaigns } from './selectors';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import Select from 'react-select';
import ResourceTable from 'components/ResourceTable';
import { ReactComponent as recycleBinIcon } from 'assets/images/delete.svg';
import { canActivate } from '../../utils/permissions';
import {
  CREATE_TOUR_CAMPAIGNS,
  DELETE_TOUR_CAMPAIGNS,
  LIST_TOUR_CAMPAIGNS,
  UPDATE_TOUR_CAMPAIGNS,
} from '../../permisssions';

const key = 'tour';

const TourCampaignPage = () => {
  const { formatMessage } = useIntl();
  // const [modalOpen, setModalOpen] = useState(false);

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const options = [
    { value: 'very-low', label: 'Very low' },
    { value: 'low', label: 'Low' },
    { value: 'medium', label: 'Medium' },
    { value: 'high', label: 'High' },
    { value: 'very-high', label: 'Very high' },
  ];

  const CampaignGenderSelection = {
    'all-genders': formatMessage(messages.allGenders),
    'mostly-male': formatMessage(messages.mostlyMale),
    'mostly-female': formatMessage(messages.mostlyFemale),
    male: formatMessage(messages.onlyMale),
    female: formatMessage(messages.onlyFemale),
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      fetchTourCampaignsRequest(
        localStorage.getItem('pageNumberCampaigns')
          ? localStorage.getItem('pageNumberCampaigns')
          : 1,
        localStorage.getItem('perPage') ? localStorage.getItem('perPage') : 25
      )
    );
  }, [dispatch]);

  const fileHandler = (event) => {
    let files = event.target.files;
    dispatch(uploadTourRequest(files));
  };

  const fetchPageCampaigns = (pageNumber) => {
    localStorage.setItem('pageNumberCampaigns', pageNumber);
    dispatch(
      fetchTourCampaignsRequest(pageNumber, perPage, search, sortBy, order)
    );
  };

  const tableHeader = [
    {
      key: 'campaignName',
      label: 'Campaign name',
      sortable: true,
    },
    {
      key: 'pricePerHour',
      label: 'Price per hour',
      sortable: true,
    },
    { key: 'genders', label: formatMessage(messages.genders) },
    {
      key: 'priority',
      label: 'Priority',
    },
    {
      key: 'actions',
      label: 'Actions',
    },
  ];

  const handleChange = (search) => {
    dispatch(fetchTourCampaignsRequest(1, perPage, search, sortBy, order));
  };

  const handleSort = (sortBy, order) => {
    dispatch(fetchTourCampaignsRequest(1, perPage, search, sortBy, order));
  };

  const handlePerPage = (perPage) => {
    localStorage.setItem('perPage', perPage);
    localStorage.setItem('pageNumberUsers', 1);
    localStorage.setItem('pageNumberCampaigns', 1);
    localStorage.setItem('pageNumberAdvertisers', 1);
    dispatch(fetchTourCampaignsRequest(1, perPage, search, sortBy, order));
  };

  const campaigns = useSelector(makeSelectTourCampaigns());
  const { data, perPage, total, search, sortBy, order } = campaigns || [];

  const getCampaignSelectedOption = (optionValue) => {
    const option = options.find((o) => o.value === optionValue);
    return option;
  };

  return (
    <DashboardLayout>
      <p>Upload instant campaigns</p>
      <input
        type="file"
        multiple
        onChange={fileHandler}
        style={{ padding: '10px' }}
      />

      <DashboardMainHeader
        titleMain={'Tour campaigns'}
        totalRows={campaigns.total}
        search
        onChange={handleChange}
        canAccess={canActivate(CREATE_TOUR_CAMPAIGNS)}
      />
      <ResourceTable
        rowsNumber={data ? data.length : 0}
        hasMore={data?.length < total}
        fetchPage={fetchPageCampaigns}
        resource={campaigns}
        headerInformation={tableHeader}
        noDataContent={formatMessage(messages.noCampaigns)}
        handleSort={handleSort}
        perPage={localStorage.getItem('perPage')}
        handlePerPage={handlePerPage}
        canAccess={canActivate(LIST_TOUR_CAMPAIGNS)}
      >
        {data.map((campaign) => {
          return (
            <Fragment key={campaign.id}>
              <TableColumnField data={campaign.name}></TableColumnField>
              <TableColumnField data={campaign.pricePerHour} />
              <TableColumnField
                data={CampaignGenderSelection[campaign.genderSelection]}
              />
              <TableColumnField
                data={
                  canActivate(UPDATE_TOUR_CAMPAIGNS) ? (
                    <div style={{ elevation: 2000, zIndex: 1000 }}>
                      <Select
                        value={getCampaignSelectedOption(campaign.priority)}
                        onChange={(e) => {
                          // dispatch(changeCampaignPriorityRequest(campaign, e));
                        }}
                        options={options}
                      />
                    </div>
                  ) : null
                }
              />
              <TableColumnField
                data={
                  canActivate(DELETE_TOUR_CAMPAIGNS) ? (
                    <button
                      className={styles.actionButton}
                      onClick={() => {
                        dispatch(deleteTourCampaignRequest(campaign));
                      }}
                    >
                      <Icon icon={recycleBinIcon} size={'sm20'} />
                    </button>
                  ) : null
                }
              />
            </Fragment>
          );
        })}
      </ResourceTable>
    </DashboardLayout>
  );
};

export default TourCampaignPage;
