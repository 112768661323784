import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import { deleteTaskSuccess, fetchTasksListSuccess } from './actions';
import { DELETE_TASK_REQUEST, FETCH_TASKS_LIST_REQUEST } from './constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import messages from './messages';
import toasterVariants from '../../constants/toasterVariants';

export function* fetchTasksListRequest() {
  try {
    const data = yield call(request, {
      url: '/admin/task-templates',
      method: 'get',
    });
    yield put(fetchTasksListSuccess(data));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* deleteTaskRequest({ id }) {
  try {
    yield call(request, {
      url: `/admin/task-templates/${id}`,
      method: 'delete',
    });
    yield put(
      enqueueSnackbar({
        message: messages.taskDeleted,
        variant: toasterVariants.success,
      })
    );
    yield put(deleteTaskSuccess(id));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export default function* eycrowdTasksSaga() {
  yield takeLatest(FETCH_TASKS_LIST_REQUEST, fetchTasksListRequest);
  yield takeLatest(DELETE_TASK_REQUEST, deleteTaskRequest);
}
