import { ENDPOINTS } from '../../constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import {
  deleteTemplateSuccess,
  fetchCampaignObjectivesSuccess,
  fetchRetailersSuccess,
  fetchTemplatesSuccess,
} from './actions';
import {
  DELETE_TEMPLATE_REQUEST,
  FETCH_CAMPAIGN_OBJECTIVES_REQUEST,
  FETCH_RETAILERS_REQUEST,
  FETCH_TEMPLATES_REQUEST,
  UPLOAD_TEMPLATE_REQUEST,
} from './constants';
import toasterVariants from 'constants/toasterVariants';
import { format } from 'util';

export function* fetchRetailers() {
  try {
    const retailers = yield call(request, {
      url: ENDPOINTS.RETAILERS,
    });
    yield put(fetchRetailersSuccess(retailers));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* fetchCampaignObjectives() {
  try {
    const objectives = yield call(request, {
      url: ENDPOINTS.CAMPAIGN_OBJECTIVES,
    });
    yield put(fetchCampaignObjectivesSuccess(objectives));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* uploadCampaignTemplate({
  file,
  retailerId,
  campaignObjectiveCategoryId,
  order,
  campaignType,
}) {
  try {
    const formData = new FormData();
    formData.append('file', file[0]);
    formData.append('retailerId', retailerId);
    formData.append('campaignObjectiveCategoryId', campaignObjectiveCategoryId);
    formData.append('order', order);
    formData.append('campaignType', campaignType);
    yield call(request, {
      url: ENDPOINTS.UPLOAD_TEMPLATES,
      method: 'post',
      data: formData,
    });
    yield put(
      enqueueSnackbar({
        message: 'Template uploaded',
        variant: toasterVariants.success,
      })
    );
    const templates = yield call(request, {
      url: format(ENDPOINTS.FETCH_TEMPLATES, 1, 15, '', '', 'ASC'),
      method: 'get',
    });
    yield put(fetchTemplatesSuccess(templates, '', '', 'ASC'));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* fetchTemplates({ page, perPage, search, sortBy, order }) {
  try {
    const templates = yield call(request, {
      url: format(
        ENDPOINTS.FETCH_TEMPLATES,
        page,
        perPage,
        search,
        sortBy,
        order
      ),
      method: 'get',
    });
    yield put(fetchTemplatesSuccess(templates, search, sortBy, order));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* deleteTemplate({ template }) {
  try {
    yield call(request, {
      url: ENDPOINTS.DELETE_TEMPLATE + '/' + template.id,
      method: 'delete',
      data: { isSales: true },
    });
    yield put(deleteTemplateSuccess(template));
  } catch (err) {}
}

export default function* templatesSaga() {
  yield takeLatest(FETCH_RETAILERS_REQUEST, fetchRetailers);
  yield takeLatest(FETCH_CAMPAIGN_OBJECTIVES_REQUEST, fetchCampaignObjectives);
  yield takeLatest(UPLOAD_TEMPLATE_REQUEST, uploadCampaignTemplate);
  yield takeLatest(FETCH_TEMPLATES_REQUEST, fetchTemplates);
  yield takeLatest(DELETE_TEMPLATE_REQUEST, deleteTemplate);
}
