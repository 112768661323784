export const USER = '[User]';

export const FETCH_USER_REQUEST = `${USER} FETCH_USER_REQUEST`;
export const FETCH_USER_SUCCESS = `${USER} FETCH_USER_SUCCESS`;
export const CREATE_USER_REQUEST = `${USER} CREATE_USER_REQUEST`;
export const CREATE_USER_SUCCESS = `${USER} CREATE_USER_SUCCESS`;
export const EDIT_USER_REQUEST = `${USER} EDIT_USER_REQUEST`;
export const CLEAR_USER = `${USER} CLEAR_USER`;
export const DELETE_USER_REQUEST = `${USER} DELETE_USER_REQUEST`;
export const DELETE_USER_SUCCESS = `${USER} DELETE_USER_SUCCESS`;
export const APPROVE_USER_REQUEST = `${USER} APPROVE_USER_REQUEST`;
export const APPROVE_USER_SUCCESS = `${USER} APPROVE_USER_SUCCESS`;
export const CHECK_IF_USERNAME_IS_AVAILABLE = `${USER} CHECK_IF_USERNAME_IS_AVAILABLE`;
export const SET_IS_USER_STAFF_REQUEST = `${USER} SET_IS_USER_STAFF_REQUEST`;
export const SET_IS_USER_STAFF_SUCCESS = `${USER} SET_IS_USER_STAFF_SUCCESS`;
export const BLOCK_USER_REQUEST = `${USER} BLOCK_USER_REQUEST`;
export const BLOCK_USER_SUCCESS = `${USER} BLOCK_USER_SUCCESS`;
