import {
  FETCH_USERS_LIST_REQUEST,
  FETCH_USERS_LIST_SUCCESS,
  APPROVE_USERS_REQUEST,
  APPROVE_USERS_SUCCESS,
  APPROVE_USERS_ERROR,
} from './constants';
import { ORDER } from '../../constants';

export function fetchUsersListRequest(
  page,
  perPage,
  search = '',
  sortBy = 'createdAt',
  order = ORDER.DESC
) {
  return {
    type: FETCH_USERS_LIST_REQUEST,
    page,
    perPage,
    search,
    sortBy,
    order,
  };
}

export function fetchUsersListSuccess(users, search, sortBy, order) {
  return {
    type: FETCH_USERS_LIST_SUCCESS,
    users,
    search,
    sortBy,
    order,
  };
}

export function approveUsersRequest(users) {
  return {
    type: APPROVE_USERS_REQUEST,
    users,
  };
}

export function approveUsersSuccess() {
  return {
    type: APPROVE_USERS_SUCCESS,
  };
}

export function approveUsersError() {
  return {
    type: APPROVE_USERS_ERROR,
  };
}
