import React, { useCallback, useEffect, useState } from 'react';
import DashboardLayout from 'components/Layout/DashboardLayout';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import styles from './BulkCampaignsPage.module.scss';
import { InputField, SelectField } from 'components/Forms';
import Button from 'components/Buttons/Button';
import { Form, Formik, Field } from 'formik';
import axios from 'axios';
import config from 'config';
import Yup from 'utils/validations';
import { useInjectSaga } from 'utils/injectSaga';
import saga from './saga';
import reducer from './reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  bulkCreateCampaignsRequest,
  changeSocialMediaUrl,
  createBrandSummaryAndSocialsRequest,
  createCampaignSuggestionsRequest,
  reorganizeJsonData,
} from './actions';
import { useInjectReducer } from 'utils/injectReducer';
import {
  makeSelectAddress,
  makeSelectBrandName,
  makeSelectBrandSummary,
  makeSelectBulkCreateCampaignsPending,
  makeSelectCampaignSuggestions,
  makeSelectCampaignsSuggestionsPending,
  makeSelectCreateBrandStorySummaryPending,
  makeSelectIndustry,
  makeSelectPlatformActions,
  makeSelectSelectedTasks,
  makeSelectSocialMediaUrls,
} from './selectors';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner.presentation';
import { useHistory } from 'react-router-dom';
import { CAMPAIGNS } from 'routes';
import { enqueueSnackbar } from 'containers/Notifier/actions';

const key = 'bulkCampaignsPage';
const URL = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

const BulkCampaignsPage = () => {
  const [step, setStep] = useState(1);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadedJsonFileName, setUploadedJsonFileName] = useState('');
  const createBrandSummaryPending = useSelector(
    makeSelectCreateBrandStorySummaryPending()
  );
  const brandSummary = useSelector(makeSelectBrandSummary());
  const socialMediaUrls = useSelector(makeSelectSocialMediaUrls());
  const brandName = useSelector(makeSelectBrandName());
  const brandIndustry = useSelector(makeSelectIndustry());
  const brandLocation = useSelector(makeSelectAddress());
  const createCampaignSuggestionsPending = useSelector(
    makeSelectCampaignsSuggestionsPending()
  );
  const platformActions = useSelector(makeSelectPlatformActions());
  const campaignSuggestions = useSelector(makeSelectCampaignSuggestions());
  const selectedTasks = useSelector(makeSelectSelectedTasks());
  const bulkCreateCampaignsPending = useSelector(
    makeSelectBulkCreateCampaignsPending()
  );
  let history = useHistory();

  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });

  const dispatch = useDispatch();

  const socialPlatforms = [
    { id: 1, slug: 'facebook' },
    { id: 2, slug: 'instagram' },
    { id: 3, slug: '' },
    { id: 4, slug: 'yelp' },
    { id: 5, slug: 'twitter' },
    { id: 8, slug: 'other' },
    { id: 9, slug: 'tiktok' },
    { id: 10, slug: 'snapchat' },
    { id: 11, slug: 'youtube' },
    { id: 12, slug: 'linkedin' },
  ];

  const PLATFORM_MAPPER = {
    FB: socialPlatforms.find((sp) => sp.slug === 'facebook')?.id,
    IG: socialPlatforms.find((sp) => sp.slug === 'instagram')?.id,
    TW: socialPlatforms.find((sp) => sp.slug === 'twitter')?.id,
    X: socialPlatforms.find((sp) => sp.slug === 'twitter')?.id,
    LI: socialPlatforms.find((sp) => sp.slug === 'linkedin')?.id,
    TT: socialPlatforms.find((sp) => sp.slug === 'tiktok')?.id,
    YELP: socialPlatforms.find((sp) => sp.slug === 'yelp')?.id,
    AMAZON: socialPlatforms.find((sp) => sp.slug === 'other')?.id,
    HTTP3: socialPlatforms.find((sp) => sp.slug === 'other')?.id,
    EYCROWD: socialPlatforms.find((sp) => sp.slug === 'other')?.id,
  };

  const SOCIAL_MEDIA_MAPPER = {
    FB: socialMediaUrls.facebook,
    IG: socialMediaUrls.instagram,
    TW: socialMediaUrls.twitter,
    X: socialMediaUrls.twitter,
    LI: socialMediaUrls.linkedin,
    TT: socialMediaUrls.tiktok,
  };

  const industryOptions = [
    { value: 'marketing', label: 'Marketing' },
    { value: 'retail', label: 'Retail' },
    { value: 'finance', label: 'Finance' },
    { value: 'technology', label: 'Technology' },
    { value: 'healthcare', label: 'Healthcare' },
    { value: 'education', label: 'Education' },
    { value: 'manufacturing', label: 'Manufacturing' },
    { value: 'realestate', label: 'Real Estate' },
    { value: 'entertainment', label: 'Entertainment' },
    { value: 'hospitality', label: 'Hospitality' },
    { value: 'transportation', label: 'Transportation' },
    { value: 'agriculture', label: 'Agriculture' },
    { value: 'construction', label: 'Construction' },
    { value: 'energy', label: 'Energy' },
    { value: 'foodservice', label: 'Food Service' },
  ];

  const getTaskProjectedValue = useCallback(
    (taskId) => {
      return platformActions.find((p) => p.taskId === taskId);
    },
    [platformActions]
  );

  useEffect(() => {
    // setLoading(true);
    let configuratorLink =
      'https://objective-configurator.s3.us-east-2.amazonaws.com/qa/configurator.json';

    switch (config.api.baseUrl) {
      case 'https://eycrowd-server.qa.eycrowd.com/api':
        configuratorLink =
          'https://objective-configurator.s3.us-east-2.amazonaws.com/qa/configurator.json';
        break;
      case 'https://eycrowd-server.hercules.eycrowd.com/api/':
        configuratorLink =
          'https://objective-configurator.s3.us-east-2.amazonaws.com/hercules/configurator.json';
        break;
      case 'https://eycrowd-server.production.eycrowd.com/api':
        configuratorLink =
          'https://objective-configurator.s3.us-east-2.amazonaws.com/production/configurator.json';
        break;
      default:
        break;
    }

    axios
      .get(configuratorLink)
      .then(function (response) {
        const data = response.data;

        dispatch(reorganizeJsonData(data));
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // setLoading(false);
      });
  }, [dispatch]);

  const nextStep = () => {
    setStep(step + 1);
  };

  const handleCreateBrandSummary = (data, resetForm) => {
    dispatch(
      createBrandSummaryAndSocialsRequest(
        data,
        () => {
          resetForm();
        },
        () => {}
      )
    );
  };

  const handleCreateCampaignSuggestions = (data, resetForm) => {
    dispatch(
      createCampaignSuggestionsRequest(
        {
          ...data,
          brandName,
          industry: brandIndustry,
          brandSummary,
          address: brandLocation,
        },
        () => {
          resetForm();
          setUploadedFileName('');
        },
        () => {}
      )
    );
  };

  const getTaskSocialPlatform = (pv) => {
    if (pv.platform_action === 'POST') {
      if (pv.platform_item === 'IMAGE') {
        return {
          type: 'photo',
          socialPlatformId: PLATFORM_MAPPER[pv.platform],
        };
      }

      if (pv.platform_item === 'VIDEO') {
        return {
          type: 'video',
          socialPlatformId: PLATFORM_MAPPER[pv.platform],
        };
      }
    } else if (pv.platform_action === 'COMMENT') {
      return {
        type: null,
        socialPlatformId: PLATFORM_MAPPER[pv.platform],
      };
    } else if (pv.platform_action === 'LIKE') {
      return {
        type: null,
        socialPlatformId: PLATFORM_MAPPER[pv.platform],
      };
    } else if (pv.platform_action === 'FOLLOW') {
      return {
        type: null,
        socialPlatformId: PLATFORM_MAPPER[pv.platform],
      };
    } else if (pv.platform_action === 'REPLY') {
      return {
        type: null,
        socialPlatformId: PLATFORM_MAPPER[pv.platform],
      };
    } else if (pv.platform_action === 'SHARE') {
      return {
        type: null,
        socialPlatformId: PLATFORM_MAPPER[pv.platform],
      };
    }

    return null;
  };

  const getSocialAccountUrl = (pv) => {
    let url = null;
    if (pv.platform) {
      url = {
        link: SOCIAL_MEDIA_MAPPER[pv.platform]
          ? SOCIAL_MEDIA_MAPPER[pv.platform]
          : null,
        platform: PLATFORM_MAPPER[pv.platform],
      };
    }

    return url;
  };

  const getCategoryTask = (pv) => {
    if (pv.url_required) {
      switch (pv.platform_action) {
        case 'COMMENT':
          return 'comment';
        case 'REPLY':
          return 'comment';
        case 'LIKE':
          return 'like';
        case 'FOLLOW':
          return 'follow';
        case 'SHARE':
          return 'share';
        default:
          return 'social-account';
      }
    }

    if (pv.platform_action === 'POST') {
      return 'social-platform';
    }
    if (pv.platform_action === 'SURVEY') {
      return 'survey';
    }

    if (pv.platform_action === 'CREATE') {
      if (pv.platform_item === 'IMAGE') {
        return 'photo';
      }

      if (pv.platform_item === 'VIDEO') {
        return 'video';
      }
    }

    switch (pv.basic_verification_method) {
      case 'GALLERY-PHOTO':
        return 'photo';
      case 'GALLERY-VIDEO':
        return 'video';
      case 'SURVEY-LONG':
        return 'survey';
      case 'SURVEY-SHORT':
        return 'survey';
      case 'SURVEY-MULTIPLE':
        return 'survey';
      case 'SURVEY-SINGLE':
        return 'survey';
      default:
        return 'default';
    }
  };

  const getTasksSocialMedia = (pv) => {
    let url = null;
    url = { link: SOCIAL_MEDIA_MAPPER[pv.platform] };
    return url;
  };

  const QUESTION_TYPE_MAPPER = {
    SINGLE_CHOICE: 'single',
    SHORT_TEXT: 'short',
    LONG_TEXT: 'long',
    MULTIPLE_CHOICE: 'multiple',
    INPUT: 'input',
  };

  const getSurveyTask = (task, pv) => {
    let questionType = QUESTION_TYPE_MAPPER[pv.platform_item];

    if (!questionType) {
      switch (pv.basic_verification_method) {
        case 'SURVEY-LONG':
          questionType = QUESTION_TYPE_MAPPER.LONG_TEXT;
          break;
        case 'SURVEY-SHORT':
          questionType = QUESTION_TYPE_MAPPER.SHORT_TEXT;
          break;
        case 'SURVEY-MULTIPLE':
          questionType = QUESTION_TYPE_MAPPER.MULTIPLE_CHOICE;
          break;
        case 'SURVEY-SINGLE':
          questionType = QUESTION_TYPE_MAPPER.SINGLE_CHOICE;
          break;
        default:
          break;
      }
    }

    if (task && questionType) {
      return {
        questionType,
        text: task.description,
        options: task.options,
      };
    }
    return null;
  };

  const handleCreateCampaign = (data, resetForm) => {
    const fileReader = new FileReader();
    fileReader.onload = async function (event) {
      try {
        const jsonArray = JSON.parse(event.target.result);
        const processedCampaigns = jsonArray.map((campaignData) => {
          return {
            timeZone: campaignData.timeZone,
            genders: campaignData.genders,
            maxEyvocates: campaignData.maxEyvocates,
            pricePerHour: campaignData.pricePerHour,
            interests: campaignData.interests,
            ageRanges: campaignData.ageRanges,
            eyvocatePlaces: campaignData.eyvocatePlaces,
            genderSelection: campaignData.genderSelection,
            startDate: campaignData.startDate,
            endDate: campaignData.endDate,
            logo: campaignData.logo,
            tasksTotalDurationInMinutes:
              campaignData.tasksTotalDurationInMinutes,
          };
        });

        const campaignArray = mergeCampaignsWithProcessedData(
          processedCampaigns
        );

        if (!!campaignArray.length) {
          dispatch(
            bulkCreateCampaignsRequest(
              campaignArray,
              () => {
                resetForm();
                setUploadedJsonFileName('');
                history.push(CAMPAIGNS);
              },
              () => {}
            )
          );
        }
      } catch (error) {
        console.error('Error parsing JSON:', error);
      }
    };

    if (data.jsonFile) {
      fileReader.readAsText(data.jsonFile);
    }
  };

  const mergeCampaignsWithProcessedData = (processedCampaigns) => {
    const campaigns = [];

    for (let i = 0; i < campaignSuggestions.length; i++) {
      const campaign = campaignSuggestions[i];
      const { campaignTitle, campaignDescription, ...rest } = campaign;
      const processedCampaign = processedCampaigns[i];

      const taskDuration = selectedTasks[campaignTitle].length
        ? selectedTasks[campaignTitle]
            .map((item) => item.time)
            .reduce((prev, next) => +prev + +next)
        : 0;

      const STAGE_MAPPER = {
        'PRE, LIVE': 'pre-campaign',
        LIVE: 'live-campaign',
        'LIVE, POST': 'follow-up',
      };

      const tasks = [];

      selectedTasks[campaignTitle].forEach((task) => {
        const pv = getTaskProjectedValue(task.id);

        const taskCategory = getCategoryTask(pv);
        const finalTaskCategory = [
          'social-account',
          'social-platform',
        ].includes(taskCategory)
          ? 'social'
          : taskCategory;

        const newTask = {
          description: task.description,
          type: STAGE_MAPPER.LIVE,
          category: finalTaskCategory,
          suggestedHashtags: null,
          ...(['social-account'].includes(taskCategory) && {
            socialAccount: getSocialAccountUrl(pv),
          }),
          ...(['share', 'comment', 'like', 'follow'].includes(taskCategory) && {
            taskSocialMedia: getTasksSocialMedia(pv),
          }),
          ...([
            'share',
            'comment',
            'like',
            'follow',
            'social-platform',
          ].includes(taskCategory) && {
            taskSocialPlatform: getTaskSocialPlatform(pv),
          }),
          ...(taskCategory === 'survey' &&
            getSurveyTask(task, pv) && {
              taskSurveyQuestion: getSurveyTask(task, pv),
            }),
        };

        tasks.push(newTask);
      });

      campaigns.push({
        name: campaignTitle,
        description: campaignDescription,
        tasks: tasks,
        ...processedCampaign,
      });
    }

    if (processedCampaigns.length !== campaigns.length) {
      dispatch(
        enqueueSnackbar({
          message:
            'Campaigns length must be equal to campaign suggestions length!',
        })
      );
      return [];
    } else {
      return campaigns;
    }
  };

  return (
    <DashboardLayout>
      <DashboardMainHeader
        titleMain="Create Bulk Campaigns"
        title={`Step ${step}/3`}
      />
      {step === 1 && (
        <>
          {createBrandSummaryPending && (
            <LoadingSpinner className={styles.spinner} />
          )}
          <div className={styles.form}>
            <Formik
              initialValues={{
                brandName: '',
                industry: '',
                address: '',
                url: '',
              }}
              validationSchema={Yup.object().shape({
                brandName: Yup.string().trim().required(),
                industry: Yup.string().trim().required(),
                address: Yup.string().trim().required(),
                url: Yup.string().matches(URL).required(),
              })}
              onSubmit={(values, { resetForm }) => {
                handleCreateBrandSummary(values, resetForm);
              }}
              enableReinitialize
            >
              {({ isValid, dirty, setFieldValue }) => {
                return (
                  <Form className={styles.form}>
                    <InputField
                      name="brandName"
                      type="text"
                      component="textarea"
                      label="Brand Name"
                      placeholder="Enter Brand Name"
                      required
                    />
                    <SelectField
                      name="industry"
                      options={industryOptions}
                      label="Industry"
                      placeholder="Choose industry"
                      onChange={(event) => {
                        setFieldValue('industry', event.target.value);
                        const industry = industryOptions.find(
                          (p) => p.key === event.target.value
                        );
                        if (industry) {
                          setFieldValue('industry', industry.value);
                        }
                      }}
                      required
                    />

                    <InputField
                      name="address"
                      type="text"
                      component="textarea"
                      label="Address"
                      placeholder="Enter Address"
                      required
                    />
                    <InputField
                      name="url"
                      type="text"
                      component="textarea"
                      label="Website"
                      placeholder="Enter url here"
                      required
                    />

                    <Button
                      alt
                      disabled={!isValid || !dirty || createBrandSummaryPending}
                      type="submit"
                      isLarge
                      className={styles.button}
                    >
                      Submit
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
          {brandSummary && socialMediaUrls && (
            <>
              <div className={styles.summaryContainer}>
                <h2 className={styles.summaryTitle}>Brand Summary</h2>
                <p className={styles.summaryText}>{brandSummary}</p>

                <h2 className={styles.socialMediaTitle}>Social Media URLs</h2>
                <div className={styles.socialMediaLinks}>
                  <ul>
                    {Object.entries(socialMediaUrls).map(([platform, url]) => (
                      <li key={platform}>
                        {platform.charAt(0).toUpperCase() + platform.slice(1)}:{' '}
                        <input
                          className={styles.socialMediaInput}
                          onChange={(e) => {
                            dispatch(
                              changeSocialMediaUrl(platform, e.target.value)
                            );
                          }}
                          value={url}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <Button
                disabled={false}
                type="submit"
                isLarge
                className={styles.nextStepButton}
                onClick={nextStep}
              >
                Next Step
              </Button>
            </>
          )}
        </>
      )}
      {step === 2 && (
        <>
          {createCampaignSuggestionsPending && (
            <LoadingSpinner className={styles.spinner} />
          )}
          <div className={styles.form}>
            <Formik
              initialValues={{
                numberOfCampaigns: '',
                numberOfObjectives: '',
                otherInstructions: '',
                jsonFile: null,
              }}
              validationSchema={Yup.object().shape({
                numberOfCampaigns: Yup.number().positive().integer().required(),
                numberOfObjectives: Yup.number()
                  .positive()
                  .integer()
                  .required(),
                otherInstructions: Yup.string().trim(),
                jsonFile: Yup.mixed().required(),
              })}
              onSubmit={(values, { resetForm }) => {
                handleCreateCampaignSuggestions(values, resetForm);
              }}
              enableReinitialize
            >
              {({ isValid, dirty, setFieldValue, errors, touched }) => {
                return (
                  <Form className={styles.form}>
                    <InputField
                      name="numberOfCampaigns"
                      type="number"
                      component="textarea"
                      label="Number of campaigns"
                      placeholder="Enter number of campaigns"
                      required
                    />

                    <InputField
                      name="numberOfObjectives"
                      type="number"
                      component="textarea"
                      label="Number of objectives per campaign"
                      placeholder="Enter number of objectives per campaign"
                      required
                    />
                    <InputField
                      name="otherInstructions"
                      type="text"
                      component="textarea"
                      label="Other Instructions"
                      placeholder="Enter other instructions"
                      required
                    />

                    <div className={styles.formGroup}>
                      <input
                        id="jsonFile"
                        name="jsonFile"
                        type="file"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue('jsonFile', file);
                          setUploadedFileName(file ? file.name : '');
                        }}
                        accept=".json"
                        className={styles.jsonInput}
                      />
                      {errors.jsonFile && touched.jsonFile && (
                        <div className={styles.inputFeedback}>
                          {errors.jsonFile}
                        </div>
                      )}
                      <div className={styles.uploadButonRowContainer}>
                        <Button
                          type="submit"
                          title={'Upload Objectives'}
                          className={styles.uploadButton}
                        />
                        {uploadedFileName && (
                          <div className={styles.fileNameDisplay}>
                            <span>{uploadedFileName}</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <Button
                      alt
                      disabled={
                        !isValid || !dirty || createCampaignSuggestionsPending
                      }
                      type="submit"
                      isLarge
                      className={styles.button}
                    >
                      Submit
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
          {!!campaignSuggestions.length && (
            <div className={styles.campaignsContainer}>
              <h2 className={styles.summaryTitle}>Campaign Suggestions</h2>
              {campaignSuggestions.map((campaign, index) => (
                <div key={index} className={styles.summaryContainer}>
                  <h2 className={styles.socialMediaTitle}>
                    {campaign.campaignTitle}
                  </h2>
                  <p className={styles.campaignDescription}>
                    {campaign.campaignDescription}
                  </p>
                  <ul>
                    {campaign.objectives.map((objective) =>
                      objective.tasks.map((task, taskIndex) => {
                        const taskDescription = selectedTasks[
                          campaign.campaignTitle
                        ]
                          ? selectedTasks[campaign.campaignTitle][taskIndex]
                              .description
                          : task.task_description;

                        return (
                          <li key={taskIndex}>
                            {`- Task ${taskIndex + 1}: ${taskDescription}`}
                          </li>
                        );
                      })
                    )}
                  </ul>
                </div>
              ))}

              <Button
                disabled={false}
                type="submit"
                isLarge
                className={styles.nextStepButton}
                onClick={nextStep}
              >
                Next Step
              </Button>
            </div>
          )}
        </>
      )}
      {step === 3 && (
        <>
          {bulkCreateCampaignsPending && (
            <LoadingSpinner className={styles.spinner} />
          )}
          <div className={styles.form}>
            <Formik
              initialValues={{
                jsonFile: null,
              }}
              validationSchema={Yup.object().shape({
                jsonFile: Yup.mixed().required(),
              })}
              onSubmit={(values, { resetForm }) => {
                handleCreateCampaign(values, resetForm);
              }}
              enableReinitialize
            >
              {({ isValid, dirty, setFieldValue, errors, touched }) => {
                return (
                  <Form className={styles.form}>
                    <div className={styles.formGroup}>
                      <input
                        id="jsonFile"
                        name="jsonFile"
                        type="file"
                        onChange={(event) => {
                          const file = event.currentTarget.files[0];
                          setFieldValue('jsonFile', file);
                          setUploadedJsonFileName(file ? file.name : '');
                        }}
                        accept=".json"
                        className={styles.jsonInput}
                      />
                      {errors.jsonFile && touched.jsonFile && (
                        <div className={styles.inputFeedback}>
                          {errors.jsonFile}
                        </div>
                      )}
                      <div className={styles.uploadButonRowContainer}>
                        <Button
                          type="submit"
                          title={'Upload JSON'}
                          className={styles.uploadButton}
                        />
                        {uploadedJsonFileName && (
                          <div className={styles.fileNameDisplay}>
                            <span>{uploadedJsonFileName}</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <Button
                      alt
                      disabled={
                        !isValid || !dirty || bulkCreateCampaignsPending
                      }
                      type="submit"
                      isLarge
                      className={styles.button}
                    >
                      Submit
                    </Button>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default BulkCampaignsPage;
