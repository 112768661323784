import produce from 'immer';
import {
  CHANGE_PRIORITY_SUCCESS,
  FETCH_TOUR_CAMPAIGNS_SUCCESS,
  DELETE_TOUR_CAMPAING_SUCCESS,
  UPLOAD_TOUR_SUCCESS,
} from './constants';

export const initialState = {
  tour: null,
  campaigns: {
    data: [],
    perPage: null,
    page: null,
    total: null,
    search: '',
    sortBy: '',
    order: '',
  },
};

/* eslint-disable default-case, no-param-reassign */
const tourPageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPLOAD_TOUR_SUCCESS:
        draft.tour = action.tour;
        break;
      case FETCH_TOUR_CAMPAIGNS_SUCCESS:
        draft.campaigns.perPage = action.campaigns.perPage;
        draft.campaigns.page = action.campaigns.page;
        draft.campaigns.total = action.campaigns.total;
        draft.campaigns.search = action.search;
        draft.campaigns.sortBy = action.sortBy;
        draft.campaigns.order = action.order;
        draft.campaigns.data = action.campaigns.data;
        break;
      case CHANGE_PRIORITY_SUCCESS:
        let newCampaigns = state.campaigns.data.map((campaign) =>
          campaign.id === action.campaign.id ? action.campaign : campaign
        );
        draft.campaigns.data = newCampaigns;
        break;
      case DELETE_TOUR_CAMPAING_SUCCESS:
        let arr = state.campaigns.data.filter(
          (campaign) => campaign.id !== action.campaign.id
        );
        draft.campaigns.data = arr;
        break;
    }
  });

export default tourPageReducer;
