import { call, put, takeLatest } from 'redux-saga/effects';
import request from 'utils/request';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import {
  CHANGE_USER_ROLE_REQUEST,
  CREATE_CAMPAIGN_SUPERVISOR_TASK_REQUEST,
  DELETE_CAMPAIGN_SUPERVISOR_TASK_REQUEST,
  FETCH_SINGLE_CAMPAIGN,
  REMOVE_JOINED_USER_FROM_CAMPAIGN,
  UPDATE_SINGLE_CAMPAIGN_REQUEST,
  UPDATE_SINGLE_CAMPAIGN_END_DATE_REQUEST,
  APPROVE_CAMPAIGN_REQUEST,
  REQUEST_CHANGES_REQUEST,
  CANCEL_CAMPAIGN_REQUEST,
  SET_IS_CAMPAIGN_INTERNAL_REQUEST,
  DELETE_CAMPAIGN_GRADE_RANGE_REQUEST,
  SET_CAMPAIGN_GRADE_RANGE_REQUEST,
} from './constants';
import {
  changeUserRoleSuccess,
  createCampaignSupervisorTaskError,
  createCampaignSupervisorTaskSuccess,
  deleteCampaignGradeRangeRequestError,
  deleteCampaignGradeRangeRequestSuccess,
  deleteCampaignSupervisorTaskError,
  deleteCampaignSupervisorTaskSuccess,
  fetchSingleCampaignSuccess,
  setCampaignGradeRangeRequestError,
  setCampaignGradeRangeRequestSuccess,
  setIsCampaignInternalSuccess,
  successfullyRemovedUserFromCampaign,
  updateSingleCampaignEndDateError,
  updateSingleCampaignEndDateSuccess,
  updateSingleCampaignError,
  updateSingleCampaignSuccess,
} from './actions';
import { ENDPOINTS } from '../../constants';
import messages from './messages';
import toasterVariants from '../../constants/toasterVariants';

function* fetchSingleCampaign({ campaignId }) {
  try {
    const campaignUser = yield call(request, {
      url: ENDPOINTS.SINGLE_CAMPAIGN.replace('{campaignId}', campaignId),
      method: 'get',
    });
    yield put(fetchSingleCampaignSuccess(campaignUser));
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

function* updateSingleCampaign({ id, campaignData }) {
  try {
    const singleCampaign = yield call(request, {
      url: ENDPOINTS.SINGLE_CAMPAIGN.replace('{campaignId}', id),
      method: 'put',
      data: campaignData,
    });
    yield put(updateSingleCampaignSuccess(singleCampaign));
    yield put(
      enqueueSnackbar({
        message: messages.campaignUpdated,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
    yield put(updateSingleCampaignError());
  }
}

function* updateSingleCampaignEndDate({ id, campaignData }) {
  try {
    const singleCampaign = yield call(request, {
      url: ENDPOINTS.SINGLE_CAMPAIGN_END_DATE.replace('{campaignId}', id),
      method: 'put',
      data: campaignData,
    });
    yield put(updateSingleCampaignEndDateSuccess(singleCampaign));
    yield put(
      enqueueSnackbar({
        message: messages.campaignEndDateUpdated,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
    yield put(updateSingleCampaignEndDateError());
  }
}

function* createCampaignSupervisorTask({ id, supervisorTask }) {
  try {
    const createdSupervisorTask = yield call(request, {
      url: ENDPOINTS.CREATE_CAMPAIGN_SUPERVISOR_TASK.replace(
        '{campaignId}',
        id
      ),
      method: 'post',
      data: supervisorTask,
    });
    yield put(createCampaignSupervisorTaskSuccess(createdSupervisorTask));
    yield put(
      enqueueSnackbar({
        message: messages.campaignUpdated,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
    yield put(createCampaignSupervisorTaskError());
  }
}

function* deleteCampaignSupervisorTask({ campaignId, supervisorTaskId }) {
  try {
    yield call(request, {
      url: ENDPOINTS.DELETE_CAMPAIGN_SUPERVISOR_TASK.replace(
        '{campaignId}',
        campaignId
      ).replace('{supervisorTaskId}', supervisorTaskId),
      method: 'delete',
    });
    yield put(
      enqueueSnackbar({
        message: messages.campaignUpdated,
        variant: toasterVariants.success,
      })
    );
    yield put(deleteCampaignSupervisorTaskSuccess(supervisorTaskId));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
    yield put(deleteCampaignSupervisorTaskError());
  }
}

function* removeJoinedUserFromCampaign({ campaignId, userId }) {
  try {
    yield call(request, {
      url: ENDPOINTS.REMOVE_JOINED_USER.replace(
        '{campaignId}',
        campaignId
      ).replace('{userId}', userId),
      method: 'delete',
    });
    yield put(successfullyRemovedUserFromCampaign(userId));
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

function* changeUserRoleSaga({ campaignId, userId, isSupervisor }) {
  try {
    yield call(request, {
      url: ENDPOINTS.CHANGE_USER_ROLE.replace(
        '{campaignId}',
        campaignId
      ).replace('{userId}', userId),
      data: { isSupervisor },
      method: 'put',
    });
    yield put(changeUserRoleSuccess(userId, isSupervisor));
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

function* approveCampaign({ campaignId }) {
  try {
    yield call(request, {
      url: ENDPOINTS.APPROVE_CAMPAIGN.replace('{campaignId}', campaignId),
      method: 'put',
    });
    yield put(
      enqueueSnackbar({
        message: messages.campaignApproved,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

function* requestChanges({ campaignId, comment }) {
  try {
    yield call(request, {
      url: ENDPOINTS.REQUEST_CHANGES.replace('{campaignId}', campaignId),
      data: { comment },
      method: 'put',
    });
    yield put(
      enqueueSnackbar({
        message: messages.campaigChangesRequested,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

function* cancelCampaign({ campaignId }) {
  try {
    yield call(request, {
      url: ENDPOINTS.CANCEL_CAMPAIGN.replace('{campaignId}', campaignId),
      method: 'delete',
    });
    yield put(
      enqueueSnackbar({
        message: messages.campaignCanceled,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

function* setIsCampaignInternal({ campaignId, isInternal }) {
  try {
    const campaign = yield call(request, {
      url: ENDPOINTS.IS_CAMPAIGN_INTERNAL.replace('{campaignId}', campaignId),
      data: isInternal,
      method: 'put',
    });
    yield put(
      enqueueSnackbar({
        message: messages.statusInternalChanged,
        variant: toasterVariants.success,
      })
    );
    yield put(setIsCampaignInternalSuccess(campaign));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

function* deleteCampaignGradeRange({ campaignId, gradeId }) {
  try {
    const campaignGradeId = yield call(request, {
      url: ENDPOINTS.HANDLE_CAMPAIGN_GRADE.replace(
        '{campaignId}',
        campaignId
      ).replace('{gradeId}', gradeId),
      method: 'delete',
    });

    yield put(deleteCampaignGradeRangeRequestSuccess(campaignGradeId));
  } catch (error) {
    yield put(deleteCampaignGradeRangeRequestError());
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

function* setCampaignGradeRange({ campaignId, gradeId }) {
  try {
    const campaigGrade = yield call(request, {
      url: ENDPOINTS.HANDLE_CAMPAIGN_GRADE.replace(
        '{campaignId}',
        campaignId
      ).replace('{gradeId}', gradeId),
      method: 'post',
    });

    yield put(setCampaignGradeRangeRequestSuccess(campaigGrade));
  } catch (error) {
    yield put(setCampaignGradeRangeRequestError());
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export default function* singleCampaignPageSaga() {
  yield takeLatest(FETCH_SINGLE_CAMPAIGN, fetchSingleCampaign);
  yield takeLatest(UPDATE_SINGLE_CAMPAIGN_REQUEST, updateSingleCampaign);
  yield takeLatest(
    UPDATE_SINGLE_CAMPAIGN_END_DATE_REQUEST,
    updateSingleCampaignEndDate
  );
  yield takeLatest(
    DELETE_CAMPAIGN_SUPERVISOR_TASK_REQUEST,
    deleteCampaignSupervisorTask
  );
  yield takeLatest(
    CREATE_CAMPAIGN_SUPERVISOR_TASK_REQUEST,
    createCampaignSupervisorTask
  );
  yield takeLatest(
    REMOVE_JOINED_USER_FROM_CAMPAIGN,
    removeJoinedUserFromCampaign
  );
  yield takeLatest(CHANGE_USER_ROLE_REQUEST, changeUserRoleSaga);
  yield takeLatest(APPROVE_CAMPAIGN_REQUEST, approveCampaign);
  yield takeLatest(REQUEST_CHANGES_REQUEST, requestChanges);
  yield takeLatest(CANCEL_CAMPAIGN_REQUEST, cancelCampaign);
  yield takeLatest(SET_IS_CAMPAIGN_INTERNAL_REQUEST, setIsCampaignInternal);
  yield takeLatest(
    DELETE_CAMPAIGN_GRADE_RANGE_REQUEST,
    deleteCampaignGradeRange
  );
  yield takeLatest(SET_CAMPAIGN_GRADE_RANGE_REQUEST, setCampaignGradeRange);
}
