import {
  CREATE_ADMIN_ERROR,
  CREATE_ADMIN_REQUEST,
  CREATE_ADMIN_SUCCESS,
  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_SUCCESS,
  EDIT_ADMIN_ERROR,
  EDIT_ADMIN_REQUEST,
  EDIT_ADMIN_SUCCESS,
  FETCH_ADMINS_ERROR,
  FETCH_ADMINS_REQUEST,
  FETCH_ADMINS_SUCCESS,
} from './constants';

export function fetchAdminsRequest(page, perPage) {
  return {
    type: FETCH_ADMINS_REQUEST,
    page,
    perPage,
  };
}

export function fetchAdminsSuccess(admins) {
  return {
    type: FETCH_ADMINS_SUCCESS,
    admins,
  };
}

export function fetchAdminsError() {
  return {
    type: FETCH_ADMINS_ERROR,
  };
}

export function createAdminRequest(adminData, onSuccess) {
  return {
    type: CREATE_ADMIN_REQUEST,
    adminData,
    meta: {
      onSuccess,
    },
  };
}

export function createAdminSuccess(admin) {
  return {
    type: CREATE_ADMIN_SUCCESS,
    admin,
  };
}

export function createAdminError() {
  return {
    type: CREATE_ADMIN_ERROR,
  };
}

export function deleteAdminRequest(adminId) {
  return {
    type: DELETE_ADMIN_REQUEST,
    adminId,
  };
}

export function deleteAdminSuccess(adminId) {
  return {
    type: DELETE_ADMIN_SUCCESS,
    adminId,
  };
}

export function editAdminRequest(adminId, adminData, onSuccess) {
  return {
    type: EDIT_ADMIN_REQUEST,
    adminId,
    adminData,
    meta: {
      onSuccess,
    },
  };
}

export function editAdminSuccess(admin) {
  return {
    type: EDIT_ADMIN_SUCCESS,
    admin,
  };
}

export function editAdminError() {
  return {
    type: EDIT_ADMIN_ERROR,
  };
}
