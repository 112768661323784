import Yup from 'utils/validations';

export const singleStaffUserActivitySchema = Yup.object().shape({
  dateFrom: Yup.date()
    .typeError('Date from is required')
    .required('Date from is required'),
  dateTo: Yup.date()
    .typeError('Date to is required')
    .required('Date to is required')
    .when('dateFrom', (dateFrom) => {
      if (dateFrom) {
        return Yup.date()
          .min(dateFrom, {
            id: `singleStaffUserActivitySchema.dateToMustBeAfterDateFrom`,
            defaultMessage: 'Starting date must be before ending date ',
          })
          .typeError('Date to is required');
      }
    }),
});
