import React from 'react';
import { Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import styles from './RadioGroup.module.scss';
import Error from '../Error';
import classNames from 'classnames';

const RadioGroup = ({
  name,
  type,
  label,
  options,
  labelHidden,
  checkedValue,
  defaultValue,
  alt,
}) => {
  const { formatMessage } = useIntl();
  const isChecked = (option) => checkedValue && checkedValue === option.id;

  return (
    <div>
      {!labelHidden && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <Field
        className={classNames({ [styles.altField]: alt })}
        name={name}
        component="div"
      >
        {options.map((item, index) => {
          return (
            <label className={styles.inputWrapper} htmlFor={item} key={index}>
              {item.label}
              <input
                className={styles.input}
                type={type || 'radio'}
                id={index}
                name={name}
                value={item.id}
                defaultValue={defaultValue}
                checked={isChecked(item)}
              />
              <span className={styles.customCheckmark}></span>
            </label>
          );
        })}
      </Field>
      <Error>
        <ErrorMessage name={name}>
          {(msg) =>
            formatMessage(msg, {
              label,
            })
          }
        </ErrorMessage>
      </Error>
    </div>
  );
};

export default RadioGroup;

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  labelHidden: PropTypes.bool,
  type: PropTypes.string,
  checkedValue: PropTypes.string,
};
