import React from 'react';
import { Form, Formik } from 'formik';
import { InputField } from 'components/Forms';
import Button from 'components/Buttons/Button';
import { singleEyCrowdTaskSchema } from './validations';
import styles from 'components/Layout/DashboardLayout/DashboardLayout.module.scss';

const SingleEyCrowdTaskForm = ({ onSubmit }) => {
  return (
    <div>
      <Formik
        initialValues={{
          description: '',
        }}
        onSubmit={onSubmit}
        validationSchema={singleEyCrowdTaskSchema}
      >
        {({ isValid, dirty }) => (
          <Form>
            <InputField
              required
              type="text"
              name="description"
              label={'Description'}
              placeholder={'Description'}
              autoFocus
            />
            <Button
              disabled={!isValid || !dirty}
              type="submit"
              title={'Create task'}
              alt
              className={styles.submitButton}
            ></Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SingleEyCrowdTaskForm;
