export const STARTER = '[STARTER]';

export const UPLOAD_STARTER_REQUEST = `${STARTER} UPLOAD_STARTER_REQUEST`;
export const UPLOAD_STARTER_SUCCESS = `${STARTER} UPLOAD_STARTER_SUCCESS`;

export const FETCH_STARTER_CAMPAIGNS_REQUEST = `${STARTER} FETCH_STARTER_CAMPAIGNS_REQUEST`;
export const FETCH_STARTER_CAMPAIGNS_SUCCESS = `${STARTER} FETCH_STARTER_CAMPAIGNS_SUCCESS`;

export const DELETE_STARTER_CAMPAING_REQUEST = `${STARTER} DELETE_STARTER_CAMPAING_REQUEST`;
export const DELETE_STARTER_CAMPAING_SUCCESS = `${STARTER} DELETE_STARTER_CAMPAING_SUCCESS`;

export const CHANGE_PRIORITY_REQUEST = `${STARTER} CHANGE_PRIORITY_REQUEST`;
export const CHANGE_PRIORITY_SUCCESS = `${STARTER} CHANGE_PRIORITY_SUCCESS`;
