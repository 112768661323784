export const FEED_ITEMS_PRIORITY = '[FeedItemsPriority]';

export const FETCH_FEED_ITEMS_REQUEST = `${FEED_ITEMS_PRIORITY} FETCH_FEED_ITEMS_REQUEST`;
export const FETCH_FEED_ITEMS_SUCCESS = `${FEED_ITEMS_PRIORITY} FETCH_FEED_ITEMS_SUCCESS`;
export const SET_FEED_ITEM_PRIORITY_REQUEST = `${FEED_ITEMS_PRIORITY} UPDATE_FEED_ITEM_PRIORITY_REQUEST`;
export const SET_FEED_ITEM_PRIORITY_SUCCESS = `${FEED_ITEMS_PRIORITY} UPDATE_FEED_ITEM_PRIORITY_SUCCESS`;
export const DELETE_FEED_ITEM_SUCCESS = `${FEED_ITEMS_PRIORITY} DELETE_FEED_ITEM_SUCCESS`;
export const DELETE_FEED_ITEM_REQUEST = `${FEED_ITEMS_PRIORITY} DELETE_FEED_ITEM_REQUEST`;
export const UPLOAD_FEED_ITEM_SUCCESS = `${FEED_ITEMS_PRIORITY} UPLOAD_FEED_ITEM_SUCCESS`;
export const UPLOAD_FEED_ITEM_REQUEST = `${FEED_ITEMS_PRIORITY} UPLOAD_FEED_ITEM_REQUEST`;
