import DropdownMenu from 'components/DropdownMenu';
import { logout } from 'containers/App/actions';
import React from 'react';
import { useDispatch } from 'react-redux';
import arrowIcon from 'assets/images/arrow.png';
import { useIntl } from 'react-intl';
import messages from './messages';
import styles from './DashboardLayout.module.scss';
import { useHistory } from 'react-router-dom';
import { getUser } from '../../../utils/jwt';

function DashboardHeader() {
  const { formatMessage } = useIntl();

  const history = useHistory();

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className={styles.header}>
      <DropdownMenu
        buttonContent={
          <div>
            <span className={styles.headerCompanyName}>
              Admin
              {/* {getUser().firstName} {getUser().lastName} */}
            </span>
            <img className={styles.arrowIcon} src={arrowIcon} alt="" />
          </div>
        }
      >
        <div className={styles.dropdownWrapper}>
          <DropdownMenu.Item>
            <button className={styles.dropdownButton} onClick={handleLogout}>
              {formatMessage(messages.logOut)}
            </button>
          </DropdownMenu.Item>
          <DropdownMenu.Item>
            <button
              className={styles.dropdownButton}
              onClick={() => {
                history.push('/change-password');
              }}
            >
              Change password
            </button>
          </DropdownMenu.Item>
        </div>
      </DropdownMenu>
    </div>
  );
}

export default DashboardHeader;
