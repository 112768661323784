import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import styles from './UploadInstantCampaign.module.scss';
import classNames from 'classnames';
import ReactSwitch from 'react-switch';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import { getApi } from 'utils/request';

const UploadInstantCampaign = ({ modalOpen, onClose, onUpload, close }) => {
  const [files, setFiles] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [isEySpecial, setIsEySpecial] = useState(false);
  const [pointsRequired, setPointsRequired] = useState('');
  const fileHandler = (event) => {
    let file = event.target.files;
    setFiles(file);
  };
  const [tourCampaigns, setTourCampaigns] = useState([]);
  const [selectedTour, setSelectedTour] = useState(null);
  const token = useSelector((state) => state.app.token);

  const handleUpload = () => {
    onUpload(files, isPrivate, isEySpecial, pointsRequired, selectedTour);
    setIsPrivate(false);
    setIsEySpecial(false);
    close();
  };

  const fetchTourCampaigns = async () => {
    const api = getApi();
    const { data } = await api.get(`/tour-campaigns?page=1&perPage=100`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const retItems = data?.map((tourCampaign) => {
      return {
        value: tourCampaign.id,
        label: tourCampaign.name,
      };
    });
    return retItems;
  };

  useEffect(() => {
    const fetchInitOptions = async () => {
      const tours = await fetchTourCampaigns();
      setTourCampaigns(tours);
    };
    fetchInitOptions();
  }, []);

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalInner}>
          <div className={styles.modalTop}>
            <p className={styles.title}>Upload Instant campaign</p>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.formWrapper}>
              <input
                type="file"
                onChange={fileHandler}
                multiple={false}
                style={{ padding: '10px' }}
              />
              <p>Private instant campaign</p>
              <ReactSwitch
                checked={isPrivate}
                onChange={() => {
                  setIsPrivate(!isPrivate);
                  setIsEySpecial(false);
                }}
              />
              <br />
              <p>EySpecial campaign</p>
              <ReactSwitch
                checked={isEySpecial}
                onChange={() => {
                  setIsEySpecial(!isEySpecial);
                  setIsPrivate(false);
                }}
              />
              <button
                className={classNames(styles.publishButton)}
                onClick={handleUpload}
                disabled={files === null}
              >
                <p className={styles.publishButtonText}>Publish</p>
              </button>
              <br />
              <p>EyPoints required</p>
              <input
                type="text"
                name="pointsRequired"
                onChange={(e) => {
                  setPointsRequired(e.target.value);
                }}
              />
              <AsyncSelect
                isSearchable={false}
                isClearable
                loadOptions={fetchTourCampaigns}
                defaultOptions={tourCampaigns}
                onChange={(e) => {
                  if (e) {
                    setSelectedTour(e.value);
                  } else {
                    setSelectedTour(null);
                  }
                }}
                placeholder="Select Tour Campaign"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    marginTop: '20px',
                    width: '50%',
                  }),
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadInstantCampaign;
