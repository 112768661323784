import React from 'react';
import DashboardHeader from './DashboardHeader';
import styles from './DashboardLayout.module.scss';
import DashboardSideBar from './DashboardSideBar';

function DashboardLayout({ children }) {
  return (
    <div className={styles.container}>
      <DashboardHeader />
      <DashboardSideBar />
      {children}
    </div>
  );
}

export default DashboardLayout;
