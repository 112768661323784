import { ORDER } from '../../constants';
import {
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  FETCH_CAMPAIGN_OBJECTIVES_REQUEST,
  FETCH_CAMPAIGN_OBJECTIVES_SUCCESS,
  FETCH_RETAILERS_REQUEST,
  FETCH_RETAILERS_SUCCESS,
  FETCH_TEMPLATES_REQUEST,
  FETCH_TEMPLATES_SUCCESS,
  UPLOAD_TEMPLATE_REQUEST,
} from './constants';

export function fetchRetailersRequest() {
  return {
    type: FETCH_RETAILERS_REQUEST,
  };
}

export function fetchRetailersSuccess(retailers) {
  return {
    type: FETCH_RETAILERS_SUCCESS,
    retailers,
  };
}

export function fetchCampaignObjectivesRequest() {
  return {
    type: FETCH_CAMPAIGN_OBJECTIVES_REQUEST,
  };
}

export function fetchCampaignObjectivesSuccess(objectives) {
  return {
    type: FETCH_CAMPAIGN_OBJECTIVES_SUCCESS,
    objectives,
  };
}

export function deleteTemplateRequest(template) {
  return {
    type: DELETE_TEMPLATE_REQUEST,
    template,
  };
}

export function deleteTemplateSuccess(template) {
  return {
    type: DELETE_TEMPLATE_SUCCESS,
    template,
  };
}

export function uploadTemplateRequest({
  file,
  retailerId,
  campaignObjectiveCategoryId,
  order,
  campaignType,
}) {
  return {
    type: UPLOAD_TEMPLATE_REQUEST,
    file,
    retailerId,
    campaignObjectiveCategoryId,
    order,
    campaignType,
  };
}

export function fetchTemplatesRequest(
  page,
  perPage,
  search = '',
  sortBy = '',
  order = ORDER.ASC
) {
  return {
    type: FETCH_TEMPLATES_REQUEST,
    page,
    perPage,
    search,
    sortBy,
    order,
  };
}

export function fetchTemplatesSuccess(templates, search, sortBy, order) {
  return {
    type: FETCH_TEMPLATES_SUCCESS,
    templates,
    search,
    sortBy,
    order,
  };
}
