import { store } from 'index';
import { ADMIN_PERMISSIONS } from 'constants/adminPermissions';

export const canActivate = (value) => {
  const state = store.getState();
  const permissions = state.app.permissions;
  const userRole = state.app.user.role;
  if (!value) {
    return true;
  }
  if (userRole === ADMIN_PERMISSIONS.SUPER_ADMIN) {
    return true;
  }
  for (const permission of permissions) {
    if (permission === value) {
      return true;
    }
  }
  return false;
};
