import { takeLatest, call, put } from 'redux-saga/effects';
import { getItem, removeItem } from 'utils/localStorage';
import request from 'utils/request';
import {
  fetchAuthenticatedUserSuccess,
  fetchCommonDataSuccess,
  logoutSuccess,
} from './actions';
import {
  FETCH_AUTHENTICATED_USER_REQUEST,
  FETCH_COMMON_DATA_REQUEST,
  LOGOUT_REQUEST,
} from './constants';
import { ENDPOINTS } from '../../constants';
import { enqueueSnackbar } from '../Notifier/actions';
import { EYCROWD_ACCESS_TOKEN, EYCROWD_REFRESH_TOKEN } from 'utils/constants';
import { push } from 'connected-react-router';
import { LOGIN } from 'routes';

export function* fetchUser() {
  try {
    const user = yield call(request, {
      url: '/auth/me',
      method: 'get',
    });
    yield put(fetchAuthenticatedUserSuccess(user));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* logout() {
  try {
    let refToken = yield call(getItem, EYCROWD_REFRESH_TOKEN);
    yield call(removeItem, EYCROWD_ACCESS_TOKEN);
    yield call(removeItem, EYCROWD_REFRESH_TOKEN);
    yield put(logoutSuccess());
    yield put(push(LOGIN));
    yield call(request, {
      url: ENDPOINTS.AUTH_LOGOUT,
      method: 'delete',
      data: {
        refreshToken: refToken,
      },
    });
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* fetchCommonData() {
  try {
    const commonData = yield call(request, {
      url: ENDPOINTS.COMMON_DATA,
      method: 'get',
    });
    yield put(fetchCommonDataSuccess(commonData));
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export default function* appSaga() {
  yield takeLatest(FETCH_AUTHENTICATED_USER_REQUEST, fetchUser);
  yield takeLatest(LOGOUT_REQUEST, logout);
  yield takeLatest(FETCH_COMMON_DATA_REQUEST, fetchCommonData);
}
