import { defineMessages } from 'react-intl';

export const scope = 'eycrowd_tasks_page';

export default defineMessages({
  createTask: {
    id: `${scope}.text.create_task`,
    defaultMessage: 'Create task',
  },
  taskDescription: {
    id: `${scope}.text.task_description`,
    defaultMessage: 'Task description',
  },
  campaignType: {
    id: `${scope}.text.campaign_type`,
    defaultMessage: 'Campaign type',
  },
  noTasks: {
    id: `${scope}.text.no_tasks`,
    defaultMessage: 'No Tasks',
  },
  areYouSure: {
    id: `${scope}.text.are_you_sure`,
    defaultMessage: 'Are you sure you want to delete this task?',
  },
  taskDeleted: {
    id: `${scope}.text.task_deleted`,
    defaultMessage: 'Task successfully deleted',
  },
});
