export const COUPONS = '[COUPONS]';

export const CREATE_COUPON_REQUEST = `${COUPONS} CREATE_COUPON_REQUEST`;
export const CREATE_COUPON_SUCCESS = `${COUPONS} CREATE_COUPON_SUCCESS`;
export const CREATE_COUPON_ERROR = `${COUPONS} CREATE_COUPON_ERROR`;

export const FETCH_COUPONS_REQUEST = `${COUPONS} FETCH_COUPONS_REQUEST`;
export const FETCH_COUPONS_SUCCESS = `${COUPONS} FETCH_COUPONS_SUCCESS`;

export const DELETE_COUPON_REQUEST = `${COUPONS} DELETE_COUPON_REQUEST`;
export const DELETE_COUPON_SUCCESS = `${COUPONS} DELETE_COUPON_SUCCESS`;

export const EDIT_COUPON_REQUEST = `${COUPONS} EDIT_COUPON_REQUEST`;
export const EDIT_COUPON_SUCCESS = `${COUPONS} EDIT_COUPON_SUCCESS`;
export const EDIT_COUPON_ERROR = `${COUPONS} EDIT_COUPON_ERROR`;
