import produce from 'immer';

import {
  FETCH_TEMPLATES_SUCCESS,
  FETCH_CAMPAIGN_OBJECTIVES_SUCCESS,
  FETCH_RETAILERS_SUCCESS,
  DELETE_TEMPLATE_SUCCESS,
} from './constants';

export const initialState = {
  templates: {
    data: [],
    perPage: null,
    page: null,
    total: null,
    search: '',
    sortBy: '',
    order: '',
  },
  retailers: [],
  objectives: [],
};

/* eslint-disable default-case, no-param-reassign */
const starterPageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_TEMPLATES_SUCCESS:
        draft.templates.perPage = action.templates.perPage;
        draft.templates.page = action.templates.page;
        draft.templates.total = action.templates.total;
        draft.templates.search = action.search;
        draft.templates.sortBy = action.sortBy;
        draft.templates.order = action.order;
        draft.templates.data = action.templates.data;
        break;
      case FETCH_RETAILERS_SUCCESS:
        draft.retailers = action.retailers;
        break;
      case FETCH_CAMPAIGN_OBJECTIVES_SUCCESS:
        draft.objectives = action.objectives;
        break;
      case DELETE_TEMPLATE_SUCCESS:
        let arr = state.templates.data.filter(
          (template) => template.id !== action.template.id
        );
        draft.templates.data = arr;
        break;
    }
  });

export default starterPageReducer;
