import { takeLatest, call, put } from 'redux-saga/effects';
import request from 'utils/request';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { fetchWebsiteFormsSuccess } from './actions';
import { FETCH_WEBSITE_FORMS_REQUEST } from './constants';
import { format } from 'util';
import { ENDPOINTS } from '../../constants';

export function* fetchWebsiteForms({ page, perPage, types }) {
  try {
    const websiteForms = yield call(request, {
      url: format(ENDPOINTS.FETCH_WEBSITE_FORMS, page, perPage, types),
      method: 'get',
    });
    yield put(fetchWebsiteFormsSuccess(websiteForms));
  } catch (error) {
    yield put(enqueueSnackbar({ message: error.data.message }));
  }
}

export default function* websiteFormsSaga() {
  yield takeLatest(FETCH_WEBSITE_FORMS_REQUEST, fetchWebsiteForms);
}
