import React from 'react';
import PropTypes from 'prop-types';
import styles from './Card.module.scss';

function Card({ title, step, children, className }) {
  return (
    <div className={`${styles.card} ${className}`}>
      {step && <p>{step}</p>}
      {title && <h4>{title}</h4>}
      {children}
    </div>
  );
}

export default Card;

Card.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  step: PropTypes.string,
  children: PropTypes.element,
};
