import Yup from 'utils/validations';

export const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(2)
    .max(100)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
    .required(),
  newPassword: Yup.string()
    .min(2)
    .max(100)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
    .required(),
  newPassowrdConfirm: Yup.string()
    .min(2)
    .max(100)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
    .required(),
});
