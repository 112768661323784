import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import { fetchBusinessRulesSuccess } from './actions';
import {
  FETCH_BUSINESS_RULES_REQUEST,
  UPDATE_BUSINESS_RULES,
} from './constants';
import { ENDPOINTS } from '../../constants';
import messages from './messages';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import toasterVariants from '../../constants/toasterVariants';

export function* fetchBusinessRules() {
  try {
    const variables = yield call(request, {
      url: ENDPOINTS.BUSINESS_RULES,
      method: 'get',
    });
    yield put(fetchBusinessRulesSuccess(variables));
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* updateBusinessRules({ businessRules }) {
  try {
    Object.keys(businessRules).forEach(function (key) {
      if (!isNaN(businessRules[key])) {
        businessRules[key] = +businessRules[key];
      }
    });
    const variables = yield call(request, {
      url: ENDPOINTS.BUSINESS_RULES,
      method: 'put',
      data: businessRules,
    });
    yield put(fetchBusinessRulesSuccess(variables));

    yield put(
      enqueueSnackbar({
        message: messages.businessRulesUpdated,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export default function* businessRulesSaga() {
  yield takeLatest(FETCH_BUSINESS_RULES_REQUEST, fetchBusinessRules);
  yield takeLatest(UPDATE_BUSINESS_RULES, updateBusinessRules);
}
