import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import {
  createBrandSummaryAndSocialsError,
  createBrandSummaryAndSocialsSuccess,
  createCampaignSuggestionsError,
  createCampaignSuggestionsSuccess,
  bulkCreateCampaignsSuccess,
  bulkCreateCampaignsError,
} from './actions';
import { ENDPOINTS } from '../../constants';
import { enqueueSnackbar } from '../Notifier/actions';
import toasterVariants from 'constants/toasterVariants';
import {
  CREATE_BRAND_SUMMARY_AND_SOCIALS_REQUEST,
  CREATE_BULK_CAMPAIGNS_REQUEST,
  CREATE_CAMPAIGN_SUGGESTIONS_REQUEST,
} from './constants';

export function* createBrandSummaryAndSocialSuggestions({
  payload,
  meta: { onSuccess, onError },
}) {
  try {
    const data = yield call(request, {
      url: ENDPOINTS.CREATE_BRAND_STORY_SUMMARY,
      method: 'post',
      data: payload,
    });

    yield put(
      enqueueSnackbar({
        message: 'Brand summary & social suggestions created succesfully',
        variant: toasterVariants.success,
      })
    );
    yield put(
      createBrandSummaryAndSocialsSuccess({ data, userPayload: payload })
    );
    onSuccess();
  } catch (error) {
    onError();
    yield put(createBrandSummaryAndSocialsError());
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* createCampaignSuggestions({
  payload,
  meta: { onSuccess, onError },
}) {
  try {
    const formData = new FormData();
    formData.append('file', payload.jsonFile);
    formData.append('numberOfCampaigns', payload.numberOfCampaigns);
    formData.append('numberOfObjectives', payload.numberOfObjectives);
    if (payload?.otherInstructions) {
      formData.append('otherInstructions', payload.otherInstructions);
    }
    formData.append('brandName', payload.brandName);
    formData.append('brandSummary', payload.brandSummary);
    formData.append('address', payload.address);
    formData.append('industry', payload.industry);
    const data = yield call(request, {
      url: ENDPOINTS.CREATE_CAMPAIGN_SUGGESTIONS,
      method: 'post',
      data: formData,
      headers: {},
    });

    yield put(
      enqueueSnackbar({
        message: 'Campaign suggestions created succesfully!',
        variant: toasterVariants.success,
      })
    );
    yield put(createCampaignSuggestionsSuccess(data));
    onSuccess();
  } catch (error) {
    onError();
    yield put(createCampaignSuggestionsError());
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* uploadBulkCampaigns({
  payload,
  meta: { onSuccess, onError },
}) {
  try {
    const data = yield call(request, {
      url: ENDPOINTS.CREATE_BULK_CAMPAIGNS,
      method: 'post',
      data: payload,
    });
    yield put(
      enqueueSnackbar({
        message: 'Bulk Campaigns Uploaded Succesfully!',
        variant: toasterVariants.success,
      })
    );
    yield put(bulkCreateCampaignsSuccess(data));
    onSuccess();
  } catch (error) {
    onError();
    yield put(bulkCreateCampaignsError());
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export default function* BulkCampaignsSaga() {
  yield takeLatest(
    CREATE_BRAND_SUMMARY_AND_SOCIALS_REQUEST,
    createBrandSummaryAndSocialSuggestions
  );
  yield takeLatest(
    CREATE_CAMPAIGN_SUGGESTIONS_REQUEST,
    createCampaignSuggestions
  );
  yield takeLatest(CREATE_BULK_CAMPAIGNS_REQUEST, uploadBulkCampaigns);
}
