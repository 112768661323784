import React from 'react';
import { Modal } from '@material-ui/core';
import styles from './ShowAiPromptLoginInfoModal.module.scss';

const ShowAiPromptLogInfoModal = ({
  modalOpen,
  onClose,
  close,
  title,
  data,
}) => {
  const handleClose = () => {
    close();
  };

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalInner}>
          <div className={styles.modalTop}>
            <p className={styles.title}>{title}</p>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.columnContainer}>
              <div className={styles.divider}></div>
              <p className={styles.sectionTitle}>User Info:</p>
              <div className={styles.rowContainer}>
                <div
                  className={`${styles.childContainer} ${styles.childContainer_third}`}
                >
                  <p>Username</p>
                  <p>{data?.user?.username}</p>
                </div>
                <div
                  className={`${styles.childContainer} ${styles.childContainer_third}`}
                >
                  <p>First name</p>
                  <p>{data?.user?.firstName}</p>
                </div>
                <div
                  className={`${styles.childContainer} ${styles.childContainer_third}`}
                >
                  <p>Last name</p>
                  <p>{data?.user?.lastName}</p>
                </div>
              </div>
            </div>
            {data?.task && (
              <div className={styles.columnContainer}>
                <div className={styles.divider}></div>
                <p className={styles.sectionTitle}>Task Info:</p>
                <div className={styles.rowContainer}>
                  <div
                    className={`${styles.childContainer} ${styles.childContainer_half}`}
                  >
                    <p>Task Description</p>
                    <p>{data?.task?.description}</p>
                  </div>
                  {data?.task?.suggestedHashtags && (
                    <div
                      className={`${styles.childContainer} ${styles.childContainer_quarter}`}
                    >
                      <p>Suggested Hashtags</p>
                      <p>{data?.task?.suggestedHashtags}</p>
                    </div>
                  )}
                  <div
                    className={`${styles.childContainer} ${styles.childContainer_quarter}`}
                  >
                    <p>Task Category</p>
                    <p>{data?.task?.category}</p>
                  </div>
                </div>
              </div>
            )}
            {!!data?.screenshotUrls?.length && (
              <div className={styles.columnContainer}>
                <div className={styles.divider}></div>
                <p className={styles.sectionTitle}>Screenshots:</p>

                {data?.screenshotUrls.map((url, index) => (
                  <div key={index}>
                    <img
                      className={styles.screenshotImage}
                      src={url}
                      alt="screenshot"
                    />
                  </div>
                ))}
              </div>
            )}
            <div className={styles.columnContainer}>
              <div className={styles.divider}></div>
              <p className={styles.sectionTitle}>Explanation:</p>
              <div>
                <p>{data?.explanation}</p>
              </div>
            </div>
            {!!data?.keyActions?.length && (
              <div className={styles.columnContainer}>
                <div className={styles.divider}></div>
                <p className={styles.sectionTitle}>Key Actions:</p>
                {data.keyActions.map((actionObject, actionIndex) => (
                  <React.Fragment key={actionIndex}>
                    {Object.entries(actionObject).map(
                      ([key, value], kvIndex) => (
                        <div
                          key={`${actionIndex}-${kvIndex}`}
                          className={styles.rowContainer}
                        >
                          <p>
                            {key}: {value}
                          </p>
                        </div>
                      )
                    )}
                    <div className={styles.divider}></div>
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
          <button className={styles.closeButton} onClick={handleClose}>
            <p className={styles.closeButtonText}>Close</p>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ShowAiPromptLogInfoModal;
