import { ADMIN_PERMISSIONS } from './adminPermissions';
import messages from 'messages';

export const ADMIN_PERMISSIONS_OPTIONS = [
  {
    id: ADMIN_PERMISSIONS.SUPER_ADMIN,
    label: messages.adminPermissions.super_admin,
  },
  {
    id: ADMIN_PERMISSIONS.ADMIN,
    label: messages.adminPermissions.admin,
  },
  {
    id: ADMIN_PERMISSIONS.FIELD_GUIDE,
    label: messages.adminPermissions.field_guide,
  },
  {
    id: ADMIN_PERMISSIONS.CLIENT_GUIDE,
    label: messages.adminPermissions.client_guide,
  },
  {
    id: ADMIN_PERMISSIONS.ENGINEERING,
    label: messages.adminPermissions.engineering,
  },
  {
    id: ADMIN_PERMISSIONS.LEARNING_AND_DEVELOPMENT,
    label: messages.adminPermissions.learning_and_development,
  },
  {
    id: ADMIN_PERMISSIONS.MARKETING,
    label: messages.adminPermissions.marketing,
  },
];
