import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import {
  approveUsersError,
  approveUsersSuccess,
  fetchUsersListSuccess,
} from './actions';
import { APPROVE_USERS_REQUEST, FETCH_USERS_LIST_REQUEST } from './constants';
import { ENDPOINTS } from '../../constants';
import { format } from 'util';
import { enqueueSnackbar } from '../Notifier/actions';
import messages from './messages';
import toasterVariants from '../../constants/toasterVariants';

export function* fetchUsersListRequest({
  page,
  perPage,
  search,
  sortBy,
  order,
}) {
  let isStaff = false;
  try {
    const users = yield call(request, {
      url: format(
        ENDPOINTS.USERS_PAGE,
        page,
        perPage,
        search,
        sortBy,
        order,
        isStaff
      ),
      method: 'get',
    });
    yield put(fetchUsersListSuccess(users, search, sortBy, order));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
    console.error(error);
  }
}

export function* approveUsersRequest({ users }) {
  try {
    yield call(request, {
      url: ENDPOINTS.APPROVE_USERS,
      method: 'post',
      data: users,
    });

    yield put(
      enqueueSnackbar({
        message: messages.bulkApproveUserSuccessMessage,
        variant: toasterVariants.success,
      })
    );

    yield put(approveUsersSuccess());
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.data.message,
      })
    );
    console.error(err);
    yield put(approveUsersError());
  }
}

export default function* usersListSaga() {
  yield takeLatest(FETCH_USERS_LIST_REQUEST, fetchUsersListRequest);
  yield takeLatest(APPROVE_USERS_REQUEST, approveUsersRequest);
}
