import produce from 'immer';
import {
  CREATE_OBJECTIVE_SUCCESS,
  FETCH_OBJECTIVE_CATEGORIES_LIST_SUCCESS,
} from './constants';

export const initialState = {
  categories: [],
};

const singleObjectiveReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_OBJECTIVE_CATEGORIES_LIST_SUCCESS:
        draft.categories = action.categories;
        break;
      case CREATE_OBJECTIVE_SUCCESS:
        return state;
      default:
        return state;
    }
  });

export default singleObjectiveReducer;
