import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Table.module.scss';
import { ORDER } from '../../constants';
import PaginationBar from 'components/PaginationBar/PaginationBar.component';
import Button from 'components/Buttons/Button';
import classNames from 'classnames';

const ResourceTable = ({
  hasExtraActions,
  headerInformation,
  children,
  noDataContent,
  handleSort,
  resource,
  fetchPage,
  perPage,
  handlePerPage,
  hidePagination = false,
  showBulkAction = false,
  bulkAction,
  canAccessBulkAction,
  bulkActionTitle = 'Action',
  canAccess,
  className,
}) => {
  const { page } = resource || [];

  const bulkRef = useRef();
  const table = useRef();

  const [selectedItems, setSelectedItems] = useState([]);
  const [sortOptions, setSortOptions] = useState({
    sortBy: '',
    order: ORDER.ASC,
  });

  const options = [
    { label: '25', id: 25 },
    { label: '50', id: 50 },
    { label: '100', id: 100 },
  ];

  useEffect(() => {
    if (showBulkAction) {
      if (selectedItems.length === 0) {
        bulkRef.current.checked = false;
        bulkRef.current.indeterminate = false;
      } else if (selectedItems.length === children?.length * page) {
        bulkRef.current.checked = true;
        bulkRef.current.indeterminate = false;
      } else {
        bulkRef.current.checked = false;
        bulkRef.current.indeterminate = true;
      }
    }
  }, [selectedItems, children, showBulkAction, page]);

  useEffect(() => {
    handleSort && handleSort(sortOptions.sortBy, sortOptions.order);
    //eslint-disable-next-line
  }, [sortOptions]);

  const handleAllItems = () => {
    if (bulkRef.current.checked) {
      setSelectedItems(children.map((child) => child.key));
    } else {
      setSelectedItems([]);
    }
  };

  const handleItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems((prevState) =>
        prevState.filter((itemId) => itemId !== id)
      );
    } else {
      setSelectedItems((prevState) => [...prevState, id]);
    }
  };

  const onSort = (key) => {
    if (sortOptions.order === ORDER.ASC && !sortOptions.sortBy) {
      setSortOptions({ ...sortOptions, sortBy: key });
    } else if (key !== sortOptions.sortBy) {
      setSortOptions({ sortBy: key, order: ORDER.ASC });
    } else if (sortOptions.order === ORDER.ASC && sortOptions.sortBy) {
      setSortOptions({ sortBy: key, order: ORDER.DESC });
    } else {
      setSortOptions({ sortBy: '', order: ORDER.ASC });
    }
  };

  const fullWidth = hasExtraActions
    ? headerInformation.length + 1
    : headerInformation.length;

  const bulkActionWrapper = () => {
    bulkAction(selectedItems);
    setSelectedItems([]);
  };

  const fetchPageWraper = (page) => {
    fetchPage(page);
    table.current.scrollTo(0, 0);
  };

  return canAccess ? (
    <>
      {showBulkAction && canAccessBulkAction && (
        <Button
          disabled={selectedItems.length === 0}
          onClick={bulkActionWrapper}
          className={[
            styles.bulkButton,
            selectedItems.length === 0 ? styles.bulkButtonDisabled : null,
          ]}
        >
          {bulkActionTitle}
        </Button>
      )}
      <div
        ref={table}
        className={classNames(
          styles.campaignsTableWrapper,
          {
            [styles.bulkTableWraper]: showBulkAction,
          },
          className && className
        )}
      >
        {/*<InfiniteScroll dataLength={rowsNumber} next={loadMore} hasMore={hasMore}>*/}
        <table className={styles.table}>
          <thead>
            <tr>
              {showBulkAction && (
                <th className={`${styles.th}`} key="bulk">
                  <input
                    ref={bulkRef}
                    className={styles.input}
                    type="checkbox"
                    onClick={handleAllItems}
                  />
                </th>
              )}
              {headerInformation.map((item, index) => {
                return (
                  <th
                    className={`${styles.th} ${
                      item.key ? styles.sortable : ''
                    } ${item.key === sortOptions.sortBy ? styles.sorted : ''}`}
                    key={index}
                    onClick={() => {
                      item.key && item.sortable && onSort(item.key);
                    }}
                  >
                    {item.label}
                    {item.key && item.sortable ? (
                      <span
                        className={`${styles.chevron} ${
                          sortOptions.order === ORDER.DESC &&
                          item.key === sortOptions.sortBy
                            ? styles.top
                            : styles.bottom
                        }`}
                      ></span>
                    ) : null}
                  </th>
                );
              })}
              {hasExtraActions && <th className={styles.th}>Actions</th>}
            </tr>
          </thead>
          {children?.length ? (
            <tbody className={styles.tbody}>
              {children?.map((child, index) => {
                return (
                  <tr className={styles.tr} key={index}>
                    {showBulkAction && (
                      <td className={styles.checkbox}>
                        <input
                          className={styles.input}
                          type="checkbox"
                          checked={selectedItems.find(
                            (itemId) => itemId === child.key
                          )}
                          onClick={() =>
                            bulkAction ? handleItem(child.key) : null
                          }
                        />
                      </td>
                    )}
                    {child}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody className={styles.tbody}>
              <tr className={styles.tr}>
                <td className={styles.noDataTd} colSpan={fullWidth}>
                  {noDataContent}
                </td>
              </tr>
            </tbody>
          )}
        </table>
        {/*</InfiniteScroll>*/}
      </div>
      {!hidePagination && resource.total !== 0 && (
        <div className={styles.paginationContainer}>
          <div className={styles.select}>
            <select
              value={perPage}
              onChange={(e) =>
                handlePerPage ? handlePerPage(e.target.value) : null
              }
            >
              {options.map((option) => (
                <option value={option.id} key={option.id}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <PaginationBar resource={resource} loadPage={fetchPageWraper} />
          </div>
        </div>
      )}
    </>
  ) : null;
};

export default ResourceTable;

ResourceTable.propTypes = {
  hasExtraActions: PropTypes.bool,
  headerInformation: PropTypes.array,
  children: PropTypes.node,
  rowsNumber: PropTypes.number,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  handleSort: PropTypes.func,
};
