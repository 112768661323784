import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import {
  DELETE_NOTICE_CARD_REQUEST,
  FETCH_NOTICE_CARDS_REQUEST,
  FETCH_TOUR_CAMPAIGNS_REQUEST,
  TOGGLE_NOTICE_CARD_ACTIVE_STATUS_REQUEST,
  UPLOAD_NOTICE_CARD_REQUEST,
} from './constants';
import { ENDPOINTS } from '../../constants';
import { format } from 'util';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import {
  deleteNoticeCardSuccess,
  fetchNoticeCardsSuccess,
  fetchTourCampaignsSuccess,
  toggleNoticeCardActiveStatusSuccess,
  uploadNoticeCardSuccess,
} from './actions';
import toasterVariants from 'constants/toasterVariants';

export function* fetchNoticeCardsRequest({ page, perPage, search }) {
  try {
    const cards = yield call(request, {
      url: format(ENDPOINTS.FETCH_NOTICE_CARDS, page, perPage, search),
      method: 'get',
    });

    yield put(fetchNoticeCardsSuccess(cards, search));
  } catch (error) {
    console.log(error);
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export function* toggleNoticeCardActiveStatusRequest({ id }) {
  try {
    yield call(request, {
      url: ENDPOINTS.TOGGLE_NOTICE_CARD_ACTIVE_STATUS.replace('{id}', id),
      method: 'put',
    });

    yield put(toggleNoticeCardActiveStatusSuccess(id));
    yield put(
      enqueueSnackbar({
        message: 'Notice card status updated successfully.',
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export function* uploadNoticeCardRequest({ data }) {
  try {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === 'file' && data[key]) {
        formData.append('logo', data[key]);
      } else if (data[key]) {
        formData.append(key, data[key]);
      }
    });

    const card = yield call(request, {
      url: ENDPOINTS.NOTICE_CARDS,
      method: 'post',
      data: formData,
    });

    yield put(uploadNoticeCardSuccess(card));
    yield put(
      enqueueSnackbar({
        message: 'Notice card uploaded successfully.',
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    console.log(error);
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export function* fetchTourCampaignsRequest() {
  try {
    const campaigns = yield call(request, {
      url: format(ENDPOINTS.FETCH_ADMIN_TOUR_CAMPAIGNS),
      method: 'get',
    });

    yield put(fetchTourCampaignsSuccess(campaigns));
  } catch (error) {
    console.log(error);
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export function* deleteNoticeCardRequest({ id }) {
  try {
    yield call(request, {
      url: ENDPOINTS.TOGGLE_NOTICE_CARD_ACTIVE_STATUS.replace('{id}', id),
      method: 'delete',
    });

    yield put(deleteNoticeCardSuccess(id));
    yield put(
      enqueueSnackbar({
        message: 'Notice card successfully deleted.',
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data?.message,
      })
    );
  }
}

export default function* noticeCardsSaga() {
  yield takeLatest(FETCH_NOTICE_CARDS_REQUEST, fetchNoticeCardsRequest);
  yield takeLatest(
    TOGGLE_NOTICE_CARD_ACTIVE_STATUS_REQUEST,
    toggleNoticeCardActiveStatusRequest
  );
  yield takeLatest(UPLOAD_NOTICE_CARD_REQUEST, uploadNoticeCardRequest);
  yield takeLatest(FETCH_TOUR_CAMPAIGNS_REQUEST, fetchTourCampaignsRequest);
  yield takeLatest(DELETE_NOTICE_CARD_REQUEST, deleteNoticeCardRequest);
}
