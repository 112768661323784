import { Modal } from '@material-ui/core';
import Button from 'components/Buttons/Button';
import { InputField, SelectField } from 'components/Forms';
import { ADMIN_PERMISSIONS_OPTIONS } from 'constants/adminPermissionsOptions';
import { Form, Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { createAdminRequest, editAdminRequest } from './actions';
import styles from './AdminsPage.module.scss';
import messages from './messages';
import {
  makeSelectCreateAdminPending,
  makeSelectEditAdminPending,
} from './selectors';
import { createAdminSchema, editAdminSchema } from './validations';

const HandleAdminModal = ({
  modalVisible,
  setModalVisible,
  selectedAdmin,
  setSelectedAdmin,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const creatingAdmin = useSelector(makeSelectCreateAdminPending());
  const editingAdmin = useSelector(makeSelectEditAdminPending());

  const onSubmit = (values) => {
    if (selectedAdmin) {
      const formValues = { ...values };
      formValues.password === '' && delete formValues.password;
      dispatch(
        editAdminRequest(selectedAdmin.id, formValues, () => {
          setModalVisible(false);
        })
      );
    } else {
      dispatch(
        createAdminRequest(values, () => {
          setModalVisible(false);
        })
      );
    }
  };

  return (
    <Modal
      open={modalVisible}
      onClose={() => {
        setModalVisible(false);
        setSelectedAdmin(null);
      }}
    >
      <div className={styles.modalWrapper}>
        <div className={styles.modalInner}>
          <div className={styles.modalTop}>
            <p className={styles.title}>
              {selectedAdmin ? 'Edit Admin' : 'Create Admin'}
            </p>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.formWrapper}>
              <div className={styles.formWrap}>
                <div className={styles.inputWrapperSm}>
                  <Formik
                    initialValues={{
                      email: selectedAdmin?.email || '',
                      firstName: selectedAdmin?.firstName || '',
                      lastName: selectedAdmin?.lastName || '',
                      password: '',
                      role: selectedAdmin?.role || '',
                    }}
                    validationSchema={
                      selectedAdmin ? editAdminSchema : createAdminSchema
                    }
                    onSubmit={onSubmit}
                    enableReinitialize
                  >
                    {({ isValid, dirty, setFieldValue }) => {
                      return (
                        <Form className={styles.form}>
                          <InputField
                            required
                            type="email"
                            name="email"
                            label={formatMessage(messages.email)}
                            placeholder={formatMessage(messages.email)}
                            disabled={selectedAdmin}
                          />
                          <InputField
                            type="text"
                            name="firstName"
                            label={formatMessage(messages.firstName)}
                            placeholder={formatMessage(messages.firstName)}
                            required
                          />
                          <InputField
                            type="text"
                            name="lastName"
                            label={formatMessage(messages.lastName)}
                            placeholder={formatMessage(messages.lastName)}
                            required
                          />
                          <InputField
                            type="text"
                            name="password"
                            label={formatMessage(messages.password)}
                            placeholder={formatMessage(messages.password)}
                            minValue={'8'}
                            maxValue={'100'}
                            required={!selectedAdmin}
                          />
                          <SelectField
                            name="role"
                            options={ADMIN_PERMISSIONS_OPTIONS}
                            label={formatMessage(messages.role)}
                            placeholder={formatMessage(messages.role)}
                            onChange={(event) =>
                              setFieldValue('role', event.target.value)
                            }
                            required
                          />
                          <Button
                            alt
                            disabled={
                              !isValid ||
                              !dirty ||
                              creatingAdmin ||
                              editingAdmin
                            }
                            type="submit"
                            isLarge
                            className={styles.button}
                          >
                            Submit
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HandleAdminModal;
