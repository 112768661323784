import { DEMOGRAPHIC_OPTIONS } from './index';

export const GENDER_OPTIONS = [
  {
    id: DEMOGRAPHIC_OPTIONS.MALE,
    label: 'Male',
    pronoun: DEMOGRAPHIC_OPTIONS.HE,
  },
  {
    id: DEMOGRAPHIC_OPTIONS.FEMALE,
    label: 'Female',
    pronoun: DEMOGRAPHIC_OPTIONS.SHE,
  },
  {
    id: DEMOGRAPHIC_OPTIONS.CUSTOM,
    label: 'Custom',
  },
];

export const PRONOUN_OPTIONS = [
  {
    id: DEMOGRAPHIC_OPTIONS.HE,
    label: 'He',
  },
  {
    id: DEMOGRAPHIC_OPTIONS.SHE,
    label: 'She',
  },
  {
    id: DEMOGRAPHIC_OPTIONS.THEY,
    label: 'They',
  },
];
