import Button from 'components/Buttons/Button';
import { InputField } from 'components/Forms';
import AuthLayout from 'components/Layout/Pages/AuthPage/AuthLayout';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getApi } from 'utils/request';
import { forgotPasswordSchema } from './validations';

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const handleOnSubmit = async (values, { setErrors }) => {
    try {
      const { email } = values;
      const api = getApi();
      await api.post('/auth/admin/forgot-password', {
        email: email,
      });
      dispatch(
        enqueueSnackbar({
          variant: 'success',
          message: 'Password reset request sent',
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          variant: 'error',
          message: err.data.message,
        })
      );
    }
  };

  return (
    <main>
      <AuthLayout>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={forgotPasswordSchema}
          onSubmit={handleOnSubmit}
        >
          <Form>
            <div>
              <InputField
                type="email"
                name="email"
                label={'Email'}
                placeholder={'Enter email'}
                required
                autoFocus
              />
            </div>
            <Button
              alt
              disabled={false}
              type="submit"
              title={'Submit'}
              isLarge
            />
          </Form>
        </Formik>
      </AuthLayout>
    </main>
  );
};

export default ForgotPassword;
