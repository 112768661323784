import { defineMessages } from 'react-intl';

export const scope = 'single_campaign.campaign.info';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Campaign info',
  },
  campaignType: {
    id: `${scope}.campaign_type`,
    defaultMessage: 'Campaign Type',
  },
  businessType: {
    id: `${scope}.business_type`,
    defaultMessage: 'Business Type',
  },
  startDate: {
    id: `${scope}.start_date`,
    defaultMessage: 'Start Date',
  },
  startTime: {
    id: `${scope}.start_time`,
    defaultMessage: 'Start Time',
  },
  endDate: {
    id: `${scope}.end_date`,
    defaultMessage: 'End Date',
  },
  endCampaignDate: {
    id: `${scope}.end_campaign_at`,
    defaultMessage: 'End Campaign at',
  },
  applicationDeadline: {
    id: `${scope}.application_deadline`,
    defaultMessage: 'Application Deadline',
  },
  applicationDeadlineTime: {
    id: `${scope}.application_deadline_time`,
    defaultMessage: 'Application Deadline Time',
  },
  eyvocates: {
    id: `${scope}.eyvocates`,
    defaultMessage: 'EyVocates',
  },
  age: {
    id: `${scope}.age`,
    defaultMessage: 'Age',
  },
  gender: {
    id: `${scope}.gender`,
    defaultMessage: 'Gender',
  },
  interests: {
    id: `${scope}.interests`,
    defaultMessage: 'Interests',
  },
  beIn: {
    id: `${scope}.be_in`,
    defaultMessage: 'Be In',
  },
  pricePerHour: {
    id: `${scope}.price_per_hour`,
    defaultMessage: 'Price Per Hour',
  },
  totalPrice: {
    id: `${scope}.total_price`,
    defaultMessage: 'Total Price',
  },
  setCampaignInternal: {
    id: `${scope}.set_campaign_internal`,
    defaultMessage: 'Set campaign internal',
  },
  cancel: {
    id: `${scope}.cancel_edit`,
    defaultMessage: 'Cancel',
  },
  pricePerCampaign: {
    id: `${scope}.price_per_campaign`,
    defaultMessage: 'Price Per Campaign',
  },
  tasksTotalDurationInMinutes: {
    id: `${scope}.tasks_total_duration_in_minutes`,
    defaultMessage: 'Tasks Total Duration In Minutes',
  },
});
