import {
  FETCH_OBJECTIVES_LIST_REQUEST,
  FETCH_OBJECTIVES_LIST_SUCCESS,
  DELETE_OBJECTIVE_REQUEST,
  DELETE_OBJECTIVE_SUCCESS,
} from './constants';

export function fetchObjectivesListRequest() {
  return {
    type: FETCH_OBJECTIVES_LIST_REQUEST,
  };
}

export function fetchObjectivesListSuccess(objectives) {
  return {
    type: FETCH_OBJECTIVES_LIST_SUCCESS,
    objectives,
  };
}

export function deleteObjectiveRequest(id) {
  return {
    type: DELETE_OBJECTIVE_REQUEST,
    id,
  };
}

export function deleteObjectiveSuccess(id) {
  return {
    type: DELETE_OBJECTIVE_SUCCESS,
    id,
  };
}
