import React from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import styles from '../FormFieldStyles.module.scss';
import Icon from 'components/Icon';
import Error from '../Error';
import { ReactComponent as UploadIcon } from 'assets/images/help.svg';

const InputField = ({
  type,
  name,
  label,
  placeholder,
  required,
  minValue,
  maxValue = 255,
  autoFocus,
  labelHidden,
  component,
  className,
  containerStyle = '',
  inputFieldLarge,
  textarea,
  textareaLarge,
  textareaExtraLarge,
  disabled,
  replaceFunction,
  //uploadMedia,
}) => {
  const { formatMessage } = useIntl();
  const formik = useFormikContext();

  const isFileInput = type === 'file';

  const inputClasses = classNames(styles.input, {
    [styles.textarea]: textarea,
    [styles.textareaLarge]: textareaLarge,
    [styles.textareaExtraLarge]: textareaExtraLarge,
    [styles.inputFieldLarge]: inputFieldLarge,
    [styles.fileInput]: isFileInput,
  });

  const inputFieldClasses = classNames(
    styles.field,
    {
      [containerStyle]: containerStyle,
      [styles.fileInputField]: isFileInput,
    },
    className
  );

  const handleChange = (value) => {
    formik.setFieldValue(
      name,
      replaceFunction
        ? value.target.value.replace(' ', '_')
        : value.target.value
    );
  };

  return (
    <div className={inputFieldClasses}>
      {!labelHidden && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <Field
        type={type}
        placeholder={placeholder}
        name={name}
        required={required}
        autoFocus={autoFocus}
        className={inputClasses}
        component={component}
        disabled={disabled}
        onChange={handleChange}
        step={0.1}
        //uploadMedia={uploadMedia}
      />
      {isFileInput && (
        <span className={styles.customFileInputField}>
          <Icon
            className={styles.fileInputFieldIcon}
            icon={UploadIcon}
            size={'md'}
          />
          {/* {uploadMedia} */}
        </span>
      )}
      {!required && <p className={styles.optional}>Optional</p>}
      <Error>
        <ErrorMessage name={name}>
          {(msg) =>
            formatMessage(msg, {
              label,
              minValue,
              maxValue,
            })
          }
        </ErrorMessage>
      </Error>
    </div>
  );
};

export default InputField;

InputField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  minValue: PropTypes.string,
  maxValue: PropTypes.string,
  autoFocus: PropTypes.bool,
  labelHidden: PropTypes.bool,
  component: PropTypes.string,
  containerStyle: PropTypes.string,
  inputFieldLarge: PropTypes.bool,
  textarea: PropTypes.bool,
  textareaLarge: PropTypes.bool,
  fileInputField: PropTypes.bool,
  //uploadMedia: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
