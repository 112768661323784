import React from 'react';
import { Modal } from '@material-ui/core';
import styles from './Gallery.module.scss';

const GalleryModal = ({ isOpen, onClose, media }) => {
  return (
    <Modal className={styles.modal} open={isOpen} onClose={onClose}>
      <div className={styles.modalWrap}>
        {media?.type === 'photo' ? (
          <div className={styles.imagePlaceHolder}>
            <img src={media.url} alt="img" className={styles.imageLg} />
          </div>
        ) : (
          <video className={styles.imageLg} autoPlay={true} controls>
            <source src={media?.url} type="video/mp4" />
          </video>
        )}
      </div>
    </Modal>
  );
};

export default GalleryModal;
