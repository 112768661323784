import produce from 'immer';
import {
  CREATE_BRAND_SUMMARY_AND_SOCIALS_REQUEST,
  CREATE_BRAND_SUMMARY_AND_SOCIALS_SUCCESS,
  CREATE_BRAND_SUMMARY_AND_SOCIALS_ERROR,
  CREATE_CAMPAIGN_SUGGESTIONS_REQUEST,
  CREATE_CAMPAIGN_SUGGESTIONS_SUCCESS,
  CREATE_CAMPAIGN_SUGGESTIONS_ERROR,
  REORGANIZE_JSON_DATA,
  CREATE_BULK_CAMPAIGNS_REQUEST,
  CREATE_BULK_CAMPAIGNS_SUCCESS,
  CREATE_BULK_CAMPAIGNS_ERROR,
  CHANGE_SOCIAL_MEDIA_URLS,
} from './constants';

export const initialState = {
  createBrandSummaryPending: false,
  createCampaignSuggestionsPending: false,
  brandSummary: '',
  socialMediaUrls: {},
  brandName: '',
  industry: '',
  address: '',
  url: '',
  objectiveCategories: [],
  objectives: [],
  tasks: [],
  platformActions: [],
  campaignSuggestions: [],
  bulkCreateCampaignsPending: false,
};

/* eslint-disable default-case */
const bulkCampaignReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case REORGANIZE_JSON_DATA:
        const { jsonData } = action.payload;
        const { objective_category } = jsonData;

        const retObjectiveCategories = [];
        const retObjectives = [];
        const retTasks = [];
        const retPlatformActions = [];

        objective_category.forEach((oc) => {
          retObjectiveCategories.push({
            id: oc.objective_category_id,
            category: oc.objective_category,
            description: oc.objective_category_desc,
            headline: oc.objective_category_headline,
            tagline: oc.objective_category_tagline,
            subcategory: oc.objective_subcategory.map((osc) => ({
              id: osc.objective_subcategory_id,
              subcategory: osc.objective_subcategory,
              description: osc.objective_subcategory_desc,
              shortDescription: osc.objective_subcategory_short_desc,
            })),
          });

          oc.objective_subcategory.forEach((sc) => {
            sc.objective_type.forEach((type) => {
              if (type.objective) {
                type.objective.forEach((o) => {
                  if (o.visible === '5. GA' || o.visible === '4. COMING_SOON') {
                    retObjectives.push({
                      id: o.objective_id,
                      headline: o.headline,
                      loadOrder: o.load_order,
                      tagline: o.tagline,
                      metatags: o.metatags,
                      objective: o.objective,
                      description: o.objective_description,
                      subcategoryId: sc.objective_subcategory_id,
                      categoryId: oc.objective_category_id,
                      subcategoryShortDesc: sc.objective_subcategory_short_desc,
                      disabled: o.visible === '4. COMING_SOON',
                      location: type.objective_location,
                      primaryPlatform: type.primary_platform,
                    });

                    if (o.tasks) {
                      o.tasks.forEach((t) => {
                        retTasks.push({
                          id: t.task_id,
                          type: t.task_type,
                          time: t.task_time,
                          category: t.task_category,
                          subcategory: t.task_subcategory,
                          priority: t.task_priority,
                          name: t.task,
                          ...(t.task_descriptions && {
                            descriptions: t.task_descriptions.map((td) => ({
                              label: td.task_description,
                            })),
                          }),
                          objectiveId: o.objective_id,
                          objectiveHeadline: o.headline,
                        });

                        t.platform_actions.forEach((pa) => {
                          retPlatformActions.push({
                            ...pa,
                            categoryId: oc.objective_category_id,
                            subcategoryId: sc.objective_subcategory_id,
                            objectiveId: o.objective_id,
                            taskId: t.task_id,
                          });
                        });
                      });
                    }
                  }
                });
              }
            });
          });
        });

        draft.objectiveCategories = retObjectiveCategories;
        draft.objectives = retObjectives;
        draft.tasks = retTasks;
        draft.platformActions = retPlatformActions;

        break;
      case CREATE_BRAND_SUMMARY_AND_SOCIALS_REQUEST:
        draft.createBrandSummaryPending = true;
        break;
      case CREATE_BRAND_SUMMARY_AND_SOCIALS_SUCCESS:
        draft.createBrandSummaryPending = false;
        draft.brandSummary = action.payload.data.brandSummary;
        draft.socialMediaUrls = action.payload.data.socialMediaUrls;
        draft.brandName = action.payload.userPayload.brandName;
        draft.industry = action.payload.userPayload.industry;
        draft.address = action.payload.userPayload.address;
        draft.url = action.payload.userPayload.url;
        break;
      case CHANGE_SOCIAL_MEDIA_URLS:
        const { key, value } = action.payload;
        const socialMediaUrlsCopy = draft.socialMediaUrls;
        socialMediaUrlsCopy[key] = value;
        draft.socialMediaUrls = socialMediaUrlsCopy;
        break;
      case CREATE_BRAND_SUMMARY_AND_SOCIALS_ERROR:
        draft.createBrandSummaryPending = false;
        break;
      case CREATE_CAMPAIGN_SUGGESTIONS_REQUEST:
        draft.createCampaignSuggestionsPending = true;
        break;
      case CREATE_CAMPAIGN_SUGGESTIONS_SUCCESS:
        draft.createCampaignSuggestionsPending = false;
        draft.campaignSuggestions = [...action.payload.campaigns];
        draft.selectedTasks = {};

        action.payload.campaigns.forEach((campaign) => {
          draft.selectedTasks[campaign.campaignTitle] = [];

          campaign.objectives.forEach((objective) => {
            objective.tasks.forEach((campaignTask) => {
              const matchedTask = draft.tasks.find(
                (task) => task.id === campaignTask.task_id
              );
              if (matchedTask) {
                const {
                  descriptions,
                  ...taskWithoutDescriptions
                } = matchedTask;

                taskWithoutDescriptions.description =
                  campaignTask.task_description;

                draft.selectedTasks[campaign.campaignTitle].push(
                  taskWithoutDescriptions
                );
              }
            });
          });
        });
        break;

      case CREATE_CAMPAIGN_SUGGESTIONS_ERROR:
        draft.createCampaignSuggestionsPending = false;
        break;
      case CREATE_BULK_CAMPAIGNS_REQUEST:
        draft.bulkCreateCampaignsPending = true;
        break;
      case CREATE_BULK_CAMPAIGNS_SUCCESS:
        draft.bulkCreateCampaignsPending = false;
        draft.campaignSuggestions = [];
        draft.brandSummary = '';
        draft.socialMediaUrls = {};
        draft.brandName = '';
        draft.industry = '';
        draft.address = '';
        draft.url = '';
        break;
      case CREATE_BULK_CAMPAIGNS_ERROR:
        draft.bulkCreateCampaignsPending = false;
        break;
    }
  });

export default bulkCampaignReducer;
