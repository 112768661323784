import React from 'react';
import { Modal } from '@material-ui/core';
import styles from './WebsiteFormsPage.module.scss';

const FormPreviewModal = ({ modalOpen, onClose, data }) => {
  if (!data) return null;

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalInner}>
          <div className={styles.modalTop}>
            <h2 className={styles.title}>Website Form</h2>
          </div>
          <div className={styles.modalContent}>
            <p className={styles.label}>Brand Name</p>
            <p className={styles.value}>{data.brandName}</p>

            <p className={styles.label}>Full Name</p>
            <p className={styles.value}>{data.fullName}</p>

            <p className={styles.label}>Work Email</p>
            <p className={styles.value}>{data.workEmail}</p>

            <p className={styles.label}>Phone Number</p>
            <p className={styles.value}>{data.phoneNumber}</p>

            <p className={styles.label}>Website</p>
            <p className={styles.value}>{data.webSite}</p>

            <p className={styles.label}>Location Type</p>
            <p className={styles.value}>{data.locationType}</p>

            <p className={styles.label}>Brand Industry</p>
            <p className={styles.value}>{data.brandIndustry}</p>

            <p className={styles.label}>Number of location</p>
            <p className={styles.value}>{data.numOfLocations}</p>

            <p className={styles.label}>Number of new consumers</p>
            <p className={styles.value}>{data.numOfNewConsumers}</p>

            <p className={styles.label}>Total monthly budget</p>
            <p className={styles.value}>{data.totalMonthlyBudget}</p>

            <p className={styles.label}>Budget Per New Consumers</p>
            <p className={styles.value}>{data.budgetPerNewConsumers}</p>

            <p className={styles.label}>Ideal Customers</p>
            <p className={styles.value}>{data.idealCustomers.join(', ')}</p>
          </div>
          <button className={styles.closeButton} onClick={onClose}>
            <p className={styles.closeButtonText}>Close</p>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FormPreviewModal;
