import { call, put, takeLatest } from '@redux-saga/core/effects';
import { ENDPOINTS } from '../../constants';
import request from 'utils/request';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import toasterVariants from 'constants/toasterVariants';
import { TRANSFER_REQUEST } from './constants';

export function* transfer({ payload }) {
  try {
    yield call(request, {
      url: ENDPOINTS.TRANSFER_MONEY,
      data: payload,
      method: 'post',
    });
    yield put(
      enqueueSnackbar({
        message: 'Transfer successful',
        variant: toasterVariants.success,
      })
    );
  } catch (err) {
    yield put(
      enqueueSnackbar({
        message: err.data.message,
      })
    );
  }
}

export default function* transferSaga() {
  yield takeLatest(TRANSFER_REQUEST, transfer);
}
