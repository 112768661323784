import React from 'react';
import styles from './Table.module.scss';
import classNames from 'classnames';

const TableColumnField = ({ className, data, onClick = () => {} }) => {
  return (
    <td className={classNames(styles.td, className)} onClick={onClick}>
      {data}
    </td>
  );
};

export default TableColumnField;
