export const UPLOAD_TEMPLATE_REQUEST = 'UPLOAD_TEMPLATE_REQUEST';
export const UPLOAD_TEMPLATE_SUCCESS = 'UPLOAD_TEMPLATE_SUCCESS';

export const DELETE_TEMPLATE_REQUEST = 'DELTE_TEMPLATE_REQUEST';
export const DELETE_TEMPLATE_SUCCESS = 'DELTE_TEMPLATE_SUCCESS';

export const FETCH_TEMPLATES_REQUEST = 'FETCH_TEMPLATES_REQUEST';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';

export const FETCH_RETAILERS_REQUEST = 'FECH_RETAILERS_REQUEST';
export const FETCH_RETAILERS_SUCCESS = 'FECH_RETAILERS_SUCCESS';

export const FETCH_CAMPAIGN_OBJECTIVES_REQUEST =
  'FETCH_CAMPAIGN_OBJECTIVES_REQUEST';
export const FETCH_CAMPAIGN_OBJECTIVES_SUCCESS =
  'FETCH_CAMPAIGN_OBJECTIVES_SUCCESS';
