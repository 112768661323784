import { defineMessages } from 'react-intl';

export const scope = 'auditLogs';

export default defineMessages({
  auditLogs: {
    id: `${scope}.auditLogs`,
    defaultMessage: 'Audit Logs',
  },
  noAuditLogs: {
    id: `${scope}.noAuditLogs`,
    defaultMessage: 'No Audit Logs',
  },
  auditLog: {
    id: `${scope}.auditLog`,
    defaultMessage: 'Audit Log',
  },
  entityId: {
    id: `${scope}.entityId`,
    defaultMessage: 'Entity ID',
  },
  entityName: {
    id: `${scope}.entityName`,
    defaultMessage: 'Entity Name',
  },
  tableName: {
    id: `${scope}.tableName`,
    defaultMessage: 'Table Name',
  },
  propertyName: {
    id: `${scope}.propertyName`,
    defaultMessage: 'Property Name',
  },
  propertyValue: {
    id: `${scope}.propertyValue`,
    defaultMessage: 'Property Value',
  },
  createdAt: {
    id: `${scope}.createdAt`,
    defaultMessage: 'Creation date',
  },
});
