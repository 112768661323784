import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectAi = (state) => state.ai || initialState;

const makeSelectCreatePromptPending = () =>
  createSelector(selectAi, (substate) => substate.createPromptPending);

const makeSelectFetchPromptsPending = () =>
  createSelector(selectAi, (substate) => substate.fetchPromptsPending);

const makeSelectFetchPrompts = () =>
  createSelector(selectAi, (substate) => substate.prompts);

export {
  makeSelectCreatePromptPending,
  makeSelectFetchPromptsPending,
  makeSelectFetchPrompts,
};
