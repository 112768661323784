import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';

import DashboardLayout from 'components/Layout/DashboardLayout';
import saga from './saga';
import reducer from './reducer';
import styles from './CouponsPage.module.scss';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import Button from 'components/Buttons/Button';
import DateField from 'components/Forms/DateField';
import { addDays, formatDate, now } from 'utils/date';
import { createCouponSchema, extendCuponDate } from './validations';
import { DATE_FORMAT } from '../../constants';
import { addMinutes, endOfDay, format, startOfDay } from 'date-fns';
import { InputField } from 'components/Forms';
import {
  createCouponRequest,
  deleteCouponRequest,
  editCouponRequest,
  fetchCouponsRequest,
} from './actions';
import {
  makeSelectCoupons,
  makeSelectEditingCoupon,
  makeSelectGeneratingCoupon,
} from './selectors';
import ResourceTable from 'components/ResourceTable';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import Icon from 'components/Icon';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import toasterVariants from 'constants/toasterVariants';
import { ReactComponent as recycleBinIcon } from 'assets/images/delete.svg';
import { ReactComponent as copyIcon } from 'assets/images/copy.svg';
import { useConfirmationModal } from 'components/ConfirmationModal';
import { canActivate } from '../../utils/permissions';
import EditIcon from 'assets/images/pen.png';
import {
  CREATE_COUPONS,
  DELETE_COUPONS,
  GET_COUPONS,
  LIST_COUPONS,
} from '../../permisssions';
import { Modal } from '@material-ui/core';
import RadioGroup from 'components/Forms/RadioGroup';
const key = 'coupons';

const TABS = {
  GENERATOR: 'generator',
  COUPONS: 'coupons',
};

export const COUPON_TYPE = {
  CUSTOM: 'custom',
  VIP: 'vip',
};

export const TYPES_OPTIONS = [
  {
    id: COUPON_TYPE.CUSTOM,
    label: 'Custom',
  },
  {
    id: COUPON_TYPE.VIP,
    label: 'VIP',
  },
];

const CouponsPage = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(TABS.GENERATOR);
  const generatingCoupon = useSelector(makeSelectGeneratingCoupon());
  const editingCoupon = useSelector(makeSelectEditingCoupon());

  const coupons = useSelector(makeSelectCoupons());
  const { data, perPage, total } = coupons || [];

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedCouponId, setSelectedCouponId] = useState(null);

  const [openConfirmationModal] = useConfirmationModal();

  const handleDeleteCoupon = (id) => {
    openConfirmationModal('Are you sure you want to delete this coupon?', () =>
      dispatch(deleteCouponRequest(id))
    );
  };

  const handleSubmit = (values) => {
    dispatch(
      createCouponRequest(
        {
          validUntil: new Date(values.validUntil).toISOString(),
          text: values.text,
          type: values.type,
          ...(values.type === COUPON_TYPE.CUSTOM && { value: +values.value }),
          secret: values.secret,
        },
        () => setSelectedTab(TABS.COUPONS)
      )
    );
  };

  const handleExtendCouponDate = (values) => {
    if (selectedCouponId) {
      dispatch(
        editCouponRequest(
          selectedCouponId,
          {
            validUntil: new Date(values.validUntil).toISOString(),
            secret: values.secret,
          },
          () => {
            setModalVisible(false);
          }
        )
      );
    }
  };

  useEffect(() => {
    dispatch(fetchCouponsRequest(1, 25));
  }, [dispatch]);

  const handlePerPage = (perPage) => {
    dispatch(fetchCouponsRequest(1, perPage));
  };

  const fetchPageCoupons = (pageNumber) => {
    dispatch(fetchCouponsRequest(pageNumber, perPage));
  };

  const tableHeader = [
    {
      key: 'value',
      label: 'Discount amount',
    },
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'validUntil',
      label: 'Valid until',
    },
    {
      key: 'coupon',
      label: 'Coupon',
    },
    {
      key: 'actions',
      label: 'Actions',
    },
  ];

  return (
    <DashboardLayout>
      <div className={styles.header}>
        <h3>Coupon Generator</h3>
        <div className={styles.switchTabsContainer}>
          <p
            className={classNames(
              styles.switchTabsText,
              selectedTab === TABS.GENERATOR && styles.switchTabsTextSelected
            )}
            onClick={() => setSelectedTab(TABS.GENERATOR)}
          >
            Generator
          </p>
          <div className={styles.divider} />
          <p
            className={classNames(
              styles.switchTabsText,
              selectedTab === TABS.COUPONS && styles.switchTabsTextSelected
            )}
            onClick={() => setSelectedTab(TABS.COUPONS)}
          >
            Coupons
          </p>
        </div>
      </div>
      <div className={styles.content}>
        {selectedTab === TABS.GENERATOR ? (
          <div className={styles.generatorContainer}>
            <Formik
              initialValues={{
                validUntil: addDays(now(), 2),
                value: '',
                text: '',
                secret: '',
                type: COUPON_TYPE.CUSTOM,
              }}
              validationSchema={createCouponSchema}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ isValid, dirty, values }) => {
                return canActivate(CREATE_COUPONS) ? (
                  <Form className={styles.form}>
                    <DateField
                      name="validUntil"
                      label={'Valid until'}
                      selected={values.validUntil}
                      minDate={addDays(now(), 0)}
                    />
                    <DateField
                      name="validUntil"
                      label={'Valid until Time'}
                      labelHidden
                      selected={values.validUntil}
                      showTimeSelect
                      showTimeSelectOnly
                      minTime={
                        format(values.validUntil, 'MM/dd/yyyy') <=
                        format(now(), 'MM/dd/yyyy')
                          ? addMinutes(now(), 1)
                          : startOfDay(values.validUntil)
                      }
                      maxTime={endOfDay(now())}
                      timeCaption="Time"
                      dateFormat={DATE_FORMAT.HOURS}
                    />
                    <RadioGroup
                      name="type"
                      label="Type"
                      options={TYPES_OPTIONS}
                      checkedValue={values.type}
                      type="radio"
                      required
                    />
                    <InputField
                      type="number"
                      name="value"
                      label="Discount Amount"
                      placeholder="Enter Discount Amount"
                      disabled={values.type === COUPON_TYPE.VIP}
                      required
                    />
                    <InputField
                      type="text"
                      name="text"
                      label="Code Text"
                      placeholder="Enter Code Text"
                      required
                      replaceFunction
                    />
                    <InputField
                      type="text"
                      name="secret"
                      label="Secret"
                      placeholder="Enter Secret"
                      required
                    />
                    {values.value.trim() && values.text.trim() && (
                      <p>Coupon Code: {values.value + values.text}</p>
                    )}
                    <Button
                      alt
                      disabled={!isValid || !dirty || generatingCoupon}
                      type="submit"
                      isLarge
                      className={styles.button}
                    >
                      Submit
                    </Button>
                  </Form>
                ) : null;
              }}
            </Formik>
          </div>
        ) : (
          <ResourceTable
            rowsNumber={data ? data.length : 0}
            hasMore={data?.length < total}
            fetchPage={fetchPageCoupons}
            resource={coupons}
            headerInformation={tableHeader}
            noDataContent={'No coupons'}
            perPage={localStorage.getItem('perPage')}
            handlePerPage={handlePerPage}
            canAccess={canActivate(LIST_COUPONS)}
          >
            {data.map((coupon) => {
              return (
                <Fragment key={coupon.id}>
                  <TableColumnField
                    data={
                      +coupon.value === 0 ? <p>-</p> : <p>${coupon.value}</p>
                    }
                  />
                  <TableColumnField data={<p>{coupon.type}</p>} />
                  <TableColumnField
                    data={formatDate(
                      coupon.validUntil,
                      DATE_FORMAT.MONTH_DAY_YEAR_TIME
                    )}
                  />
                  <TableColumnField
                    data={
                      coupon.type === 'vip'
                        ? `${coupon.text}`
                        : `${coupon.value}${coupon.text}`
                    }
                  />
                  <TableColumnField
                    data={
                      <div className={styles.actionsWrap}>
                        {canActivate(DELETE_COUPONS) ? (
                          <div
                            className={styles.actionButton}
                            onClick={() => {
                              handleDeleteCoupon(coupon.id);
                            }}
                          >
                            <Icon icon={recycleBinIcon} size={'sm20'} />
                          </div>
                        ) : null}
                        {canActivate(GET_COUPONS) ? (
                          <div
                            className={styles.actionButton}
                            onClick={() => {
                              dispatch(
                                enqueueSnackbar({
                                  message: 'Copied',
                                  variant: toasterVariants.success,
                                })
                              );
                              navigator.clipboard.writeText(
                                coupon.value + coupon.text
                              );
                            }}
                          >
                            <Icon icon={copyIcon} size={'sm20'} />
                          </div>
                        ) : null}

                        <img
                          onClick={() => {
                            setSelectedCouponId(coupon.id);
                            setModalVisible(true);
                          }}
                          className={styles.editIcon}
                          src={EditIcon}
                          alt=""
                        />
                      </div>
                    }
                  />
                </Fragment>
              );
            })}
          </ResourceTable>
        )}
      </div>
      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <div className={styles.modalWrapper}>
          <div className={styles.modalInner}>
            <div className={styles.modalTop}>
              <p className={styles.title}>{'Extend Coupon'}</p>
            </div>
            <div className={styles.modalContent}>
              <div className={styles.formWrapper}>
                <div className={styles.formWrap}>
                  <div className={styles.inputWrapperSm}>
                    <Formik
                      initialValues={{
                        validUntil: addDays(now(), 2),
                        secret: '',
                      }}
                      validationSchema={extendCuponDate}
                      onSubmit={handleExtendCouponDate}
                      enableReinitialize
                    >
                      {({ isValid, dirty, values }) => {
                        return (
                          <Form className={styles.form}>
                            <DateField
                              name="validUntil"
                              label={'Valid until'}
                              selected={values.validUntil}
                              minDate={addDays(now(), 0)}
                            />
                            <DateField
                              name="validUntil"
                              label={'Valid until Time'}
                              labelHidden
                              selected={values.validUntil}
                              showTimeSelect
                              showTimeSelectOnly
                              minTime={
                                format(values.validUntil, 'MM/dd/yyyy') <=
                                format(now(), 'MM/dd/yyyy')
                                  ? addMinutes(now(), 1)
                                  : startOfDay(values.validUntil)
                              }
                              maxTime={endOfDay(now())}
                              timeCaption="Time"
                              dateFormat={DATE_FORMAT.HOURS}
                            />

                            <InputField
                              type="text"
                              name="secret"
                              label="Secret"
                              placeholder="Enter Secret"
                              required
                            />

                            <Button
                              alt
                              disabled={!isValid || !dirty || editingCoupon}
                              type="submit"
                              isLarge
                              className={styles.button}
                            >
                              Submit
                            </Button>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </DashboardLayout>
  );
};

export default CouponsPage;
