import { defineMessages } from 'react-intl';

export const scope = 'single_campaign.campaign.grades';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Campaign Grades',
  },
  gradeRanges: {
    id: `${scope}.grade_ranges`,
    defaultMessage: 'Grade Ranges',
  },
});
