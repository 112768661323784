import messages from 'messages';

const CAMPAIGN_MIN_START_DATE = 'campaignStartDateMinDaysValue';
const CAMPAIGN_MAX_START_DATE = 'campaignStartDateMaxDaysValue';
const CAMPAIGN_MIN_DURATION = 'campaignDurationMinHoursValue';
const CAMPAIGN_MAX_DURATION = 'campaignDurationMaxHoursValue';
const CAMPAIGN_MIN_NUM_OF_EYVOCATES = 'campaignMaxEyvocatesMinValue';
const CAMPAIGN_MAX_NUM_OF_EYVOCATES = 'campaignMaxEyvocatesMaxValue';
const CAMPAIGN_DEF_NUM_OF_EYVOCATES = 'campaignMaxEyvocatesDefaultValue';
const CAMPAIGN_MIN_NUM_OF_APPLIED_EYVOCATES =
  'campaignMinNumOfAppliedEyvocates';
const CAMPAIGN_MIN_PRICE_PER_HOUR = 'campaignPricePerHourMinValue';
const CAMPAIGN_DEFAULT_PRICE_PER_HOUR = 'campaignPricePerHourDefaultValue';
const CAMPAIGN_FOLLOW_UP_TASKS_DURATION =
  'campaignFollowUpTasksDurationDefaultValue';
const CUSTOMERS_FREE_SLOTS = 'customersFreeSlots';
const CUSTOMERS_FREE_SLOTS_UPDATED_AT = 'customersFreeSlotsUpdatedAt';
const CUSTOM_NOTIFICATION_TIME = 'customNotificationTime';
const USERS_IDLE_TIME = 'usersIdleTime';
const CAMPAIGN_EXCLUSIVE_PERIOD = 'campaignExclusivePeriod';
const CAMPAIGN_EXCLUSIVE_POINTS_REQUIRED = 'campaignExclusivePointsRequired';
const CAMPAIGN_WINDOW_MIN_DURATION = 'campaignWindowDurationMinDaysValue';
const CAMPAIGN_WINDOW_MAX_DURATION = 'campaignWindowDurationMaxDaysValue';
const MIN_CAMPAINGS_FOR_CUSTOM_EXPERIENCE = 'minCampaignsForCustomExperience';

export const BUSINESS_RULES_LABELS = {
  [CAMPAIGN_MIN_START_DATE]: messages.businessRules.campaignMinStartDate,
  [CAMPAIGN_MAX_START_DATE]: messages.businessRules.campaignMaxStartDate,
  [CAMPAIGN_MIN_DURATION]: messages.businessRules.campaignMinDuration,
  [CAMPAIGN_MAX_DURATION]: messages.businessRules.campaignMaxDuration,
  [CAMPAIGN_MIN_NUM_OF_EYVOCATES]:
    messages.businessRules.campaignMinNumOfEyvocatesSelector,
  [CAMPAIGN_MAX_NUM_OF_EYVOCATES]:
    messages.businessRules.campaignMaxNumOfEyvocatesSelector,
  [CAMPAIGN_DEF_NUM_OF_EYVOCATES]:
    messages.businessRules.campaignDefNumOfEyvocatesSelector,
  [CAMPAIGN_MIN_NUM_OF_APPLIED_EYVOCATES]:
    messages.businessRules.campaignMinNumOfAppliedEyvocates,
  [CAMPAIGN_MIN_PRICE_PER_HOUR]: messages.businessRules.campaignMinPricePerHour,
  [CAMPAIGN_DEFAULT_PRICE_PER_HOUR]:
    messages.businessRules.campaignDefaultPricePerHour,
  [CAMPAIGN_FOLLOW_UP_TASKS_DURATION]:
    messages.businessRules.campaignFollowUpTasksDurationDefaultValue,
  [CUSTOMERS_FREE_SLOTS]: messages.businessRules.customersFreeSlots,
  [CUSTOMERS_FREE_SLOTS_UPDATED_AT]:
    messages.businessRules.customersFreeSlotsUpdatedAt,
  [CUSTOM_NOTIFICATION_TIME]: messages.businessRules.customNotificationTime,
  [USERS_IDLE_TIME]: messages.businessRules.usersIdleTime,
  [CAMPAIGN_EXCLUSIVE_PERIOD]: messages.businessRules.campaignExclusivePeriod,
  [CAMPAIGN_EXCLUSIVE_POINTS_REQUIRED]:
    messages.businessRules.campaignExclusivePointsRequired,
  [CAMPAIGN_WINDOW_MIN_DURATION]:
    messages.businessRules.campaignWindowMinDuration,
  [CAMPAIGN_WINDOW_MAX_DURATION]:
    messages.businessRules.campaignWindowMaxDuration,
  [MIN_CAMPAINGS_FOR_CUSTOM_EXPERIENCE]:
    messages.businessRules.minCampaignsForCustomExperience,
};
