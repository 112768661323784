import { defineMessages } from 'react-intl';

export const scope = 'single_campaign_page';

export default defineMessages({
  buttonText: {
    id: `${scope}.button.text`,
    defaultMessage: 'Edit',
  },
  eyvocates: {
    id: `${scope}.eyvocates`,
    defaultMessage: 'EyVocates',
  },
  eyvisors: {
    id: `${scope}.eyisors`,
    defaultMessage: 'EyVisors',
  },
  noEyvocates: {
    id: `${scope}.no_eyvocates`,
    defaultMessage: 'No EyVocates yet',
  },
  noEyvisors: {
    id: `${scope}.no_eyvisors`,
    defaultMessage: 'No EyVisors yet',
  },
  campaignUpdated: {
    id: `${scope}.campaign_updated`,
    defaultMessage: 'Campaign is updated successfully',
  },
  campaignApproved: {
    id: `${scope}.review.campaign_approved`,
    defaultMessage: 'Campaign successfully approved',
  },
  campaigChangesRequested: {
    id: `${scope}.review.campaign_changes_requested`,
    defaultMessage: 'Campaign changes successfully requested',
  },
  campaignCanceled: {
    id: `${scope}.review.campaign_canceled`,
    defaultMessage: 'Campaign successfully canceled',
  },
  statusInternalChanged: {
    id: `${scope}.status_internal_changed`,
    defaultMessage: 'Status internal successfully changed',
  },
  campaignEndDateUpdated: {
    id: `${scope}.campaign_end_date_updated`,
    defaultMessage: 'Campaign end date is updated successfully',
  },
});
