import { ORDER } from '../../constants';
import {
  FETCH_CAMPAIGNS_REQUEST,
  FETCH_CAMPAIGNS_SUCCESS,
  UPLOAD_CAMPAIGNS_REQUEST,
  UPLOAD_CAMPAIGNS_SUCCESS,
} from './constants';

export function fetchCampaignsRequest(
  page,
  perPage,
  search = '',
  sortBy = 'startDate',
  order = ORDER.DESC
) {
  return {
    type: FETCH_CAMPAIGNS_REQUEST,
    page,
    perPage,
    search,
    sortBy,
    order,
  };
}

export function fetchCampaignsSuccess(campaigns, search, sortBy, order) {
  return {
    type: FETCH_CAMPAIGNS_SUCCESS,
    campaigns,
    search,
    sortBy,
    order,
  };
}

export function uploadBulkCreateCampaigns(payload) {
  return {
    type: UPLOAD_CAMPAIGNS_REQUEST,
    payload: payload.jsonObj,
  };
}

export function uploadBulkCreateCampaignsSuccess(payload) {
  return {
    type: UPLOAD_CAMPAIGNS_SUCCESS,
    payload,
  };
}
