export const SINGLE_CAMPAIGN_PAGE = '[Single Campaign Page]';

export const FETCH_SINGLE_CAMPAIGN = `${SINGLE_CAMPAIGN_PAGE} FETCH_SINGLE_CAMPAIGN_USER`;
export const FETCH_SINGLE_CAMPAIGN_SUCESS = `${SINGLE_CAMPAIGN_PAGE} FETCH_SINGLE_CAMPAIGN_USER_SUCESS`;

export const UPDATE_SINGLE_CAMPAIGN_REQUEST = `${SINGLE_CAMPAIGN_PAGE} UPDATE_SINGLE_CAMPAIGN_REQUEST`;
export const UPDATE_SINGLE_CAMPAIGN_SUCCESS = `${SINGLE_CAMPAIGN_PAGE} UPDATE_SINGLE_CAMPAIGN_SUCCESS`;
export const UPDATE_SINGLE_CAMPAIGN_ERROR = `${SINGLE_CAMPAIGN_PAGE} UPDATE_SINGLE_CAMPAIGN_ERROR`;

export const UPDATE_SINGLE_CAMPAIGN_END_DATE_REQUEST = `${SINGLE_CAMPAIGN_PAGE} UPDATE_SINGLE_CAMPAIGN_END_DATE_REQUEST`;
export const UPDATE_SINGLE_CAMPAIGN_END_DATE_SUCCESS = `${SINGLE_CAMPAIGN_PAGE} UPDATE_SINGLE_CAMPAIGN_END_DATE_SUCCESS`;
export const UPDATE_SINGLE_CAMPAIGN_END_DATE_ERROR = `${SINGLE_CAMPAIGN_PAGE} UPDATE_SINGLE_CAMPAIGN_END_DATE_ERROR`;

export const CREATE_CAMPAIGN_SUPERVISOR_TASK_REQUEST = `${SINGLE_CAMPAIGN_PAGE} CREATE_CAMPAIGN_SUPERVISOR_TASK_REQUEST`;
export const CREATE_CAMPAIGN_SUPERVISOR_TASK_SUCCESS = `${SINGLE_CAMPAIGN_PAGE} CREATE_CAMPAIGN_SUPERVISOR_TASK_SUCCESS`;
export const CREATE_CAMPAIGN_SUPERVISOR_TASK_ERROR = `${SINGLE_CAMPAIGN_PAGE} CREATE_CAMPAIGN_SUPERVISOR_TASK_ERROR`;

export const DELETE_CAMPAIGN_SUPERVISOR_TASK_REQUEST = `${SINGLE_CAMPAIGN_PAGE} DELETE_CAMPAIGN_SUPERVISOR_TASK_REQUEST`;
export const DELETE_CAMPAIGN_SUPERVISOR_TASK_SUCCESS = `${SINGLE_CAMPAIGN_PAGE} DELETE_CAMPAIGN_SUPERVISOR_TASK_SUCCESS`;
export const DELETE_CAMPAIGN_SUPERVISOR_TASK_ERROR = `${SINGLE_CAMPAIGN_PAGE} DELETE_CAMPAIGN_SUPERVISOR_TASK_ERROR`;

export const REMOVE_JOINED_USER_FROM_CAMPAIGN = `${SINGLE_CAMPAIGN_PAGE} REMOVE_JOINED_USER_FROM_CAMPAIGN`;
export const SUCCESSFULLY_REMOVED_USER_FROM_CAMPAIGN = `${SINGLE_CAMPAIGN_PAGE} SUCCESSFULLY_REMOVED_USER_FROM_CAMPAIGN`;
export const CHANGE_USER_ROLE_REQUEST = `${SINGLE_CAMPAIGN_PAGE} CHANGE_USER_ROLE_REQUEST`;
export const CHANGE_USER_ROLE_SUCCESS = `${SINGLE_CAMPAIGN_PAGE} CHANGE_USER_ROLE_SUCCESS`;

export const APPROVE_CAMPAIGN_REQUEST = `${SINGLE_CAMPAIGN_PAGE} APPROVE_CAMPAIGN_REQUEST`;
export const REQUEST_CHANGES_REQUEST = `${SINGLE_CAMPAIGN_PAGE} REQUEST_CHANGES_REQUEST`;
export const CANCEL_CAMPAIGN_REQUEST = `${SINGLE_CAMPAIGN_PAGE} CANCEL_CAMPAIGN_REQUEST`;

export const SET_IS_CAMPAIGN_INTERNAL_REQUEST = `${SINGLE_CAMPAIGN_PAGE} SET_IS_CAMPAIGN_INTERNAL_REQUEST`;
export const SET_IS_CAMPAIGN_INTERNAL_SUCCESS = `${SINGLE_CAMPAIGN_PAGE} SET_IS_CAMPAIGN_INTERNAL_SUCCESS`;

export const DELETE_CAMPAIGN_GRADE_RANGE_REQUEST = `${SINGLE_CAMPAIGN_PAGE} DELETE_CAMPAIGN_GRADE_RANGE_REQUEST`;
export const DELETE_CAMPAIGN_GRADE_RANGE_SUCCESS = `${SINGLE_CAMPAIGN_PAGE} DELETE_CAMPAIGN_GRADE_RANGE_SUCCESS`;
export const DELETE_CAMPAIGN_GRADE_RANGE_ERROR = `${SINGLE_CAMPAIGN_PAGE} DELETE_CAMPAIGN_GRADE_RANGE_ERROR`;

export const SET_CAMPAIGN_GRADE_RANGE_REQUEST = `${SINGLE_CAMPAIGN_PAGE} SET_CAMPAIGN_GRADE_RANGE_REQUEST`;
export const SET_CAMPAIGN_GRADE_RANGE_SUCCESS = `${SINGLE_CAMPAIGN_PAGE} SET_CAMPAIGN_GRADE_RANGE_SUCCESS`;
export const SET_CAMPAIGN_GRADE_RANGE_ERROR = `${SINGLE_CAMPAIGN_PAGE} SET_CAMPAIGN_GRADE_RANGE_ERROR`;
