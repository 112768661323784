import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import styles from './TemplatesPage.module.scss';
import classNames from 'classnames';

const UploadTemplateModal = ({
  modalOpen,
  onClose,
  onUpload,
  retailers,
  objectives,
}) => {
  const [file, setFile] = useState(null);
  const fileHandler = (event) => {
    let file = event.target.files;
    setFile(file);
  };
  const alpha = Array.from(Array(26)).map((e, i) => i + 65);
  const alphabet = alpha.map((x) => String.fromCharCode(x));
  const [retailer, setRetailer] = useState(() => retailers && retailers[0]?.id);
  const [objective, setObjective] = useState(
    () => objectives && objectives[0]?.id
  );
  const [order, setOrder] = useState(alphabet[0]);
  const [campaignType, setCampaignType] = useState('digital');

  useEffect(() => {
    if (retailers) {
      setRetailer(retailers[0]?.id);
    }
  }, [retailers]);

  useEffect(() => {
    if (objectives) {
      setObjective(objectives[0]?.id);
    }
  }, [objectives]);

  const buttonDisabled =
    retailer === null ||
    objective === null ||
    order === null ||
    campaignType === null ||
    file === null;
  const handleUpload = () => {
    onUpload({
      file: file,
      retailerId: retailer,
      campaignObjectiveCategoryId: objective,
      campaignType: campaignType,
      order: order,
    });
  };

  const campaignTypes = ['digital', 'hybrid', 'in-person'];
  const priorityOptions = alphabet;

  const onSelect = (value, optionsName) => {
    switch (optionsName) {
      case 'campaign-type':
        setCampaignType(value);
        break;
      case 'retailers':
        setRetailer(value);
        break;
      case 'objectives':
        setObjective(value);
        break;
      case 'order':
        setOrder(value);
        break;
      default:
        return;
    }
  };

  const selectField = (label, placeholder, options, optionsName) => {
    return (
      <div className={styles.selectFieldWrap}>
        <p>{label}</p>
        <select
          className={styles.selectInput}
          onChange={(e) => onSelect(e.target.value, optionsName)}
        >
          <option className={styles.placeholder} disabled defaultValue value="">
            {placeholder}
          </option>
          {options.map((item, index) => (
            <option
              className={styles.option}
              value={item.id ? item.id : item}
              key={index}
            >
              {item.name ? item.name : item}
            </option>
          ))}
        </select>
      </div>
    );
  };

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalInner}>
          <div className={styles.modalTop}>
            <p className={styles.title}>Upload template</p>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.formWrapper}>
              <input
                type="file"
                onChange={fileHandler}
                multiple={false}
                style={{ padding: '10px' }}
              />
              <div className={styles.formWrap}>
                {selectField(
                  'Campaign type',
                  'Chose one',
                  campaignTypes,
                  'campaign-type'
                )}
                {selectField('Retailer', 'Choose one', retailers, 'retailers')}
                {selectField(
                  'Priority',
                  'Choose one',
                  priorityOptions,
                  'order'
                )}
                {selectField(
                  'Campaign objective',
                  'Chose one',
                  objectives,
                  'objectives'
                )}
              </div>
              <button
                className={classNames(styles.publishButton)}
                onClick={handleUpload}
                disabled={buttonDisabled}
              >
                <p className={styles.publishButtonText}>Publish</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UploadTemplateModal;
