import produce from 'immer';
import { FETCH_CAMPAIGNS_SUCCESS } from './constants';

export const initialState = {
  campaigns: {
    data: [],
    perPage: null,
    page: null,
    total: null,
    search: '',
    sortBy: '',
    order: '',
  },
};

/* eslint-disable default-case */
const campaignsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_CAMPAIGNS_SUCCESS:
        draft.campaigns.perPage = action.campaigns.perPage;
        draft.campaigns.page = action.campaigns.page;
        draft.campaigns.total = action.campaigns.total;
        draft.campaigns.search = action.search;
        draft.campaigns.sortBy = action.sortBy;
        draft.campaigns.order = action.order;
        draft.campaigns.data = action.campaigns.data;
        break;
    }
  });

export default campaignsReducer;
