import { defineMessages } from 'react-intl';

export const scope = 'objectives_page';

export default defineMessages({
  areYouSure: {
    id: `${scope}.text.are_you_sure`,
    defaultMessage: 'Are you sure you want to delete this task?',
  },
  objectiveDeleted: {
    id: `${scope}.text.objective_deleted`,
    defaultMessage: 'Objective successfully deleted',
  },
  title: {
    id: `${scope}.text.title`,
    defaultMessage: 'Title',
  },
  info: {
    id: `${scope}.text.info`,
    defaultMessage: 'Info',
  },
  campaignType: {
    id: `${scope}.text.campaign_type`,
    defaultMessage: 'Campaign type',
  },
  createObjective: {
    id: `${scope}.text.create_objective`,
    defaultMessage: 'Create objective',
  },
  supervisorTasks: {
    id: `${scope}.text.supervisor_tasks`,
    defaultMessage: 'Supervisor tasks',
  },
  noObjectives: {
    id: `${scope}.text.no_objectives`,
    defaultMessage: 'No Objectives',
  },
});
