import produce from 'immer';
import { FETCH_AUDIT_LOGS_SUCCESS, LogType } from './constants';

export const initialState = {
  auditLogs: {
    data: [],
    perPage: null,
    page: null,
    total: null,
    search: '',
  },
};

const auditLogsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_AUDIT_LOGS_SUCCESS:
        draft.auditLogs.data = action.auditLogs.data;
        draft.auditLogs.perPage = action.auditLogs.perPage;
        draft.auditLogs.page = action.auditLogs.page;
        draft.auditLogs.total = action.auditLogs.total;
        draft.auditLogs.search = action.search;
        break;
      default:
        break;
    }
  });

export default auditLogsReducer;
