import {
  FETCH_NOTICE_CARDS_REQUEST,
  FETCH_NOTICE_CARDS_SUCCESS,
  TOGGLE_NOTICE_CARD_ACTIVE_STATUS_REQUEST,
  TOGGLE_NOTICE_CARD_ACTIVE_STATUS_SUCCESS,
  UPLOAD_NOTICE_CARD_REQUEST,
  UPLOAD_NOTICE_CARD_SUCCESS,
  FETCH_TOUR_CAMPAIGNS_REQUEST,
  FETCH_TOUR_CAMPAIGNS_SUCCESS,
  DELETE_NOTICE_CARD_REQUEST,
  DELETE_NOTICE_CARD_SUCCESS,
} from './constants';

export function fetchNoticeCardsRequest(page, perPage, search = '') {
  return {
    type: FETCH_NOTICE_CARDS_REQUEST,
    page,
    perPage,
    search,
  };
}

export function fetchNoticeCardsSuccess(cards, search) {
  return {
    type: FETCH_NOTICE_CARDS_SUCCESS,
    cards,
    search,
  };
}

export function toggleNoticeCardActiveStatusRequest(id) {
  return {
    type: TOGGLE_NOTICE_CARD_ACTIVE_STATUS_REQUEST,
    id,
  };
}

export function toggleNoticeCardActiveStatusSuccess(id) {
  return {
    type: TOGGLE_NOTICE_CARD_ACTIVE_STATUS_SUCCESS,
    id,
  };
}

export function deleteNoticeCardRequest(id) {
  return {
    type: DELETE_NOTICE_CARD_REQUEST,
    id,
  };
}

export function deleteNoticeCardSuccess(id) {
  return {
    type: DELETE_NOTICE_CARD_SUCCESS,
    id,
  };
}

export function uploadNoticeCardRequest(data) {
  return {
    type: UPLOAD_NOTICE_CARD_REQUEST,
    data,
  };
}

export function uploadNoticeCardSuccess(data) {
  return {
    type: UPLOAD_NOTICE_CARD_SUCCESS,
    data,
  };
}

export function fetchTourCampaignsRequest() {
  return {
    type: FETCH_TOUR_CAMPAIGNS_REQUEST,
  };
}

export function fetchTourCampaignsSuccess(campaigns) {
  return {
    type: FETCH_TOUR_CAMPAIGNS_SUCCESS,
    campaigns,
  };
}
