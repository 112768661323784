import Yup from 'utils/validations';

export const updateCampaignSchema = Yup.object().shape({
  startDate: Yup.date().required(),
  applicationDeadline: Yup.date().required(),
});

export const updateCampaignSupervisorTasksSchema = Yup.object().shape({
  question: Yup.string(),
});

export const requestChangesCommentSchema = Yup.object().shape({
  comment: Yup.string(),
});
