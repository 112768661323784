import React from 'react';
import messages from './messages';
import { Form, Formik } from 'formik';
import { InputField, SelectField } from 'components/Forms';
import { CAMPAIGN_TYPES_OPTIONS } from '../../constants';
import Button from 'components/Buttons/Button';
import { useIntl } from 'react-intl';
import { singleTaskSchema } from './validations';
import styles from 'components/Layout/DashboardLayout/DashboardLayout.module.scss';

const SingleSupervisorTaskForm = ({ onSubmit }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Formik
        initialValues={{
          question: '',
          campaignType: '',
        }}
        onSubmit={onSubmit}
        validationSchema={singleTaskSchema}
      >
        {({ isValid, dirty, setFieldValue }) => (
          <Form>
            <InputField
              required
              type="text"
              name="question"
              label={formatMessage(messages.taskDescription)}
              placeholder={formatMessage(messages.taskDescription)}
              autoFocus
            />
            <SelectField
              name="campaignType"
              options={CAMPAIGN_TYPES_OPTIONS}
              label={formatMessage(messages.campaignType)}
              placeholder={formatMessage(messages.campaignType)}
              onChange={(event) =>
                setFieldValue('campaignType', event.target.value)
              }
              required
            />
            <Button
              disabled={!isValid || !dirty}
              type="submit"
              title={formatMessage(messages.createTask)}
              alt
              className={styles.submitButton}
            ></Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SingleSupervisorTaskForm;
