import React from 'react';
import { ErrorMessage, useFormikContext, Field } from 'formik';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from '../FormFieldStyles.module.scss';
import Error from '../Error';

const DateField = ({
  name,
  label,
  placeholder,
  selected,
  minDate,
  maxDate,
  minTime,
  maxTime,
  showTimeSelect,
  showTimeSelectOnly,
  timeIntervals,
  onChange,
  dateFormat,
  labelHidden,
  unitValue,
  unit,
  when,
  dateField,
  isDisabled,
  draftErrorMessage,
  readOnly = false,
  isEditableByTyping = false,
}) => {
  const { formatMessage } = useIntl();

  const formik = useFormikContext();
  const field = formik.getFieldProps(name);

  const handleChange = (value) => {
    formik.setFieldValue(name, value);
    onChange && onChange(value, name);
  };

  const handleBlur = () => {
    formik.handleBlur(field.name);
    formik.setFieldTouched(field.name);
  };

  return (
    <div className={styles.field}>
      {!labelHidden && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <Field
        name={field.name}
        component={DatePicker}
        value={field.value}
        placeholderText={placeholder}
        selected={selected}
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        minTime={minTime}
        maxTime={maxTime}
        readOnly={readOnly}
        showTimeSelect={showTimeSelect}
        showTimeSelectOnly={showTimeSelectOnly}
        timeIntervals={timeIntervals}
        dateFormat={dateFormat}
        onBlur={handleBlur}
        className={`${styles.input} ${isDisabled ? styles.disabled : ''}`}
        disabled={isDisabled}
        onFocus={(e) => !isEditableByTyping && e.target.blur()}
      />
      {draftErrorMessage && <Error>{draftErrorMessage}</Error>}
      <Error>
        <ErrorMessage name={field.name}>
          {(msg) => {
            return formatMessage(msg, {
              label,
              value: unitValue,
              unit,
              when,
              dateField,
            });
          }}
        </ErrorMessage>
      </Error>
    </div>
  );
};

export default DateField;

DateField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelHidden: PropTypes.bool,
  placeholder: PropTypes.string,
  selected: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  minTime: PropTypes.instanceOf(Date),
  maxTime: PropTypes.instanceOf(Date),
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  timeIntervals: PropTypes.number,
  dateFormat: PropTypes.string,
  unitValue: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  unit: PropTypes.string,
  dateField: PropTypes.string,
  draftErrorMessage: PropTypes.string,
};
