import React from 'react';
import PropTypes from 'prop-types';
import styles from '../FormFieldStyles.module.scss';
import { ReactComponent as SearchIcon } from 'assets/images/search.svg';
import Icon from 'components/Icon';

const SearchField = ({ onChange }) => {
  return (
    <div className={styles.searchField}>
      <input
        className={styles.searchInput}
        type="search"
        placeholder="Search"
        onChange={(e) => onChange(e.target.value)}
      />
      <div className={styles.searchIconWrapper}>
        <Icon icon={SearchIcon} size={'sm'} />
      </div>
    </div>
  );
};

export default SearchField;

SearchField.propTypes = {
  onChange: PropTypes.func,
};
