import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import messages from './messages';
import globalMessages from './../../messages';
import { useIntl } from 'react-intl';
import DashboardLayout from 'components/Layout/DashboardLayout';
import { makeSelectCampaigns } from './selectors';
import saga from './saga';
import reducer from './reducer';
import { fetchCampaignsRequest, uploadBulkCreateCampaigns } from './actions';
import { DATE_FORMAT } from '../../constants';
import ResourceTable from 'components/ResourceTable';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import { diffHours, formatDate } from '../../utils/date';
import { SINGLE_ADVERTISER, SINGLE_CAMPAIGN } from 'routes';
import styles from 'components/ResourceTable/Table.module.scss';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import eyCrowdIcon from '../../assets/images/logo-short.svg';
import Tooltip from 'components/Tooltip';
import { getApi } from 'utils/request';
import { canActivate } from '../../utils/permissions';
import {
  // CREATE_BULK_CAMPAIGNS,
  DOWNLOAD_CAMPAIGNS_ANALYTICS,
  GET_CAMPAIGNS,
  LIST_CAMPAIGNS,
} from '../../permisssions';
// import UploadJsonModal from './UploadJsonModal';

const key = 'campaigns';

function CampaignsPage() {
  const { formatMessage } = useIntl();
  // const [modalOpen, setModalOpen] = useState(false);

  const CampaignGenderSelection = {
    'all-genders': formatMessage(messages.allGenders),
    'mostly-male': formatMessage(messages.mostlyMale),
    'mostly-female': formatMessage(messages.mostlyFemale),
    male: formatMessage(messages.onlyMale),
    female: formatMessage(messages.onlyFemale),
  };

  const token = useSelector((state) => state.app.token);

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const campaigns = useSelector(makeSelectCampaigns());
  const { data, perPage, total, search, sortBy, order } = campaigns || [];

  useEffect(() => {
    dispatch(
      fetchCampaignsRequest(
        localStorage.getItem('pageNumberCampaigns')
          ? localStorage.getItem('pageNumberCampaigns')
          : 1,
        localStorage.getItem('perPage') ? localStorage.getItem('perPage') : 25
      )
    );
  }, [dispatch]);

  const fetchPageCampaigns = (pageNumber) => {
    localStorage.setItem('pageNumberCampaigns', pageNumber);
    dispatch(fetchCampaignsRequest(pageNumber, perPage, search, sortBy, order));
  };

  const downloadReport = async (campaignId) => {
    const api = getApi();
    const response = await api.get(
      `analytics/get-campaign-report/${campaignId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          responseType: 'blob',
        },
        responseType: 'blob',
      }
    );
    const blob = new Blob([response], { type: 'application/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `analytics-${+new Date()}.csv`;
    link.click();
  };

  const tableHeader = [
    { key: 'status', label: formatMessage(messages.status), sortable: false },
    {
      key: 'campaignName',
      label: formatMessage(messages.campaignName),
      sortable: true,
    },
    {
      key: 'createdFromNewBuilder',
      label: 'Created From New Builder',
      sortable: true,
    },
    { key: 'type', label: formatMessage(messages.type), sortable: true },
    {
      key: 'companyName',
      label: formatMessage(globalMessages.advertisers),
      sortable: true,
    },
    {
      key: 'startDate',
      label: formatMessage(globalMessages.start),
      sortable: true,
    },
    {
      key: 'duration',
      label: formatMessage(messages.duration),
    },
    { key: 'eyvocates', label: formatMessage(messages.eyvocates) },
    {
      key: 'pricePerHour',
      label: formatMessage(globalMessages.price),
      sortable: true,
    },
    { key: 'genders', label: formatMessage(messages.genders) },
    { key: 'city', label: formatMessage(messages.city), sortable: true },
    { key: 'analytics', label: 'Analytics', sortable: false },
  ];

  const handleChange = (search) => {
    dispatch(fetchCampaignsRequest(1, perPage, search, sortBy, order));
  };

  const handleSort = (sortBy, order) => {
    dispatch(fetchCampaignsRequest(1, perPage, search, sortBy, order));
  };

  const handlePerPage = (perPage) => {
    localStorage.setItem('perPage', perPage);
    localStorage.setItem('pageNumberUsers', 1);
    localStorage.setItem('pageNumberCampaigns', 1);
    localStorage.setItem('pageNumberAdvertisers', 1);
    dispatch(fetchCampaignsRequest(1, perPage, search, sortBy, order));
  };

  const eyVocatesColumn = (
    numberOfEyVocates = 0,
    numberOfEyVisors = 0,
    maxEyvocates = 0
  ) => {
    return `${
      numberOfEyVocates + numberOfEyVisors
    }/${maxEyvocates}(${numberOfEyVisors})`;
  };

  // const onUpload = (file) => {
  //   const reader = new FileReader();

  //   reader.onload = (e) => {
  //     try {
  //       const jsonObj = JSON.parse(e.target.result);
  //       dispatch(uploadBulkCreateCampaigns({ jsonObj }));
  //     } catch (error) {
  //       console.error('Error parsing JSON:', error);
  //     }
  //   };

  //   reader.onerror = (error) => {
  //     console.error('Error reading file:', error);
  //   };

  //   reader.readAsText(file);
  // };

  return (
    <div>
      <DashboardLayout>
        {/* <UploadJsonModal
          modalOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          onUpload={onUpload}
          close={() => setModalOpen(false)}
        /> */}
        <DashboardMainHeader
          titleMain={formatMessage(messages.campaigns)}
          totalRows={campaigns.total}
          search
          onChange={handleChange}
          // btnText={'Create Bulk Campaigns'}
          // canAccess={canActivate(CREATE_BULK_CAMPAIGNS)}
          // onClick={() => setModalOpen(true)}
        />
        <ResourceTable
          rowsNumber={data ? data.length : 0}
          hasMore={data?.length < total}
          fetchPage={fetchPageCampaigns}
          resource={campaigns}
          headerInformation={tableHeader}
          noDataContent={formatMessage(messages.noCampaigns)}
          handleSort={handleSort}
          perPage={localStorage.getItem('perPage')}
          handlePerPage={handlePerPage}
          canAccess={canActivate(LIST_CAMPAIGNS)}
        >
          {data.map((campaign, index) => {
            return (
              <Fragment key={campaign.id}>
                <TableColumnField
                  data={!!campaign?.deletedAt ? 'canceled' : campaign.status}
                />
                <TableColumnField
                  data={
                    canActivate(GET_CAMPAIGNS) && (
                      <Link to={SINGLE_CAMPAIGN.replace(':id', campaign.id)}>
                        <div className={styles.campaignName}>
                          <Tooltip label={campaign.name} />
                          {campaign.isInternal ? (
                            <img
                              className={styles.eyCrowdIcon}
                              src={eyCrowdIcon}
                              alt="eycrowd-icon"
                            />
                          ) : null}
                        </div>
                      </Link>
                    )
                  }
                ></TableColumnField>
                <TableColumnField
                  className={styles.typeField}
                  data={campaign.createdFromNewBuilder ? 'YES' : 'NO'}
                />
                <TableColumnField
                  className={styles.typeField}
                  data={campaign.type}
                />
                <TableColumnField
                  data={
                    <Link
                      to={SINGLE_ADVERTISER.replace(
                        ':id',
                        campaign.company.advertiser?.id
                      )}
                    >
                      <Tooltip label={campaign.company.advertiser?.email} />
                    </Link>
                  }
                ></TableColumnField>
                <TableColumnField
                  data={formatDate(
                    campaign.startDate,
                    DATE_FORMAT.MONTH_DAY_YEAR_TIME
                  )}
                />
                <TableColumnField
                  data={
                    diffHours(
                      new Date(campaign.endDate),
                      new Date(campaign.startDate)
                    ) + 'h'
                  }
                />
                <TableColumnField
                  data={eyVocatesColumn(
                    campaign.numberOfEyVocates,
                    campaign.numberOfEyVisors,
                    campaign.maxEyvocates
                  )}
                />

                <TableColumnField data={campaign.pricePerHour} />
                <TableColumnField
                  data={CampaignGenderSelection[campaign.genderSelection]}
                />
                <TableColumnField
                  data={<Tooltip label={campaign.company.city} />}
                />
                {canActivate(DOWNLOAD_CAMPAIGNS_ANALYTICS) && (
                  <TableColumnField
                    data={
                      <p
                        className={styles.simpleButton}
                        onClick={() => {
                          downloadReport(campaign.id);
                        }}
                      >
                        Download
                      </p>
                    }
                  />
                )}
              </Fragment>
            );
          })}
        </ResourceTable>
      </DashboardLayout>
    </div>
  );
}

export default CampaignsPage;
