import { createSelector } from 'reselect';
import { initialState } from './reducer';

const usersSelector = (state) => state.users || initialState;

const makeSelectUsers = () =>
  createSelector(usersSelector, (substate) => substate.users);

const makeSelectRequestSent = () =>
  createSelector(usersSelector, (substate) => substate.requestSent);

export { makeSelectUsers, makeSelectRequestSent };
