import React, { Fragment, useEffect, useState } from 'react';
import UploadTemplateModal from './UploadTemplateModal';
import DashboardLayout from 'components/Layout/DashboardLayout';
import reducer from './reducer';
import saga from './saga';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteTemplateRequest,
  fetchCampaignObjectivesRequest,
  fetchRetailersRequest,
  fetchTemplatesRequest,
  uploadTemplateRequest,
} from './actions';
import {
  makeSelectTemplates,
  makeSelectObjectives,
  makeSeletRetailers,
} from './selectors';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import ResourceTable from 'components/ResourceTable';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import styles from './TemplatesPage.module.scss';
import Icon from 'components/Icon';
import { ReactComponent as recycleBinIcon } from 'assets/images/delete.svg';
import { canActivate } from '../../utils/permissions';
import {
  CREATE_TEMPLATES,
  DELETE_TEMPLATES,
  LIST_TEMPLATES,
} from '../../permisssions';

const key = 'templates';

const TemplatesPage = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const onUpload = ({
    file,
    retailerId,
    campaignObjectiveCategoryId,
    campaignType,
    order,
  }) => {
    dispatch(
      uploadTemplateRequest({
        file,
        retailerId,
        campaignObjectiveCategoryId,
        campaignType,
        order,
      })
    );
  };

  const templates = useSelector(makeSelectTemplates());
  const { data, perPage, total, search, sortBy, order } = templates || [];

  const retailers = useSelector(makeSeletRetailers());

  const objectives = useSelector(makeSelectObjectives());

  useEffect(() => {
    dispatch(
      fetchTemplatesRequest(
        localStorage.getItem('pageNumberCampaigns')
          ? localStorage.getItem('pageNumberCampaigns')
          : 1,
        localStorage.getItem('perPage') ? localStorage.getItem('perPage') : 25
      )
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchCampaignObjectivesRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchRetailersRequest());
  }, [dispatch]);

  const fetchPageTemplates = (pageNumber) => {
    localStorage.setItem('pageNumberCampaigns', pageNumber);
    dispatch(fetchTemplatesRequest(pageNumber, perPage, search, sortBy, order));
  };

  const handleChange = (search) => {
    dispatch(fetchTemplatesRequest(1, perPage, search, sortBy, order));
  };

  const handleSort = (sortBy, order) => {
    dispatch(fetchTemplatesRequest(1, perPage, search, sortBy, order));
  };

  const handlePerPage = (perPage) => {
    localStorage.setItem('perPage', perPage);
    localStorage.setItem('pageNumberUsers', 1);
    localStorage.setItem('pageNumberCampaigns', 1);
    localStorage.setItem('pageNumberAdvertisers', 1);
    dispatch(fetchTemplatesRequest(1, perPage, search, sortBy, order));
  };

  const tableHeader = [
    {
      key: 'retailer',
      label: 'Retailer',
      sortable: true,
    },
    {
      key: 'objective',
      label: 'Objective',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'Actions',
    },
  ];

  return (
    <DashboardLayout>
      <UploadTemplateModal
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onUpload={onUpload}
        retailers={retailers}
        objectives={objectives}
      />
      <DashboardMainHeader
        titleMain={'Templates'}
        totalRows={templates.total}
        btnText={'Upload template'}
        onClick={() => setModalOpen(true)}
        search
        onChange={handleChange}
        canAccess={canActivate(CREATE_TEMPLATES)}
      />
      <ResourceTable
        rowsNumber={data ? data.length : 0}
        hasMore={data?.length < total}
        fetchPage={fetchPageTemplates}
        resource={templates}
        headerInformation={tableHeader}
        noDataContent={'No templates'}
        handleSort={handleSort}
        perPage={localStorage.getItem('perPage')}
        handlePerPage={handlePerPage}
        canAccess={canActivate(LIST_TEMPLATES)}
      >
        {data.map((template) => {
          return (
            <Fragment key={template.id}>
              <TableColumnField
                data={template.retailer.name}
              ></TableColumnField>
              <TableColumnField
                data={template.campaignObjectiveCategory.name}
              />
              <TableColumnField
                data={
                  canActivate(DELETE_TEMPLATES) && (
                    <button
                      className={styles.actionButton}
                      onClick={() => {
                        dispatch(deleteTemplateRequest(template));
                      }}
                    >
                      <Icon icon={recycleBinIcon} size={'sm20'} />
                    </button>
                  )
                }
              />
            </Fragment>
          );
        })}
      </ResourceTable>
    </DashboardLayout>
  );
};

export default TemplatesPage;
