export const formatObjectValues = (object) => {
  return Object.keys(object).reduce((acc, propertyKey) => {
    if (!['id', 'createdAt', 'updatedAt'].includes(propertyKey)) {
      acc[propertyKey] =
        propertyKey === 'customersFreeSlotsUpdatedAt'
          ? new Date(object[propertyKey]).toUTCString()
          : propertyKey === 'maintenanceMode'
          ? object[propertyKey]
          : parseFloat(object[propertyKey]);
    }
    return acc;
  }, {});
};
