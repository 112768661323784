import { defineMessages } from 'react-intl';

export const scope = 'single_campaign_page.review';

export default defineMessages({
  approvalTitle: {
    id: `${scope}.approval_title`,
    defaultMessage: 'Campaign Approval',
  },
  approveButtonText: {
    id: `${scope}.approve_campaign_button_text`,
    defaultMessage: 'Approve campaign',
  },
  requestChangesButtonText: {
    id: `${scope}.request_changes_button_text`,
    defaultMessage: 'Request changes',
  },
  requestChangesLabel: {
    id: `${scope}.request_changes_button_text`,
    defaultMessage: 'Comment for changes',
  },
  cancelButtonText: {
    id: `${scope}.cancel_campaign_button_text`,
    defaultMessage: 'Cancel campaign',
  },
  areYouSureApprove: {
    id: `${scope}.are_you_sure_approve`,
    defaultMessage: 'Are you sure you want to approve this campaign?',
  },
  areYouSureReqestChanges: {
    id: `${scope}.are_you_sure_request_changes`,
    defaultMessage:
      'Are you sure you want to request changes for this campaign?',
  },
  areYouSureCancel: {
    id: `${scope}.are_you_sure_cancel`,
    defaultMessage: 'Are you sure you want to cancel this campaign?',
  },
});
