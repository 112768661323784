import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { makeSelectSingleCampaign } from './selectors';
import reducer from './reducer';
import saga from './saga';
import { fetchSingleCampaign } from 'containers/SingleCampaignPage/actions';
import EditCampaignForm from './EditCampaignForm';
import DashboardLayout from 'components/Layout/DashboardLayout';

const key = 'singleCampaignPage';

const SingleCampaignPage = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  let { id } = useParams();

  const dispatch = useDispatch();
  const campaignUser = useSelector(makeSelectSingleCampaign());

  useEffect(() => {
    dispatch(fetchSingleCampaign(id));
    //eslint-disable-next-line
  }, [dispatch]);

  return (
    <div>
      <DashboardLayout>
        <EditCampaignForm singleCampaign={campaignUser} />
      </DashboardLayout>
    </div>
  );
};

export default SingleCampaignPage;
