import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import styles from './SingleCampaign.module.scss';
import CampaignDetails from 'components/Campaign/CampaignDetails';
import SingleCampaignTasks from 'components/Campaign/CampaignTasks';
import ContentBox from 'components/ContentBox';
import CampaignInfo from '../../components/Campaign/CampaignInfo';
import { useIntl } from 'react-intl';
import CampaignUserList from 'components/Campaign/CampaignUserList';
import messages from './messages';
import { CAMPAIGNS } from 'routes';
import { useDispatch } from 'react-redux';
import {
  changeUserRoleRequest,
  removeJoinedUserFromCampaign,
  setIsCampaignInternalRequest,
} from './actions';
import CampaignReviewForm from 'components/Campaign/CampaignReviewForm';
import { CAMPAIGN_STATUS } from '../../constants';
import Gallery from 'components/Gallery/Gallery';
import AddUserModal from './AddUserModal';
import { canActivate } from '../../utils/permissions';
import { UPDATE_CAMPAIGNS } from '../../permisssions';
import CampaignGrades from 'components/Campaign/CampaignGrades';

const EditCampaignForm = ({ singleCampaign }) => {
  const { formatMessage } = useIntl();

  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);

  const dispatch = useDispatch();

  const handleRemoveJoinedUserFromCampaign = (userId) => {
    dispatch(removeJoinedUserFromCampaign(singleCampaign.id, userId));
  };

  const handleChangeUserRole = (userId, isSupervisor) => {
    dispatch(changeUserRoleRequest(singleCampaign.id, userId, isSupervisor));
  };

  const handleSubmitIsCampaignInternal = (isInternal) => {
    dispatch(
      setIsCampaignInternalRequest(singleCampaign.id, {
        internalStatus: isInternal,
      })
    );
  };

  return (
    <>
      <DashboardMainHeader
        isCompleted
        isEditBtn
        linkTo={CAMPAIGNS}
        status={singleCampaign?.status}
        titleMain={singleCampaign?.name}
        title={singleCampaign?.company?.name}
        btnStyle={styles.btn}
      />
      <AddUserModal
        modalOpen={addUserModalOpen}
        type={modalType}
        onClose={() => {
          setAddUserModalOpen(false);
        }}
        campaignId={singleCampaign.id}
      />
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <ContentBox className={styles.contentBox}>
            <div className={styles.firstRowBoxLeft}>
              <CampaignInfo
                handleSubmitIsCampaignInternal={handleSubmitIsCampaignInternal}
                singleCampaign={singleCampaign}
              />
            </div>
          </ContentBox>

          <div className={styles.rightBoxes}>
            <div className={styles.userBoxes}>
              <ContentBox className={styles.contentBox}>
                <div className={styles.firstRowBox}>
                  <CampaignUserList
                    title={formatMessage(messages.eyvocates)}
                    campaignUsers={singleCampaign.campaignUsers?.filter(
                      ({ isSupervisor }) => !isSupervisor
                    )}
                    onRemoveJoinedUserFromCampaign={
                      handleRemoveJoinedUserFromCampaign
                    }
                    noResultMessage={formatMessage(messages.noEyvocates)}
                    showDropdown={
                      singleCampaign.status !== CAMPAIGN_STATUS.FINISHED
                    }
                    onChangeUserRole={handleChangeUserRole}
                    // onAdd={() => {
                    //   setModalType('EyVocate');
                    //   setAddUserModalOpen(true)
                    // }}
                    // addActionText={"Add EyVocate"}
                  />
                </div>
              </ContentBox>
              <ContentBox className={styles.contentBox}>
                <div className={styles.firstRowBox}>
                  <CampaignUserList
                    title={formatMessage(messages.eyvisors)}
                    campaignUsers={singleCampaign.campaignUsers?.filter(
                      ({ isSupervisor }) => isSupervisor
                    )}
                    onRemoveJoinedUserFromCampaign={
                      handleRemoveJoinedUserFromCampaign
                    }
                    showDropdown={
                      singleCampaign.status !== CAMPAIGN_STATUS.FINISHED
                    }
                    onChangeUserRole={handleChangeUserRole}
                    noResultMessage={formatMessage(messages.noEyvisors)}
                    onAdd={() => {
                      setModalType('EyVisor');
                      setAddUserModalOpen(true);
                    }}
                    addActionText={
                      singleCampaign.status === CAMPAIGN_STATUS.PUBLISHED
                        ? 'Add EyVisor'
                        : ''
                    }
                    addActionVisible={
                      singleCampaign.status === CAMPAIGN_STATUS.PUBLISHED
                    }
                  />
                </div>
              </ContentBox>
            </div>
          </div>

          <div className={styles.secondRowLeft}>
            <ContentBox className={styles.campaignDetailsContentBox}>
              <div className={styles.secondRowBoxLeft}>
                <CampaignDetails singleCampaign={singleCampaign} />
              </div>
            </ContentBox>
            <ContentBox className={styles.contentBox}>
              <div className={styles.secondRowBoxLeft}>
                <CampaignGrades singleCampaign={singleCampaign} />
              </div>
            </ContentBox>
          </div>

          <div className={styles.rightBoxes}>
            <SingleCampaignTasks tasks={singleCampaign?.tasks} />
          </div>
        </div>
        <div
          className={
            singleCampaign.status === CAMPAIGN_STATUS.IN_REVIEW
              ? styles.reviewApprovalBox
              : styles.invisible
          }
        >
          {canActivate(UPDATE_CAMPAIGNS) && (
            <CampaignReviewForm campaignId={singleCampaign.id} />
          )}
        </div>
      </div>
      {/* <div className={styles.galleryWrap}>
        <p className={styles.galleryTitle}>Gallery</p>
        <div className={styles.gallery}>
          {
            singleCampaign?.gallery?.map((item, index) => {
              return <div key={index} className={styles.imagePlaceHolder}>
                <img
                  src={item.galleryMedia.thumbnailUrl}
                  alt="img"
                  className={styles.image}
                />
              </div>
            })
          }
        </div>
      </div> */}
      <Gallery galleryTitle={'Gallery'} gallery={singleCampaign?.gallery} />
    </>
  );
};

export default EditCampaignForm;

EditCampaignForm.propTypes = {
  singleCampaign: PropTypes.object,
};
