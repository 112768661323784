import React from 'react';
import messages from './messages';
import { Form, Formik } from 'formik';
import { InputField, SelectField } from 'components/Forms';
import { CAMPAIGN_TYPES_OPTIONS } from '../../constants';
import Button from 'components/Buttons/Button';
import { useIntl } from 'react-intl';
import { singleObjectiveSchema } from './validations';
import styles from 'components/Layout/DashboardLayout/DashboardLayout.module.scss';

const SingleObjectiveForm = ({ onSubmit, categories }) => {
  const { formatMessage } = useIntl();

  return (
    <div>
      <Formik
        initialValues={{
          title: '',
          objectiveCategory: '',
          campaignType: '',
          info: '',
        }}
        onSubmit={onSubmit}
        validationSchema={singleObjectiveSchema}
      >
        {({ isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <InputField
                required
                type="text"
                name="title"
                label={formatMessage(messages.objectiveTitle)}
                placeholder={formatMessage(messages.objectiveTitle)}
                autoFocus
              />
              <SelectField
                name="objectiveCategory"
                options={categories.map((category) => ({
                  id: category.id,
                  label: category.name,
                }))}
                label={formatMessage(messages.objectiveCategory)}
                placeholder={formatMessage(messages.objectiveCategory)}
                onChange={(event) =>
                  setFieldValue('objectiveCategory', event.target.value)
                }
                required
              />
              <SelectField
                name="campaignType"
                options={CAMPAIGN_TYPES_OPTIONS}
                label={formatMessage(messages.campaignType)}
                placeholder={formatMessage(messages.campaignType)}
                onChange={(event) =>
                  setFieldValue('campaignType', event.target.value)
                }
                required
              />
              <InputField
                required
                type="text"
                name="info"
                label={formatMessage(messages.objectiveInfo)}
                placeholder={formatMessage(messages.objectiveInfo)}
                autoFocus
              />
              <Button
                disabled={!isValid || !dirty}
                type="submit"
                title={formatMessage(messages.createObjective)}
                alt
                className={styles.submitButton}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SingleObjectiveForm;
