import Yup from 'utils/validations';

export const updateBusinessRulesSchema = Yup.object().shape({
  campaignStartDateMinDaysValue: Yup.number().integer().min(0).required(),
  campaignStartDateMaxDaysValue: Yup.number().integer().positive().required(),
  campaignDurationMinHoursValue: Yup.number().integer().min(0).required(),
  campaignDurationMaxHoursValue: Yup.number().integer().positive().required(),
  campaignMaxEyvocatesMinValue: Yup.number().integer().positive().required(),
  campaignMaxEyvocatesMaxValue: Yup.number().integer().positive().required(),
  campaignMaxEyvocatesDefaultValue: Yup.number()
    .integer()
    .positive()
    .required(),
  campaignPricePerHourMinValue: Yup.number().positive().required(),
  campaignPricePerHourDefaultValue: Yup.number().positive().required(),
  campaignExclusivePeriod: Yup.number().positive().required(),
  campaignExclusivePointsRequired: Yup.number().positive().required(),
});
