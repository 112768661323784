import { DATE_FORMAT } from '../../constants';
import React from 'react';
import { formatDate } from 'utils/date';
import styles from './SingleStaffActivityPage.module.scss';
import { ReactComponent as CircleChecked } from 'assets/images/circle_checked.svg';
import Icon from 'components/Icon';

const StaffGalleryActivity = ({
  staffGalleryActivity,
  selectedGalleries,
  setSelectedGalleries,
}) => {
  const handleGalleryMediaClick = (gallery) => {
    if (selectedGalleries.includes(gallery)) {
      setSelectedGalleries(
        selectedGalleries.filter(
          (selectedGallery) => selectedGallery.id !== gallery.id
        )
      );
    } else {
      setSelectedGalleries([...selectedGalleries, gallery]);
    }
  };

  const renderGalleryMedia = (gallery) => {
    const isSelected = selectedGalleries.includes(gallery);
    return (
      <div
        key={gallery.id}
        className={
          isSelected
            ? styles.selectedGalleryMediaContainer
            : styles.galleryMediaContainer
        }
        onClick={() => handleGalleryMediaClick(gallery)}
      >
        {isSelected && (
          <div className={styles.selectedIconAbsoluteWrapper}>
            <Icon className={styles.inputEye} icon={CircleChecked} />
          </div>
        )}
        <p className={styles.dateLabel}>
          {formatDate(gallery.createdAt, DATE_FORMAT.MONTH_DAY_YEAR_TIME)}
        </p>
        <div className={styles.imagePlaceHolder}>
          {gallery.galleryMedia?.type === 'photo' ? (
            <img
              src={gallery.galleryMedia.thumbnailUrl}
              alt="img"
              className={styles.imageLg}
            />
          ) : (
            <video className={styles.imageLg} autoPlay={false} controls>
              <source src={gallery.galleryMedia?.url} type="video/mp4" />
            </video>
          )}
        </div>
        {gallery.campaign && <p>{gallery.campaign.name}</p>}
      </div>
    );
  };

  return (
    <div>
      <div className={styles.staffGalleryActivityWrapper}>
        {staffGalleryActivity.length ? (
          staffGalleryActivity.map((gallery) => renderGalleryMedia(gallery))
        ) : (
          <p className={styles.noContentFoundForThisDateIntervalText}>
            No content found for this date interval
          </p>
        )}
      </div>
    </div>
  );
};

export default StaffGalleryActivity;
