import { defineMessages } from 'react-intl';

export const scope = 'campaign_tasks';

export default defineMessages({
  task: {
    id: `${scope}.input_label.task`,
    defaultMessage: 'Task',
  },
  taskDescription: {
    id: `${scope}.placeholder.taskDescription`,
    defaultMessage: 'Enter task description',
  },
  step: {
    id: `${scope}.step`,
    defaultMessage: 'Step',
  },
  arrival: {
    id: `${scope}.text.arrival`,
    defaultMessage: 'Arrival task',
  },
  default: {
    id: `${scope}.text.default`,
    defaultMessage: 'Default task',
  },
  'friend-invitation': {
    id: `${scope}.text.friend-invitation`,
    defaultMessage: 'Friend invitation task',
  },
  survey: {
    id: `${scope}.text.survey`,
    defaultMessage: 'Survey task',
  },
  like: {
    id: `${scope}.text.like`,
    defaultMessage: 'Like task',
  },
  comment: {
    id: `${scope}.text.comment`,
    defaultMessage: 'Comment task',
  },
  follow: {
    id: `${scope}.text.follow`,
    defaultMessage: 'Follow task',
  },
  share: {
    id: `${scope}.text.share`,
    defaultMessage: 'Share task',
  },
  visit: {
    id: `${scope}.text.visit`,
    defaultMessage: 'Visit task',
  },
  photo: {
    id: `${scope}.text.photo`,
    defaultMessage: 'Custom media task (photo)',
  },
  video: {
    id: `${scope}.text.video`,
    defaultMessage: 'Custom media task (video)',
  },
  'check-in': {
    id: `${scope}.text.check-in`,
    defaultMessage: 'Check-in task',
  },
  social: {
    id: `${scope}.text.social`,
    defaultMessage: 'Social Media task',
  },
  arrivalTaskPlaceholder: {
    id: `${scope}.placeholder.arrival_task_placeholder`,
    defaultMessage: 'Enter meeting point address',
  },
});
