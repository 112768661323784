import Button from 'components/Buttons/Button';
import { PasswordField } from 'components/Forms';
import AuthLayout from 'components/Layout/Pages/AuthPage/AuthLayout';
import { AUTH } from '../../constants';
import { Form, Formik } from 'formik';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { resetPasswordSchema } from './validations';
import { getApi } from 'utils/request';
import { useDispatch } from 'react-redux';
import { enqueueSnackbar } from 'containers/Notifier/actions';

const ResetPassword = () => {
  const { token } = useParams();

  const history = useHistory();

  const dispatch = useDispatch();

  const handleOnSubmit = async (values, { setErrors }) => {
    try {
      const { password, passwordConfirmation } = values;
      const api = getApi();
      if (password !== passwordConfirmation) {
        dispatch(
          enqueueSnackbar({
            variant: 'error',
            message: "Passwords don't match",
          })
        );
        return;
      }
      await api.post('/auth/admin/reset-forgot-password', {
        password: password,
        token: token,
      });
      dispatch(
        enqueueSnackbar({
          variant: 'success',
          message: 'Password reset',
        })
      );
      history.push('/login');
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          variant: 'error',
          message: err.data.message,
        })
      );
    }
  };

  return (
    <main>
      <AuthLayout>
        <Formik
          initialValues={{
            password: '',
            passwordConfirmation: '',
          }}
          validationSchema={resetPasswordSchema}
          onSubmit={handleOnSubmit}
        >
          <Form>
            <div>
              <PasswordField
                name="password"
                label={'New password'}
                placeholder={'New password'}
                required
                minValue={`${AUTH.MIN_PASSWORD_CHARACTERS}`}
                maxValue={`${AUTH.MAX_PASSWORD_CHARACTERS}`}
              />
            </div>
            <div>
              <PasswordField
                name="passwordConfirmation"
                label={'Confirm new password'}
                placeholder={'Confirm new password'}
                minValue={`${AUTH.MIN_PASSWORD_CHARACTERS}`}
                maxValue={`${AUTH.MAX_PASSWORD_CHARACTERS}`}
                required
              />
            </div>
            <Button
              alt
              disabled={false}
              type="submit"
              title={'Submit'}
              isLarge
            />
          </Form>
        </Formik>
      </AuthLayout>
    </main>
  );
};

export default ResetPassword;
