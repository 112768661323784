export const NOTICE_CARDS = '[NoticeCards]';

export const FETCH_NOTICE_CARDS_REQUEST = `${NOTICE_CARDS} FETCH_NOTICE_CARDS_REQUEST`;
export const FETCH_NOTICE_CARDS_SUCCESS = `${NOTICE_CARDS} FETCH_NOTICE_CARDS_SUCCESS`;
export const TOGGLE_NOTICE_CARD_ACTIVE_STATUS_REQUEST = `${NOTICE_CARDS} TOGGLE_NOTICE_CARD_ACTIVE_STATUS_REQUEST`;
export const TOGGLE_NOTICE_CARD_ACTIVE_STATUS_SUCCESS = `${NOTICE_CARDS} TOGGLE_NOTICE_CARD_ACTIVE_STATUS_SUCCESS`;
export const UPLOAD_NOTICE_CARD_REQUEST = `${NOTICE_CARDS} UPLOAD_NOTICE_CARD_REQUEST`;
export const UPLOAD_NOTICE_CARD_SUCCESS = `${NOTICE_CARDS} UPLOAD_NOTICE_CARD_SUCCESS`;
export const FETCH_TOUR_CAMPAIGNS_REQUEST = `${NOTICE_CARDS} FETCH_TOUR_CAMPAIGNS_REQUEST`;
export const FETCH_TOUR_CAMPAIGNS_SUCCESS = `${NOTICE_CARDS} FETCH_TOUR_CAMPAIGNS_SUCCESS`;
export const DELETE_NOTICE_CARD_REQUEST = `${NOTICE_CARDS} DELETE_NOTICE_CARD_REQUEST`;
export const DELETE_NOTICE_CARD_SUCCESS = `${NOTICE_CARDS} DELETE_NOTICE_CARD_SUCCESS`;
