import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import {
  creatAiPromptError,
  creatAiPromptSuccess,
  fetchAiPromptsError,
  fetchAiPromptsSuccess,
} from './actions';

import { ENDPOINTS } from '../../constants';

import { enqueueSnackbar } from '../Notifier/actions';
import toasterVariants from 'constants/toasterVariants';
import {
  CREATE_AI_PROMPT_REQUEST,
  FETCH_AI_PROMPTS_REQUEST,
} from './constants';

export function* createAiPrompt({ payload, meta: { onSuccess, onError } }) {
  try {
    const data = yield call(request, {
      url: ENDPOINTS.AI_PROMPT,
      method: 'post',
      data: payload,
    });

    yield put(
      enqueueSnackbar({
        message: 'Prompt created successfully',
        variant: toasterVariants.success,
      })
    );
    yield put(creatAiPromptSuccess(data));
    onSuccess();
  } catch (error) {
    onError();
    yield put(creatAiPromptError());
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* fetchAiPrompts() {
  try {
    const data = yield call(request, {
      url: ENDPOINTS.AI_PROMPT,
      method: 'get',
    });

    yield put(fetchAiPromptsSuccess(data));
  } catch (error) {
    yield put(fetchAiPromptsError());
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export default function* aiSaga() {
  yield takeLatest(CREATE_AI_PROMPT_REQUEST, createAiPrompt);
  yield takeLatest(FETCH_AI_PROMPTS_REQUEST, fetchAiPrompts);
}
