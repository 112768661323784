import produce from 'immer';

import { FETCH_BUSINESS_RULES_SUCCESS } from './constants';

export const initialState = {
  businessRules: {},
};

/* eslint-disable default-case, no-param-reassign */
const businessRulesPageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_BUSINESS_RULES_SUCCESS:
        draft.businessRules = action.businessRules;
        break;
    }
  });

export default businessRulesPageReducer;
