import Yup from 'utils/validations';

export const createCouponSchema = Yup.object().shape({
  validUntil: Yup.date().required(),
  text: Yup.string().trim().required(),
  type: Yup.string().trim().required(),
  value: Yup.number().when('type', {
    is: 'custom',
    then: Yup.number().required(),
  }),
  secret: Yup.string().trim().required(),
});

export const extendCuponDate = Yup.object().shape({
  validUntil: Yup.date().required(),
  secret: Yup.string().trim().required(),
});
