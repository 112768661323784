export const ENDPOINTS = {
  AUTH_REFRESH_TOKEN: '/auth/refresh-token',
  AUTH_LOGIN: '/auth/admin/login',
  AUTH_LOGOUT: '/auth/user/logout',
  ADVERTISERS:
    '/admin/business-representatives?page=%s&perPage=%s&search=%s&sortBy=%s&order=%s',
  CREATE_SINGLE_ADVERTISER: '/admin/business-representatives',
  SINGLE_ADVERTISER: '/admin/business-representatives/{id}',
  IMPERSONATE_ADVERTISER: '/admin/business-representatives/{id}/impersonate',
  CAMPAIGNS: '/admin/campaigns?page=%s&perPage=%s&search=%s&sortBy=%s&order=%s',
  USERS_PAGE:
    '/admin/users?page=%s&perPage=%s&search=%s&sortBy=%s&order=%s&isStaff=%s',
  USERS: '/admin/users',
  USER: '/admin/users/{id}',
  APPROVE_USER: '/admin/users/{id}/approval',
  APPROVE_USERS: '/admin/users/bulk-approve',
  COMMON_DATA: '/common-data',
  SUPERVISOR_TASKS: '/admin/supervisor-default-tasks',
  SUPERVISOR_TASK: '/admin/supervisor-default-tasks/{id}',
  CREATE_CAMPAIGN_SUPERVISOR_TASK:
    '/admin/campaigns/{campaignId}/supervisor-tasks',
  DELETE_CAMPAIGN_SUPERVISOR_TASK:
    '/admin/campaigns/{campaignId}/supervisor-tasks/{supervisorTaskId}',
  SINGLE_CAMPAIGN: '/admin/campaigns/{campaignId}',
  SINGLE_CAMPAIGN_END_DATE: '/admin/campaigns/{campaignId}/end',
  REMOVE_JOINED_USER: '/admin/campaigns/{campaignId}/users/{userId}',
  CHECK_IS_USERNAME_AVAILABLE: '/auth/user/is-username-available?username=%s',
  CHANGE_USER_ROLE:
    '/admin/campaigns/{campaignId}/users/{userId}/campaign-user',
  APPROVE_CAMPAIGN: '/admin/campaigns/{campaignId}/approve',
  REQUEST_CHANGES: '/admin/campaigns/{campaignId}/request-changes',
  CANCEL_CAMPAIGN: '/admin/campaigns/{campaignId}',
  BUSINESS_RULES: '/admin/business-rules-variables',
  IS_CAMPAIGN_INTERNAL: '/admin/campaigns/{campaignId}/internal',
  IS_USER_STAFF: '/admin/users/{userId}/staff',
  LOGS: '/logs?page=%s&perPage=%s&logType=%s&search=%s',
  AUDIT_LOGS: '/audit-logs?page=%s&perPage=%s',
  EYCROWD_TASKS: '/admin/task-templates',
  OBJECTIVES: '/objectives',
  OBJECTIVES_ALL: '/objectives/all',
  OBJECTIVE_SINGLE: '/objectives/{id}',
  CATEGORIES: '/categories',
  STARTER: 'starter-campaigns/upload',
  STARTER_CAMPIGNS:
    '/admin/starter-campaigns?page=%s&perPage=%s&search=%s&sortBy=%s&order=%s',
  SALES_CAMPAIGNS:
    '/admin/starter-campaigns?page=%s&perPage=%s&search=%s&sortBy=%s&order=%s&isSales=true',
  CHANGE_PRIORITY: '/admin/campaigns/{campaignId}/set-priority',
  DELTE_STARTER_CAMPAIGN: '/admin/starter-campaigns',
  RETAILERS: '/campaign-templates/retailers',
  CAMPAIGN_OBJECTIVES: '/campaign-templates/campaign-objective-categories',
  UPLOAD_TEMPLATES: '/campaign-templates/upload',
  FETCH_TEMPLATES:
    '/campaign-templates?page=%s&perPage=%s&search=%s&sortBy=%s&order=%s&isSales=true',
  DELETE_TEMPLATE: '/campaign-templates',
  UPLOAD_TOUR: '/tour-campaigns/upload',
  TOUR_CAMPAIGNS:
    '/tour-campaigns?page=%s&perPage=%s&search=%s&sortBy=%s&order=%s',
  DELTE_TOUR_CAMPAIGN: '/tour-campaigns',
  SET_ADVERTISER_AS_STAFF:
    '/admin/business-representatives/set-si-staff/{advertiserId}',
  GET_ANALYTICS: '/analytics/get-latest',
  TRANSFER_MONEY: '/admin/payment/transfer-to-user',
  BLOCK_USER: '/admin/users/kick-user/{userId}',
  CREATE_COUPON: '/coupons',
  FETCH_COUPONS: '/coupons?page=%s&perPage=%s',
  COUPON_SINGLE: '/coupons/{id}',
  GET_STAFF_ACTIVITY:
    '/analytics/get-staff-activity?dateFrom={dateFrom}&dateTo={dateTo}&staffUserId={staffUserId}',
  ADMINS: '/admins?page=%s&perPage=%s',
  CREATE_SINGLE_ADMIN: '/admins',
  DELETE_ADMIN: '/admins/{adminId}',
  EDIT_ADMIN: '/admins/{adminId}',
  HANDLE_CAMPAIGN_GRADE: '/admin/campaigns/{campaignId}/grades/{gradeId}',
  AI_PROMPT: '/admin/ai/prompt',
  CREATE_BULK_CAMPAIGNS: '/admin/campaigns/createBulkCampaigns',
  CREATE_BRAND_STORY_SUMMARY: '/admin/campaigns/create-brand-story-summary',
  CREATE_CAMPAIGN_SUGGESTIONS: 'admin/campaigns/create-campaign-suggestions',
  AI_PROMPT_LOGS: '/admin/ai/prompt-logs?page=%s&perPage=%s&search=%s',
  // FETCH_FEED_ITEMS: '/admin/feed?page=%s&perPage=%s',
  // SET_FEED_ITEMS_PRIORITY:
  //   '/admin/feed/priority/{page}/{firstItemId}/{secondItemId}',
  // FEED_ITEM: '/admin/feed/{id}',
  // FEED_ITEM_UPLOAD: '/admin/feed/upload',
  NOTICE_CARDS: '/admin/notice-cards',
  FETCH_NOTICE_CARDS: '/admin/notice-cards?page=%s&perPage=%s&search=%s',
  TOGGLE_NOTICE_CARD_ACTIVE_STATUS: '/admin/notice-cards/{id}',
  FETCH_ADMIN_TOUR_CAMPAIGNS: 'admin/campaigns/tour-campaigns',
  FETCH_WEBSITE_FORMS: 'feedback/website-form?page=%s&perPage=%s&types=%s',
};
