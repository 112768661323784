import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import ResourceTable from 'components/ResourceTable';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import { useDispatch, useSelector } from 'react-redux';
import { makeSelectRequestSent, makeSelectUsers } from './selectors';
import { fetchUsersListRequest, approveUsersRequest } from './actions';
import { TABLES, DATE_FORMAT } from '../../constants';
import DashboardLayout from 'components/Layout/DashboardLayout';
import { formatDate } from 'utils/date';
import globalMessages from 'messages';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import { CREATE_USER, SINGLE_USER } from 'routes';
import { useHistory, Link } from 'react-router-dom';
import styles from 'components/ResourceTable/Table.module.scss';
import { Fragment } from 'react';
import eyCrowdIcon from '../../assets/images/icon-eycrowd.png';
import notApprovedIcon from '../../assets/images/not-approved.svg';
import Tooltip from 'components/Tooltip';
import { canActivate } from '../../utils/permissions';
import {
  CREATE_USERS,
  GET_USERS,
  LIST_USERS,
  UPDATE_USERS,
} from '../../permisssions';

const key = 'users';

const UsersTable = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const history = useHistory();

  const redirectSingleUser = () => {
    history.push(CREATE_USER);
  };

  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const users = useSelector(makeSelectUsers());
  const requestSent = useSelector(makeSelectRequestSent());

  useEffect(() => {
    dispatch(
      fetchUsersListRequest(
        localStorage.getItem('pageNumberUsers')
          ? localStorage.getItem('pageNumberUsers')
          : 1,
        localStorage.getItem('perPage') ? localStorage.getItem('perPage') : 25
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (requestSent) {
      dispatch(fetchUsersListRequest(1, TABLES.NUMBER_OF_ITEMS_PER_PAGE));
    }
  }, [requestSent, dispatch]);

  const { data, perPage, total, search, sortBy, order } = users || [];

  // const fetchMoreUsers = () => {
  //   dispatch(fetchUsersListRequest(page + 1, perPage, search, sortBy, order));
  // };

  const fetchUsersPage = (pageNumber) => {
    localStorage.setItem('pageNumberUsers', pageNumber);
    dispatch(fetchUsersListRequest(pageNumber, perPage, search, sortBy, order));
  };

  const tableHeader = [
    {
      key: 'username',
      label: formatMessage(messages.username),
      sortable: true,
    },
    { key: 'name', label: formatMessage(messages.name), sortable: true },
    {
      key: 'dateOfBirth',
      label: formatMessage(messages.dateOfBirth),
      sortable: true,
    },
    { key: 'gender', label: formatMessage(messages.gender) },
    { key: 'city', label: formatMessage(messages.city), sortable: true },
    {
      key: 'createdAt',
      label: formatMessage(messages.createdAt),
      sortable: true,
    },
  ];

  const handleChange = (search) => {
    dispatch(fetchUsersListRequest(1, perPage, search));
  };

  const handleSort = (sortBy, order) => {
    dispatch(fetchUsersListRequest(1, perPage, search, sortBy, order));
  };

  const handlePerPage = (perPage) => {
    localStorage.setItem('perPage', perPage);
    localStorage.setItem('pageNumberUsers', 1);
    localStorage.setItem('pageNumberCampaigns', 1);
    localStorage.setItem('pageNumberAdvertisers', 1);
    dispatch(fetchUsersListRequest(1, perPage, search, sortBy, order));
  };

  const handleUsersApproval = (users) => {
    const parsedUserId = users.map((userId) => parseInt(userId, 10));
    dispatch(approveUsersRequest(parsedUserId));
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardMainHeader
          titleMain={formatMessage(messages.header)}
          totalRows={users.total}
          btnText="Create User"
          onClick={redirectSingleUser}
          search
          onChange={handleChange}
          canAccess={canActivate(CREATE_USERS)}
        />
        <ResourceTable
          rowsNumber={data ? data.length : 0}
          // loadMore={fetchMoreUsers}
          hasMore={data?.length < total}
          headerInformation={tableHeader}
          noDataContent={formatMessage(messages.noUsers)}
          handleSort={handleSort}
          fetchPage={fetchUsersPage}
          resource={users}
          perPage={
            localStorage.getItem('perPage')
              ? localStorage.getItem('perPage')
              : perPage
          }
          handlePerPage={handlePerPage}
          showBulkAction
          bulkAction={handleUsersApproval}
          canAccessBulkAction={canActivate(UPDATE_USERS)}
          bulkActionTitle="Approve selected users"
          canAccess={canActivate(LIST_USERS)}
        >
          {data.map((user, index) => {
            return (
              <Fragment key={user.id}>
                {canActivate(GET_USERS) && (
                  <TableColumnField
                    data={
                      <Link
                        to={{
                          pathname: SINGLE_USER.replace(':id', user.id),
                          shouldRedirectToEdit: false,
                        }}
                      >
                        <>
                          <p className={styles.underlinedUsername}>
                            {user.username}
                          </p>
                        </>
                        {/* <Tooltip label={user.username} /> */}
                      </Link>
                    }
                  />
                )}
                <TableColumnField
                  data={
                    <div className={styles.userFullName}>
                      <Tooltip label={user.firstName + ' ' + user.lastName} />
                      {user.isStaff ? (
                        <img
                          className={styles.eyCrowdIcon}
                          src={eyCrowdIcon}
                          alt="isStaff-icon"
                        />
                      ) : null}
                      {!user.approvedAt ? (
                        <img
                          className={styles.notApprovedIcon}
                          src={notApprovedIcon}
                          alt="notApproved-icon"
                        />
                      ) : null}
                    </div>
                  }
                />
                <TableColumnField
                  data={formatDate(user.dateOfBirth, DATE_FORMAT.YEAR)}
                />
                <TableColumnField
                  data={
                    user.gender && formatMessage(globalMessages[user.gender])
                  }
                />
                <TableColumnField
                  data={user.city ? <Tooltip label={user.city} /> : user.city}
                />
                <TableColumnField
                  data={formatDate(
                    user.createdAt,
                    DATE_FORMAT.MONTH_DAY_YEAR_TIME
                  )}
                />
              </Fragment>
            );
          })}
        </ResourceTable>
      </DashboardLayout>
    </div>
  );
};

export default UsersTable;
