import { useIntl } from 'react-intl';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import { useInjectSaga } from '../../utils/injectSaga';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  deleteFeedItemRequest,
  fetchFeedItemsRequest,
  updateFeedItemPriorityRequest,
  uploadFeedItemRequest,
} from './actions';
import { makeSelectFeedItems } from './selectors';
import DashboardMainHeader from '../../components/Layout/DashboardLayout/DashboardMainHeader';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { canActivate } from '../../utils/permissions';
import { UPDATE_FEED_ITEM_PRIORITY } from '../../permisssions';
import saga from './saga';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styles from './FeedItemPriority.module.scss';
import { FILE_TYPE } from '../../constants/file-type';
import { Modal } from '@material-ui/core';
import Button from 'components/Buttons/Button';
import { useConfirmationModal } from 'components/ConfirmationModal';
import { formatDate } from 'utils/date';
import { DATE_FORMAT } from '../../constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import LoadingSpinner from './../../components/LoadingSpinner/LoadingSpinner.presentation';

const key = 'feed_items_priority';

function FeedItemsPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const dispatch = useDispatch();
  const feedItems = useSelector(makeSelectFeedItems());
  const [openConfirmationModal] = useConfirmationModal();
  const { data, perPage, page, total } = feedItems || [];

  useEffect(() => {
    dispatch(fetchFeedItemsRequest(1, 25));
  }, [dispatch]);

  const loadMoreFeedItems = () => {
    dispatch(fetchFeedItemsRequest(+page + 1, perPage));
  };

  const handleDeleteFeedItem = (id) => {
    openConfirmationModal(
      'Are you sure you want to delete this media item?',
      () => dispatch(deleteFeedItemRequest(id))
    );
  };

  const updateFeedItemsOrder = (page, firstItem, secondItem) => {
    dispatch(updateFeedItemPriorityRequest(page, firstItem.id, secondItem.id));
  };

  const callback = () => {
    setModalOpen(false);
    setIsPending(false);
  };

  const onUpload = (files) => {
    dispatch(uploadFeedItemRequest(files, callback, callback));
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const num = data.filter((item) => item.isUGC === false).length;

    if (oldIndex !== newIndex && newIndex < num) {
      updateFeedItemsOrder(page, data[oldIndex], data[newIndex]);
    } else if (newIndex >= num) {
      setModalVisible(false);
    } else {
      setSelectedItem({
        url: data[oldIndex].galleryMedia.url,
        type: data[oldIndex].galleryMedia.type,
      });
      setModalVisible(true);
    }
  };

  const SortableItem = SortableElement(({ item }) => (
    <div className={item.isUGC ? styles.item : styles.dragableItem}>
      <div
        className={styles.img}
        onClick={() => {
          setSelectedItem({
            url: item.galleryMedia.url,
            type: item.galleryMedia.type,
          });
          setModalVisible(true);
        }}
      >
        {item.galleryMedia.type === FILE_TYPE.PHOTO ? (
          <img src={item.galleryMedia.thumbnailUrl} alt="img" />
        ) : (
          <video autoPlay={false}>
            <source src={item.galleryMedia.url} type="video/mp4" />
          </video>
        )}
      </div>
      <div className={styles.desc}>
        <span>
          <b>{item.user.username}</b>
          <p>
            {formatDate(
              item.createdAt,
              DATE_FORMAT.USA_DATE_VALIDATION_INPUT_VALUE
            )}
          </p>
        </span>
        <span className={styles.button}>
          <Button
            title="Delete"
            alt
            onClick={() => {
              handleDeleteFeedItem(item.id);
            }}
          />
        </span>
      </div>
    </div>
  ));

  const SortableList = SortableContainer(({ items }) => (
    <div className={styles.orderMediaContainer}>
      {items.map((item, index) => (
        <SortableItem
          key={`${item.id}`}
          index={index}
          item={item}
          disabled={item.isUGC}
        />
      ))}
    </div>
  ));

  return (
    <DashboardLayout>
      <DashboardMainHeader
        titleMain="Feed Items"
        canAccess={canActivate(UPDATE_FEED_ITEM_PRIORITY)}
        btnText="Upload"
        onClick={() => setModalOpen(true)}
      />
      <InfiniteScroll
        dataLength={total}
        next={loadMoreFeedItems}
        hasMore={data?.length < total}
        style={{ overflow: 'inherit' }}
      >
        <SortableList axis="xy" items={data} onSortEnd={onSortEnd} />
      </InfiniteScroll>
      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <div className={styles.modalFeedWrapper}>
          <div className={styles.modalFeedInner}>
            {selectedItem.type === FILE_TYPE.PHOTO ? (
              <img src={selectedItem.url} alt="img" />
            ) : (
              <video controls>
                <source src={selectedItem.url} type="video/mp4" />
              </video>
            )}
          </div>
        </div>
      </Modal>
      <UploadFeedItem
        modalOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onUpload={onUpload}
        pending={isPending}
        setIsPending={setIsPending}
      />
    </DashboardLayout>
  );
}

export default FeedItemsPage;

const UploadFeedItem = ({
  modalOpen,
  onClose,
  onUpload,
  pending,
  setIsPending,
}) => {
  const [files, setFiles] = useState(null);

  const fileHandler = (event) => {
    let file = event.target.files[0];
    setFiles(file);
  };

  const handleUpload = () => {
    setIsPending(true);
    onUpload(files);
    setFiles(null);
  };

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrapper}>
        {pending ? (
          <LoadingSpinner className={styles.spinner} />
        ) : (
          <div className={styles.modalInner}>
            <div className={styles.modalTop}>
              <p className={styles.title}>Upload Feed Item</p>
            </div>
            <div className={styles.modalContent}>
              <span className={styles.inputSpan}>
                <input
                  type="file"
                  className={styles.customFileInput}
                  onChange={fileHandler}
                  multiple={false}
                  id="file"
                />
                <label className={styles.inputLabel} for="file">
                  Choose file
                </label>
              </span>
              <span className={styles.buttonSpan}>
                <Button
                  title="Upload"
                  alt
                  onClick={handleUpload}
                  disabled={files === null}
                />
              </span>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};
