import { createSelector } from 'reselect';
import { initialState } from './reducer';

const noticeCards = (state) => state.noticeCards || initialState;

const makeSelectNoticeCards = () =>
  createSelector(noticeCards, (subState) => subState.noticeCards);

const makeSelectTourCampaigns = () =>
  createSelector(noticeCards, (subState) => subState.tourCampaigns);

export { makeSelectNoticeCards, makeSelectTourCampaigns };
