import Yup from 'utils/validations';

export const createAdminSchema = Yup.object().shape({
  email: Yup.string().email().max(255).required(),
  password: Yup.string()
    .min(8)
    .max(100)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/),
  firstName: Yup.string().max(255).required(),
  lastName: Yup.string().max(255).required(),
  role: Yup.string().max(255).required(),
});

export const editAdminSchema = Yup.object().shape({
  email: Yup.string().email().max(255).required(),
  password: Yup.string()
    .min(8)
    .max(100)
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
    .optional(),
  firstName: Yup.string().max(255).required(),
  lastName: Yup.string().max(255).required(),
  role: Yup.string().max(255).required(),
});
