import { defineMessages } from 'react-intl';

export const scope = 'starter.content.info';

export default defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Campaign info',
  },
  campaignType: {
    id: `${scope}.campaign_type`,
    defaultMessage: 'Campaign Type',
  },
  businessType: {
    id: `${scope}.business_type`,
    defaultMessage: 'Business Type',
  },
  startDate: {
    id: `${scope}.start_date`,
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: `${scope}.end_date`,
    defaultMessage: 'End Date',
  },
  createdAt: {
    id: `${scope}.created_at`,
    defaultMessage: 'Creation Date',
  },
  eyvocates: {
    id: `${scope}.eyvocates`,
    defaultMessage: 'EyVocates',
  },
  age: {
    id: `${scope}.age`,
    defaultMessage: 'Age',
  },
  gender: {
    id: `${scope}.gender`,
    defaultMessage: 'Gender',
  },
  interests: {
    id: `${scope}.interests`,
    defaultMessage: 'Interests',
  },
  beIn: {
    id: `${scope}.be_in`,
    defaultMessage: 'Event Type',
  },
  pricePerHour: {
    id: `${scope}.price_per_hour`,
    defaultMessage: 'Price per Hour',
  },
  totalPrice: {
    id: `${scope}.total_price`,
    defaultMessage: 'Total Price',
  },
  amountRefunded: {
    id: `${scope}.amount_refunded`,
    defaultMessage: 'Amount refunded',
  },
  coolDownPeriod: {
    id: `${scope}.cool_down_period`,
    defaultMessage: 'Cool Down Period',
  },
  numOfUsersWhoFinished: {
    id: `${scope}.num_of_users_who_finished`,
    defaultMessage: 'Number Of Users Who Finished Campaign',
  },
  companyValues: {
    id: `${scope}.company_values`,
    defaultMessage: 'Company Values',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  whoFinishedCampaing: {
    id: `${scope}.who_finished_campaing`,
    defaultMessage: 'Who finished campaing?',
  },
});
