export const TOUR = '[TOUR]';

export const UPLOAD_TOUR_REQUEST = `${TOUR} UPLOAD_TOUR_REQUEST`;
export const UPLOAD_TOUR_SUCCESS = `${TOUR} UPLOAD_TOUR_SUCCESS`;

export const FETCH_TOUR_CAMPAIGNS_REQUEST = `${TOUR} FETCH_TOUR_CAMPAIGNS_REQUEST`;
export const FETCH_TOUR_CAMPAIGNS_SUCCESS = `${TOUR} FETCH_TOUR_CAMPAIGNS_SUCCESS`;

export const DELETE_TOUR_CAMPAING_REQUEST = `${TOUR} DELETE_TOUR_CAMPAING_REQUEST`;
export const DELETE_TOUR_CAMPAING_SUCCESS = `${TOUR} DELETE_TOUR_CAMPAING_SUCCESS`;

export const CHANGE_PRIORITY_REQUEST = `${TOUR} CHANGE_PRIORITY_REQUEST`;
export const CHANGE_PRIORITY_SUCCESS = `${TOUR} CHANGE_PRIORITY_SUCCESS`;
