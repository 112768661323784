import produce from 'immer';

import {
  GET_STAFF_ACTIVITY_ATTEMPT,
  GET_STAFF_ACTIVITY_SUCCESS,
  GET_STAFF_ACTIVITY_ERROR,
  GET_STAFF_ACTIVITY_RESET,
  FETCH_STAFF_USERS_LIST_SUCCESS,
  SET_SELECTED_STAFF_USER,
} from './constants';

export const initialState = {
  staffUsers: {
    data: [],
    perPage: null,
    page: null,
    total: null,
    search: '',
    sortBy: '',
    order: '',
  },
  staffActivity: {},
  getStaffActivityStatus: {
    attempt: false,
    success: false,
    error: null,
  },
  selectedStaffUser: {},
};

/* eslint-disable default-case, no-param-reassign */
const staffActivityReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case GET_STAFF_ACTIVITY_ATTEMPT:
        draft.getStaffActivityStatus = {
          attempt: true,
          success: false,
          error: null,
        };
        break;
      case GET_STAFF_ACTIVITY_SUCCESS:
        draft.staffActivity = action.payload;
        draft.getStaffActivityStatus = {
          attempt: false,
          success: true,
          error: null,
        };
        break;
      case GET_STAFF_ACTIVITY_ERROR:
        draft.getStaffActivityStatus = {
          attempt: false,
          success: false,
          error: action.payload,
        };
        break;
      case GET_STAFF_ACTIVITY_RESET:
        draft.getStaffActivityStatus = {
          attempt: false,
          success: false,
          error: null,
        };
        break;
      case FETCH_STAFF_USERS_LIST_SUCCESS:
        draft.staffUsers.data = action.users.data;
        draft.staffUsers.perPage = action.users.perPage;
        draft.staffUsers.page = action.users.page;
        draft.staffUsers.total = action.users.total;
        draft.staffUsers.search = action.search;
        draft.staffUsers.sortBy = action.sortBy;
        draft.staffUsers.order = action.order;
        break;
      case SET_SELECTED_STAFF_USER:
        draft.selectedStaffUser = action.user;
        break;
    }
  });

export default staffActivityReducer;
