import React, { useState } from 'react';
import ContentBox from 'components/ContentBox';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { requestChangesCommentSchema } from 'containers/SingleCampaignPage/validations';
import {
  approveCampaign,
  cancelCampaign,
  requestChanges,
} from 'containers/SingleCampaignPage/actions';
import messages from './messages';
import { InputField } from 'components/Forms';
import { useConfirmationModal } from 'components/ConfirmationModal';
import Button from 'components/Buttons/Button';
import styles from './CampaignReviewForm.module.scss';
import classNames from 'classnames';

const CampaignReviewForm = ({ campaignId }) => {
  const { formatMessage } = useIntl();

  const [openConfirmationModal] = useConfirmationModal();

  const [isCampaignReviewed, setIsCampaignReviewed] = useState(false);

  const dispatch = useDispatch();

  const handleApproveCampaign = () => {
    openConfirmationModal(formatMessage(messages.areYouSureApprove), () => {
      dispatch(approveCampaign(campaignId));
      setIsCampaignReviewed(true);
    });
  };

  const handleRequestChanges = (comment) => {
    openConfirmationModal(
      formatMessage(messages.areYouSureReqestChanges),
      () => {
        dispatch(requestChanges(campaignId, comment));
        setIsCampaignReviewed(true);
      }
    );
  };

  const handleCancelCampaign = () => {
    openConfirmationModal(formatMessage(messages.areYouSureCancel), () => {
      dispatch(cancelCampaign(campaignId));
      setIsCampaignReviewed(true);
    });
  };

  return (
    !isCampaignReviewed && (
      <ContentBox className={styles.approvalContentBox}>
        <h6>{formatMessage(messages.approvalTitle)}</h6>
        <Formik
          initialValues={{ comment: '' }}
          validationSchema={requestChangesCommentSchema}
          onSubmit={(values, { resetForm }) => {
            handleRequestChanges(values.comment);
            resetForm();
          }}
        >
          <Form className={styles.approvalForm} disabled={true}>
            <div>
              <fieldset>
                <InputField
                  id="request-changes-comment"
                  name="comment"
                  type="text"
                  component="textarea"
                  label={formatMessage(messages.requestChangesLabel)}
                  textareaExtraLarge
                  required
                />
                <Button
                  className={classNames(styles.button, styles.successButton)}
                  type="button"
                  onClick={handleApproveCampaign}
                >
                  {formatMessage(messages.approveButtonText)}
                </Button>
                <Button
                  className={classNames(
                    styles.button,
                    styles.requestChangesButton
                  )}
                  type="submit"
                >
                  {formatMessage(messages.requestChangesButtonText)}
                </Button>
                <Button
                  className={classNames(styles.button, styles.cancelButton)}
                  type="button"
                  onClick={handleCancelCampaign}
                >
                  {formatMessage(messages.cancelButtonText)}
                </Button>
              </fieldset>
            </div>
          </Form>
        </Formik>
      </ContentBox>
    )
  );
};

export default CampaignReviewForm;
