import {
  CREATE_BRAND_SUMMARY_AND_SOCIALS_REQUEST,
  CREATE_BRAND_SUMMARY_AND_SOCIALS_SUCCESS,
  CREATE_BRAND_SUMMARY_AND_SOCIALS_ERROR,
  REORGANIZE_JSON_DATA,
  CREATE_CAMPAIGN_SUGGESTIONS_REQUEST,
  CREATE_CAMPAIGN_SUGGESTIONS_SUCCESS,
  CREATE_CAMPAIGN_SUGGESTIONS_ERROR,
  CREATE_BULK_CAMPAIGNS_REQUEST,
  CREATE_BULK_CAMPAIGNS_SUCCESS,
  CREATE_BULK_CAMPAIGNS_ERROR,
  CHANGE_SOCIAL_MEDIA_URLS,
} from './constants';

export function createBrandSummaryAndSocialsRequest(
  payload,
  onSuccess,
  onError
) {
  return {
    type: CREATE_BRAND_SUMMARY_AND_SOCIALS_REQUEST,
    payload,
    meta: { onSuccess, onError },
  };
}

export function createBrandSummaryAndSocialsSuccess(payload) {
  return {
    type: CREATE_BRAND_SUMMARY_AND_SOCIALS_SUCCESS,
    payload,
  };
}

export function createBrandSummaryAndSocialsError() {
  return {
    type: CREATE_BRAND_SUMMARY_AND_SOCIALS_ERROR,
  };
}

export function reorganizeJsonData(jsonData) {
  return {
    type: REORGANIZE_JSON_DATA,
    payload: { jsonData },
  };
}

export function createCampaignSuggestionsRequest(payload, onSuccess, onError) {
  return {
    type: CREATE_CAMPAIGN_SUGGESTIONS_REQUEST,
    payload,
    meta: { onSuccess, onError },
  };
}

export function createCampaignSuggestionsSuccess(payload) {
  return {
    type: CREATE_CAMPAIGN_SUGGESTIONS_SUCCESS,
    payload,
  };
}

export function createCampaignSuggestionsError() {
  return {
    type: CREATE_CAMPAIGN_SUGGESTIONS_ERROR,
  };
}

export function bulkCreateCampaignsRequest(payload, onSuccess, onError) {
  return {
    type: CREATE_BULK_CAMPAIGNS_REQUEST,
    payload: payload,
    meta: { onSuccess, onError },
  };
}

export function bulkCreateCampaignsSuccess(payload) {
  return {
    type: CREATE_BULK_CAMPAIGNS_SUCCESS,
    payload,
  };
}

export function bulkCreateCampaignsError() {
  return {
    type: CREATE_BULK_CAMPAIGNS_ERROR,
  };
}

export function changeSocialMediaUrl(key, value) {
  return {
    type: CHANGE_SOCIAL_MEDIA_URLS,
    payload: { key, value },
  };
}
