import produce from 'immer';
import {
  CREATE_COUPON_ERROR,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  DELETE_COUPON_SUCCESS,
  EDIT_COUPON_ERROR,
  EDIT_COUPON_REQUEST,
  EDIT_COUPON_SUCCESS,
  FETCH_COUPONS_SUCCESS,
} from './constants';

export const initialState = {
  coupons: {
    data: [],
    perPage: null,
    page: null,
    total: null,
  },
  generatingCoupon: false,
  editingCoupon: false,
};

/* eslint-disable default-case */
const couponsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CREATE_COUPON_REQUEST:
        draft.generatingCoupon = true;
        break;
      case CREATE_COUPON_SUCCESS:
        draft.generatingCoupon = false;
        draft.coupons.data = [action.payload, ...draft.coupons.data];
        break;
      case CREATE_COUPON_ERROR:
        draft.generatingCoupon = false;
        break;
      case EDIT_COUPON_REQUEST:
        draft.editingCoupon = true;
        break;
      case EDIT_COUPON_SUCCESS:
        draft.editingCoupon = false;
        draft.coupons.data = draft.coupons.data.map((coupon) => {
          if (coupon.id === action.payload.id) {
            return action.payload.coupon;
          } else {
            return coupon;
          }
        });
        break;
      case EDIT_COUPON_ERROR:
        draft.editingCoupon = false;
        break;
      case FETCH_COUPONS_SUCCESS:
        draft.coupons.perPage = action.coupons.perPage;
        draft.coupons.page = action.coupons.page;
        draft.coupons.total = action.coupons.total;
        draft.coupons.data = action.coupons.data;
        break;

      case DELETE_COUPON_SUCCESS:
        draft.coupons.data = draft.coupons.data.filter((coupon) => {
          return coupon.id !== action.id;
        });
        break;
    }
  });

export default couponsReducer;
