import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSingleCampaignPageDomain = (state) =>
  state.singleCampaignPage || initialState;

const makeSelectSingleCampaign = () =>
  createSelector(
    selectSingleCampaignPageDomain,
    (substate) => substate.campaign
  );

const makeSelectSingleCampaignIsPending = () =>
  createSelector(
    selectSingleCampaignPageDomain,
    (substate) => substate.isPending
  );

const makeSelectHandleCampaignGradeRangePending = () =>
  createSelector(
    selectSingleCampaignPageDomain,
    (substate) => substate.handleCampaignGradeRangePending
  );

export {
  makeSelectSingleCampaign,
  makeSelectSingleCampaignIsPending,
  makeSelectHandleCampaignGradeRangePending,
};
