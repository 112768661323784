import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectSingleObjective = (state) => state.objective || initialState;

const makeSelectSingleObjective = () =>
  createSelector(selectSingleObjective, (substate) => substate.objective);

const makeSelectObjectiveCategories = () =>
  createSelector(selectSingleObjective, (substate) => substate.categories);

export { makeSelectSingleObjective, makeSelectObjectiveCategories };
