import { defineMessages } from 'react-intl';

export const scope = 'aiPromptLogs';

export default defineMessages({
  aiPromptLogs: {
    id: `${scope}.aiPromptLogs`,
    defaultMessage: 'Ai Prompt Logs',
  },
  noAiPromptLogs: {
    id: `${scope}.noAiPromptLogs`,
    defaultMessage: 'No Ai Prompt Logs',
  },
  aiPromptLog: {
    id: `${scope}.aiPromptLog`,
    defaultMessage: 'Ai Prompt Log',
  },
  entityId: {
    id: `${scope}.entityId`,
    defaultMessage: 'ID',
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type',
  },
  task: {
    id: `${scope}.task`,
    defaultMessage: 'Task',
  },
  user: {
    id: `${scope}.user`,
    defaultMessage: 'User',
  },
  userId: {
    id: `${scope}.userId`,
    defaultMessage: 'User Id',
  },
  campaignUserTaskId: {
    id: `${scope}.campaignUserTaskId`,
    defaultMessage: 'Campaign User Task Id',
  },
  taskId: {
    id: `${scope}.taskId`,
    defaultMessage: 'Task Id',
  },
  result: {
    id: `${scope}.result`,
    defaultMessage: 'Result',
  },
  resultPercentage: {
    id: `${scope}.resultPercentage`,
    defaultMessage: 'Result Percentage',
  },
  explanation: {
    id: `${scope}.explanation`,
    defaultMessage: 'Explanation',
  },
  keyActions: {
    id: `${scope}.keyActions`,
    defaultMessage: 'Key Actions',
  },
  createdAt: {
    id: `${scope}.createdAt`,
    defaultMessage: 'Creation date',
  },
});
