import { CREATE_TASK_REQUEST, CREATE_TASK_SUCCESS } from './constants';

export function createTaskRequest(taskData) {
  return {
    type: CREATE_TASK_REQUEST,
    taskData,
  };
}

export function createTaskSuccess(task) {
  return {
    type: CREATE_TASK_SUCCESS,
    task,
  };
}
