import { createSelector } from 'reselect';
import { initialState } from './reducer';

const templates = (state) => state.templates || initialState;

const makeSelectTemplates = () =>
  createSelector(templates, (substate) => substate.templates);

const makeSelectObjectives = () =>
  createSelector(templates, (substate) => substate.objectives);

const makeSeletRetailers = () =>
  createSelector(templates, (substate) => substate.retailers);

export { makeSelectTemplates, makeSelectObjectives, makeSeletRetailers };
