import { defineMessages } from 'react-intl';

export const scope = 'business_rules_page';

export default defineMessages({
  businessRules: {
    id: `${scope}.businessRules`,
    defaultMessage: 'Business rules',
  },
  businessRulesUpdated: {
    id: `${scope}.businessRulesUpdated`,
    defaultMessage: 'Business rules updated!',
  },
  update: {
    id: `${scope}.button.update`,
    defaultMessage: 'Update',
  },
});
