import { defineMessages } from 'react-intl';

export const scope = 'users';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Users',
  },
  id: {
    id: `${scope}.id`,
    defaultMessage: 'Id',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First name',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last Name',
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: 'Username',
  },
  phone: {
    id: `${scope}.phone`,
    defaultMessage: 'Phone',
  },
  dateOfBirth: {
    id: `${scope}.dateOfBirth`,
    defaultMessage: 'Date of Birth',
  },
  gender: {
    id: `${scope}.gender`,
    defaultMessage: 'Gender',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'City',
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: 'Country',
  },
  state: {
    id: `${scope}.state`,
    defaultMessage: 'State',
  },
  zip: {
    id: `${scope}.zip`,
    defaultMessage: 'Zip',
  },
  createdBy: {
    id: `${scope}.title.createdBy`,
    defaultMessage: 'Created by',
  },
  createdAt: {
    id: `${scope}.createdAt`,
    defaultMessage: 'Creation date',
  },
  defaultCreatedBy: {
    id: `${scope}.text.default_createdBy`,
    defaultMessage: 'Registration',
  },
  approvedAt: {
    id: `${scope}.title.approvedAt`,
    defaultMessage: 'Approved at',
  },
  defaultApprovedAt: {
    id: `${scope}.text.default_approvedAt`,
    defaultMessage: 'Not yet approved',
  },
  noUsers: {
    id: `${scope}.text.no_users`,
    defaultMessage: 'No Users',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  yearOfBirth: {
    id: `${scope}.yearOfBirth`,
    defaultMessage: 'Year of birth',
  },
  bulkApproveUserSuccessMessage: {
    id: `${scope}.bulk.approve.success.message`,
    defaultMessage: 'Users have been successfully approved',
  },
});
