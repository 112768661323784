import React from 'react';
import styles from './LoadingSpinner.module.scss';
import spinner from '../../assets/images/spinner.gif';
import classNames from 'classnames';

const LoadingSpinner = ({ className }) => (
  <div className={classNames(styles.loadingSpinner, className && className)}>
    <img src={spinner} alt="loading spinner" />
  </div>
);

export default LoadingSpinner;
