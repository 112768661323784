export const DATE_FORMAT = {
  USA_DATE: 'MM dd yyyy',
  MONTH_DAY_YEAR: 'MMM d, yyyy',
  MONTH_DAY_YEAR_TIME: 'MMM d, yyyy, hh:mm a',
  USA_DATE_VALIDATION_INPUT_VALUE: 'MM/dd/yyyy',
  USA_DATE_VALIDATION_INPUT_VALUE_WITH_HOURS: 'MM/dd/yyyy h:mm aa',
  HOURS: 'h:mm aa',
  DATABASE_BIRTH_DATE: 'yyyy-MM-dd',
  YEAR: 'yyyy',
};
