import {
  FETCH_WEBSITE_FORMS_REQUEST,
  FETCH_WEBSITE_FORMS_SUCCESS,
} from './constants';

export function fetchWebsiteFormsRequest(page, perPage, types) {
  return {
    type: FETCH_WEBSITE_FORMS_REQUEST,
    page,
    perPage,
    types,
  };
}

export function fetchWebsiteFormsSuccess({ data, total, page, perPage }) {
  return {
    type: FETCH_WEBSITE_FORMS_SUCCESS,
    data,
    total,
    page,
    perPage,
  };
}
