import {
  format,
  add,
  subDays,
  parseJSON,
  differenceInHours,
  differenceInMinutes,
  differenceInDays,
} from 'date-fns';

const now = () => new Date();

const addDays = (date, daysToAdd) => add(date, { days: daysToAdd });

const addHours = (date, hoursToAdd) => add(date, { hours: hoursToAdd });

const subtractDays = (date, daysToSubtract) => subDays(date, daysToSubtract);

const formatJsonDate = (jsonDate) => parseJSON(jsonDate);

const diffMinutes = (endDate, startDate) =>
  differenceInMinutes(endDate, startDate);

const diffHours = (endDate, startDate) => differenceInHours(endDate, startDate);

const diffDays = (endDate, startDate) => differenceInDays(endDate, startDate);

const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString);

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes()
  );
};

const formatDate = (date, dateFormat) =>
  format(parseJSON(date || new Date()), dateFormat);

const formatParsedDate = (date, dateFormat) =>
  format(date || new Date(), dateFormat);

export {
  now,
  addDays,
  addHours,
  subtractDays,
  formatJsonDate,
  formatDate,
  getUTCDate,
  diffMinutes,
  diffHours,
  diffDays,
  formatParsedDate,
};
