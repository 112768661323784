import produce from 'immer';
import {
  CREATE_AI_PROMPT_REQUEST,
  CREATE_AI_PROMPT_SUCCESS,
  CREATE_AI_PROMPT_ERROR,
  FETCH_AI_PROMPTS_SUCCESS,
  FETCH_AI_PROMPTS_ERROR,
  FETCH_AI_PROMPTS_REQUEST,
} from './constants';

export const initialState = {
  createPromptPending: false,
  prompts: [],
  fetchPromptsPending: false,
};

/* eslint-disable default-case */
const aiReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case CREATE_AI_PROMPT_REQUEST:
        draft.createPromptPending = true;
        break;
      case CREATE_AI_PROMPT_SUCCESS:
        draft.createPromptPending = false;
        if (draft.prompts.find((p) => p.id === action.payload.id)) {
          draft.prompts = draft.prompts.map((pr) => {
            if (pr.id === action.payload.id) {
              return action.payload;
            } else {
              return pr;
            }
          });
        } else {
          draft.prompts = [...draft.prompts, action.payload];
        }
        break;
      case CREATE_AI_PROMPT_ERROR:
        draft.createPromptPending = false;
        break;
      case FETCH_AI_PROMPTS_REQUEST:
        draft.fetchPromptsPending = true;
        break;
      case FETCH_AI_PROMPTS_SUCCESS:
        draft.fetchPromptsPending = false;
        draft.prompts = action.payload;
        break;
      case FETCH_AI_PROMPTS_ERROR:
        draft.fetchPromptsPending = false;
        break;
    }
  });

export default aiReducer;
