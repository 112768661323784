import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './CampaignTasks.module.scss';
import { useIntl } from 'react-intl';
import messages from './messages';
import Card from 'components/Card';
import { CAMPAIGN_TASKS_CATEGORIES } from '../../constants';

const CampaignTasks = ({ type, title, isSingleCampaign, tasks }) => {
  const { formatMessage } = useIntl();

  const formatTaskLabel = (index, category) => {
    return `${formatMessage(messages.step)} ${index + 1} ${
      category !== CAMPAIGN_TASKS_CATEGORIES.DEFAULT
        ? `- ${category && formatMessage(messages[category])}`
        : ''
    }`;
  };

  const formatTaskBody = (category, description) => {
    return category !== CAMPAIGN_TASKS_CATEGORIES.CHECK_IN
      ? description
      : description && formatMessage(messages[description]);
  };

  const tasksOrder = [
    CAMPAIGN_TASKS_CATEGORIES.DEFAULT,
    CAMPAIGN_TASKS_CATEGORIES.CHECK_IN,
    CAMPAIGN_TASKS_CATEGORIES.ARRIVAL,
  ];
  return (
    <Card className={styles[`${type}`]}>
      <>
        <h6 className={isSingleCampaign && styles.title}>{title}</h6>
        <div>
          {tasks
            .sort(
              (a, b) =>
                tasksOrder.indexOf(a.category) - tasksOrder.indexOf(b.category)
            )
            .map((task, index) => (
              <Fragment key={index}>
                <p className={styles.step}>
                  {formatTaskLabel(index, task.category)}
                </p>
                <p className={styles.taskDesc}>
                  {formatTaskBody(
                    task.category,
                    task.description
                      ? task.description
                      : task.taskSurveyQuestion.text
                  )}
                </p>
              </Fragment>
            ))}
        </div>
      </>
    </Card>
  );
};

export default CampaignTasks;

CampaignTasks.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.array,
  isSingleCampaign: PropTypes.bool,
  tasks: PropTypes.array,
  isDigitalCampaign: PropTypes.bool,
};
