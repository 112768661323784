export const ADMINS = '[Admins]';

export const FETCH_ADMINS_REQUEST = `${ADMINS} FETCH_ADMINS_REQUEST`;
export const FETCH_ADMINS_SUCCESS = `${ADMINS} FETCH_ADMINS_SUCCESS`;
export const FETCH_ADMINS_ERROR = `${ADMINS} FETCH_ADMINS_ERROR`;

export const CREATE_ADMIN_REQUEST = `${ADMINS} CREATE_ADMIN_REQUEST`;
export const CREATE_ADMIN_SUCCESS = `${ADMINS} CREATE_ADMIN_SUCCESS`;
export const CREATE_ADMIN_ERROR = `${ADMINS} CREATE_ADMIN_ERROR`;

export const DELETE_ADMIN_REQUEST = `${ADMINS} DELETE_ADMIN_REQUEST`;
export const DELETE_ADMIN_SUCCESS = `${ADMINS} DELETE_ADMIN_SUCCESS`;

export const EDIT_ADMIN_REQUEST = `${ADMINS} EDIT_ADMIN_REQUEST`;
export const EDIT_ADMIN_SUCCESS = `${ADMINS} EDIT_ADMIN_SUCCESS`;
export const EDIT_ADMIN_ERROR = `${ADMINS} EDIT_ADMIN_ERROR`;
