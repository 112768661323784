import React, { useEffect, useState } from 'react';

import styles from './CampaignGrades.module.scss';
import { useIntl } from 'react-intl';
import messages from './messages';
import { getApi } from 'utils/request';
import { useDispatch, useSelector } from 'react-redux';
import ReactSwitch from 'react-switch';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner.presentation';
import {
  deleteCampaignGradeRangeRequest,
  setCampaignGradeRangeRequest,
} from 'containers/SingleCampaignPage/actions';
import { makeSelectHandleCampaignGradeRangePending } from 'containers/SingleCampaignPage/selectors';

const CampaignGrades = ({ singleCampaign }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.app.token);
  const handlingCampaignRange = useSelector(
    makeSelectHandleCampaignGradeRangePending()
  );

  const [grades, setGrades] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState(null);

  const { campaignGrades } = singleCampaign || {};

  useEffect(() => {
    const fetchGrades = async () => {
      const api = getApi();
      const response = await api.get(`admins/grades`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setGrades(response);
    };
    fetchGrades();
  }, [token]);

  const handleSwitch = async (newValue, gradeId) => {
    if (newValue) {
      dispatch(setCampaignGradeRangeRequest(singleCampaign.id, gradeId));
    } else {
      dispatch(deleteCampaignGradeRangeRequest(singleCampaign.id, gradeId));
    }
  };

  return (
    <div className={styles.container}>
      <h6>{formatMessage(messages.title)}</h6>
      <p className={styles.label}>{formatMessage(messages.gradeRanges)}</p>

      {campaignGrades &&
        grades.map((grade) => {
          return (
            <div key={grade.id}>
              <div className={styles.gradeRangeContainer}>
                <p className={styles.text}>
                  {grade.from} - {grade.to}
                </p>
                <div className={styles.switchContainer}>
                  {selectedGrade === grade.id && handlingCampaignRange ? (
                    <LoadingSpinner className={styles.loadingSpinner} />
                  ) : (
                    <ReactSwitch
                      disabled={handlingCampaignRange}
                      checked={
                        !!campaignGrades.find((g) => g.gradeId === grade.id)
                      }
                      onChange={(value) => {
                        setSelectedGrade(grade.id);
                        handleSwitch(value, grade.id);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default CampaignGrades;
