import React, { Fragment, useEffect, useState } from 'react';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAiPromptLogsRequest } from './actions';
import reducer from './reducer';
import saga from './saga';
import { useIntl } from 'react-intl';
import DashboardLayout from 'components/Layout/DashboardLayout';
import ResourceTable from 'components/ResourceTable';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import { makeSelectAiPromptLogs } from './selectors';
import messages from './messages';
import { canActivate } from 'utils/permissions';
import { LIST_AI_PROMPT_LOGS } from 'permisssions';
import { formatDate } from '../../utils/date';
import { DATE_FORMAT } from '../../constants';
import styles from 'components/ResourceTable/Table.module.scss';
import Icon from 'components/Icon';
import { ReactComponent as eyeIcon } from 'assets/images/eye-icon.svg';
import ShowAiPromptLogInfoModal from './ShowAiPromptLogInfoModal';

const key = 'aiPromptLogs';

function AiPromptLogsPage() {
  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();
  const [selectedLog, setSelectedLog] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const aiPromptLogs = useSelector(makeSelectAiPromptLogs());
  const { data, perPage, total, search } = aiPromptLogs || [];

  useEffect(() => {
    dispatch(fetchAiPromptLogsRequest(1, 25));
  }, [dispatch]);

  const fetchPageAiPromptLogs = (pageNumber) => {
    dispatch(fetchAiPromptLogsRequest(pageNumber, perPage, search));
  };

  const tableData = data?.map((aiPromptLog) => {
    return {
      entityId: aiPromptLog.id,
      type: aiPromptLog.type === 'task-verification' ? 'Task' : 'Social',
      user: aiPromptLog.user.username,
      task: aiPromptLog.task ? aiPromptLog.task.description : '',
      result: aiPromptLog.result,
      resultPercentage: aiPromptLog.resultPercentage
        ? aiPromptLog.resultPercentage.endsWith('%')
          ? aiPromptLog.resultPercentage
          : aiPromptLog.resultPercentage + '%'
        : '',
      createdAt: aiPromptLog.createdAt,
    };
  });

  const handleViewClick = (id) => {
    const log = data.find((el) => el.id === id);
    setSelectedLog(log);
    setModalOpen(true);
  };

  const tableHeader = [
    { key: 'type', label: formatMessage(messages.type) },
    { key: 'user', label: formatMessage(messages.user) },
    { key: 'task', label: formatMessage(messages.task) },
    { key: 'result', label: formatMessage(messages.result) },
    {
      key: 'resultPercentage',
      label: formatMessage(messages.resultPercentage),
    },
    { key: 'createdAt', label: formatMessage(messages.createdAt) },
  ];

  const handlePerPage = (perPage) => {
    localStorage.setItem('perPage', perPage);
    localStorage.setItem('pageNumberUsers', 1);
    localStorage.setItem('pageNumberCampaigns', 1);
    localStorage.setItem('pageNumberAdvertisers', 1);
    dispatch(fetchAiPromptLogsRequest(1, perPage, search));
  };

  const handleChange = (search) => {
    dispatch(fetchAiPromptLogsRequest(1, perPage, search));
  };

  return (
    <div>
      <DashboardLayout>
        {selectedLog && (
          <ShowAiPromptLogInfoModal
            modalOpen={modalOpen}
            title={'Ai Prompt Log Info'}
            onClose={() => {
              setModalOpen(false);
              setSelectedLog(null);
            }}
            close={() => {
              setModalOpen(false);
              setSelectedLog(null);
            }}
            data={selectedLog}
          />
        )}
        <DashboardMainHeader
          titleMain={formatMessage(messages.aiPromptLogs)}
          search
          onChange={handleChange}
        />
        <ResourceTable
          rowsNumber={data ? data.length : 0}
          hasMore={data?.length < total}
          fetchPage={fetchPageAiPromptLogs}
          resource={aiPromptLogs}
          headerInformation={tableHeader}
          noDataContent={formatMessage(messages.noAiPromptLogs)}
          handleSort={() => {}}
          perPage={perPage ? perPage : ''}
          handlePerPage={handlePerPage}
          canAccess={canActivate(LIST_AI_PROMPT_LOGS)}
          hasExtraActions
        >
          {tableData?.map((el) => (
            <Fragment key={el.entityId}>
              {Object.entries(el).map(([propertyName, propertyValue], index) =>
                propertyName === 'entityId' ? null : propertyName ===
                  'createdAt' ? (
                  <TableColumnField
                    key={index}
                    data={formatDate(
                      propertyValue,
                      DATE_FORMAT.MONTH_DAY_YEAR_TIME
                    )}
                  />
                ) : (
                  <TableColumnField key={index} data={propertyValue} />
                )
              )}
              <td className={styles.td}>
                <button
                  className={styles.actionButton}
                  onClick={() => handleViewClick(el.entityId)}
                >
                  <Icon icon={eyeIcon} size={'md30'} />
                </button>
              </td>
            </Fragment>
          ))}
        </ResourceTable>
      </DashboardLayout>
    </div>
  );
}

export default AiPromptLogsPage;
