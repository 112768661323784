import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import DateField from 'components/Forms/DateField';

import styles from './CampaignInfo.module.scss';
import { useIntl } from 'react-intl';
import messages from './messages';
import globalMessages from 'messages';
import { useParams } from 'react-router-dom';
import {
  formatDate,
  formatJsonDate,
  diffHours,
  addHours,
  now,
  addDays,
} from 'utils/date';
import { updateCampaignSchema } from 'containers/SingleCampaignPage/validations';
import {
  CAMPAIGN_VALIDATIONS,
  DATE_FORMAT,
  CAMPAIGN_STATUS,
} from '../../../constants';
import {
  updateSingleCampaignEndDateRequest,
  updateSingleCampaignRequest,
} from 'containers/SingleCampaignPage/actions';
import { makeSelectSingleCampaignIsPending } from 'containers/SingleCampaignPage/selectors';
import Button from 'components/Buttons/Button';
import ReactSwitch from 'react-switch';
import classNames from 'classnames';
import { addMinutes, endOfDay, format, startOfDay } from 'date-fns';
import { canActivate } from '../../../utils/permissions';
import { UPDATE_CAMPAIGNS } from '../../../permisssions';
import { InputField } from 'components/Forms';

const CampaignInfo = ({ singleCampaign, handleSubmitIsCampaignInternal }) => {
  const { formatMessage } = useIntl();

  const [newEndDate, setNewEndDate] = useState(now());

  const dispatch = useDispatch();

  const {
    type,
    startDate,
    endDate,
    applicationDeadline,
    maxEyvocates,
    genderSelection,
    campaignInterests,
    eyvocatesBeIn,
    isPrivate,
    isStarter,
    pricePerHour,
    tasksTotalDurationInMinutes,
    customerInvitationFileUrl,
  } = singleCampaign || {};

  const { id } = useParams();

  const isPending = useSelector(makeSelectSingleCampaignIsPending());

  const campaignDuration = diffHours(
    formatJsonDate(endDate),
    formatJsonDate(startDate)
  );

  const [endDateEdited, setEndDateEdited] = useState(null);
  const [editEndDataVisible, setEditEndDateVisible] = useState(false);

  useEffect(() => {
    setEditEndDateVisible(false);
  }, [singleCampaign]);

  useEffect(() => {
    endDate
      ? setEndDateEdited(formatJsonDate(endDate))
      : setEndDateEdited(new Date());
    //eslint-disable-next-line
  }, [singleCampaign]);

  const today = now();

  const handleEditEndDate = (startDate) =>
    setEndDateEdited(addHours(startDate, campaignDuration));

  const handleSubmit = (values) => {
    if (editEndDataVisible) {
      dispatch(
        updateSingleCampaignEndDateRequest(id, {
          ...values,
          endDate: newEndDate,
          pricePerHour: +values.pricePerHour,
          tasksTotalDurationInMinutes: +values.tasksTotalDurationInMinutes,
        })
      );
    } else
      dispatch(
        updateSingleCampaignRequest(id, {
          ...values,
          endDate: endDateEdited,
          pricePerHour: +values.pricePerHour,
          tasksTotalDurationInMinutes: +values.tasksTotalDurationInMinutes,
        })
      );
  };

  return (
    <div className={styles.container}>
      <h6 className={styles.title}>{formatMessage(messages.title)}</h6>
      <div className={styles.wrapper}>
        <div className={styles.infoRow}>
          <p className={styles.label}>{formatMessage(messages.campaignType)}</p>
          <p className={styles.infoText}>
            {type && formatMessage(globalMessages[type])}
          </p>
        </div>
        <Formik
          initialValues={{
            startDate: startDate ? formatJsonDate(startDate) : new Date(),
            endDate: endDate ? formatJsonDate(endDate) : new Date(),
            applicationDeadline: applicationDeadline
              ? formatJsonDate(applicationDeadline)
              : new Date(),
            isPrivate: isPrivate,
            isStarter: isStarter,
            pricePerHour: pricePerHour,
            tasksTotalDurationInMinutes: tasksTotalDurationInMinutes,
          }}
          validationSchema={updateCampaignSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isValid, dirty, values, setFieldValue }) => {
            return (
              <Form>
                <DateField
                  name="startDate"
                  label={formatMessage(messages.startDate)}
                  placeholder={formatMessage(messages.startDate)}
                  selected={values.startDate}
                  minDate={addDays(today, 0)}
                  onChange={handleEditEndDate}
                  readOnly={singleCampaign.status === CAMPAIGN_STATUS.FINISHED}
                />
                <DateField
                  name="startDate"
                  label={formatMessage(messages.startTime)}
                  placeholder={formatMessage(messages.startTime)}
                  selected={values.startDate}
                  onChange={handleEditEndDate}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={
                    format(values.startDate, 'MM/dd/yyyy') <=
                    format(today, 'MM/dd/yyyy')
                      ? addMinutes(today, 1)
                      : startOfDay(values.startDate)
                  }
                  maxTime={endOfDay(today)}
                  timeIntervals={
                    CAMPAIGN_VALIDATIONS.TIME_PICKER_INTERVALS_MINS
                  }
                  timeCaption="Time"
                  dateFormat={DATE_FORMAT.HOURS}
                  readOnly={singleCampaign.status === CAMPAIGN_STATUS.FINISHED}
                />

                {(singleCampaign.status === CAMPAIGN_STATUS.ACTIVE ||
                  !!singleCampaign?.deletedAt) &&
                editEndDataVisible ? (
                  <>
                    <div className="timepicker-fullwidth">
                      <DateField
                        name="endDate"
                        label={formatMessage(messages.endCampaignDate)}
                        placeholder={formatMessage(messages.endCampaignDate)}
                        selected={newEndDate}
                        onChange={(date) => {
                          date.setSeconds(0);
                          date.setMilliseconds(0);
                          setNewEndDate(date);
                        }}
                        minDate={addDays(today, 0)}
                        minTime={
                          format(newEndDate, 'MM/dd/yyyy') <=
                          format(today, 'MM/dd/yyyy')
                            ? addMinutes(today, 1)
                            : startOfDay(values.startDate)
                        }
                        maxTime={endOfDay(today)}
                        showTimeSelect
                        timeIntervals={
                          CAMPAIGN_VALIDATIONS.TIME_PICKER_INTERVALS_MINS
                        }
                        timeCaption="Time"
                        dateFormat={
                          DATE_FORMAT.USA_DATE_VALIDATION_INPUT_VALUE_WITH_HOURS
                        }
                      />
                    </div>

                    <div
                      onClick={() => {
                        setEditEndDateVisible(false);
                      }}
                      className={classNames(
                        styles.infoRow,
                        singleCampaign.status === CAMPAIGN_STATUS.ACTIVE &&
                          styles.selectable
                      )}
                    >
                      <p className={styles.infoText}>
                        {formatMessage(messages.cancel)}
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <div className={classNames(styles.infoRow)}>
                      <p className={styles.label}>
                        {formatMessage(messages.endDate)}
                      </p>
                      <p className={styles.infoText}>
                        {formatDate(
                          endDateEdited,
                          DATE_FORMAT.USA_DATE_VALIDATION_INPUT_VALUE_WITH_HOURS
                        )}
                      </p>
                    </div>
                    <div
                      onClick={() => {
                        setEditEndDateVisible(true);
                      }}
                      className={classNames(
                        styles.infoRow,
                        singleCampaign.status === CAMPAIGN_STATUS.ACTIVE &&
                          styles.selectable
                      )}
                    >
                      <p className={styles.infoText}>
                        {formatMessage(messages.endCampaignDate)}
                      </p>
                    </div>
                  </>
                )}
                <DateField
                  name="applicationDeadline"
                  label={formatMessage(messages.applicationDeadline)}
                  placeholder={formatMessage(messages.applicationDeadline)}
                  selected={values.applicationDeadline}
                  minDate={addDays(today, 0)}
                  onChange={() => {}}
                  readOnly={singleCampaign.status === CAMPAIGN_STATUS.FINISHED}
                />
                <DateField
                  name="applicationDeadline"
                  label={formatMessage(messages.applicationDeadlineTime)}
                  placeholder={formatMessage(messages.applicationDeadlineTime)}
                  selected={values.applicationDeadline}
                  onChange={() => {}}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={
                    CAMPAIGN_VALIDATIONS.TIME_PICKER_INTERVALS_MINS
                  }
                  timeCaption="Time"
                  minTime={
                    format(values.applicationDeadline, 'MM/dd/yyyy') <=
                    format(today, 'MM/dd/yyyy')
                      ? addMinutes(today, 1)
                      : startOfDay(values.applicationDeadline)
                  }
                  maxTime={endOfDay(today)}
                  dateFormat={DATE_FORMAT.HOURS}
                  readOnly={singleCampaign.status === CAMPAIGN_STATUS.FINISHED}
                />
                <div className={styles.privateContainer}>
                  <p>Private</p>
                  <ReactSwitch
                    offColor="#dcdcdc"
                    onColor="#268a45"
                    checked={!!values.isPrivate}
                    onChange={(value) => {
                      setFieldValue('isPrivate', value);
                    }}
                  />
                </div>

                <div className={styles.privateContainer}>
                  <p>Starter</p>
                  <ReactSwitch
                    offColor="#dcdcdc"
                    onColor="#268a45"
                    checked={!!values.isStarter}
                    onChange={(value) => {
                      setFieldValue('isStarter', value);
                    }}
                  />
                </div>

                <div className={styles.privateContainer}>
                  <p>
                    {values.isStarter ? 'Price per campaing' : 'Price per hour'}
                  </p>
                  <InputField
                    type="text"
                    name="pricePerHour"
                    label={
                      values.isStarter
                        ? formatMessage(messages.pricePerCampaign)
                        : formatMessage(messages.pricePerHour)
                    }
                    placeholder={
                      values.isStarter
                        ? formatMessage(messages.pricePerCampaign)
                        : formatMessage(messages.pricePerHour)
                    }
                    required
                  />
                </div>

                <div className={styles.privateContainer}>
                  <p>Total duration of tasks in minutes</p>
                  <InputField
                    type="text"
                    name="tasksTotalDurationInMinutes"
                    label={formatMessage(messages.tasksTotalDurationInMinutes)}
                    placeholder={formatMessage(
                      messages.tasksTotalDurationInMinutes
                    )}
                    required
                  />
                </div>

                {canActivate(UPDATE_CAMPAIGNS) && (
                  <Button
                    alt
                    disabled={!isValid || !dirty || isPending}
                    type="submit"
                    className={styles.saveButton}
                  >
                    {formatMessage(globalMessages.save)}
                  </Button>
                )}
              </Form>
            );
          }}
        </Formik>
        {singleCampaign.status === CAMPAIGN_STATUS.PUBLISHED && (
          <div className={styles.infoRow}>
            <p className={styles.label}>
              {formatMessage(messages.setCampaignInternal)}
            </p>
            <ReactSwitch
              checked={singleCampaign.isInternal}
              onChange={() => {
                handleSubmitIsCampaignInternal(!singleCampaign.isInternal);
              }}
            />
          </div>
        )}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.infoRow}>
          <p className={styles.label}>{formatMessage(messages.eyvocates)}</p>
          <p className={styles.infoText}> {maxEyvocates}</p>
        </div>
        <div className={styles.infoRow}>
          <p className={styles.label}>{formatMessage(messages.gender)}</p>
          <p className={styles.infoText}>
            {genderSelection &&
              formatMessage(globalMessages.genderSelection[genderSelection])}
          </p>
        </div>
        <div className={styles.infoRow}>
          <p className={styles.label}>{formatMessage(messages.interests)}</p>
          <p className={styles.infoText}>
            {campaignInterests?.map((interest, index) => {
              return `${formatMessage(
                globalMessages.interests[interest.interest.slug]
              )} ${
                campaignInterests.length === 1 ||
                index === campaignInterests.length - 1
                  ? ''
                  : ', '
              }`;
            })}
          </p>
        </div>
        <div className={styles.infoRow}>
          <p className={styles.label}>{formatMessage(messages.beIn)}</p>
          <p className={styles.infoText}>
            {eyvocatesBeIn && formatMessage(globalMessages[eyvocatesBeIn])}
          </p>
        </div>
      </div>
      {customerInvitationFileUrl && (
        <div className={styles.infoRow}>
          <p className={styles.label}>Customer Invitaiton File</p>
          <a href={customerInvitationFileUrl} className={styles.infoText}>
            Download
          </a>
        </div>
      )}
    </div>
  );
};

export default CampaignInfo;

CampaignInfo.propTypes = {
  singleCampaign: PropTypes.object,
};
