export const DASHBOARD = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const ADVERTISERS = '/advertisers';
export const SINGLE_ADVERTISER = '/advertisers/:id/edit';
export const CREATE_ADVERTISER = '/advertisers/create';
export const CAMPAIGNS = '/campaigns';
export const WEBSITE_FORMS = '/website-forms';
export const USERS = '/users';
export const SINGLE_USER = '/users/:id/edit';
export const CREATE_USER = '/users/create-user';
export const NOT_FOUND = '/404';
export const SUPERVISOR_TASKS = '/supervisor-tasks';
export const CREATE_TASK = '/tasks/create-task';
export const SINGLE_CAMPAIGN = '/campaigns/:id';
export const BUSINESS_RULES = '/business-rules';
export const AUDIT_LOGS = '/audit-logs';
export const LOGS = '/logs';
export const EYCROWD_TASKS = '/eycrowd-tasks';
export const CREATE_EYCROWD_TASK_PAGE = '/eycrowd-tasks/create';
export const OBJECTIVES = '/objectives';
export const CREATE_OBJECTIVE = '/objectives/create-objective';
export const STARTER_CAMPAIGN = '/starter-campaign';
export const SALES_EXAMPLE_CAMPAIGNS = '/sales-example-campaigns';
export const TEMPLATES = '/templates';
export const TOURS = '/tour-campaigns';
export const ANALYTICS = '/analytics';
export const TRANSFER = '/transfer';
export const ADMIN_ROLES = '/admin-roles';
export const COUPONS = '/coupons';
export const CHANGE_PASSWORD = '/change-password';
export const RESET_PASSWORD = '/reset-password/:token';
export const FORGOT_PASSWORD = '/forgot-password';
export const STAFF_ACTIVITY = '/staff-activity';
export const SINGLE_STAFF_ACTIVITY = '/staff-activity/:id';
export const STARTER_CAMPAIGN_CONTENT = '/starter-campaign/:id';
export const ADMINISTRATORS = '/administrators';
// export const FEED_ITEMS_PRIORITY = '/feed-items';
export const AI = '/ai';
export const BULKCAMPAIGNS = '/bulk-campaigns';
export const AI_PROMPT_LOGS = '/ai-prompt-logs';
export const NOTICE_CARDS = '/notice-cards';
export const TOUR_CAMPAIGNS = '/campaigns/tour-campaigns';
