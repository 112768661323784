import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import {
  fetchCampaignsSuccess,
  uploadBulkCreateCampaignsSuccess,
} from './actions';
import { FETCH_CAMPAIGNS_REQUEST, UPLOAD_CAMPAIGNS_REQUEST } from './constants';
import { ENDPOINTS } from '../../constants';
import { format } from 'util';
import { enqueueSnackbar } from '../Notifier/actions';

export function* fetchCampaignsRequest({
  page,
  perPage,
  search,
  sortBy,
  order,
}) {
  try {
    const campaigns = yield call(request, {
      url: format(ENDPOINTS.CAMPAIGNS, page, perPage, search, sortBy, order),
      method: 'get',
    });
    yield put(fetchCampaignsSuccess(campaigns, search, sortBy, order));
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

// export function* uploadBulkCampaigns({ payload }) {
//   try {
//     const data = yield call(request, {
//       url: ENDPOINTS.CREATE_BULK_CAMPAIGNS,
//       method: 'post',
//       data: payload,
//     });
//     yield put(
//       enqueueSnackbar({
//         message: 'Bulk Campaigns Uploaded Succesfully!',
//         variant: toasterVariants.success,
//       })
//     );
//     yield put(uploadBulkCreateCampaignsSuccess(data));
//     const campaigns = yield call(request, {
//       url: format(ENDPOINTS.CAMPAIGNS, 1, 25, '', 'startDate', 'DESC'),
//       method: 'get',
//     });
//     yield put(fetchCampaignsSuccess(campaigns, '', 'startDate', 'DESC'));
//   } catch (error) {
//     yield put(
//       enqueueSnackbar({
//         message: error.data.message,
//       })
//     );
//   }
// }

export default function* campaignsSaga() {
  yield takeLatest(FETCH_CAMPAIGNS_REQUEST, fetchCampaignsRequest);
  // yield takeLatest(UPLOAD_CAMPAIGNS_REQUEST, uploadBulkCampaigns);
}
