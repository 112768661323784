import {
  FETCH_AUDIT_LOGS_REQUEST,
  FETCH_AUDIT_LOGS_SUCCESS,
} from './constants';

export function fetchAuditLogsRequest(page, perPage) {
  return {
    type: FETCH_AUDIT_LOGS_REQUEST,
    page,
    perPage,
  };
}

export function fetchAuditLogsSuccess(auditLogs) {
  return {
    type: FETCH_AUDIT_LOGS_SUCCESS,
    auditLogs: auditLogs,
  };
}
