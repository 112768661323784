import { defineMessages } from 'react-intl';

export const scope = 'noticeCards';

export default defineMessages({
  noticeCards: {
    id: `${scope}.noticeCards`,
    defaultMessage: 'Notice Cards',
  },
  noNoticeCards: {
    id: `${scope}.noNoticeCards`,
    defaultMessage: 'No Notice Cards',
  },
  noticeCard: {
    id: `${scope}.noticeCard`,
    defaultMessage: 'Notice Card',
  },
  entityId: {
    id: `${scope}.entityId`,
    defaultMessage: 'ID',
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type',
  },
  subType: {
    id: `${scope}.subType`,
    defaultMessage: 'Sub Type',
  },
  campaignId: {
    id: `${scope}.campaignId`,
    defaultMessage: 'Campaign Id',
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Title',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  footerDescription: {
    id: `${scope}.footerDescription`,
    defaultMessage: 'Footer Description',
  },
  position: {
    id: `${scope}.position`,
    defaultMessage: 'Position',
  },
  createdAt: {
    id: `${scope}.createdAt`,
    defaultMessage: 'Creation date',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
});
