import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { makeSelectBusinessRules } from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import DashboardLayout from 'components/Layout/DashboardLayout';
import BusinessRulesForm from './BusinessRulesForm';
import { fetchBusinessRulesRequest, updateBusinessRules } from './actions';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';

const key = 'businessRulesPage';

function BusinessRulesPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();
  const rules = useSelector(makeSelectBusinessRules());
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(fetchBusinessRulesRequest());
  }, [dispatch]);

  const handleSubmit = (data) => {
    dispatch(updateBusinessRules(data));
  };

  return (
    <div>
      <DashboardLayout>
        <DashboardMainHeader
          titleMain={formatMessage(messages.businessRules)}
        />
        <BusinessRulesForm data={rules} onSubmit={handleSubmit} />
      </DashboardLayout>
    </div>
  );
}

export default BusinessRulesPage;
