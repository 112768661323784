import {
  CREATE_OBJECTIVE_REQUEST,
  CREATE_OBJECTIVE_SUCCESS,
  FETCH_OBJECTIVE_CATEGORIES_LIST_SUCCESS,
  FETCH_OOBJECTIVE_CATEGORIES_LIST_REQUEST,
} from './constants';

export function createObjectiveRequest(objectiveData) {
  return {
    type: CREATE_OBJECTIVE_REQUEST,
    objectiveData,
  };
}

export function createObjectiveSuccess(objective) {
  return {
    type: CREATE_OBJECTIVE_SUCCESS,
    objective,
  };
}

export function fetchObjectiveCategoriesListRequest() {
  return {
    type: FETCH_OOBJECTIVE_CATEGORIES_LIST_REQUEST,
  };
}

export function fetchObjectiveCategoriesListSuccess(objectiveCategories) {
  return {
    type: FETCH_OBJECTIVE_CATEGORIES_LIST_SUCCESS,
    categories: objectiveCategories,
  };
}
