import produce from 'immer';
import {
  DELETE_NOTICE_CARD_SUCCESS,
  FETCH_NOTICE_CARDS_SUCCESS,
  FETCH_TOUR_CAMPAIGNS_SUCCESS,
  TOGGLE_NOTICE_CARD_ACTIVE_STATUS_SUCCESS,
  UPLOAD_NOTICE_CARD_SUCCESS,
} from './constants';

export const initialState = {
  noticeCards: {
    data: [],
    perPage: null,
    page: null,
    total: null,
    search: '',
  },
  tourCampaigns: {
    data: [],
  },
};

const noticeCardsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_NOTICE_CARDS_SUCCESS:
        draft.noticeCards.data = action.cards.data;
        draft.noticeCards.perPage = action.cards.perPage;
        draft.noticeCards.page = action.cards.page;
        draft.noticeCards.total = action.cards.total;
        draft.noticeCards.search = action.search;
        break;
      case TOGGLE_NOTICE_CARD_ACTIVE_STATUS_SUCCESS:
        draft.noticeCards.data = draft.noticeCards.data.map((card) =>
          card.id === action.id ? { ...card, isActive: !card.isActive } : card
        );
        break;
      case DELETE_NOTICE_CARD_SUCCESS:
        draft.noticeCards.data = draft.noticeCards.data.filter(
          (card) => card.id !== action.id
        );
        break;
      case UPLOAD_NOTICE_CARD_SUCCESS:
        draft.noticeCards.data = [action.data, ...draft.noticeCards.data];
        break;
      case FETCH_TOUR_CAMPAIGNS_SUCCESS:
        draft.tourCampaigns.data = action.campaigns;
        break;
      default:
        break;
    }
  });

export default noticeCardsReducer;
