import React, { useEffect, useState } from 'react';
import {
  getNumOfPages,
  getPageArray,
  getVisiblePageNumbers,
} from 'utils/pagination';
import styles from './PaginationBar.module.scss';
import _ from 'lodash';
import { PAGINATION } from 'constants/tables';
import PaginationBarPresentation from './PaginationBar.presentation';

const PaginationBar = ({ resource, loadPage }) => {
  const { page, total, perPage } = resource || [];
  const totalPages = Math.ceil(total / perPage);

  const [pageArray, setPageArray] = useState([]);
  const [visiblePageNumbers, setVisiblePageNumbers] = useState([]);

  useEffect(() => {
    if (total) {
      const numOfPages = getNumOfPages(total, perPage);
      const pageArray = getPageArray(numOfPages);
      setPageArray(pageArray);
    }
  }, [total, perPage]);

  useEffect(() => {
    const visiblePageNumbersArray = getVisiblePageNumbers(
      PAGINATION.NUM_OF_VISIBLE_PAGE_NUMBERS,
      pageArray,
      page
    );
    setVisiblePageNumbers(visiblePageNumbersArray);
  }, [page, pageArray]);

  const renderPageNumbers = (pageNumbers, page, totalPages) => {
    return _.map(pageNumbers, (pageNumber) => {
      const aStyle =
        page === pageNumber
          ? styles.paginationFooterBtnSelected
          : styles.paginationFooterBtn;

      if ((pageNumber !== 1) === (pageNumber !== totalPages)) {
        return (
          <div className={styles.numberWrap}>
            <a
              className={aStyle}
              key={pageNumber}
              onClick={() => loadPage(pageNumber)}
            >
              <span>{pageNumber}</span>
            </a>
          </div>
        );
      }
    });
  };

  const loadPreviousPage = () => {
    if (page > 1) {
      loadPage(page - 1);
    }
  };

  const loadNextPage = () => {
    if (page < totalPages) {
      loadPage(page + 1);
    }
  };

  return (
    <PaginationBarPresentation
      loadPreviousPage={loadPreviousPage}
      loadNextPage={loadNextPage}
      renderPageNumbers={renderPageNumbers}
      visiblePageNumbers={visiblePageNumbers}
      loadPage={loadPage}
      totalPages={totalPages}
      page={page}
    />
  );
};

export default PaginationBar;
