import produce from 'immer';

import {
  UPLOAD_STARTER_SUCCESS,
  FETCH_STARTER_CAMPAIGNS_SUCCESS,
  CHANGE_PRIORITY_SUCCESS,
  DELETE_STARTER_CAMPAING_SUCCESS,
} from './constants';

export const initialState = {
  starter: null,
  campaigns: {
    data: [],
    perPage: null,
    page: null,
    total: null,
    search: '',
    sortBy: '',
    order: '',
  },
};

/* eslint-disable default-case, no-param-reassign */
const starterPageReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case UPLOAD_STARTER_SUCCESS:
        draft.starter = action.starter;
        break;
      case FETCH_STARTER_CAMPAIGNS_SUCCESS:
        draft.campaigns.perPage = action.campaigns.perPage;
        draft.campaigns.page = action.campaigns.page;
        draft.campaigns.total = action.campaigns.total;
        draft.campaigns.search = action.search;
        draft.campaigns.sortBy = action.sortBy;
        draft.campaigns.order = action.order;
        draft.campaigns.data = action.campaigns.data;
        break;
      case CHANGE_PRIORITY_SUCCESS:
        let newCampaigns = state.campaigns.data.map((campaign) =>
          campaign.id === action.campaign.id ? action.campaign : campaign
        );
        draft.campaigns.data = newCampaigns;
        break;
      case DELETE_STARTER_CAMPAING_SUCCESS:
        let arr = state.campaigns.data.filter(
          (campaign) => campaign.id !== action.campaign.id
        );
        draft.campaigns.data = arr;
        break;
    }
  });

export default starterPageReducer;
