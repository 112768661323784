export const LIST_ADVERTISERS = 'list_admin_advertisers';
export const GET_ADVERTISERS = 'get_admin_advertisers';
export const CREATE_ADVERTISERS = 'create_admin_advertisers';
export const UPDATE_ADVERTISERS = 'update_admin_advertisers';
export const DELETE_ADVERTISERS = 'delete_admin_advertisers';
export const ADVERTISERS_GOD_MODE = 'impersonate';
export const GENERATE_EARLY_ACCESS_CODE = 'generate_early_access_code';
export const LIST_CAMPAIGNS = 'list_admin_campaigns';
export const GET_CAMPAIGNS = 'get_admin_campaigns';
export const UPDATE_CAMPAIGNS = 'update_admin_campaigns';
export const DOWNLOAD_CAMPAIGNS_ANALYTICS =
  'get_campaign_report_by_campaign_id';
export const LIST_USERS = 'list_admin_users';
export const GET_USERS = 'get_admin_users';
export const CREATE_USERS = 'create_admin_users';
export const UPDATE_USERS = 'update_admin_users';
export const DELETE_USERS = 'delete_admin_users';
export const BLOCK_USERS = 'update_kick_user';
export const LIST_USERS_CAMPAIGNS = 'list_users_campaigns';
export const SET_AS_STAFF_USERS = 'update_set_users_as_staff';
export const LIST_BUSINESS_RULES_VARIABLES =
  'list_admin_business_rules_variables';
export const UPDATE_BUSINESS_RULES_VARIABLES =
  'update_admin_business_rules_variables';
export const LIST_AUDIT_LOGS = 'list_audit_logs';
export const LIST_LOGS = 'list_log';
export const LIST_EYCROWD_TASKS = 'list_admin_supervisors_default_tasks';
export const CREATE_EYCROWD_TASKS = 'create_admin_supervisors_default_tasks';
export const DELETE_EYCROWD_TASKS = 'delete_admin_supervisors_default_tasks';
export const LIST_OBJECTIVES = 'list_objective_by_categories';
export const CREATE_OBJECTIVES = 'create_objective';
export const DELETE_OBJECTIVES = 'delete_objective';
export const LIST_INSTANT_CAMPAIGNS = 'list_admin_starter_campaigns';
export const CREATE_INSTANT_CAMPAIGNS = 'create_starter_campaign';
export const DELETE_INSTANT_CAMPAIGNS = 'delete_admin_starter_campaigns';
export const LIST_TEMPLATES = 'list_campaign_templates';
export const CREATE_TEMPLATES = 'create_campaign_templates';
export const DELETE_TEMPLATES = 'delete_campaign_templates';
export const LIST_TOUR_CAMPAIGNS = 'list_tour_campaign';
export const CREATE_TOUR_CAMPAIGNS = 'create_tour_campaign';
export const UPDATE_TOUR_CAMPAIGNS = 'update_tour_campaigns';
export const DELETE_TOUR_CAMPAIGNS = 'delete_tour_campaign';
export const LIST_COUPONS = 'list_coupon';
export const GET_COUPONS = 'get_coupons';
export const CREATE_COUPONS = 'create_coupon';
export const DELETE_COUPONS = 'delete_coupon';
export const CREATE_TRANSFERS = 'create_transfer_to_user';
export const LIST_SUPERVISORS_TASKS = 'list_supervisors_tasks';
export const CREATE_SUPERVISORS_TASKS = 'create_supervisors_tasks';
export const DELETE_SUPERVISORS_TASKS = 'delete_supervisors_tasks';
export const CHANGE_PASSWORD = 'create_change_admin_password';
export const LIST_ADMINISTRATORS = 'list_admin_administrators';
export const CREATE_ADMINISTRATORS = 'create_admin_administrators';
export const UPDATE_ADMINISTRATORS = 'update_admin_administrators';
export const DELETE_ADMINISTRATORS = 'delete_admin_administrators';
export const LIST_PERMISSIONS = 'list_permissions';
export const UPDATE_PERMISSIONS = 'update_permissions';
export const GET_STAFF_ACTIVITIES = 'list_staff_activity';
export const UPDATE_FEED_ITEM_PRIORITY = 'update_admin_feed_priority';
export const CREATE_AI_PROMPTS = 'create_ai_prompts';
export const CREATE_BULK_CAMPAIGNS = 'create_bulk_campaigns';
export const CREATE_BRAND_STORY_SUMMARY = 'create_brand_story_summary';
export const CREATE_CAMPAIGN_SUGGESTIONS = 'create_campaign_suggestions';
export const LIST_AI_PROMPT_LOGS = 'list_ai_prompt_logs';
export const LIST_NOTICE_CARDS = 'list_notice_card';
export const CREATE_NOTICE_CARD = 'create_notice_card';
