import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useInjectReducer } from 'utils/injectReducer';
import { makeSelectNotifications } from './selectors';
import { removeSnackbar } from './actions';
import reducer from './reducer';
import { ReactComponent as CloseIcon } from 'assets/images/close.svg';
import messages from './../../messages.js';
import toasterVariants from '../../constants/toasterVariants';

const key = 'notifier';

export function Notifier({ enqueueSnackbar, closeSnackbar }) {
  useInjectReducer({ key, reducer });
  const displayed = useRef([]);

  const dispatch = useDispatch();
  const notifications = useSelector(makeSelectNotifications());

  const { formatMessage } = useIntl();

  const renderMessage = (message) => {
    if (message?.constructor === Object) {
      return formatMessage(message);
    }

    return message;
  };

  const closeIcon = (key) => {
    return (
      <button
        title={formatMessage(messages.close)}
        className="Qsa3au2gGYhs57eE"
        onClick={() => closeSnackbar(key)}
      >
        <CloseIcon />
      </button>
    );
  };

  const defaultOptions = {
    action: closeIcon,
    variant: toasterVariants.error,
    autoHideDuration: 10000,
  };

  useEffect(() => {
    if (!notifications.length) {
      displayed.current = [];
      return;
    }

    notifications.forEach(
      ({ key, variant, message, dismissed, options = defaultOptions }) => {
        if (displayed.current.includes(key)) return;

        if (variant) {
          options.variant = variant;
        }

        if (dismissed) {
          closeSnackbar(key);
          dispatch(removeSnackbar(key));
        } else {
          enqueueSnackbar(renderMessage(message), {
            key,
            ...options,
            onClose: (event, reason, key) => {
              if (options.onClose) {
                options.onClose(event, reason, key);
              }
              dispatch(removeSnackbar(key));
            },
          });

          storeDisplayed(key);
        }
      }
    );
  });

  const storeDisplayed = (id) => {
    displayed.current = [...displayed.current, id];
  };

  return null;
}

Notifier.propTypes = {
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
};

export default withSnackbar(Notifier);
