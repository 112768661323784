export const BULKCAMPAIGNS = '[Bulk Campaigns Page]';

export const CREATE_BRAND_SUMMARY_AND_SOCIALS_REQUEST = `${BULKCAMPAIGNS} CREATE_BRAND_SUMMARY_AND_SOCIALS_REQUEST`;
export const CREATE_BRAND_SUMMARY_AND_SOCIALS_SUCCESS = `${BULKCAMPAIGNS} CREATE_BRAND_SUMMARY_AND_SOCIALS_SUCCESS`;
export const CREATE_BRAND_SUMMARY_AND_SOCIALS_ERROR = `${BULKCAMPAIGNS} CREATE_BRAND_SUMMARY_AND_SOCIALS_ERROR`;
export const REORGANIZE_JSON_DATA = `${BULKCAMPAIGNS} REORGANIZE_JSON_DATA`;
export const CREATE_CAMPAIGN_SUGGESTIONS_REQUEST = `${BULKCAMPAIGNS} CREATE_CAMPAIGN_SUGGESTIONS_REQUEST`;
export const CREATE_CAMPAIGN_SUGGESTIONS_SUCCESS = `${BULKCAMPAIGNS} CREATE_CAMPAIGN_SUGGESTIONS_SUCCESS`;
export const CREATE_CAMPAIGN_SUGGESTIONS_ERROR = `${BULKCAMPAIGNS} CREATE_CAMPAIGN_SUGGESTIONS_ERROR`;
export const CREATE_BULK_CAMPAIGNS_REQUEST = `${BULKCAMPAIGNS} CREATE_BULK_CAMPAIGNS_REQUEST`;
export const CREATE_BULK_CAMPAIGNS_SUCCESS = `${BULKCAMPAIGNS} CREATE_BULK_CAMPAIGNS_SUCCESS`;
export const CREATE_BULK_CAMPAIGNS_ERROR = `${BULKCAMPAIGNS} CREATE_BULK_CAMPAIGNS_ERROR`;
export const CHANGE_SOCIAL_MEDIA_URLS = `${BULKCAMPAIGNS} CHANGE_SOCIAL_MEDIA_URLS`;
