import { defineMessages } from 'react-intl';

export const scope = 'campaigns';

export default defineMessages({
  campaigns: {
    id: `${scope}.campaigns`,
    defaultMessage: 'Campaigns',
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type',
  },
  companyName: {
    id: `${scope}.company_name`,
    defaultMessage: 'Company Name',
  },
  campaignName: {
    id: `${scope}.campaign_name`,
    defaultMessage: 'Camapaign Name',
  },
  startDate: {
    id: `${scope}.start_date`,
    defaultMessage: 'Start Date',
  },
  endDate: {
    id: `${scope}.end_date`,
    defaultMessage: 'End Date',
  },
  minEyvocates: {
    id: `${scope}.min_eyvocates`,
    defaultMessage: 'Min EyVocates',
  },
  minEyvocateAge: {
    id: `${scope}.min_eyvocate_age`,
    defaultMessage: 'Min EyVocate Age',
  },
  maxEyvocates: {
    id: `${scope}.max_eyvocates`,
    defaultMessage: 'Max EyVocates',
  },
  maxEyvocateAge: {
    id: `${scope}.max_eyvocate_age`,
    defaultMessage: 'Max EyVocate Age',
  },
  eyvocatesBeIn: {
    id: `${scope}.eyvocates_be_in`,
    defaultMessage: 'EyVocates Be In',
  },
  campaignUsers: {
    id: `${scope}.campaign_users`,
    defaultMessage: 'Campaign Users',
  },
  pricePerHour: {
    id: `${scope}.price_per_hour`,
    defaultMessage: 'Price Per Hour',
  },
  applicationDeadline: {
    id: `${scope}.application_deadline`,
    defaultMessage: 'Application Deadline',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Actions',
  },
  noCampaigns: {
    id: `${scope}.text.no_campaigns`,
    defaultMessage: 'No Campaigns',
  },
  eyvocateAge: {
    id: `${scope}.eyvocate_age`,
    defaultMessage: 'Age',
  },
  duration: {
    id: `${scope}.duration`,
    defaultMessage: 'Duration',
  },
  genders: {
    id: `${scope}.genders`,
    defaultMessage: 'Genders',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'City',
  },
  eyvocates: {
    id: `${scope}.eyvocates`,
    defaultMessage: 'EyVocates',
  },
  mostlyMale: {
    id: `${scope}.mostlyMale`,
    defaultMessage: 'Mostly male',
  },
  mostlyFemale: {
    id: `${scope}.mostlyFemale`,
    defaultMessage: 'Mostly female',
  },
  allGenders: {
    id: `${scope}.allGenders`,
    defaultMessage: 'All genders',
  },
  onlyMale: {
    id: `${scope}.onlyMale`,
    defaultMessage: 'Only male',
  },
  onlyFemale: {
    id: `${scope}.onlyFemale`,
    defaultMessage: 'Only female',
  },
});
