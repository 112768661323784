import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import { fetchLogsSuccess } from './actions';
import { FETCH_LOGS_REQUEST } from './constants';
import { ENDPOINTS } from '../../constants';
import { format } from 'util';
import { enqueueSnackbar } from 'containers/Notifier/actions';

export function* fetchAuditLogsRequest({ page, perPage, logType, search }) {
  try {
    const auditLogs = yield call(request, {
      url: format(ENDPOINTS.LOGS, page, perPage, logType, search),
      method: 'get',
    });
    yield put(fetchLogsSuccess(auditLogs, logType, search));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export default function* auditLogsSaga() {
  yield takeLatest(FETCH_LOGS_REQUEST, fetchAuditLogsRequest);
}
