import {
  FETCH_TASKS_LIST_REQUEST,
  FETCH_TASKS_LIST_SUCCESS,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
} from './constants';

export function fetchTasksListRequest() {
  return {
    type: FETCH_TASKS_LIST_REQUEST,
  };
}

export function fetchTasksListSuccess(tasks) {
  return {
    type: FETCH_TASKS_LIST_SUCCESS,
    tasks,
  };
}

export function deleteTaskRequest(id) {
  return {
    type: DELETE_TASK_REQUEST,
    id,
  };
}

export function deleteTaskSuccess(id) {
  return {
    type: DELETE_TASK_SUCCESS,
    id,
  };
}
