import React, { useEffect, useRef, useState } from 'react';
import { Modal } from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import styles from './CreateNoticeCardModal.module.scss';
import { InputField, SelectField } from 'components/Forms';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTourCampaignsRequest } from './actions';
import { makeSelectTourCampaigns } from './selectors';

const initialValues = {
  type: '',
  subType: '',
  title: '',
  description: '',
  footerDescription: '',
  url: '',
  file: null,
};

const validationSchema = Yup.object({
  file: Yup.mixed().required(),
  type: Yup.string().required(),
  position: Yup.number().integer().min(0).required(),
  title: Yup.string().required(),
  subType: Yup.string().when('type', {
    is: 'in-app',
    then: Yup.string().required(),
  }),
  description: Yup.string().required(),
  footerDescription: Yup.string(),
  url: Yup.string().when('type', {
    is: 'link',
    then: Yup.string()
      .required()
      .matches(
        /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/
      ),
  }),
  campaignId: Yup.number().when('type', {
    is: 'demo',
    then: Yup.number().required(),
  }),
});

const CreateNoticeCardModal = ({
  modalOpen,
  onClose,
  onUpload,
  close,
  previewImage,
  setPreviewImage,
}) => {
  const fileInputRef = useRef(null);
  const tourCampaigns = useSelector(makeSelectTourCampaigns());
  const { data } = tourCampaigns || [];
  const dispatch = useDispatch();

  useEffect(() => {
    if (modalOpen) {
      dispatch(fetchTourCampaignsRequest());
    }
  }, [modalOpen, dispatch]);

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setFieldValue('file', file);
      const reader = new FileReader();
      reader.onloadend = () => setPreviewImage(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (values) => {
    onUpload(values);
    close();
  };

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalInner}>
          <div className={styles.modalTop}>
            <p className={styles.title}>Create Notice Card</p>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values }) => (
              <Form className={styles.modalContent}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, setFieldValue)}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                />
                <div className={styles.formWrapper}>
                  {!previewImage && (
                    <div
                      className={styles.fileInputButton}
                      onClick={() => fileInputRef.current.click()}
                    />
                  )}
                  {previewImage && (
                    <img
                      src={previewImage}
                      alt="Preview"
                      className={styles.previewImage}
                      onClick={() => fileInputRef.current.click()}
                    />
                  )}
                  <SelectField
                    name="type"
                    options={['in-app', 'demo', 'link'].map((type) => ({
                      id: type,
                      label: type,
                    }))}
                    label="Type"
                    placeholder="Select Type"
                    onChange={(event) => {
                      setFieldValue('type', event.target.value);
                    }}
                    required
                  />
                  {values.type === 'in-app' && (
                    <SelectField
                      name="subType"
                      options={['auth', 'brands'].map((subType) => ({
                        id: subType,
                        label: subType,
                      }))}
                      label="Sub Type"
                      placeholder="Select Sub Type"
                      onChange={(event) => {
                        setFieldValue('subType', event.target.value);
                      }}
                      required
                    />
                  )}
                  <InputField
                    name="position"
                    type="number"
                    label="Position"
                    placeholder="Enter position"
                    required
                  />
                  <InputField
                    name="title"
                    type="text"
                    label="Title"
                    placeholder="Enter Title"
                    required
                  />
                  <InputField
                    name="description"
                    placeholder="Enter Description"
                    type="text"
                    label="Description"
                    required
                  />
                  <InputField
                    name="footerDescription"
                    placeholder="Enter Footer Description"
                    type="text"
                    label="Footer Description"
                    required
                  />
                  {values.type === 'link' && (
                    <InputField name="url" type="text" label="URL" required />
                  )}
                  {values.type === 'demo' && (
                    <SelectField
                      name="campaignId"
                      options={data.map((campaign) => ({
                        id: campaign.id,
                        label: campaign.name,
                      }))}
                      label="Select Campaign"
                      placeholder="Select Campaign"
                      onChange={(event) => {
                        setFieldValue('campaignId', event.target.value);
                      }}
                      required
                    />
                  )}

                  <button type="submit" className={styles.publishButton}>
                    <p className={styles.publishButtonText}>Submit</p>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default CreateNoticeCardModal;
