export const STAFF_ACTIVITY = '[Staff Activity]';

export const GET_STAFF_ACTIVITY_ATTEMPT = `${STAFF_ACTIVITY} GET_STAFF_ACTIVITY_ATTEMPT`;
export const GET_STAFF_ACTIVITY_SUCCESS = `${STAFF_ACTIVITY} GET_STAFF_ACTIVITY_SUCCESS`;
export const GET_STAFF_ACTIVITY_ERROR = `${STAFF_ACTIVITY} GET_STAFF_ACTIVITY_ERROR`;
export const GET_STAFF_ACTIVITY_RESET = `${STAFF_ACTIVITY} GET_STAFF_ACTIVITY_RESET`;

export const FETCH_STAFF_USERS_LIST_REQUEST = `${STAFF_ACTIVITY} FETCH_STAFF_USERS_LIST_REQUEST`;
export const FETCH_STAFF_USERS_LIST_SUCCESS = `${STAFF_ACTIVITY} FETCH_STAFF_USERS_LIST_SUCCESS`;

export const SET_SELECTED_STAFF_USER = `${STAFF_ACTIVITY} SET_SELECTED_STAFF_USER`;
