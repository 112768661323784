import {
  CHANGE_PRIORITY_REQUEST,
  CHANGE_PRIORITY_SUCCESS,
  DELETE_STARTER_CAMPAING_REQUEST,
  DELETE_STARTER_CAMPAING_SUCCESS,
  FETCH_STARTER_CAMPAIGNS_REQUEST,
  FETCH_STARTER_CAMPAIGNS_SUCCESS,
  UPLOAD_STARTER_REQUEST,
  UPLOAD_STARTER_SUCCESS,
} from './constants';
import { ORDER } from '../../constants';

export function uploadStarterRequest(payload) {
  return {
    type: UPLOAD_STARTER_REQUEST,
    payload,
  };
}

export function uploadStarterSuccess(starter) {
  return {
    type: UPLOAD_STARTER_SUCCESS,
    starter,
  };
}

export function fetchStarterCampaignsRequest(
  page,
  perPage,
  search = '',
  sortBy = '',
  order = ORDER.ASC
) {
  return {
    type: FETCH_STARTER_CAMPAIGNS_REQUEST,
    page,
    perPage,
    search,
    sortBy,
    order,
  };
}

export function fetchStarterCampaignsSuccess(campaigns, search, sortBy, order) {
  return {
    type: FETCH_STARTER_CAMPAIGNS_SUCCESS,
    campaigns,
    search,
    sortBy,
    order,
  };
}

export function deleteStarterCampaignRequest(campaign) {
  return {
    type: DELETE_STARTER_CAMPAING_REQUEST,
    campaign,
  };
}

export function deleteStarterCampaignSuccess(campaign) {
  return {
    type: DELETE_STARTER_CAMPAING_SUCCESS,
    campaign,
  };
}

export function changeCampaignPriorityRequest(campaign, priority) {
  return {
    type: CHANGE_PRIORITY_REQUEST,
    campaign,
    priority,
  };
}

export function changeCampaignPrioritySuccess(campaign) {
  return {
    type: CHANGE_PRIORITY_SUCCESS,
    campaign,
  };
}
