import { Modal } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApi } from 'utils/request';
import styles from './SingleCampaign.module.scss';
import AsyncSelect from 'react-select/async';
import Button from 'components/Buttons/Button';
import { fetchSingleCampaign } from './actions';
import { enqueueSnackbar } from 'containers/Notifier/actions';

const AddUserModal = ({ modalOpen, onClose, type, campaignId }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [userOptions, setUserOptions] = useState([]);
  const token = useSelector((state) => state.app.token);
  const dispatch = useDispatch();

  const fetchUserOptions = async (term) => {
    const api = getApi();
    const response = await api.get(
      `/admin/users?page=1&perPage=25&search=${term}&sortBy=createdAt&order=DESC`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const retItems = response.data.map((user) => {
      return {
        value: user,
        label: user.username,
      };
    });
    return retItems;
  };

  const addUser = async () => {
    try {
      const api = getApi();
      await api.put(
        `/admin/campaigns/${campaignId}/users/${selectedUser?.id}/add-user`,
        {
          isSupervisor: type === 'EyVocate' ? false : true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(fetchSingleCampaign(campaignId));
      dispatch(enqueueSnackbar({ message: 'Success', variant: 'success' }));
      onClose();
    } catch (err) {
      dispatch(
        enqueueSnackbar({ message: err.data.message, variant: 'error' })
      );
    }
  };

  useEffect(() => {
    const fetchInitOptions = async () => {
      const users = await fetchUserOptions('');
      setUserOptions(users);
    };
    fetchInitOptions();
  }, []);

  return (
    <Modal open={modalOpen} onClose={onClose}>
      <div className={styles.modalWrapper}>
        <div className={styles.modalInner}>
          <div className={styles.modalTop}>
            <p className={styles.title}>
              {type === 'EyVocate' ? 'Add EyVocate' : 'Add EyVisor'}
            </p>
          </div>
          <div className={styles.modalContent}>
            <div className={styles.formWrapper}>
              <div className={styles.formWrap}>
                <div className={styles.inputWrapperSm}>
                  <p>Pick a user</p>
                  <AsyncSelect
                    onInputChange={(e) => {
                      fetchUserOptions(e);
                    }}
                    loadOptions={fetchUserOptions}
                    defaultOptions={userOptions}
                    onChange={(e) => {
                      setSelectedUser(e.value);
                    }}
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        width: '100%',
                      }),
                    }}
                  />
                </div>
                <div className={styles.buttonWrap}>
                  <Button
                    type="submit"
                    disabled={selectedUser === null}
                    title="Submit"
                    alt
                    className={styles.submitButton}
                    onClick={() => {
                      addUser();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddUserModal;
