import produce from 'immer';
import { DELETE_TASK_SUCCESS, FETCH_TASKS_LIST_SUCCESS } from './constants';

export const initialState = {
  tasks: [],
};

/* eslint-disable default-case, no-param-reassign */
const eycrwodTasksReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_TASKS_LIST_SUCCESS:
        draft.tasks = action.tasks;
        break;
      case DELETE_TASK_SUCCESS:
        draft.tasks = draft.tasks.filter((task) => {
          return task.id !== action.id;
        });
        break;
    }
  });

export default eycrwodTasksReducer;
