import produce from 'immer';
import { EYCROWD_ACCESS_TOKEN } from 'utils/constants';
import { getItem } from 'utils/localStorage';
import {
  FETCH_AUTHENTICATED_USER_SUCCESS,
  FETCH_COMMON_DATA_SUCCESS,
  LOGOUT_SUCCESS,
  SESSION_EXPIRED,
  SET_TOKEN,
} from './constants';

export const initialState = {
  token: getItem(EYCROWD_ACCESS_TOKEN) || null,
  user: null,
  permissions: [],
  interests: [],
  disabilities: [],
};

/* eslint-disable default-case */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_AUTHENTICATED_USER_SUCCESS:
        draft.user = action.user;
        if (action.user.permissions.length) {
          draft.permissions = [
            ...action.user.permissions.map((permission) => permission.value),
          ];
        }
        break;
      case LOGOUT_SUCCESS:
      case SESSION_EXPIRED:
        draft.user = null;
        draft.token = null;
        break;
      case SET_TOKEN:
        draft.token = action.token;
        break;
      case FETCH_COMMON_DATA_SUCCESS:
        draft.interests = action.commonData.interests;
        draft.disabilities = action.commonData.disabilities;
        break;
    }
  });

export default appReducer;
