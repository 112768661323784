import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import DashboardLayout from 'components/Layout/DashboardLayout';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import {
  createObjectiveRequest,
  fetchObjectiveCategoriesListRequest,
} from './actions';
import SingleObjectiveForm from './SingleObjectiveForm';
import { useHistory } from 'react-router-dom';
import { makeSelectObjectiveCategories } from './selectors';

const key = 'objective';

function SingleObjectivePage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const categories = useSelector(makeSelectObjectiveCategories());

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchObjectiveCategoriesListRequest());
  }, [dispatch]);

  const handleSubmit = (objectiveData) => {
    dispatch(createObjectiveRequest(objectiveData));
  };

  return (
    <DashboardLayout>
      <DashboardMainHeader cancel onCancel={() => history.goBack()} />
      <SingleObjectiveForm
        onSubmit={handleSubmit}
        categories={categories || []}
      />
    </DashboardLayout>
  );
}

export default SingleObjectivePage;
