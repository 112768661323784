import {
  CREATE_COUPON_ERROR,
  CREATE_COUPON_REQUEST,
  CREATE_COUPON_SUCCESS,
  DELETE_COUPON_REQUEST,
  DELETE_COUPON_SUCCESS,
  EDIT_COUPON_ERROR,
  EDIT_COUPON_REQUEST,
  EDIT_COUPON_SUCCESS,
  FETCH_COUPONS_REQUEST,
  FETCH_COUPONS_SUCCESS,
} from './constants';

export function createCouponRequest(couponData, onSuccess) {
  return {
    type: CREATE_COUPON_REQUEST,
    couponData,
    meta: {
      onSuccess,
    },
  };
}

export function createCouponSuccess(coupon) {
  return {
    type: CREATE_COUPON_SUCCESS,
    payload: coupon,
  };
}

export function createCouponError() {
  return {
    type: CREATE_COUPON_ERROR,
  };
}

export function fetchCouponsRequest(page, perPage) {
  return {
    type: FETCH_COUPONS_REQUEST,
    page,
    perPage,
  };
}

export function fetchCouponsSuccess(coupons) {
  return {
    type: FETCH_COUPONS_SUCCESS,
    coupons,
  };
}

export function deleteCouponRequest(id) {
  return {
    type: DELETE_COUPON_REQUEST,
    id,
  };
}

export function deleteCouponSuccess(id) {
  return {
    type: DELETE_COUPON_SUCCESS,
    id,
  };
}

export function editCouponRequest(id, couponData, onSuccess) {
  return {
    type: EDIT_COUPON_REQUEST,
    id,
    couponData,
    meta: {
      onSuccess,
    },
  };
}

export function editCouponSuccess(id, coupon) {
  return {
    type: EDIT_COUPON_SUCCESS,
    payload: { id, coupon },
  };
}

export function editCouponError() {
  return {
    type: EDIT_COUPON_ERROR,
  };
}
