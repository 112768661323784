import { defineMessages } from 'react-intl';

export const scope = 'single_task_page';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the Single Objective container!',
  },
  objectiveCreated: {
    id: `${scope}.objectiveCreated`,
    defaultMessage: 'Objective successfully created',
  },
  objectiveTitle: {
    id: `${scope}.text.objectiveTitle`,
    defaultMessage: 'Objective title',
  },
  objectiveInfo: {
    id: `${scope}.text.objectiveInfo`,
    defaultMessage: 'Objective info',
  },
  objectiveCategory: {
    id: `${scope}.text.objectiveCategory`,
    defaultMessage: 'Category',
  },
  campaignType: {
    id: `${scope}.text.campaignType`,
    defaultMessage: 'Campaign type',
  },
  createObjective: {
    id: `${scope}.text.createObjective`,
    defaultMessage: 'Create objective',
  },
});
