import { defineMessages } from 'react-intl';

export const scope = 'dashboard_layout';

export default defineMessages({
  logOut: {
    id: `${scope}.text.log_out`,
    defaultMessage: 'Log Out',
  },
  admin: {
    id: `${scope}.text.admin`,
    defaultMessage: 'Admin',
  },
});
