import DashboardLayout from 'components/Layout/DashboardLayout';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { deleteAdminRequest, fetchAdminsRequest } from './actions';
import reducer from './reducer';
import saga from './saga';
import { makeSelectAdmins } from './selectors';
import messages from './messages';
import { canActivate } from 'utils/permissions';
import {
  CREATE_ADMINISTRATORS,
  DELETE_ADMINISTRATORS,
  LIST_ADMINISTRATORS,
  UPDATE_ADMINISTRATORS,
} from 'permisssions';
import ResourceTable from 'components/ResourceTable';
import { formatDate } from 'utils/date';
import { DATE_FORMAT } from '../../constants';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import LoadingSpinner from './../../components/LoadingSpinner/LoadingSpinner.presentation';
import styles from './AdminsPage.module.scss';
import { ReactComponent as recycleBinIcon } from 'assets/images/delete.svg';
import Icon from 'components/Icon';
import EditIcon from 'assets/images/pen.png';
import HandleAdminModal from './HandleAdminModal';
import globalMessages from 'messages';
import { useConfirmationModal } from 'components/ConfirmationModal';

const key = 'admins';

const AdminsPage = () => {
  const { formatMessage } = useIntl();

  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();

  const [openConfirmationModal] = useConfirmationModal();

  const admins = useSelector(makeSelectAdmins());

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const { data, perPage, total, loading } = admins || [];

  const fetchPageAdmins = (pageNumber) => {
    dispatch(fetchAdminsRequest(pageNumber, perPage));
  };

  const handlePerPage = (perPage) => {
    localStorage.setItem('perPage', perPage);
    localStorage.setItem('pageNumberUsers', 1);
    localStorage.setItem('pageNumberCampaigns', 1);
    localStorage.setItem('pageNumberAdvertisers', 1);
    dispatch(fetchAdminsRequest(1, perPage));
  };

  const tableHeader = [
    { key: 'email', label: formatMessage(messages.email) },
    { key: 'firstName', label: formatMessage(messages.firstName) },
    { key: 'lastName', label: formatMessage(messages.lastName) },
    { key: 'createdAt', label: formatMessage(messages.createdAt) },
    { key: 'role', label: formatMessage(messages.role) },
    { key: 'createdBy', label: formatMessage(messages.createdBy) },
  ];

  const onDeleteClick = (adminId) => {
    openConfirmationModal(formatMessage(messages.areYouSureDeleteAdmin), () => {
      dispatch(deleteAdminRequest(adminId));
    });
  };

  useEffect(() => {
    dispatch(
      fetchAdminsRequest(
        1,
        localStorage.getItem('perPage') ? localStorage.getItem('perPage') : 25
      )
    );
  }, [dispatch]);

  return (
    <main>
      <DashboardLayout>
        <DashboardMainHeader
          titleMain={formatMessage(messages.admins)}
          totalRows={total}
          btnText={formatMessage(messages.createAdmin)}
          onClick={() => {
            setModalVisible(true);
          }}
          canAccess={canActivate(CREATE_ADMINISTRATORS)}
        />
        {loading ? (
          <LoadingSpinner className={styles.spinner} />
        ) : (
          <ResourceTable
            rowsNumber={data ? data.length : 0}
            hasMore={data?.length < total}
            hasExtraActions
            resource={admins}
            fetchPage={fetchPageAdmins}
            headerInformation={tableHeader}
            noDataContent={formatMessage(messages.noAdmins)}
            perPage={localStorage.getItem('perPage')}
            handlePerPage={handlePerPage}
            canAccess={canActivate(LIST_ADMINISTRATORS)}
          >
            {data?.map((el) => (
              <Fragment key={el.id}>
                <TableColumnField data={el.email} />
                <TableColumnField data={el.firstName} />
                <TableColumnField data={el.lastName} />
                <TableColumnField
                  data={formatDate(
                    el.createdAt,
                    DATE_FORMAT.MONTH_DAY_YEAR_TIME
                  )}
                />
                <TableColumnField
                  data={formatMessage(globalMessages.adminPermissions[el.role])}
                />
                <TableColumnField
                  className={styles.createByText}
                  data={el.createdByAdmin.email}
                />

                <TableColumnField
                  data={
                    <div className={styles.actionsWrap}>
                      {canActivate(DELETE_ADMINISTRATORS) && (
                        <button
                          className={styles.actionButton}
                          onClick={() => {
                            onDeleteClick(el.id);
                          }}
                        >
                          <Icon icon={recycleBinIcon} size={'sm20'} />
                        </button>
                      )}
                      {canActivate(UPDATE_ADMINISTRATORS) && (
                        <button
                          className={styles.actionButton}
                          onClick={() => {
                            setSelectedAdmin(el);
                            setModalVisible(true);
                          }}
                        >
                          <img
                            className={styles.editIcon}
                            src={EditIcon}
                            alt="editIcon"
                          />
                        </button>
                      )}
                    </div>
                  }
                />
              </Fragment>
            ))}
          </ResourceTable>
        )}
      </DashboardLayout>
      <HandleAdminModal
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        selectedAdmin={selectedAdmin}
        setSelectedAdmin={setSelectedAdmin}
      />
    </main>
  );
};

export default AdminsPage;
