import { ENDPOINTS } from '../../constants';
import request from 'utils/request';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createCouponError,
  createCouponSuccess,
  deleteCouponSuccess,
  editCouponError,
  editCouponSuccess,
  fetchCouponsSuccess,
} from './actions';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import {
  CREATE_COUPON_REQUEST,
  DELETE_COUPON_REQUEST,
  EDIT_COUPON_REQUEST,
  FETCH_COUPONS_REQUEST,
} from './constants';
import { format } from 'util';
import toasterVariants from 'constants/toasterVariants';

export function* createCouponRequest({ couponData, meta: { onSuccess } }) {
  try {
    const newCoupon = yield call(request, {
      url: ENDPOINTS.CREATE_COUPON,
      method: 'post',
      data: couponData,
    });
    yield put(createCouponSuccess(newCoupon));
    onSuccess();
  } catch (error) {
    yield put(createCouponError());
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* editCouponRequest({ id, couponData, meta: { onSuccess } }) {
  try {
    const newCoupon = yield call(request, {
      url: ENDPOINTS.COUPON_SINGLE.replace('{id}', id),
      method: 'put',
      data: couponData,
    });
    yield put(editCouponSuccess(id, newCoupon));
    onSuccess();
  } catch (error) {
    yield put(editCouponError());
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* fetchCoupons({ page, perPage }) {
  try {
    const coupons = yield call(request, {
      url: format(ENDPOINTS.FETCH_COUPONS, page, perPage),
      method: 'get',
    });
    yield put(fetchCouponsSuccess(coupons));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export function* deleteCouponRequest({ id }) {
  try {
    yield call(request, {
      url: ENDPOINTS.COUPON_SINGLE.replace('{id}', id),
      method: 'delete',
    });
    yield put(
      enqueueSnackbar({
        message: 'Coupon successfully deleted',
        variant: toasterVariants.success,
      })
    );
    yield put(deleteCouponSuccess(id));
  } catch (error) {
    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export default function* couponsSaga() {
  yield takeLatest(CREATE_COUPON_REQUEST, createCouponRequest);
  yield takeLatest(FETCH_COUPONS_REQUEST, fetchCoupons);
  yield takeLatest(DELETE_COUPON_REQUEST, deleteCouponRequest);
  yield takeLatest(EDIT_COUPON_REQUEST, editCouponRequest);
}
