import React from 'react';
import styles from './CampaignUserList.module.scss';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import messages from './messages';
import DropdownMenu from '../../DropdownMenu';
import Icon from 'components/Icon';
import { ReactComponent as threeDots } from 'assets/images/three-dots.svg';

const CampaignUserList = ({
  title,
  campaignUsers,
  onRemoveJoinedUserFromCampaign,
  onChangeUserRole,
  noResultMessage,
  showDropdown = true,
  onAdd,
  addActionText,
  addActionVisible,
}) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.titleWrap}>
        <p className={styles.title}>{title}</p>
        {addActionVisible && onAdd && (
          <p
            className={styles.addAction}
            onClick={() => {
              onAdd();
            }}
          >
            {addActionText}
          </p>
        )}
      </div>
      <ul className={styles.list}>
        {campaignUsers?.length > 0 ? (
          campaignUsers.map((campaignUser) => (
            <li className={styles.listItem} key={campaignUser.userId}>
              <span className={styles.name}>{campaignUser.user.username}</span>
              {showDropdown && !campaignUser.finishedAt ? (
                <DropdownMenu
                  buttonContent={<Icon icon={threeDots} size={'sm20'} />}
                >
                  <div className={styles.dropdownWrapper}>
                    {!campaignUser.finishedAt && (
                      <DropdownMenu.Item>
                        <button
                          className={styles.dropdownButton}
                          onClick={() => {
                            onRemoveJoinedUserFromCampaign(campaignUser.userId);
                          }}
                        >
                          {formatMessage(messages.remove)}
                        </button>
                      </DropdownMenu.Item>
                    )}
                    <DropdownMenu.Item>
                      <button
                        className={styles.dropdownButton}
                        onClick={() => {
                          onChangeUserRole(
                            campaignUser.userId,
                            !campaignUser.isSupervisor
                          );
                        }}
                      >
                        {campaignUser.isSupervisor
                          ? formatMessage(messages.demote)
                          : formatMessage(messages.promote)}
                      </button>
                    </DropdownMenu.Item>
                  </div>
                </DropdownMenu>
              ) : null}
            </li>
          ))
        ) : (
          <li className={styles.listItem}>{noResultMessage}</li>
        )}
      </ul>
    </div>
  );
};

export default CampaignUserList;

CampaignUserList.propTypes = {
  title: PropTypes.string,
  showDropdown: PropTypes.bool,
  campaignUsers: PropTypes.array,
  onRemoveJoinedUserFromCampaign: PropTypes.func,
};
