export const TABLES = {
  NUMBER_OF_ITEMS_PER_PAGE: 25,
};

export const ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const PAGINATION = { NUM_OF_VISIBLE_PAGE_NUMBERS: 3 };
