import DashboardLayout from 'components/Layout/DashboardLayout';
import React, { Fragment, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { formatDate } from 'utils/date';
import { getApi } from 'utils/request';
import messages from './messages';
import styles from './StarterContent.module.scss';
import globalMessages from 'messages';
import TableColumnField from 'components/ResourceTable/TableColumnField';
import ResourceTable from 'components/ResourceTable';
import toasterVariants from 'constants/toasterVariants';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import { ReactComponent as copyIcon } from 'assets/images/copy.svg';
import Icon from 'components/Icon';
import Gallery from 'components/Gallery/Gallery';

const StarterContentPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { id } = useParams();
  const token = useSelector((state) => state.app.token);
  const [campaign, setCampaign] = useState(null);
  const [gallery, setGallery] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchStarterContent = async () => {
      const api = getApi();
      const response = await api.get(`admin/campaigns/${id}/starter-content`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCampaign(response.campaign);
      setUsers(response.users);
      setTasks(response.tasks);
      setGallery(response.gallery);
    };

    if (id) {
      fetchStarterContent();
    }
  }, [id, token]);

  const tableHeader = [
    {
      key: 'username',
      label: 'Username',
    },
    {
      key: 'fullName',
      label: 'Full name',
    },
    {
      key: 'createdAt',
      label: 'Created:',
    },
    {
      key: 'answer',
      label: 'Answer',
    },
  ];

  return (
    <DashboardLayout>
      <p className={styles.title} style={{ fontSize: 36, fontWeight: '700' }}>
        {campaign && campaign.name}
      </p>
      {campaign && (
        <div className={styles.container}>
          <h6 className={styles.title}>{formatMessage(messages.title)}</h6>
          <div className={styles.wrapper}>
            <div className={styles.infoRow}>
              <p className={styles.label}>
                {formatMessage(messages.campaignType)}
              </p>
              <p className={styles.infoText}>
                {campaign.type && formatMessage(globalMessages[campaign.type])}
              </p>
            </div>
            <div className={styles.infoRow}>
              <p className={styles.label}>
                {formatMessage(messages.companyValues)}
              </p>
              <p className={styles.infoText}>{campaign.companyValues}</p>
            </div>
            <div className={styles.infoRow}>
              <p className={styles.label}>
                {formatMessage(messages.description)}
              </p>
              <p className={styles.infoText}>{campaign.description}</p>
            </div>
            <div className={styles.infoRow}>
              <p className={styles.label}>
                {formatMessage(messages.createdAt)}
              </p>
              <p className={styles.infoText}>
                {formatDate(campaign.createdAt, 'MMM d, yyyy HH:mmaa')}
              </p>
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.infoRow}>
              <p className={styles.label}>
                {formatMessage(messages.eyvocates)}
              </p>
              <p className={styles.infoText}>{campaign.maxEyvocates}</p>
            </div>
            <div className={styles.infoRow}>
              <p className={styles.label}>
                {formatMessage(messages.numOfUsersWhoFinished)}
              </p>
              <p className={styles.infoText}>{users.length}</p>
            </div>
          </div>
          <div className={styles.infoRow}>
            <p className={styles.label}>
              {formatMessage(messages.coolDownPeriod)}
            </p>
            <p className={styles.infoText}>{`${campaign.coolDownPeriod}`}</p>
          </div>
        </div>
      )}
      {!!users.length && (
        <div className={styles.container}>
          <h6 className={styles.title}>
            {formatMessage(messages.whoFinishedCampaing)}
          </h6>

          <div className={styles.usersContainer}>
            <div className={styles.usernameContainer}>Username:</div>
            <div className={styles.users}>
              {users.map((user) => {
                return <p key={user}>@{user}</p>;
              })}
            </div>
          </div>
        </div>
      )}
      {tasks.map((data) => {
        return (
          <>
            <h6
              className={styles.title}
              style={{ marginTop: 30 }}
            >{`Task: ${data.taskSurveyQuestion.text}`}</h6>
            <ResourceTable
              key={data.id}
              rowsNumber={data.answers.length}
              hasExtraActions
              headerInformation={tableHeader}
              noDataContent={'No answers'}
              hidePagination
              canAccess={true}
              className={styles.table}
            >
              {data.answers.map((answer) => {
                return (
                  <Fragment key={answer.user.username}>
                    <TableColumnField data={`@${answer.user.username}`} />
                    <TableColumnField
                      data={answer.user.firstName + ' ' + answer.user.lastName}
                    />
                    <TableColumnField
                      data={formatDate(answer.createdAt, 'MM/dd/yyyy')}
                    />
                    <TableColumnField data={answer.text} />
                    <TableColumnField
                      data={
                        <div
                          className={styles.actionButton}
                          onClick={() => {
                            dispatch(
                              enqueueSnackbar({
                                message: 'Copied',
                                variant: toasterVariants.success,
                              })
                            );
                            navigator.clipboard.writeText(answer.text);
                          }}
                        >
                          <Icon icon={copyIcon} size={'sm20'} />
                        </div>
                      }
                    />
                  </Fragment>
                );
              })}
            </ResourceTable>
          </>
        );
      })}
      {!!gallery.length && (
        <div style={{ marginTop: 30 }}>
          <Gallery galleryTitle={'Gallery'} gallery={gallery} showUsernames />
        </div>
      )}
    </DashboardLayout>
  );
};

export default StarterContentPage;
