import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectBusinessRulesPageDomain = (state) =>
  state.businessRulesPage || initialState;

const makeSelectBusinessRules = () =>
  createSelector(
    selectBusinessRulesPageDomain,
    (substate) => substate.businessRules
  );

export { makeSelectBusinessRules };
