import produce from 'immer';

import {
  DELETE_OBJECTIVE_SUCCESS,
  FETCH_OBJECTIVES_LIST_SUCCESS,
} from './constants';

export const initialState = {
  objectives: [],
};

const objectivesReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_OBJECTIVES_LIST_SUCCESS:
        draft.objectives = action.objectives;
        break;
      case DELETE_OBJECTIVE_SUCCESS:
        draft.objectives = draft.objectives.filter((objective) => {
          return objective.id !== action.id;
        });
        break;
      default:
        return state;
    }
  });

export default objectivesReducer;
