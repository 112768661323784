import { takeLatest, put, call } from 'redux-saga/effects';
import request from 'utils/request';
import { createTaskSuccess } from './actions';
import { CREATE_TASK_REQUEST } from './constants';
import { ENDPOINTS } from '../../constants';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import messages from './messages';
import { push } from 'connected-react-router';
import { SUPERVISOR_TASKS } from 'routes';
import toasterVariants from '../../constants/toasterVariants';

export function* createTaskRequest({ taskData }) {
  try {
    const newTask = yield call(request, {
      url: ENDPOINTS.SUPERVISOR_TASKS,
      method: 'post',
      data: taskData,
    });
    yield put(push(SUPERVISOR_TASKS));
    yield put(createTaskSuccess(newTask));
    yield put(
      enqueueSnackbar({
        message: messages.taskCreated,
        variant: toasterVariants.success,
      })
    );
  } catch (error) {
    console.error(error);

    yield put(
      enqueueSnackbar({
        message: error.data.message,
      })
    );
  }
}

export default function* taskPageSaga() {
  yield takeLatest(CREATE_TASK_REQUEST, createTaskRequest);
}
