import { defineMessages } from 'react-intl';

export const scope = 'admins';

export default defineMessages({
  admins: {
    id: `${scope}.title.admins`,
    defaultMessage: 'Admins',
  },
  createAdmin: {
    id: `${scope}.title.create_admin`,
    defaultMessage: 'Create Admin',
  },
  email: {
    id: `${scope}.title.email`,
    defaultMessage: 'Email',
  },
  firstName: {
    id: `${scope}.title.first_name`,
    defaultMessage: 'First Name',
  },
  lastName: {
    id: `${scope}.title.last_name`,
    defaultMessage: 'Last Name',
  },
  createdAt: {
    id: `${scope}.title.created_at`,
    defaultMessage: 'Created At',
  },
  createdBy: {
    id: `${scope}.title.created_vy`,
    defaultMessage: 'Created By',
  },
  noAdmins: {
    id: `${scope}.text.no_admins`,
    defaultMessage: 'No Admins',
  },
  role: {
    id: `${scope}.text.role`,
    defaultMessage: 'Role',
  },
  password: {
    id: `${scope}.text.password`,
    defaultMessage: 'Password',
  },
  adminCreated: {
    id: `${scope}.text.admin_created`,
    defaultMessage: 'Admin successfully created',
  },
  adminDeleted: {
    id: `${scope}.text.admin_deleted`,
    defaultMessage: 'Admin successfully deleted',
  },
  areYouSureDeleteAdmin: {
    id: `${scope}.text.are_you_sure_delete_admin`,
    defaultMessage: 'Are you sure you want to delete this admin?',
  },
  adminEdited: {
    id: `${scope}.text.admin_edited`,
    defaultMessage: 'Admin successfully edited',
  },
});
