import produce from 'immer';
import { FETCH_LOGS_SUCCESS, LogType } from './constants';

export const initialState = {
  logs: {
    data: [],
    perPage: null,
    page: null,
    total: null,
    logType: LogType.Exception,
    search: '',
  },
};

const logsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_LOGS_SUCCESS:
        draft.logs.data = action.logs.data;
        draft.logs.perPage = action.logs.perPage;
        draft.logs.page = action.logs.page;
        draft.logs.total = action.logs.total;
        draft.logs.logType = action.logs.logType;
        draft.logs.search = action.search;
        break;
      default:
        break;
    }
  });

export default logsReducer;
