import React, { useEffect, useState } from 'react';
import DashboardLayout from 'components/Layout/DashboardLayout';
import { useInjectSaga } from 'utils/injectSaga';
import saga from './saga';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import { canActivate } from 'utils/permissions';
import { CREATE_AI_PROMPTS } from 'permisssions';
import { Modal } from '@material-ui/core';
import styles from './AiPage.module.scss';
import { Form, Formik, useFormik } from 'formik';
import Button from 'components/Buttons/Button';
import { InputField, SelectField } from 'components/Forms';
import Yup from 'utils/validations';
import { useInjectReducer } from 'utils/injectReducer';
import reducer from './reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  makeSelectCreatePromptPending,
  makeSelectFetchPrompts,
  makeSelectFetchPromptsPending,
} from './selectors';
import { creatAiPromptRequest, fetchAiPromptsRequest } from './actions';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner.presentation';

const key = 'ai';

const AiPage = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const dispatch = useDispatch();

  const [modalVisible, setModalVisible] = useState(false);
  const createPending = useSelector(makeSelectCreatePromptPending());
  const fetchPromptsPending = useSelector(makeSelectFetchPromptsPending());
  const prompts = useSelector(makeSelectFetchPrompts());

  const handleCreatePrompt = (data) => {
    dispatch(
      creatAiPromptRequest(
        data,
        () => {
          setModalVisible(false);
        },
        () => {}
      )
    );
  };

  useEffect(() => {
    dispatch(fetchAiPromptsRequest());
  }, [dispatch]);

  return (
    <DashboardLayout>
      <DashboardMainHeader
        titleMain="AI Prompts"
        btnText="Add new prompt"
        onClick={() => setModalVisible(true)}
        canAccess={canActivate(CREATE_AI_PROMPTS)}
      />

      {fetchPromptsPending ? (
        <LoadingSpinner className={styles.spinner} />
      ) : (
        prompts.map((prompt) => {
          return (
            <div key={prompt.id} className={styles.card}>
              <div>Key: {prompt.key}</div>
              <div>
                <p>Value: {prompt.value}</p>
              </div>
            </div>
          );
        })
      )}

      <Modal open={modalVisible} onClose={() => setModalVisible(false)}>
        <div className={styles.modalWrapper}>
          <div className={styles.modalInner}>
            <div className={styles.modalTop}>
              <p className={styles.title}>Add new prompt</p>
            </div>
            <div className={styles.modalContent}>
              <div className={styles.formWrapper}>
                <div className={styles.formWrap}>
                  <div className={styles.inputWrapperSm}>
                    <Formik
                      initialValues={{
                        value: '',
                        key: '',
                      }}
                      validationSchema={Yup.object().shape({
                        value: Yup.string().trim().required(),
                        key: Yup.string().trim().required(),
                      })}
                      onSubmit={handleCreatePrompt}
                      enableReinitialize
                    >
                      {({ isValid, dirty, setFieldValue }) => {
                        return (
                          <Form className={styles.form}>
                            <SelectField
                              name="key"
                              options={[
                                {
                                  id: 'CAMPAIGN_BASIC_DETAILS',
                                  label: 'Campaign basic details',
                                },
                                {
                                  id: 'CAMPAIGN_SOCIAL_ACCOUNTS',
                                  label: 'Campaign social accounts',
                                },
                                {
                                  id: 'CAMPAIGN_OBJECTIVES',
                                  label: 'Campaign objectives',
                                },
                                {
                                  id: 'BULK_CREATE_STEP_1',
                                  label: 'Bulk create step 1',
                                },
                                {
                                  id: 'BULK_CREATE_STEP_2',
                                  label: 'Bulk create step 2',
                                },
                                {
                                  id: 'CREATE_BRAND_STORY',
                                  label: 'Create brand story',
                                },
                                {
                                  id: 'GENERATE_TASK_DESCRIPTIONS',
                                  label: 'Generate task descriptions',
                                },
                                {
                                  id:
                                    'GENERATE_TASK_DESCRIPTIONS_URLS_HASHTAGS_MENTIONS',
                                  label:
                                    'Generate task descriptions, social media urls, hashtags & mentions',
                                },
                                {
                                  id: 'CAMPAIGN_EVALUATION_DECISION',
                                  label: 'Evaluate campaign',
                                },
                                {
                                  id: 'USER_CUSTOM_BRAND',
                                  label: 'User custom brand',
                                },
                              ]}
                              label="Prompt Key"
                              placeholder="Prompt Key"
                              onChange={(event) => {
                                setFieldValue('key', event.target.value);
                                const prompt = prompts.find(
                                  (p) => p.key === event.target.value
                                );
                                if (prompt) {
                                  setFieldValue('value', prompt.value);
                                }
                              }}
                              required
                            />

                            <InputField
                              name="value"
                              type="text"
                              component="textarea"
                              label="Prompt Value"
                              placeholder="Enter Prompt Value"
                              required
                              textareaExtraLarge
                            />

                            <Button
                              alt
                              disabled={!isValid || !dirty || createPending}
                              type="submit"
                              isLarge
                              className={styles.button}
                            >
                              Submit
                            </Button>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </DashboardLayout>
  );
};

export default AiPage;
