import React from 'react';
import LoginPage from 'containers/LoginPage/Loadable';
import AdvertisersPage from 'containers/AdvertisersPage/Loadable';
import SingleAdvertiserPage from 'containers/SingleAdvertiserPage/Loadable';
import CampaignsPage from 'containers/CampaignsPage';
// import DefaultSupervisorTasks from 'containers/SupervisorTasksPage';
import UsersPage from 'containers/UsersPage';
import SingleTask from 'containers/SingleSupervisorTaskPage';
import SingleCampaignPage from 'containers/SingleCampaignPage';
import SingleUser from '../../containers/SingleUserPage/Loadable';
import BusinessRules from '../../containers/BusinessRulesPage';
import LogsPage from 'containers/LogsPage';
import ObjectivesPage from 'containers/ObjectivesPage';
import SingleObjectivePage from 'containers/SingleObjectivePage';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import PublicRoute from 'containers/PublicRoute';
import PrivateRoute from 'containers/PrivateRoute';
import ChangePassword from 'containers/ChangePassword/index';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ADMIN_ROLES,
  ADMINISTRATORS,
  ADVERTISERS,
  AI,
  AUDIT_LOGS,
  BUSINESS_RULES,
  CAMPAIGNS,
  CHANGE_PASSWORD,
  COUPONS,
  CREATE_ADVERTISER,
  CREATE_EYCROWD_TASK_PAGE,
  CREATE_OBJECTIVE,
  CREATE_TASK,
  CREATE_USER,
  DASHBOARD,
  EYCROWD_TASKS,
  // FEED_ITEMS_PRIORITY,
  FORGOT_PASSWORD,
  LOGIN,
  LOGS,
  OBJECTIVES,
  RESET_PASSWORD,
  SINGLE_ADVERTISER,
  SINGLE_CAMPAIGN,
  SINGLE_STAFF_ACTIVITY,
  SINGLE_USER,
  STAFF_ACTIVITY,
  STARTER_CAMPAIGN,
  STARTER_CAMPAIGN_CONTENT,
  TEMPLATES,
  TOURS,
  TRANSFER,
  USERS,
  BULKCAMPAIGNS,
  AI_PROMPT_LOGS,
  NOTICE_CARDS,
  WEBSITE_FORMS,
} from 'routes';
import EyCrowdTasks from 'containers/EyCrowdTasksPage';
import SingleEyCrowdTaskPage from 'containers/SingleEyCrowdTaskPage';
import StarterCampaignPage from 'containers/StarterCampaignPage';
// import SalesExampleCampaigns from 'containers/SalesExampleCampaings';
import TemplatesPage from 'containers/TemplatesPage';
import TourCampaignPage from 'containers/TourCampaignPage';
// import AnalyticsPage from 'containers/AnalyticsPage';
import TransferMoneyPage from 'containers/TransferMoneyScreen';
import AdminsRolesPage from 'containers/AdminsRolesPage';

import CouponsPage from 'containers/CouponsPage';
import ResetPassword from 'containers/ResetPassword';
import ForgotPassword from 'containers/ForgotPassword';
import StaffActivityPage from 'containers/StaffActivityPage';
import SingleStaffActivityPage from 'containers/SingleStaffActivityPage';
import AuditLogsPage from '../AuditLogsPage';
import StarterContentPage from 'containers/StarterContentPage';
import AdminsPage from 'containers/AdminsPage';
import FeedItemsPriorityPage from '../FeedItemPriority';
import AiPage from 'containers/AiPage';
import BulkCampaignsPage from 'containers/BulkCampaignsPage';
import AiPromptLogsPage from 'containers/AiPromptLogsPage';
import NoticeCardsPage from 'containers/NoticeCardsPage';
import WebsiteFormsPage from 'containers/WebsiteFormsPage';

export default function Routes() {
  return (
    <Switch>
      <PrivateRoute exact path={DASHBOARD}>
        <Redirect to={ADVERTISERS} />
      </PrivateRoute>
      <PrivateRoute exact path={ADVERTISERS} component={AdvertisersPage} />
      <PrivateRoute exact path={ADMINISTRATORS} component={AdminsPage} />
      <PrivateRoute
        exact
        path={SINGLE_ADVERTISER}
        component={SingleAdvertiserPage}
      />
      <PrivateRoute
        exact
        path={CREATE_ADVERTISER}
        component={SingleAdvertiserPage}
      />
      <PrivateRoute exact path={CAMPAIGNS} component={CampaignsPage} />
      <PrivateRoute exact path={WEBSITE_FORMS} component={WebsiteFormsPage} />
      <PublicRoute exact path={LOGIN} component={LoginPage} />
      <PrivateRoute exact path={USERS} component={UsersPage} />
      <PrivateRoute
        exact
        path={SINGLE_CAMPAIGN}
        component={SingleCampaignPage}
      />
      <PrivateRoute exact path={SINGLE_USER} component={SingleUser} />
      <PrivateRoute exact path={CREATE_USER} component={SingleUser} />
      <PrivateRoute exact path={CREATE_TASK} component={SingleTask} />
      <PrivateRoute exact path={NOTICE_CARDS} component={NoticeCardsPage} />
      <PrivateRoute exact path={BUSINESS_RULES} component={BusinessRules} />
      {/* <PrivateRoute
        exact
        path={SUPERVISOR_TASKS}
        component={DefaultSupervisorTasks}
      /> */}
      <PrivateRoute exact path={LOGS} component={LogsPage} />
      <PrivateRoute exact path={AI_PROMPT_LOGS} component={AiPromptLogsPage} />
      {/* <PrivateRoute
        exact
        path={FEED_ITEMS_PRIORITY}
        component={FeedItemsPriorityPage}
      /> */}
      <PrivateRoute exact path={AUDIT_LOGS} component={AuditLogsPage} />
      <PrivateRoute exact path={EYCROWD_TASKS} component={EyCrowdTasks} />
      <PrivateRoute
        exact
        path={CREATE_EYCROWD_TASK_PAGE}
        component={SingleEyCrowdTaskPage}
      />
      <PrivateRoute exact path={OBJECTIVES} component={ObjectivesPage} />
      <PrivateRoute
        exact
        path={CREATE_OBJECTIVE}
        component={SingleObjectivePage}
      />
      <PrivateRoute
        exact
        path={STARTER_CAMPAIGN}
        component={StarterCampaignPage}
      />
      {/* <PrivateRoute
        exact
        path={SALES_EXAMPLE_CAMPAIGNS}
        component={SalesExampleCampaigns}
      /> */}
      <PrivateRoute exact path={TOURS} component={TourCampaignPage} />
      <PrivateRoute exact path={TEMPLATES} component={TemplatesPage} />
      {/* <PrivateRoute exact path={ANALYTICS} component={AnalyticsPage} /> */}
      <PrivateRoute exact path={TRANSFER} component={TransferMoneyPage} />
      <PrivateRoute exact path={ADMIN_ROLES} component={AdminsRolesPage} />
      <PrivateRoute exact path={STAFF_ACTIVITY} component={StaffActivityPage} />
      <PrivateRoute
        exact
        path={SINGLE_STAFF_ACTIVITY}
        component={SingleStaffActivityPage}
      />
      <PrivateRoute exact path={COUPONS} component={CouponsPage} />
      <PrivateRoute exact path={COUPONS} component={CouponsPage} />
      <PrivateRoute exact path={CHANGE_PASSWORD} component={ChangePassword} />
      <PrivateRoute
        exact
        path={STARTER_CAMPAIGN_CONTENT}
        component={StarterContentPage}
      />
      <Route exact path={FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={RESET_PASSWORD} component={ResetPassword} />
      <Route exact path={AI} component={AiPage} />
      <Route exact path={BULKCAMPAIGNS} component={BulkCampaignsPage} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  );
}
