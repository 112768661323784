import {
  CREATE_AI_PROMPT_ERROR,
  CREATE_AI_PROMPT_REQUEST,
  CREATE_AI_PROMPT_SUCCESS,
  FETCH_AI_PROMPTS_ERROR,
  FETCH_AI_PROMPTS_REQUEST,
  FETCH_AI_PROMPTS_SUCCESS,
} from './constants';

export function creatAiPromptRequest(payload, onSuccess, onError) {
  return {
    type: CREATE_AI_PROMPT_REQUEST,
    payload,
    meta: { onSuccess, onError },
  };
}

export function creatAiPromptSuccess(payload) {
  return {
    type: CREATE_AI_PROMPT_SUCCESS,
    payload,
  };
}

export function creatAiPromptError() {
  return {
    type: CREATE_AI_PROMPT_ERROR,
  };
}

export function fetchAiPromptsRequest() {
  return {
    type: FETCH_AI_PROMPTS_REQUEST,
  };
}

export function fetchAiPromptsSuccess(payload) {
  return {
    type: FETCH_AI_PROMPTS_SUCCESS,
    payload,
  };
}

export function fetchAiPromptsError() {
  return {
    type: FETCH_AI_PROMPTS_ERROR,
  };
}
