import DashboardLayout from 'components/Layout/DashboardLayout';
import React, { useEffect, useState } from 'react';
import styles from './AdminsRolesPage.module.scss';
import AsyncSelect from 'react-select/async';
import { getApi } from 'utils/request';
import Button from 'components/Buttons/Button';
import { useDispatch, useSelector } from 'react-redux';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import toasterVariants from 'constants/toasterVariants';

const AdminsRolesPage = () => {
  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [adminOptions, setAdminOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);

  const token = useSelector((state) => state.app.token);

  const dispatch = useDispatch();

  const fetchAdmins = async () => {
    const api = getApi();
    const response = await api.get(`admins?page=1&perPage=2500`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const retItems = response.data.map((admin) => {
      return {
        value: admin,
        label: admin.email,
      };
    });
    return retItems;
  };

  const fetchRoles = async () => {
    const api = getApi();
    const response = await api.get(`permissions/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const retItems = response?.map((role) => {
      return {
        value: role,
        label: role,
      };
    });
    return retItems;
  };

  const onSubmit = async () => {
    const api = getApi();

    const addPermissionPayload = {
      role: selectedRole,
      adminId: selectedAdmin.id,
    };

    try {
      await api.put(`admins`, addPermissionPayload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(
        enqueueSnackbar({
          message: 'Role set',
          variant: toasterVariants.success,
        })
      );
    } catch (err) {
      dispatch(
        enqueueSnackbar({
          message: err?.data?.message,
          variant: toasterVariants.error,
        })
      );
    }
  };

  useEffect(() => {
    const fetchInitOptions = async () => {
      const users = await fetchAdmins('');
      setAdminOptions(users);
      const roles = await fetchRoles('');
      setRoleOptions(roles);
    };
    fetchInitOptions();
  }, []);

  return (
    <DashboardLayout>
      <div className={styles.flexColumn}>
        <div className={styles.inputWrapperSm}>
          <p>Pick an admin</p>
          <AsyncSelect
            onInputChange={(e) => {
              fetchAdmins(e);
            }}
            loadOptions={fetchAdmins}
            defaultOptions={adminOptions}
            onChange={(e) => {
              setSelectedAdmin(e.value);
              fetchRoles('');
            }}
            styles={{
              container: (provided) => ({
                ...provided,
                width: '100%',
              }),
            }}
          />
        </div>
        <div className={styles.inputWrapperSm}>
          <p>Pick a role</p>
          <AsyncSelect
            isSearchable={false}
            isDisabled={!selectedAdmin}
            loadOptions={fetchRoles}
            defaultOptions={roleOptions}
            onChange={(e) => {
              setSelectedRole(e.value);
            }}
            styles={{
              container: (provided) => ({
                ...provided,
                width: '100%',
              }),
            }}
          />
        </div>
        <div className={styles.buttonWrap}>
          <Button
            type="submit"
            disabled={selectedAdmin === null || selectedRole === null}
            title="Submit"
            alt
            className={styles.submitButton}
            onClick={() => {
              onSubmit();
            }}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default AdminsRolesPage;
