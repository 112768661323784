import React, { useState, useRef, cloneElement } from 'react';
import { useOutsideClick } from 'utils/useOutsideClick';
import styles from './DropdownMenu.module.scss';
import PropTypes from 'prop-types';

const DropdownMenu = ({ closeOnClick = true, children, buttonContent }) => {
  const handleCloseDropdown = () => setIsOpen(false);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => {
    setIsOpen(false);
  });
  return (
    <div className={styles.container} ref={ref}>
      <button onClick={() => setIsOpen(!isOpen)} className={styles.button}>
        {buttonContent}
      </button>
      <div className={isOpen ? styles.dropdownShown : styles.dropdown}>
        <ul>
          {React.Children.map(children, (child) =>
            cloneElement(child, {
              onClick: () => {
                closeOnClick && handleCloseDropdown();
              },
            })
          )}
        </ul>
      </div>
    </div>
  );
};

DropdownMenu.Item = ({ onClick, children }) => {
  return <li onClick={onClick}>{children}</li>;
};

DropdownMenu.propTypes = {
  children: PropTypes.node,
};

export default DropdownMenu;
