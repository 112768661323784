import produce from 'immer';
import { FETCH_WEBSITE_FORMS_SUCCESS } from './constants';

export const initialState = {
  websiteForms: {
    data: [],
    perPage: null,
    page: null,
    total: null,
  },
};

/* eslint-disable default-case */
const websiteFormsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_WEBSITE_FORMS_SUCCESS:
        draft.websiteForms.perPage = action.perPage;
        draft.websiteForms.page = action.page;
        draft.websiteForms.total = action.total;
        draft.websiteForms.data = action.data;
        break;
    }
  });

export default websiteFormsReducer;
