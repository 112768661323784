import React from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from './reducer';
import saga from './saga';
import DashboardLayout from 'components/Layout/DashboardLayout';
import DashboardMainHeader from 'components/Layout/DashboardLayout/DashboardMainHeader';
import { createTaskRequest } from './actions';
import SingleSupervisorTaskForm from './SingleSupervisorTaskForm';
import { useHistory } from 'react-router-dom';

const key = 'task';

function SingleSupervisorTaskPage() {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const history = useHistory();

  const dispatch = useDispatch();

  const handleSubmit = (userData) => {
    dispatch(createTaskRequest(userData));
  };

  return (
    <DashboardLayout>
      <DashboardMainHeader cancel onCancel={() => history.goBack()} />
      <SingleSupervisorTaskForm onSubmit={handleSubmit} />
    </DashboardLayout>
  );
}

export default SingleSupervisorTaskPage;
