import { CREATE_USER_SUCCESS } from 'containers/SingleUserPage/constants';
import produce from 'immer';
import {
  APPROVE_USERS_ERROR,
  APPROVE_USERS_SUCCESS,
  FETCH_USERS_LIST_SUCCESS,
} from './constants';

export const initialState = {
  users: {
    data: [],
    perPage: null,
    page: null,
    total: null,
    search: '',
    sortBy: '',
    order: '',
  },
  requestSent: false,
};

/* eslint-disable default-case */
const usersListReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case FETCH_USERS_LIST_SUCCESS:
        draft.users.perPage = action.users.perPage;
        draft.users.page = action.users.page;
        draft.users.total = action.users.total;
        draft.users.search = action.search;
        draft.users.sortBy = action.sortBy;
        draft.users.order = action.order;
        draft.users.data = action.users.data;
        // if (action.users.page === 1) {
        //   draft.users.data = action.users.data;
        // } else {
        //   draft.users.data = [...draft.users.data, ...action.users.data];
        // }
        draft.requestSent = false;
        break;
      case CREATE_USER_SUCCESS:
        const user = {
          ...action.user,
          dateOfBirth: new Date(action.user.dateOfBirth),
        };
        draft.users.data.push(user);
        break;
      case APPROVE_USERS_SUCCESS:
        draft.requestSent = true;
        break;
      case APPROVE_USERS_ERROR:
        draft.requestSent = true;
        break;
    }
  });

export default usersListReducer;
