import { FETCH_LOGS_REQUEST, FETCH_LOGS_SUCCESS } from './constants';

export function fetchLogsRequest(page, perPage, type, search) {
  return {
    type: FETCH_LOGS_REQUEST,
    page,
    perPage,
    logType: type,
    search,
  };
}

export function fetchLogsSuccess(logs, type, search) {
  return {
    type: FETCH_LOGS_SUCCESS,
    logs: logs,
    logType: type,
    search,
  };
}
